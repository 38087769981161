import * as React from 'react';
import { Component } from 'react';
import {
  ColumnLayout,
  ExpandableSection,
  Icon,
  Spinner,
  Button,
  Container,
  Header,
  SpaceBetween,
} from '@amzn/awsui-components-react-v3';

import {
  deleteResourceGroup,
  getResourceGroup,
  getBootstrapAction,
  updateResourceGroup,
} from '../../../api/resourcesmanager';
import {
  ErrorAlert,
  LabeledText,
  labeledTextFromList,
  RMPageHeader,
  Guardrail_UNBOUND,
  coloredStatus,
  AuditLogsCard,
  s3url,
} from '../components';
import { Redirect } from 'react-router-dom';
import * as utils from './resourceGroupUtils';
import { Page } from '../../../routes/Paths';
import { ResourcesTable } from './resourcesTable';
import { dateString, set } from '../helpers';

export interface resourceGroupDetailProps {
  setContentType: any;
  match: any;
  activeGroup: string;
}

export interface resourceGroupDetailState {
  loadingRGs: boolean;
  resourceGroup: object;
  bootstrapActions: object;
  redirect: string;
  redirectParams: any;
  contentType: string;
  error: object;
  guardrail: any;
}

export class ResourceGroupDetails extends Component<
  resourceGroupDetailProps,
  resourceGroupDetailState
> {
  state = {
    error: undefined,
    loadingRGs: true,
    resourceGroup: undefined,
    redirect: undefined,
    redirectParams: undefined,
    contentType: undefined,
    guardrail: null,
    bootstrapActions: {},
  };

  componentDidMount = async () => {
    this.props.setContentType('default');
    await this.handleRefresh();
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.activeGroup !== this.props.activeGroup) {
      await this.handleRefresh();
    }
  };

  getRG = async () => {
    const resourcegroup = await getResourceGroup({
      id: this.props.match.params.id,
    });

    var bootstrapActions = Object.fromEntries(
      resourcegroup.emrGroupConfig.bootstrapActions.map((id) => [id, null]),
    );

    this.setState({
      resourceGroup: resourcegroup,
      loadingRGs: false,
      bootstrapActions,
    });

    Object.keys(bootstrapActions).map(async (id) => {
      const bsa = await getBootstrapAction({ id });
      var bsas = new Object({ ...this.state.bootstrapActions });
      this.setState({ bootstrapActions: set(bsas, [id], bsa) });
    });
  };

  /**
   * Asynchronously fetch and store resource groups.
   */
  handleRefresh = async () => {
    this.setState({ loadingRGs: true });
    try {
      this.getRG();
    } catch (err) {
      this.setState({ error: { ...err, while: 'LOADING RESOURCE GROUP' } });
    }
  };

  /**
   * [UNGUARDED]: Calls the deleteResourceGroup API on the current resource group ID and redirects upon success.
   */
  deleteRG_UNGUARDED = async () => {
    try {
      const response = await deleteResourceGroup({
        id: this.props.match.params.id,
      });

      if (response.successful) {
        this.setState({ redirect: Page.RESOURCEGROUPS });
      } else {
        this.setState({
          error: {
            ...response,
            code: 'ResponseNotSuccessful',
            while: 'DELETING RESOURCE GROUP',
          },
        });
      }
    } catch (err) {
      this.setState({ error: { ...err, while: 'DELETING RESOURCE GROUP' } });
    }
  };

  /**
   * A guardrailed wrapper around the Delete Resource Groups functions.
   */
  deleteRG = async () => {
    this.setState({
      guardrail: {
        header: `DELETE ${this.props.match.params.id}?`,
        action: this.deleteRG_UNGUARDED,
      },
    });
  };

  /**
   * Redirects user to the Update Resource Group page for the selected resource group ID.
   */
  updateRG = () => {
    this.setState({
      redirect: Page.UPDATE_RESOURCEGROUP.replace(
        ':id',
        this.props.match.params.id,
      ),
    });
  };

  /**
   * Redirects user to the Create Resource Group page, and auto-populates the fields with the current resource group's configuration.
   */
  cloneRG = () => {
    this.setState({
      redirect: Page.CREATE_RESOURCEGROUP,
      redirectParams: { values: this.state.resourceGroup },
    });
  };

  clearFailureStat = async () => {
    try {
      await updateResourceGroup({
        id: this.state.resourceGroup.id,
        status: this.state.resourceGroup.status,
      });
      this.handleRefresh();
    } catch (err) {
      this.setState({
        error: { ...err, while: 'CLEAR FAILURE STAT FOR RESOURCE GROUP' },
      });
    }
  };

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.redirect,
            state: this.state.redirectParams,
          }}
        />
      );
    }

    const copiable = true;
    const bsaEntries = Object.entries(this.state.bootstrapActions);

    return (
      <>
        {ErrorAlert(this.state.error)}
        {Guardrail_UNBOUND.bind(this)()}

        <SpaceBetween size='l'>
          <RMPageHeader
            buttons={[
              {
                text: 'Delete',
                onItemClick: this.deleteRG,
                disabled:
                  !this.state.resourceGroup?.status ||
                  this.state.resourceGroup?.status === 'DELETED',
              },
              {
                text: 'Edit',
                onItemClick: this.updateRG,
                disabled:
                  !this.state.resourceGroup?.status ||
                  this.state.resourceGroup?.status === 'DELETED',
              },
              {
                text: 'Clone',
                onItemClick: this.cloneRG,
              },
            ]}
            subheader={this.state.resourceGroup?.name}
          />

          <Container
            header={<Header variant={'h2'}>Resource group details</Header>}
          >
            <SpaceBetween size='xxl'>
              <ColumnLayout columns={2}>
                {labeledTextFromList(
                  [
                    {
                      label: 'Resource group ID',
                      value: this.state.resourceGroup?.id,
                      copiable,
                    },
                    {
                      label: 'Name',
                      value: this.state.resourceGroup?.name,
                    },
                    {
                      label: 'Owner (Group ID)',
                      value: this.state.resourceGroup?.groupId,
                      copiable,
                    },
                    {
                      label: 'Description',
                      value: this.state.resourceGroup?.description,
                    },
                    {
                      label: 'Status',
                      value: coloredStatus(this.state.resourceGroup?.status),
                    },
                    {
                      label: 'Status reason',
                      value: this.state.resourceGroup?.statusReason,
                    },
                  ],
                  this.state.loadingRGs,
                )}
              </ColumnLayout>

              {this.state.resourceGroup?.failureStats &&
              this.state.resourceGroup?.failureStats.consecutiveFailureCount >
                0 ? (
                <ExpandableSection
                  header={[
                    <span className='awsui-util-status-negative'>
                      <Icon name='status-negative'></Icon>
                    </span>,
                    ` Failures (${this.state.resourceGroup?.failureStats.consecutiveFailureCount})`,
                  ]}
                  expanded={true}
                >
                  <ColumnLayout columns={2}>
                    {labeledTextFromList(
                      [
                        {
                          label:
                            `EC2 subnet ID's tried (` +
                            this.state.resourceGroup?.failureStats
                              .ec2SubnetIdsTried.length +
                            ')',
                          value: (
                            <ul>
                              {this.state.resourceGroup?.failureStats.ec2SubnetIdsTried.map(
                                (id) => (
                                  <li>
                                    <LabeledText value={id} copiable={true} />
                                  </li>
                                ),
                              )}
                            </ul>
                          ),
                        },
                        {
                          label: 'Failed resource ID',
                          value:
                            this.state.resourceGroup?.failureStats
                              .lastFailedClusterId,
                          copiable,
                        },
                        {
                          label: 'Reason',
                          value:
                            this.state.resourceGroup?.failureStats
                              .lastFailureReason,
                        },
                        {
                          label: 'Time',
                          value: dateString(
                            this.state.resourceGroup?.failureStats
                              .lastFailureTime,
                          ),
                        },
                      ],
                      this.state.loadingRGs,
                    )}
                    <Button onClick={this.clearFailureStat}>
                      Reset Failures
                    </Button>
                  </ColumnLayout>
                </ExpandableSection>
              ) : (
                <LabeledText
                  label='No failures detected'
                  loading={this.state.loadingRGs}
                />
              )}

              <ExpandableSection header='More details'>
                <ColumnLayout columns={2}>
                  {labeledTextFromList(
                    [
                      {
                        label: 'Created by',
                        value: this.state.resourceGroup?.createdBy,
                        copiable,
                      },
                      {
                        label: 'Create date',
                        value: dateString(this.state.resourceGroup?.createDate),
                      },
                      {
                        label: 'Updated by',
                        value: this.state.resourceGroup?.updatedBy,
                        copiable,
                      },
                      {
                        label: 'Update date',
                        value: dateString(this.state.resourceGroup?.updateDate),
                      },
                    ],
                    this.state.loadingRGs,
                  )}
                </ColumnLayout>
              </ExpandableSection>

              <ExpandableSection header='Notification configuration'>
                <pre>
                  <LabeledText
                    value={JSON.stringify(
                      this.state.resourceGroup?.notification,
                      null,
                      2,
                    )}
                    loading={this.state.loadingRGs}
                    copiable
                  />
                </pre>
              </ExpandableSection>
            </SpaceBetween>
          </Container>

          <ResourcesTable
            activeGroup={this.props.activeGroup}
            resourceGroupId={this.props.match.params.id}
            redirect={(rd, rdp = null) =>
              this.setState({ redirect: rd, redirectParams: rdp })
            }
          />

          {this.state.resourceGroup?.type == 'EMR_GROUP' && (
            <ExpandableSection
              header={ <Header variant='h2'> EMR group configuration </Header>}
              variant='container'
              defaultExpanded
            >
              <ColumnLayout columns={3} borders='horizontal'>
                {labeledTextFromList(
                  [
                    {
                      label: 'Account ID',
                      value: this.state.resourceGroup?.emrGroupConfig.accountId,
                      copiable,
                    },
                    {
                      label: 'Region',
                      value: this.state.resourceGroup?.emrGroupConfig.region,
                    },
                    {
                      label: `Subnet ID's`,
                      value:
                        this.state.resourceGroup?.emrGroupConfig.ec2SubnetId ||
                        '' +
                          this.state.resourceGroup?.emrGroupConfig.ec2SubnetIds,
                      copiable,
                    },
                    {
                      label: 'Min clusters',
                      value:
                        this.state.resourceGroup?.emrGroupConfig.minClusters ||
                        '0',
                    },
                    {
                      label: 'Max clusters',
                      value:
                        this.state.resourceGroup?.emrGroupConfig.maxClusters,
                    },
                    {
                      label: 'Auto scaling',
                      value: this.state.resourceGroup?.emrGroupConfig
                        .isAutoScalingEnabled
                        ? 'On'
                        : 'Off',
                    },
                    {
                      label: 'Core inst. type',
                      value:
                        this.state.resourceGroup?.emrGroupConfig
                          .coreInstanceType,
                      copiable,
                    },
                    {
                      label: 'Core inst. count',
                      value:
                        this.state.resourceGroup?.emrGroupConfig
                          .coreInstanceCount,
                    },
                    {
                      label: 'Core inst. market',
                      value:
                        this.state.resourceGroup?.emrGroupConfig.coreMarket,
                    },
                    {
                      label: 'Master inst. type',
                      value:
                        this.state.resourceGroup?.emrGroupConfig
                          .masterInstanceType,
                      copiable,
                    },
                    {
                      label: 'Master inst. count',
                      value:
                        this.state.resourceGroup?.emrGroupConfig
                          .masterInstanceCount,
                    },
                    {
                      label: 'Master inst. market',
                      value:
                        this.state.resourceGroup?.emrGroupConfig.masterMarket,
                    },
                    {
                      label: 'Management role ARN',
                      value:
                        this.state.resourceGroup?.emrGroupConfig
                          .emrManagementRoleArn,
                      copiable,
                    },
                    {
                      label: 'Job flow role',
                      value:
                        this.state.resourceGroup?.emrGroupConfig.jobFlowRole,
                      copiable,
                    },
                    {
                      label: 'Service role',
                      value:
                        this.state.resourceGroup?.emrGroupConfig.serviceRole,
                      copiable,
                    },
                    {
                      label: 'S3 log URI',
                      value: this.state.resourceGroup?.emrGroupConfig.s3LogUri,
                      url: s3url(
                        this.state.resourceGroup?.emrGroupConfig.s3LogUri,
                      ),
                      target: '_blank',
                      copiable,
                    },
                    {
                      label: 'Security configuration',
                      value:
                        this.state.resourceGroup?.emrGroupConfig
                          .securityConfiguration,
                      copiable,
                    },
                    {
                      label: 'Release label',
                      value:
                        this.state.resourceGroup?.emrGroupConfig.releaseLabel,
                      copiable,
                    },
                    {
                      label: 'Refresh every',
                      value: `${
                        this.state.resourceGroup?.emrGroupConfig
                          .clusterRefreshDays || '30'
                      } days (approx. ${utils.nextRefresh(
                        this.state.resourceGroup,
                      )} remaining)`,
                    },
                    {
                      label: 'Refresh days',
                      value: utils.chunkifyLists(
                        'clusterRefreshWeekDaysInUTC',
                        this.state.resourceGroup?.emrGroupConfig
                          .clusterRefreshWeekDaysInUTC,
                        ['emrGroupConfig'],
                      ),
                    },
                    {
                      label: 'Refresh hours',
                      value: utils.chunkifyLists(
                        'clusterRefreshDayHoursInUTC',
                        this.state.resourceGroup?.emrGroupConfig
                          .clusterRefreshDayHoursInUTC,
                        ['emrGroupConfig'],
                      ),
                    },
                    {
                      label: 'Release label',
                      value:
                        this.state.resourceGroup?.emrGroupConfig.releaseLabel,
                      copiable,
                    },
                    {
                      label: 'Custom AMI ID',
                      value:
                        this.state.resourceGroup?.emrGroupConfig.customAmiId,
                      copiable,
                    },
                    {
                      label: 'Chronicle bootstrap',
                      value: this.state.resourceGroup?.emrGroupConfig
                        .installChronicle ? (
                        <span className='awsui-util-status-positive'>
                          <Icon name='status-positive' /> On
                        </span>
                      ) : (
                        <span className='awsui-util-status-inactive'>
                          <Icon name='status-stopped' /> Off
                        </span>
                      ),
                    },
                    {
                      label: 'SSM patch',
                      value: this.state.resourceGroup?.emrGroupConfig
                        .installSSMPatch ? (
                        <span className='awsui-util-status-positive'>
                          <Icon name='status-positive' /> On
                        </span>
                      ) : (
                        <span className='awsui-util-status-inactive'>
                          <Icon name='status-stopped' /> Off
                        </span>
                      ),
                    },
                  ],
                  this.state.loadingRGs,
                )}
              </ColumnLayout>

              <ColumnLayout>
                {bsaEntries.length > 0 ? (
                  <LabeledText
                    label={`Bootstrap actions (${bsaEntries.length})`}
                    value={
                      <ul>
                        {bsaEntries.map(([id, bsa]) => (
                          <li>
                            <ColumnLayout columns={bsa ? 2 : 1}>
                              <LabeledText
                                label={bsa ? bsa['name'] : <Spinner />}
                                value={id}
                                url={Page.BOOTSTRAPACTION_DETAILS.replace(
                                  ':id',
                                  id,
                                )}
                                target={'_blank'}
                                copiable
                              />
                              <LabeledText
                                label='S3 file location'
                                value={
                                  bsa ? bsa['s3FileLocation'] : <Spinner />
                                }
                                copiable
                              />
                            </ColumnLayout>
                          </li>
                        ))}
                      </ul>
                    }
                  />
                ) : (
                  <LabeledText
                    label={'Bootstrap actions'}
                    value={'No boostrap actions found'}
                  />
                )}
              </ColumnLayout>
            </ExpandableSection>
          )}
          <AuditLogsCard
            auditLogs={this.state.resourceGroup?.auditLogs}
            loading={this.state.loadingRGs}
          />
        </SpaceBetween>
      </>
    );
  }
}
