import * as React from 'react';
import { useState, useEffect } from 'react';

import {
  deleteResourceGroup,
  listResourceGroups,
  listResources,
  updateResourceGroup,
} from '../../../api/resourcesmanager';

import { Link } from 'react-router-dom';
import { coloredStatus, RMPageHeader } from '../components';
import * as utils from './resourceGroupUtils';
import { Redirect } from 'react-router-dom';
import { Page } from '../../../routes/Paths';
import { MyTemplates } from '../templates/myTemplatesTable';
import { dateString, permissionToRM } from '../helpers';
import {
  CREATE_RESOURCE_GROUP_ACTION,
  LIST_RESOURCE_GROUP,
} from '../constants';

import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Icon,
  Spinner,
  Table,
  CollectionPreferencesProps,
  Pagination,
  TableProps,
  TextFilter,
  CollectionPreferences,
} from '@amzn/awsui-components-react-v3';
import {
  defaultWrapLinesPreference,
  paginationLabels,
} from 'src/commons/tables';
import { GuardrailUnbound, UnauthorizedModal } from '../components-V3';
import awsdatalakeresourcesmanagerlambda from 'aws-sdk/clients/awsdatalakeresourcesmanagerlambda';

export interface MyResourceGroupsProps {
  setContentType: any;
  activeGroup: string;
  userInfo: any;
}

interface iResourcegroup
  extends awsdatalakeresourcesmanagerlambda.ResourceGroup {
  nextRefresh: any;
  resourceCount: any;
}

export const MyResourceGroups = (props: MyResourceGroupsProps) => {
  const [resourcegroups, setResourcegroups] = useState<iResourcegroup[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [redirectParams, setRedirectParams] = useState({});
  const [guardrail, setGuardrail] = useState(null);
  const [onboardModal, setOnboardModal] = useState(false);
  const [redirect, setRedirect] = useState(undefined);
  const [tableMessage, setTableMessage] = useState('No resource groups');

  const [preferences, setPreferences] =
    useState<CollectionPreferencesProps.Preferences>({
      wrapLines: false,
      pageSize: 10,
    });

  const {
    items,
    collectionProps,
    paginationProps,
    filterProps,
    filteredItemsCount,
  } = useCollection(resourcegroups, {
    filtering: {
      noMatch: '',
      empty: (
        <div className='awsui-util-t-c'>
          <div className='awsui-util-pt-s awsui-util-mb-xs'>
            <b>{tableMessage}</b>
          </div>
          <p className='awsui-util-mb-s'>No resource groups to display.</p>
        </div>
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties: [],
    },
  });

  const rgColumnDefinitions: TableProps.ColumnDefinition<iResourcegroup>[] = [
    {
      id: 'resourceGroupId',
      header: 'Resource group ID',
      cell: (item) => (
        <Link to={Page.RESOURCEGROUP_DETAILS.replace(':id', item.id)}>
          {item.id}
        </Link>
      ),
      minWidth: 325,
    },
    {
      id: 'resourceGroupName',
      header: 'Name',
      cell: (item) => item.name,
      minWidth: 200,
      sortingField: 'name',
    },
    {
      id: 'status',
      header: 'Status',
      cell: (item) => coloredStatus(item.status),
      minWidth: 150,
      sortingField: 'status',
    },
    {
      id: 'nextRefresh',
      header: 'Refresh in',
      cell: (item) => item.nextRefresh,
      minWidth: 130,
      sortingComparator: ({ nextRefresh: a }, { nextRefresh: b }) =>
        parseInt(a) - parseInt(b) ? 1 : -1,
    },
    {
      id: 'resourceCount',
      header: 'Resources',
      cell: (item) => [
        item.resourceCount,
        ' of ',
        item.emrGroupConfig.minClusters,
        item.emrGroupConfig.isAutoScalingEnabled &&
          '-' + item.emrGroupConfig.maxClusters,
      ],
      minWidth: 100,
    },
    {
      id: 'autoscaling',
      header: 'Scaling',
      cell: (item) =>
        item.emrGroupConfig.isAutoScalingEnabled ? (
          <span
            className={
              item.status == 'ENABLED'
                ? 'awsui-util-status-positive'
                : 'awsui-util-status-inactive'
            }
          >
            <Icon name='status-positive' /> On
          </span>
        ) : (
          <span className='awsui-util-status-inactive'>
            <Icon name='status-stopped' /> Off
          </span>
        ),
      minWidth: 95,
    },
    {
      id: 'core',
      header: 'Core instance(s)',
      cell: (item) =>
        `${item.emrGroupConfig.coreInstanceCount} x ${item.emrGroupConfig.coreInstanceType}`,
    },
    {
      id: 'release',
      header: 'Release label',
      cell: (item) => item.emrGroupConfig.releaseLabel,
    },
    {
      id: 'updateDate',
      header: 'Updated',
      cell: (item) => dateString(item.updateDate),
      minWidth: 200,
      sortingField: 'updateDate',
    },
  ];

  useEffect(() => {
    props.setContentType('table');
  }, []);

  useEffect(() => {
    handleRefresh();
  }, [props.activeGroup]);

  const listRGs = async (status) =>
    listResourceGroups({
      groupId: props.activeGroup,
      status,
    });

  const getResourceCount = async (resourceGroup: iResourcegroup) => {
    const { id } = resourceGroup;

    try {
      const { resources } = await listResources({
        groupId: props.activeGroup,
        resourceGroupId: id,
      });

      const resourceCount = resources.filter(
        ({ status }) =>
          status !== 'TERMINATED' && status !== 'READY_TO_RELEASE',
      ).length;

      return resourceCount;
    } catch (err) {
      console.error(
        `Quiet failure on rg.id: ${id} during updateResourceCount: `,
        err,
      );
      return;
    }
  };

  const updateResources = async (
    enabledGroups: iResourcegroup[],
    idx: number = enabledGroups.length - 1,
  ) => {
    const resourceGroup = enabledGroups[idx];
    const resourceCount = await getResourceCount(resourceGroup);

    const updatedResources = [...enabledGroups].map((rg) =>
      rg.id === resourceGroup.id ? { ...rg, resourceCount } : rg,
    );

    if (idx === 0) return updatedResources;
    return await updateResources(updatedResources, idx - 1);
  };

  const fetchRGs = async (status) => {
    const { resourceGroups } = await listRGs(status);

    return resourceGroups.map((rg) => ({
      ...rg,
      resourceCount: status === 'DISABLED' ? 0 : <Spinner />,
      nextRefresh:
        status === 'DISABLED' ? 'n/a' : `${utils.nextRefresh(rg)} days`,
    }));
  };

  const handleRefresh = async () => {
    if (!props.activeGroup) return;
    setLoading(true);
    setResourcegroups([]);
    setGuardrail(null);
    setOnboardModal(false);

    if (
      permissionToRM(
        props.userInfo.whitelistedServices.ResourcesManager,
        props.activeGroup,
        LIST_RESOURCE_GROUP,
      )
    ) {
      try {
        const enabledRGs = await fetchRGs('ENABLED');
        const disabledRGs = await fetchRGs('DISABLED');

        const resourceGroups = [...enabledRGs, ...disabledRGs];
        setResourcegroups(resourceGroups);
        const updatedResources = await updateResources(enabledRGs);
        setResourcegroups([...updatedResources, ...disabledRGs]);
        setLoading(false);
      } catch (err) {
        setTableMessage(`Unable to load resource groups. ${err.message}`);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const handleAction = async (e) => {
    const item = collectionProps.selectedItems[0];
    switch (e.detail.id) {
      case 'details':
        setRedirect(Page.RESOURCEGROUP_DETAILS.replace(':id', item.id));
        break;
      case 'edit':
        setRedirect(Page.UPDATE_RESOURCEGROUP.replace(':id', item.id));
        break;
      case 'clone':
        setRedirect(Page.CREATE_RESOURCEGROUP);
        setRedirectParams({ values: item });
        break;
      case 'enable':
        setGuardrail({
          header: `Enable resource group: ${item.name} (${item.id})?`,
          action: async () => {
            await updateResourceGroup({ id: item.id, status: 'ENABLED' });
            setGuardrail(null);
            handleRefresh();
          },
        });
        break;
      case 'disable':
        setGuardrail({
          header: `Disable resource group: ${item.name} (${item.id})?`,
          action: async () => {
            await updateResourceGroup({ id: item.id, status: 'DISABLED' });
            setGuardrail(null);
            handleRefresh();
          },
        });
        break;
      case 'delete':
        setGuardrail({
          header: `Delete resource group: ${item.name} (${item.id})?`,
          action: async () => {
            await deleteResourceGroup({ id: item.id });
            setGuardrail(null);
            handleRefresh();
          },
        });
        break;
    }
  };

  if (redirect) {
    return (
      <Redirect
        push
        to={{
          pathname: redirect,
          state: redirectParams,
        }}
      />
    );
  }

  return (
    <>
      <GuardrailUnbound
        header={guardrail?.header || ''}
        action={guardrail?.action}
        visible={!!guardrail}
        onClose={() => setGuardrail(null)}
      />

      <UnauthorizedModal
        visible={onboardModal}
        onClose={() => setOnboardModal(false)}
      />

      <Table
        {...collectionProps}
        loadingText='Loading resource groups...'
        columnDefinitions={rgColumnDefinitions}
        items={items}
        wrapLines={false}
        resizableColumns={true}
        loading={isLoading}
        header={
          <>
            <RMPageHeader
              buttons={[
                {
                  text: '',
                  icon: 'refresh',
                  onItemClick: handleRefresh,
                },
                {
                  text: 'Actions',
                  onItemClick: handleAction,
                  items: [
                    {
                      text: 'View details',
                      id: 'details',
                      disabled: !collectionProps.selectedItems.length,
                    },
                    {
                      text: 'Edit',
                      id: 'edit',
                      disabled: !collectionProps.selectedItems.length,
                    },
                    {
                      text: 'Clone',
                      id: 'clone',
                      disabled: !collectionProps.selectedItems.length,
                    },
                    {
                      text: 'Enable',
                      id: 'enable',
                      disabled:
                        !collectionProps.selectedItems.length ||
                        collectionProps.selectedItems[0].status == 'ENABLED',
                    },
                    {
                      text: 'Disable',
                      id: 'disable',
                      disabled:
                        !collectionProps.selectedItems.length ||
                        collectionProps.selectedItems[0].status == 'DISABLED',
                    },
                    {
                      text: 'Delete',
                      id: 'delete',
                      disabled:
                        !collectionProps.selectedItems.length ||
                        ['DELETED', 'DELETE_PENDING'].includes(
                          collectionProps.selectedItems[0].status,
                        ),
                    },
                  ],
                  // Loading iff there are still resourceGroups loading
                  loading: isLoading,
                },
                {
                  text: 'Create',
                  variant: 'primary',
                  onItemClick: () => {
                    if (
                      permissionToRM(
                        props.userInfo.whitelistedServices.ResourcesManager,
                        props.activeGroup,
                        CREATE_RESOURCE_GROUP_ACTION,
                      )
                    ) {
                      setRedirect(Page.CREATE_RESOURCEGROUP);
                    } else {
                      setOnboardModal(true);
                    }
                  },
                },
              ]}
              subheader={
                <>
                  EMR resource groups{' '}
                  <span className='awsui-util-header-counter'>
                    {!isLoading && `(${resourcegroups.length})`}
                  </span>
                </>
              }
            />
          </>
        }
        pagination={
          <Pagination {...paginationProps} ariaLabels={paginationLabels} />
        }
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel='Filter resources'
            filteringPlaceholder='Find resources'
            countText={`${filteredItemsCount} ${
              filteredItemsCount === 1 ? 'match' : 'matches'
            }`}
          />
        }
        selectionType={'single'}
        trackBy='id'
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={{
              title: 'Page size',
              options: [
                { value: 10, label: '10 resource groups' },
                { value: 25, label: '25 resource groups' },
                { value: 50, label: '50 resource groups' },
              ],
            }}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
      />
      <br />
      {/* TEMPLATES */}
      <br />

      <MyTemplates
        activeGroup={props.activeGroup}
        redirect={(rd, rdp = null) => {
          setRedirect(rd);
          setRedirectParams(rdp);
        }}
        userInfo={props.userInfo}
      />
    </>
  );
};
