import { RegisterDataSetRequest } from 'aws-sdk/clients/tethyscontractservicelambda';
import { validatePayload } from './utils/validation';
import { registerDataset } from '../../../api/ingestion';
import { RegisterStep } from './steps/1-Register';
import { DataPropsStep } from './steps/2-Data';
import { SchemaStep } from './steps/3-Schema';
import { IngestionStep } from './steps/4-Ingestion';
import { ReviewStep } from './steps/5-Review';

import { scrollUp } from '../../utils/navigation';
import { Redirect } from 'react-router-dom';
import { Page } from 'src/routes';
import { useState } from 'react';
import * as React from 'react';

import {
  AppLayoutProps,
  FlashbarProps,
  Wizard,
  Button,
  Link,
} from '@amzn/awsui-components-react-v3';
import { stepsHeader } from './utils/content';

const i18nStrings = {
  stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
  collapsedStepsLabel: (stepNumber, stepsCount) =>
    `Step ${stepNumber} of ${stepsCount}`,
  skipToButtonLabel: (step) => `Skip to ${step.title}`,
  cancelButton: 'Cancel',
  previousButton: 'Previous',
  nextButton: 'Next',
  submitButton: 'Create contract',
  optional: 'optional',
};

export interface iStep {
  validate: boolean;
  isUpdate?: boolean;
  request: RegisterDataSetRequest;
  setRequest(request: RegisterDataSetRequest): void;
}

export interface iIngestionComponentProps {
  toggleHelp(): void;
  activeGroup: string;
  setContentType(contentType: AppLayoutProps.ContentType): void;
}

export interface iRegisterDataset extends iIngestionComponentProps {
  username: string;
  setNotifications(notifications: FlashbarProps.MessageDefinition[]): void;
}

export const RegisterDataset = ({
  username,
  activeGroup,
  toggleHelp,
  setContentType,
  setNotifications,
}: iRegisterDataset) => {
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [validationStep, setValidationStep] = useState(0);
  const [redirect, setRedirect] = useState<string>();
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    setContentType('wizard');
  }, []);

  const [request, setRequest] = useState<RegisterDataSetRequest>({
    TableName: null, // Step 1
    DataSetName: null,
    DatabaseName: null, // Step 1
    CatalogId: '785357098987', // Step 1
    DataContract: {
      DataFormat: 'FILE', // Omited
      FileProperties: {
        FileType: 'delimited', // Ommited. Should be CSV.
        HeaderLines: 0, // Step 2-A
        FieldDelimiter: null, // Step 2-A # "," | ";" | "|"
      },
      DataProperties: {
        CompressionFormat: null, // Step 2-A # "GZIP" // Missing on docs.
        SchemaFormat: 'AVRO', // Ommited
        SchemaDefinition: '', // Step 3
        PrimaryKeyColumns: [], // Step 4
        DeDupKeyColumn: null, // Step 4
      },
      ServiceLevelAgreement: {
        IAMRole: null, // Step 2-B
        PublishType: '', // Step 2-B # "INCREMENTAL" | "OVERWRITE"
      },
    },
    Region: 'us-east-1', // This should be defaulted on the back-end.
    CreatedBy: username, // This should be fetched from Gladstone.
    PrimaryOwner: activeGroup, // This should be fetched from Gladstone. Docs need update.
  });

  const validate = (step: number) => {
    setValidationStep(step);
    const validatedRequest = validatePayload(request);

    const validateStep = (step: any) => {
      const isStepValid = !Object.values(step).some((v) => !v);
      return isStepValid;
    };

    if (step === 1) {
      const isStepOneValid = validateStep(validatedRequest.step1);
      if (!isStepOneValid) return; // Watch out: Triple negation!
    }

    if (step === 2) {
      const isStepTwoValid = validateStep(validatedRequest.step2);
      if (!isStepTwoValid) return;
    }

    if (step === 3) {
      const isStepThreeValid = validateStep(validatedRequest.step3);
      if (!isStepThreeValid) return;
    }

    if (step === 4) {
      const isStepFourValid = validateStep(validatedRequest.step4);
      if (!isStepFourValid) return;
    }

    setActiveStepIndex(step);
  };

  const navigate = () => {
    setNotifications([]);
    setRedirect(Page.INGESTION.MANAGE_DATASETS);
  };

  const submit = async () => {
    setLoading(true);
    try {
      await registerDataset(request);
      setNotifications([
        {
          type: 'success',
          dismissible: true,
          onDismiss: () => setNotifications([]),
          content: 'Data contract registered successfully.',
          action: <Button onClick={navigate}>Visit your datasets</Button>,
        },
      ]);
    } catch (e) {
      setNotifications([
        {
          type: 'error',
          dismissible: true,
          onDismiss: () => setNotifications([]),
          content: e.message,
        },
      ]);
      setLoading(false);
    }
    scrollUp();
  };

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <Wizard
      isLoadingNextStep={loading}
      i18nStrings={i18nStrings}
      steps={[
        {
          ...stepsHeader.step1,
          content: (
            <RegisterStep
              request={request}
              setRequest={setRequest}
              validate={validationStep > 0}
            />
          ),
        },
        {
          ...stepsHeader.step2,
          content: (
            <DataPropsStep
              request={request}
              setRequest={setRequest}
              validate={validationStep > 1}
            />
          ),
        },
        {
          ...stepsHeader.step3,
          content: (
            <SchemaStep
              request={request}
              setRequest={setRequest}
              validate={validationStep > 2}
            />
          ),
        },
        {
          ...stepsHeader.step4,
          content: (
            <IngestionStep
              request={request}
              setRequest={setRequest}
              validate={validationStep > 3}
            />
          ),
        },
        {
          ...stepsHeader.step5,
          content: (
            <ReviewStep
              request={request}
              setActiveStepIndex={setActiveStepIndex}
            />
          ),
        },
      ].map((step) => ({
        ...step,
        info: <Link variant='info' onFollow={toggleHelp}>Feedback</Link>,
      }))}
      onSubmit={submit}
      activeStepIndex={activeStepIndex}
      onCancel={() => setRedirect(Page.INGESTION.MANAGE_DATASETS)}
      onNavigate={({ detail }) => validate(detail.requestedStepIndex)}
    />
  );
};
