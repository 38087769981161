import {
  Box,
  Button,
  Popover,
  StatusIndicator,
  StatusIndicatorProps,
} from '@amzn/awsui-components-react-v3';
import React, { useState } from 'react';

const SUCCESS_STATUS = 'success';
const ERROR_STATUS = 'error';

export const CopyText = ({
  copyText,
  copyButtonLabel,
  successText,
  errorText,
}) => {
  const [status, setStatus] =
    useState<StatusIndicatorProps.Type>(SUCCESS_STATUS);
  const [message, setMessage] = useState(successText);

  return (
    <div style={{ wordBreak: 'break-all' }}>
      <Box margin={{ right: 'xxs' }} display='inline-block'>
        <Popover
          size='small'
          position='top'
          dismissButton={false}
          triggerType='custom'
          content={<StatusIndicator type={status}>{message}</StatusIndicator>}
        >
          <Button
            variant='inline-icon'
            iconName='copy'
            ariaLabel={copyButtonLabel}
            onClick={() => {
              try {
                navigator.clipboard.writeText(copyText);
                setStatus(SUCCESS_STATUS);
                setMessage(successText);
              } catch (e) {
                setStatus(ERROR_STATUS);
                setMessage(errorText);
              }
            }}
          />
        </Popover>
      </Box>
      {copyText}
    </div>
  );
};
