import * as React from 'react';
import {
  Button,
  ColumnLayout,
  Flashbar,
  Form,
  FormField,
  Input,
  FlashbarProps,
  Header,
  Container,
  SpaceBetween,
} from '@amzn/awsui-components-react-v3';

import { Redirect } from 'react-router-dom';
import * as validate from '../../commons/validationUtils';
import { createAccount } from '../../api/publisher';
import { Page } from '../../routes/Paths';

export interface RegisterPublisherAccountProps {
  setContentType: any;
  location: any;
  activeGroup: string;
}

export interface RegisterPublisherAccountState {
  iamRole: string;
  iamRolePlaceholder: string;
  notifications: FlashbarProps.MessageDefinition[];
  accountID: string;
  buttonLoading: boolean;
  redirect: string;
  buttonText: string;
  cancelRedirect: string;
}

export class RegisterPublisherAccount extends React.Component<
  RegisterPublisherAccountProps,
  RegisterPublisherAccountState
> {
  state = {
    iamRolePlaceholder: 'arn:aws:iam::<yourAccount>:role/YourRole',
    iamRole: null,
    notifications: [],
    accountID: null,
    buttonLoading: false,
    redirect: undefined,
    buttonText: 'Register account',
    accountNumPlaceholder: '12 digit account number, e.g. 123456789012',
    cancelRedirect: Page.PUBLISHER_ACCOUNTS,
  };

  componentDidMount = async () => {
    this.props.setContentType('form');
  };

  handleConfirm = async () => {
    this.setState({ buttonLoading: true });
    try {
      const createAccountResponse = await createAccount({
        AwsAccountId: this.state.accountID,
        IamRole: this.state.iamRole,
        GroupId: this.props.activeGroup,
      });
      console.log(
        'Register Account page:: createAccountResponse',
        createAccountResponse,
      );
      const status =
        createAccountResponse.Status == 'Success' ? 'success' : 'error';
      this.setState({ buttonLoading: false });
      this.setState({
        notifications: [
          {
            type: status,
            content: ` ${createAccountResponse.Message}: ${this.state.accountID}`,
            dismissible: true,
            onDismiss: () => this.setState({ notifications: [] }),
          },
        ],
      });
    } catch (e) {
      console.log(
        'Register Account page:: Error in account registration',
        e.errorText,
      );
      this.setState({
        notifications: [
          {
            type: 'error',
            content: `Error in registering the account: ${e.message}`,
            dismissible: true,
            onDismiss: () => this.setState({ notifications: [] }),
          },
        ],
      });
    }
  };

  allFieldsSet() {
    return (
      validate.isValidRole(this.state.iamRole) &&
      validate.isValidAccoundId(this.state.accountID)
    );
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }
    return (
      <div>
        <Flashbar items={this.state.notifications}></Flashbar>
        <Form
          header={
            <Header
              variant='h1'
              description='Please specify the details of your AWS account.'
            >
              Register account
            </Header>
          }
          actions={
            <SpaceBetween direction='horizontal' size='s'>
              <Button
                variant='link'
                onClick={() =>
                  this.setState({ redirect: this.state.cancelRedirect })
                }
              >
                Cancel
              </Button>
              <Button
                variant='primary'
                onClick={this.handleConfirm}
                disabled={!this.allFieldsSet()}
              >
                {this.state.buttonText}
              </Button>
            </SpaceBetween>
          }
        >
          <Container
            className='custom-screenshot-hide'
            header={<Header variant='h2'>Account details</Header>}
          >
            <ColumnLayout>
              <SpaceBetween size='m'>
                <FormField
                  label={<div>AWS account ID</div>}
                  description='Input the AWS account that owns the DynamoDB tables.'
                >
                  <Input
                    name='aws-account-id'
                    placeholder={this.state.accountNumPlaceholder}
                    value={this.state.accountID}
                    ariaRequired={true}
                    onChange={(e) =>
                      this.setState({ accountID: e.detail.value.trim() })
                    }
                    invalid={
                      this.state.accountID !== null &&
                      !validate.isValidAccoundId(this.state.accountID)
                    }
                  />
                </FormField>

                <FormField
                  label='IAM role'
                  description='This role will be used by the service to gather details of your account.'
                >
                  <Input
                    name='account-iam-role'
                    placeholder={this.state.iamRolePlaceholder}
                    value={this.state.iamRole}
                    ariaRequired={true}
                    onChange={(e) =>
                      this.setState({ iamRole: e.detail.value.trim() })
                    }
                    invalid={
                      this.state.iamRole !== null &&
                      !validate.isValidRole(this.state.iamRole)
                    }
                  />
                </FormField>
              </SpaceBetween>
            </ColumnLayout>
          </Container>
        </Form>
      </div>
    );
  }
}
