import { MidwayIdentityCredentialProvider } from '@amzn/midway-identity-credential-provider';
import { getAuthConfig } from './config';

var credentials;
var config = getAuthConfig();
var user;

export function getCredentialProvider() {
  if (credentials !== undefined) {
    return credentials;
  }
  credentials = new MidwayIdentityCredentialProvider(
    {
      IdentityPoolId: config.identityPoolId,
      Logins: {},
    },
    { region: config.region },
  );
  return credentials;
}

export async function getToken() {
  const cred = await getCredentialProvider();
  if (cred.needsRefresh()) {
    await cred.refresh(() => {});
  }
  return cred.params['Logins']['midway-auth.amazon.com'];
}

export async function getUser() {
  if (user) {
    return user;
  }
  const token = await getToken();
  user = parseJwt(token).sub;
  return user;
}

function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );
  return JSON.parse(jsonPayload);
}
