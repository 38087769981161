import * as React from 'react';
import { Redirect } from 'react-router';
import { ColumnLayout, Icon, Button } from '@amzn/awsui-components-react';
import { WEBSITE_NAME } from '../../../src/commons/constants';
import './style.css';
const graph = require('./Omni.png');

interface HomeProps {
  allowListed: boolean;
  setContentType: any;
  setNavigationOpen: any;
  getStartedTarget: string;
  getStartedBtnText?: string;
}

interface HomeState {
  redirect: string;
}

export class Home extends React.Component<HomeProps, HomeState> {
  state = {
    redirect: undefined,
  };
  componentDidMount() {
    const { setContentType, setNavigationOpen } = this.props;
    setContentType('default');
    setNavigationOpen(false);
  }

  onGetStarted = () => {
    this.setState({ redirect: this.props.getStartedTarget });
    this.props.setNavigationOpen(true);
  };

  static defaultProps = {
    getStartedBtnText: 'Get started',
  };

  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }
    return (
      <div className='awsui-grid'>
        <div className='custom-home__header awsui-row'>
          <div className='col-xxs-12'>
            <div className='awsui-row'>
              <div className='custom-home__category col-xxs-10 offset-xxs-1 col-l-8 offset-l-1'>
                AWS Data Platform
              </div>
            </div>
            <div className='awsui-row'>
              <div className='custom-home__header-title col-xxs-10 offset-xxs-1 col-s-6 col-l-6 offset-l-1 col-xl-6'>
                <div className='awsui-text-large'>
                  <div>
                    <strong>{WEBSITE_NAME}</strong>
                  </div>
                  <div>
                    Discover, manage access, publish and subscribe to data in
                    AWS Data Lake.
                  </div>
                </div>
                <p>
                  With {WEBSITE_NAME} you can discover and understand the
                  datasets available in the AWS Data Lake data catalog. After
                  finding the data you need, you can submit requests to access
                  individual datasets. {WEBSITE_NAME} provides a secure way for
                  teams to request access and manage permissions. Once
                  permission is granted by the data owner, you can then access
                  the data directly using an IAM role or subscribe to the data
                  using a one-click data subscription process.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='awsui-util-pt-xxl awsui-row'>
          <div className='col-xxs-10 offset-xxs-1 col-s-6 col-l-6 offset-l-1 col-xl-6'>
            <h1>How it works</h1>
            <div className='awsui-util-container awsui-util-no-gutters how-it-works'>
              <div>
                <div className='how-it-works-svg awsui-util-m-xl'>
                  <img src={graph} />
                </div>
              </div>
            </div>
            <h1>Benefits</h1>
            <div className='awsui-util-container awsui-util-no-gutters'>
              <div>
                <ColumnLayout
                  columns={2}
                  borders='vertical'
                  className='awsui-util-no-gutters'
                >
                  <div
                    data-awsui-column-layout-root={true}
                    className='awsui-util-mv-l'
                  >
                    <div className='awsui-util-ph-l awsui-util-mb-m'>
                      <h3>Browse datasets</h3>
                      <p>
                        Discover datasets and get the latest metadata such as
                        schema, versions, SLA, etc.
                      </p>
                    </div>
                    <div className='awsui-util-ph-l awsui-util-mb-m'>
                      <h3>Get a Data Lake Role</h3>
                      <p>
                        Request access to the datasets in AWS Data Lake and use
                        your Data Lake Role on the AWS Data Services.
                      </p>
                    </div>
                    <div className='awsui-util-ph-l awsui-util-mb-m'>
                      <h3>Subscribe to data</h3>
                      <p>
                        No need to create complex ETL jobs to keep your data in
                        sync.
                      </p>
                    </div>
                    <div className='awsui-util-ph-l awsui-util-mb-m'>
                      <h3>Manage permissions</h3>
                      <p>
                        Control how your data is used with group-based
                        permissions.
                      </p>
                    </div>
                  </div>
                </ColumnLayout>
              </div>
            </div>
          </div>
          <div className='custom-home__sidebar col-xxs-10 offset-xxs-1 col-s-4 offset-s-0 col-l-3 col-xl-2'>
            <div className='awsui-util-mt-xxl'>
              <div className='awsui-util-container'>
                <h2>{this.props.getStartedBtnText}</h2>
                <div>
                  <p>
                    Start browsing the datasets that are available in AWS Data
                    Lake.
                  </p>
                  <Button
                    variant='primary'
                    className='awsui-util-mt-s'
                    text={this.props.getStartedBtnText}
                    onClick={this.onGetStarted}
                  />
                </div>
              </div>
            </div>
            <div className='awsui-util-mt-xxl'>
              <div className='awsui-util-container'>
                <div className='awsui-util-container-header'>
                  <h2>Learn more</h2>
                </div>
                <div>
                  <div className='custom-inner-table-row'>
                    <span>
                      <a
                        href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Omni'
                        target='_blank'
                        className='custom-home__external'
                      >
                        Getting started guide
                        <Icon name='external' />
                      </a>
                    </span>
                  </div>
                  <div className='custom-inner-table-row'>
                    <span>
                      <a
                        href='https://w.amazon.com/bin/view/AWSDataLake/AWSDataPlatform/AWSDataLake/Omni/AccessData'
                        target='_blank'
                        className='custom-home__external'
                      >
                        Accessing data from AWS Data Lake
                        <Icon name='external' />
                      </a>
                    </span>
                  </div>
                  {/* TODO: enable this after we support publisher for new customers */}
                  {/* <div className='custom-inner-table-row'>
                    <span>
                      <a
                        href='#'
                        target='_blank'
                        className='custom-home__external'
                      >
                        Publish Data into AWS Data Lake
                        <Icon name='external' />
                      </a>
                    </span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
