import React, { useCallback, useEffect, useState } from 'react';
import {
  ExpandableSection,
  CheckboxProps,
  ColumnLayout,
  FormField,
  Checkbox,
  NonCancelableCustomEvent,
} from '@amzn/awsui-components-react-v3';

const databasePermissionOptions = [
  { id: 'CREATE_TABLE', name: 'Create Table' },
  { id: 'ALTER', name: 'Alter' },
  { id: 'DROP', name: 'Drop' },
  { id: 'DESCRIBE', name: 'Describe' },
].map((option) => ({
  id: option.id,
  label: option.name,
}));

const tablePermissionOptions = [
  //   { id: 'ALTER', name: 'Alter' },
  //   { id: 'INSERT', name: 'Insert' },
  //   { id: 'DROP', name: 'Drop' },
  //   { id: 'DELETE', name: 'Delete' },
  { id: 'SELECT', name: 'Select' },
  //   { id: 'DESCRIBE', name: 'Describe' },
].map((option) => ({
  id: option.id,
  label: option.name,
}));

const resourceLinkPermissions = [
  { id: 'DROP', name: 'Drop' },
  { id: 'DESCRIBE', name: 'Describe' },
].map((option) => ({
  id: option.id,
  label: option.name,
}));

/**
 * Utility function to get the correct permissions according to resource. A resource can be a `Table`,
 * `Database` or a `ResourceLink` and based on that this function returns the applicable permissions.
 */
const getResourcePermissions = (resource: string) => {
  if (resource === 'database') return databasePermissionOptions;
  if (resource === 'table') return tablePermissionOptions;
  if (resource === 'resourceLink') return resourceLinkPermissions;
  return tablePermissionOptions;
};

/**
 * The labels should reflect the current resource that the user is setting permissions for.
 */
const getResourceLabel = (resource: string) => {
  if (resource === 'database') return 'Database';
  if (resource === 'table') return 'Table';
  if (resource === 'resourceLink') return 'Resource link';
  return 'Table';
};

type CheckboxChange = NonCancelableCustomEvent<CheckboxProps.ChangeDetail>;


interface TablePermissionsFormFieldsProps {
  permissions: TablePermissions;
  resource: 'database' | 'table' | 'resourceLink';
  onChange?: (change: TablePermissions) => void;
}

export interface TablePermissions {
  tablePermissions: string[];
  grantablePermissions: string[];
}

const TablePermissionsFormFields = (props: TablePermissionsFormFieldsProps) => {
  const { onChange, resource } = props;
  const [permissionOptions, setPermissionOptions] = useState(
    getResourcePermissions(resource),
  );
  const [tablePermissions, setTablePermission] = useState<string[]>(
    props.permissions.tablePermissions,
  );
  const [grantablePermissions, setGrantablePermissions] = useState<string[]>(
    props.permissions.grantablePermissions,
  );

  useEffect(() => {
    setPermissionOptions(getResourcePermissions(resource));
    setTablePermission(props.permissions.tablePermissions);
    setGrantablePermissions(props.permissions.grantablePermissions);
  }, [resource]);

  const handleTablePermissionsChange = useCallback(
    (e: CheckboxChange, key: string) => {
      const checked = e.detail.checked;

      let permissions;

      if (!checked) {
        permissions = tablePermissions.filter((perm) => perm !== key);
        setTablePermission(permissions);
        if (onChange) {
          onChange({
            grantablePermissions,
            tablePermissions: permissions,
          });
        }
        return;
      }

      permissions = [...tablePermissions, key];
      setTablePermission(permissions);

      if (onChange) {
        onChange({
          grantablePermissions,
          tablePermissions: permissions,
        });
      }
    },
    [tablePermissions, grantablePermissions],
  );

  const handleGrantablePermissionsChange = useCallback(
    (e: CheckboxChange, key: string) => {
      const checked = e.detail.checked;

      let permissions;

      if (!checked) {
        permissions = grantablePermissions.filter((perm) => perm !== key);
        setGrantablePermissions(permissions);
        if (onChange) {
          onChange({
            tablePermissions,
            grantablePermissions: permissions,
          });
        }
        return;
      }

      permissions = [...grantablePermissions, key];
      setGrantablePermissions(permissions);

      if (onChange) {
        onChange({
          tablePermissions,
          grantablePermissions: permissions,
        });
      }
    },
    [tablePermissions, grantablePermissions],
  );

  return (
    <>
      <ExpandableSection header='Advanced settings'>
        <ColumnLayout>
          <FormField
            label={`${getResourceLabel(resource)} permissions`}
            description='Choose specific access permissions to grant.'
          >
            <div className='permission-options'>
              {permissionOptions.map((option) => (
                <Checkbox
                  id={option.id}
                  key={option.id}
                  checked={
                    !!props?.permissions?.tablePermissions?.find(
                      (p) => p === option.id,
                    )
                  }
                  onChange={(e) => handleTablePermissionsChange(e, option.id)}
                >
                  {option.label}
                </Checkbox>
              ))}
            </div>
          </FormField>
        </ColumnLayout>

        <ColumnLayout>
          <FormField
            label='Grantable permissions'
            description='Choose the permission that may be granted to others.'
          >
            <div className='permission-options'>
              {permissionOptions.map((option) => (
                <Checkbox
                  id={option.id}
                  key={option.id}
                  checked={
                    !!props?.permissions?.grantablePermissions?.find(
                      (p) => p === option.id,
                    )
                  }
                  onChange={(e) =>
                    handleGrantablePermissionsChange(e, option.id)
                  }
                >
                  {option.label}
                </Checkbox>
              ))}
            </div>
          </FormField>
        </ColumnLayout>
      </ExpandableSection>
    </>
  );
};

export default TablePermissionsFormFields;
