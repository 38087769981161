import { Widget } from '@amzn/cloudwatchdashboards-inside';

const getLogGroup = (team: string, logGroup: string): string => {
  try {
    const source = `SOURCE '/aws/tethys/${team}/DSLG-INFO-${logGroup}'`
    const query =
      '| fields @timestamp, @message | sort @timestamp desc | limit 20';

      console.log('source', source, query)
      return `${source} ${query}`;
  } catch (e) {
    return '';
  }
};

export const debugLogs: Widget = {
  type: 'log',
  x: 0,
  y: 14,
  width: 24,
  height: 9,
  properties: {
    query: `SOURCE 'tethys/fraud/enforcement/errors' | fields @timestamp, @message | sort @timestamp desc | limit 20`,
    region: 'us-east-1',
    title: 'Ingestion error logs',
  },
};


export const ingestionLogs = (team: string, id: string): Widget => ({
  type: 'log',
  x: 0,
  y: 14,
  width: 24,
  height: 9,
  properties: {
    query: getLogGroup(team, id),
    region: 'us-east-1',
    title: 'Ingestion logs',
  },
});
