import React, { useCallback, useState } from 'react';
import { Container, ColumnLayout, Header } from '@amzn/awsui-components-react-v3';
import { DataLakePrincipal } from 'aws-sdk/clients/lakeformation';
import OrganizationsSelector from './organizationsSelector';

interface RecipientSelectorComponentProps {
  activeGroup?: string;
  onChange: (principal: DataLakePrincipal) => void;
  onRamRoleChange: (ramRole: any) => void;
}

const RecipientSelectorComponent = (props: RecipientSelectorComponentProps) => {
  const [orgFields] = useState<any>({
    accountId: {
      errorText: '',
      value: '',
      validation: [],
    },
    accountIds: {
      errorText: '',
      value: [],
      validation: [],
    },
  });

  const handleRoleChange = useCallback(
    (accounts) => {
      props.onRamRoleChange(accounts.accountIds.value[0]);
      props.onChange({
        DataLakePrincipalIdentifier: accounts.accountIds.value
          ? accounts.accountIds.value[0].split(':')[4]
          : '',
      });
    },
    [props.onRamRoleChange, props.onChange],
  );

  return (
    <Container
      header={
        <Header
          variant="h2"
          description='Select the Resource Access Manager roles from an account to grant permissions to.'
        >
          Target principals
        </Header>
      }
    >
      <ColumnLayout>
        <div data-awsui-column-layout-root={true}>
          <OrganizationsSelector
            label='Enter an AWS RAM Share Role'
            description={
              <>
                Enter an RAM Share role ARN. Press Enter after.
                <a
                  href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Omni/LakeFormationRoles/#HConsumingRole'
                  className='awsui-util-help-info-link'
                >
                  Learn more
                </a>
              </>
            }
            hintText=''
            handleInputChange={(e) => e}
            isAccountsOnly={false}
            placeholder={
              orgFields.accountIds.value.length == 0
                ? 'Enter an AWS RAM Share role ARN.'
                : 'You can only enter one; deselect to type another.'
            }
            setFields={handleRoleChange}
            parentFields={orgFields}
          />
        </div>
      </ColumnLayout>
    </Container>
  );
};

export default RecipientSelectorComponent;
