import * as React from 'react';
import {
  AttributeEditor,
  Button,
  Cards,
  ColumnLayout,
  Flashbar,
  Form,
  FormField,
  Container,
  Input,
  Select,
  Toggle, SelectProps, FlashbarProps, Header,
} from '@amzn/awsui-components-react-v3';
import {
  listDataSources,
  registerDataSets,
  syncDataSets,
} from '../../../src/api/catalog';
import { Redirect } from 'react-router-dom';
import { getUser } from '../../../src/api/auth';
import { PublishDataSetGlueLF } from './share/publishDataSetGlueLF';
import {
  dataClassificationOptions,
  refreshCadenceOptions,
} from 'src/commons/constants';
import { scrollUp } from 'src/components/utils/navigation';

export const adminList = ['AWSDL_Services', 'AWSDataWarehouse'];

export interface PublishDataSetComponentProps {
  setContentType: any;
  activeGroup: string;
}

export interface PublishDataSetComponentState {
  dataSourceOptions: SelectProps.Option[];
  dataSourcesLoading: boolean;
  createdBy: string;
  dataSourceId: any;
  dataShareType: string;
  glueDataSourceId: object;
  catalogId: string;
  region: string;

  dataSetList: object[];
  dataSetCards: object[];

  currentDataSetName: string;
  currentDatabase: string;
  currentTable: string;
  currentOwners: string;
  currentPrimaryOwner: string;
  currentClassification: object;
  restrictedClassification: object;
  currentPreview: boolean;

  currentWheeljackConfidenceFileLocation: string;
  currentRefreshCadence: object;
  defaultRefreshCadence: object;
  currentPII: boolean;
  currentDatalakeSLA: string;
  currentTableState: object;
  activeTableState: object;
  currentKmsKey: string;
  currentLakeFormationRoleARN: string;

  notifications: FlashbarProps.MessageDefinition[];
  visible: boolean;
  buttonLoading: boolean;
  redirect: string;
  buttonText: string;
}

export default class PublishDataSetComponent extends React.Component<
  PublishDataSetComponentProps,
  PublishDataSetComponentState
> {
  state = {
    dataShareType: undefined,
    dataSourceOptions: [],
    dataSourcesLoading: true,
    createdBy: '',
    dataSourceId: null,
    glueDataSourceId: null,
    catalogId: '',
    region: 'us-east-1',
    dataSetList: [],
    dataSetCards: [],

    // The status of the current dataset being edited
    currentDataSetName: '',
    currentDatabase: '',
    currentTable: '',
    currentOwners: 'AWSDL_Services',
    currentPrimaryOwner: '',
    currentClassification: null,
    restrictedClassification: null,
    currentPreview: false,
    currentWheeljackConfidenceFileLocation: '',
    currentRefreshCadence: null,
    defaultRefreshCadence: null,
    currentPII: false,
    currentDatalakeSLA: '07:00 (PST)',
    currentTableState: null,
    activeTableState: null,
    currentKmsKey: '',
    currentLakeFormationRoleARN: '',

    notifications: [],
    visible: false,
    buttonLoading: false,
    redirect: undefined,
    buttonText: 'Publish Dataset',
  };

  dataSetAttributes = [
    {
      key: 'dataset',
      value: 'Dataset name as it will appear in the catalog',
    },
    {
      key: 'database',
      value: 'Database name',
    },
    {
      key: 'table',
      value: 'Table name',
    },
    {
      key: 'owners',
      value: 'Table owners (comma-separated Omni Group IDs)',
    },
    {
      key: 'primaryOwner',
      value: 'Table primary owner',
    },
    {
      key: 'classification',
      value: 'Data classification',
    },
    {
      key: 'preview',
      value: 'Allow data preview',
    },
    {
      key: 'wheeljackConfidenceFileLocation',
      value: 'Confidence file S3 location',
    },
    {
      key: 'refreshCadence',
      value: 'Refresh cadence',
    },
    {
      key: 'pii',
      value: 'Is a PII dataset',
    },
    {
      key: 'datalakeSLA',
      value: "SLA (typically '07:00 (PST)')",
    },
    {
      key: 'tableState',
      value: "Table status (typically 'Active')",
    },
    {
      key: 'kmsKey',
      value: 'KMS key (if necessary)',
    },
    {
      key: 'lakeFormationRoleArn',
      value: 'Lake Formation role ARN',
    },
  ];

  cardDefinition = {
    header: (item) => item.DataSetName,
    sections: [
      {
        id: 'database',
        header: 'Database',
        content: (item) => item.DatabaseName,
      },
      {
        id: 'table',
        header: 'Table',
        content: (item) => item.TableName,
      },
      {
        id: 'owners',
        header: 'Owners',
        content: (item) => item.TableOwners,
      },
      {
        id: 'primaryOwner',
        header: 'PrimaryOwner',
        content: (item) => item.PrimaryOwner,
      },
      {
        id: 'classification',
        header: 'Data classification',
        content: (item) => item.DataClassification,
      },
      {
        id: 'preview',
        header: 'Preview enabled',
        content: (item) => (item.DataPreview ? 'True' : 'False'),
      },
      {
        id: 'wheeljackConfidenceFileLocation',
        header: 'Confidence file s3 location',
        content: (item) => item.WheeljackConfidenceFileLocation,
      },
      {
        id: 'refreshCadence',
        header: 'Refresh cadence',
        content: (item) => item.RefreshCadence,
      },
      {
        id: 'pii',
        header: 'Is a PII dataset',
        content: (item) => (item.PII ? 'True' : 'False'),
      },
      {
        id: 'datalakeSLA',
        header: 'SLA',
        content: (item) => item.DatalakeSLA,
      },
      {
        id: 'tableState',
        header: 'Table status',
        content: (item) => item.TableState,
      },
      {
        id: 'kmsKey',
        header: 'KMS key',
        content: (item) => item.KmsKey,
      },
      {
        id: 'lakeFormationRoleARN',
        header: 'Lake formation role ARN',
        content: (item) => item.LakeFormationRoleArn,
      },
    ],
  };

  tableStateOptions = [
    {
      label: 'Active',
      id: '1',
    },
    {
      label: 'Pending Deprecation',
      id: '2',
    },
    {
      label: 'Deprecated',
      id: '3',
    },
  ];

  dataSourceNames = {
    glueLF: 'Lake Formation',
    glue: 'Glue',
  };

  setDefaultAttributes() {
    this.setState({
      restrictedClassification: dataClassificationOptions.find(function (
        currentVal,
      ) {
        return currentVal.label == 'Restricted';
      }),
      defaultRefreshCadence: refreshCadenceOptions.find(function (currentVal) {
        return currentVal.label == 'Never';
      }),
      activeTableState: this.tableStateOptions.find(function (currentVal) {
        return currentVal.label == 'Active';
      }),
      currentOwners: 'AWSDL_Services',
      currentPrimaryOwner: 'AWSDataWarehouse',
      currentDatalakeSLA: '07:00 (PST)',
      region: 'us-east-1',
    });
    if (this.state.dataSourceOptions != []) {
      this.setState({
        glueDataSourceId: this.state.dataSourceOptions.find(function (
          currentVal,
        ) {
          return currentVal.value == 'glue';
        }),
      });
    }
  }

  componentDidMount = async () => {
    this.setDefaultAttributes();

    this.props.setContentType('form');

    const options = await listDataSources();
    const formattedOptions = options['DataSourceList']
      .filter((source) => source.includes('glue'))
      .map((source, i) => {
        return {
          id: i.toString(),
          value: source,
          label: this.dataSourceNames[source],
        };
      });
    this.setState({
      dataSourceOptions: formattedOptions,
      dataSourcesLoading: false,
      glueDataSourceId: formattedOptions.find(function (currentVal) {
        return currentVal.value == 'glue'; // Note: Glue is default
      }),
      dataSourceId: formattedOptions.find(function (currentVal) {
        return currentVal.value == 'glue'; // Note: Glue is default
      }),
    });

    this.addSingleDataSet = this.addSingleDataSet.bind(this);
    this.updateButtonText = this.updateButtonText.bind(this);
  };

  publishDataSets = async () => {
    const dataSourceIdValue =
      this.state.dataSourceId == null
        ? this.state.glueDataSourceId.value
        : this.state.dataSourceId.value;
    console.log(dataSourceIdValue);
    const registerRequest = {
      CreatedBy: await getUser(),
      DataSourceId: dataSourceIdValue,
      CatalogId: this.state.catalogId,
      Region: this.state.region,
      DataSetList: this.state.dataSetList,
    };

    // if register step fails, do not move on to sync step
    let registerResponse;
    try {
      registerResponse = await registerDataSets(registerRequest);
      console.log('Register returned response:');
      console.log(registerResponse);
    } catch (err) {
      this.setState({
        notifications: [
          {
            type: 'error' as FlashbarProps.Type,
            content: `Failed to register datasets.`,
            dismissible: true,
            onDismiss: () => this.setState({ notifications: [] }),
          },
        ],
      });
      return;
    }

    // now sync datasets' metadata with glue (or other data sources in the future)
    const idList = registerResponse.DataSetList.map((e) => e.Id);
    const syncRequest = {
      IdList: idList,
    };

    try {
      const syncResponse = await syncDataSets(syncRequest);
      console.log('Sync returned response:');
      console.log(syncResponse);
    } catch (err) {
      this.setState({
        notifications: [
          {
            type: 'error' as FlashbarProps.Type,
            content: `Failed to sync datasets with source.`,
            dismissible: true,
            onDismiss: () => this.setState({ notifications: [] }),
          },
        ],
      });
      return;
    }

    this.setState({
      notifications: [
        {
          type: 'success' as FlashbarProps.Type,
          content: `Successfully registered and synced.`,
          dismissible: true,
          onDismiss: () => this.setState({ notifications: [] }),
        },
      ],
    });
  };

  addSingleDataSet() {
    // Need to split and trim the owners array for POST request
    const ownersArr = this.state.currentOwners
      .split(',')
      .map((item) => item.trim());

    const classification =
      this.state.currentClassification == null
        ? this.state.restrictedClassification.label
        : this.state.currentClassification.label;
    const refreshCadence =
      this.state.currentRefreshCadence == null
        ? this.state.defaultRefreshCadence.label
        : this.state.currentRefreshCadence.label;
    const tableState =
      this.state.currentTableState == null
        ? this.state.activeTableState.label
        : this.state.currentTableState.label;

    const dataSet = {
      DataSetName: this.state.currentDataSetName,
      DatabaseName: this.state.currentDatabase,
      TableName: this.state.currentTable,
      TableOwners: ownersArr,
      PrimaryOwner: this.state.currentPrimaryOwner,
      DataClassification: classification,
      DataPreview: this.state.currentPreview,
      DatalakeSLA: this.state.currentDatalakeSLA,
      TableState: tableState,
      RefreshCadence: refreshCadence,
      PII: this.state.currentPII,
      KmsKey: this.state.currentKmsKey,
      LakeFormationRoleArn: this.state.currentLakeFormationRoleARN,
      WheeljackConfidenceFileLocation: this.state
        .currentWheeljackConfidenceFileLocation,
    };
    const cardDataSet = {
      DataSetName: this.state.currentDataSetName,
      DatabaseName: this.state.currentDatabase,
      TableName: this.state.currentTable,
      TableOwners: this.state.currentOwners,
      PrimaryOwner: this.state.currentPrimaryOwner,
      DataClassification: classification,
      DataPreview: this.state.currentPreview,
      DatalakeSLA: this.state.currentDatalakeSLA,
      TableState: tableState,
      RefreshCadence: refreshCadence,
      PII: this.state.currentPII,
      KmsKey: this.state.currentKmsKey,
      LakeFormationRoleArn: this.state.currentLakeFormationRoleARN,
      WheeljackConfidenceFileLocation: this.state
        .currentWheeljackConfidenceFileLocation,
    };
    this.setState({
      dataSetList: [...this.state.dataSetList, dataSet],
      dataSetCards: [...this.state.dataSetCards, cardDataSet],
      currentDataSetName: '', // reset the text input fields
      currentDatabase: '',
      currentTable: '',
      currentOwners: '',
      currentPrimaryOwner: '',
      currentLakeFormationRoleARN: '',
      currentWheeljackConfidenceFileLocation: '',
      currentKmsKey: '',
      currentDatalakeSLA: '',
    });
    this.updateButtonText();
    this.setDefaultAttributes();
  }

  handleConfirm = async () => {
    this.setState({ buttonLoading: true });

    // check inputs and etc
    await this.publishDataSets();

    this.setState({ buttonLoading: false });
  };

  getNumberOfDataSets() {
    return this.state.dataSetList.length;
  }

  disableAddButton() {
    return (
      this.state.currentDataSetName.length === 0 ||
      this.state.currentDatabase.length === 0 ||
      this.state.currentTable.length === 0 ||
      this.state.currentOwners.length === 0 ||
      this.state.currentPrimaryOwner.length == 0 ||
      (this.state.currentClassification == null &&
        this.state.restrictedClassification == null) ||
      this.state.currentWheeljackConfidenceFileLocation.length === 0 ||
      (this.state.currentRefreshCadence == null &&
        this.state.defaultRefreshCadence == null) ||
      this.state.currentDatalakeSLA.length === 0 ||
      (this.state.currentTableState == null &&
        this.state.activeTableState == null)
    );
  }

  updateButtonText() {
    if (this.getNumberOfDataSets() > 1) {
      this.setState({ buttonText: 'Publish datasets' });
    } else {
      this.setState({ buttonText: 'Publish dataset' });
    }
  }

  setNotifications = (value) => {
    scrollUp();
    this.setState({ notifications: value });
  };

  render() {
    // temporary hard-coding to hide this page from everyone except admins
    // and pentest team, remove these when we can validate the groups in the backend
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }
    return (
      <div>
        <Flashbar items={this.state.notifications} />
        <div className='awsui-util-mb-m awsui-util-mt-xs'>
          <div className='awsui-util-action-stripe'>
            <div className='awsui-util-action-stripe-title'>
              <h2>
                <span>
                  <h1 className='awsui-util-container-header'>Publish data</h1>
                </span>
              </h2>
              <div className='awsui-util-label'>
                You can add your data to the catalog from the desired data
                source.
              </div>
            </div>
          </div>
        </div>

        <Container
          className='custom-screenshot-hide'
          header = {
          <Header variant='h2'>Data source settings</Header>
          }
        >
          <ColumnLayout>
            <div data-awsui-column-layout-root={true}>
              <FormField
                label={<div>Data source</div>}
                description='The type of the data source to be used.'
              >
                <Select
                  selectedOption={
                    this.state.dataSourceId != null
                      ? this.state.dataSourceId
                      : this.state.glueDataSourceId
                  }
                  options={this.state.dataSourceOptions}
                  selectedAriaLabel='Selected'
                  onChange={(e) => {
                    this.setState({
                      dataSourceId: e.detail.selectedOption,
                    });
                  }}
                />
              </FormField>
            </div>
          </ColumnLayout>
        </Container>
        <br/>

        {this.state.dataSourceId?.value === 'glueLF' && (
          <PublishDataSetGlueLF
            {...this.props}
            onNotificationChange={this.setNotifications}
            dataSourceId={this.state.dataSourceId?.value}
          />
        )}

        {this.state.dataSourceId?.value === 'glue' &&
          adminList.includes(this.props.activeGroup) && (
            <Form
              actions={
                <div>
                  <Button
                    variant='link'
                    onClick={() => {
                      this.setState({
                        dataSetList: [],
                        dataSetCards: [],
                      });
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant='primary'
                    onClick={this.handleConfirm}
                    loading={this.state.buttonLoading}
                    disabled={this.getNumberOfDataSets() == 0}
                  >
                    {this.state.buttonText}
                  </Button>
                </div>
              }
            >
              <Container
                className='custom-screenshot-hide'
                header={<h2>Catalog details</h2>}
              >
                <ColumnLayout>
                    <FormField
                      label={<div>Catalog ID</div>}
                      description='AWS Account ID of the catalog to submit to. (e.g., Data Lake prod, Data Lake beta)'
                    >
                      <Input
                        name='catalog-id'
                        ariaRequired={true}
                        onChange={(e) =>
                          this.setState({ catalogId: e.detail.value })
                        }
                        value={this.state.catalogId}
                      />
                    </FormField>
                    <FormField
                      label={<div>Region</div>}
                      description='The region where the data source resides.'
                    >
                      <Input
                        name='region'
                        value={this.state.region}
                        ariaRequired={true}
                        onChange={(e) =>
                          this.setState({ region: e.detail.value })
                        }
                      />
                    </FormField>
                </ColumnLayout>
              </Container>

              <br/>
              <Container
                className='custom-screenshot-hide'
                header={<h2>Add datasets</h2>}
              >
                <ColumnLayout>
                    <FormField>
                      <Cards
                        cardDefinition={this.cardDefinition}
                        items={this.state.dataSetCards}
                        cardsPerRow={[
                          {
                            cards: 1,
                          },
                          {
                            minWidth: 500,
                            cards: 2,
                          },
                        ]}
                        empty={
                          <div className='awsui-util-t-c'>
                            <div className='awsui-util-pt-xs awsui-util-mb-xs'>
                              <b>No datasets yet</b>
                            </div>
                            <p className='awsui-util-mb-s'>
                              You can add a dataset below.
                            </p>
                          </div>
                        }
                      />
                    </FormField>
                      <AttributeEditor
                        items={this.dataSetAttributes}

                        definition={[
                          // Definition of the left column, containing the descriptions of the input fields
                          {
                            label: '',
                            control: (item) => <p>{item.value}</p>,
                          },
                          // Definition of the right column, containing text fields, dropdown menus, and toggles
                          {
                            label: '',
                            control: (item) => {
                              if (item.key == 'preview') {
                                return (
                                  <Toggle
                                    id={'toggle:' + item.key}
                                    onChange={(e) =>
                                      this.setState({
                                        currentPreview: e.detail.checked,
                                      })
                                    }

                                   checked = {this.state.currentPreview}
                                  />
                                );
                              } else if (item.key == 'classification') {
                                return (
                                  <Select
                                    id={'select:' + item.key}
                                    selectedOption={
                                      this.state.currentClassification == null
                                        ? this.state.restrictedClassification
                                        : this.state.currentClassification
                                    }
                                    options={dataClassificationOptions}
                                    selectedAriaLabel='Selected'
                                    onChange={(e) =>
                                      this.setState({
                                        currentClassification:
                                          e.detail.selectedOption,
                                      })
                                    }
                                  />
                                );
                              } else if (item.key == 'dataset') {
                                return (
                                  <Input
                                    id={'input:' + item.key}
                                    placeholder='Enter value'
                                    value={this.state.currentDataSetName}
                                    onChange={(e) =>
                                      this.setState({
                                        currentDataSetName: e.detail.value,
                                      })
                                    }
                                  />
                                );
                              } else if (item.key == 'database') {
                                return (
                                  <Input
                                    id={'input:' + item.key}
                                    placeholder='Enter value'
                                    value={this.state.currentDatabase}
                                    onChange={(e) =>
                                      this.setState({
                                        currentDatabase: e.detail.value,
                                      })
                                    }
                                  />
                                );
                              } else if (item.key == 'owners') {
                                return (
                                  <Input
                                    id={'input:' + item.key}
                                    placeholder={'Enter value'}
                                    value={this.state.currentOwners}
                                    onChange={(e) =>
                                      this.setState({
                                        currentOwners: e.detail.value,
                                      })
                                    }
                                  />
                                );
                              } else if (item.key == 'primaryOwner') {
                                return (
                                  <Input
                                    id={'input:' + item.key}
                                    placeholder={'Enter value'}
                                    value={this.state.currentPrimaryOwner}
                                    onChange={(e) =>
                                      this.setState({
                                        currentPrimaryOwner: e.detail.value,
                                      })
                                    }
                                  />
                                );
                              } else if (item.key == 'table') {
                                return (
                                  <Input
                                    id={'input:' + item.key}
                                    placeholder='Enter value'
                                    value={this.state.currentTable}
                                    onChange={(e) =>
                                      this.setState({
                                        currentTable: e.detail.value,
                                      })
                                    }
                                  />
                                );
                              } else if (
                                item.key == 'wheeljackConfidenceFileLocation'
                              ) {
                                return (
                                  <Input
                                    id={'input:' + item.key}
                                    placeholder='Enter value'
                                    value={
                                      this.state
                                        .currentWheeljackConfidenceFileLocation
                                    }
                                    onChange={(e) => {
                                      this.setState({
                                        currentWheeljackConfidenceFileLocation:
                                          e.detail.value,
                                      });
                                    }}
                                  />
                                );
                              } else if (item.key == 'refreshCadence') {
                                return (
                                  <Select
                                    id={'select:' + item.key}
                                    options={refreshCadenceOptions}
                                    selectedAriaLabel='Selected'
                                    selectedOption={
                                      this.state.currentRefreshCadence == null
                                        ? this.state.defaultRefreshCadence
                                        : this.state.currentRefreshCadence
                                    }
                                    onChange={(e) => {
                                      this.setState({
                                        currentRefreshCadence:
                                          e.detail.selectedOption,
                                      });
                                    }}
                                  />
                                );
                              } else if (item.key == 'pii') {
                                return (
                                  <Toggle
                                    id={'toggle:' + item.key}
                                    onChange={(e) => {
                                      this.setState({
                                        currentPII: e.detail.checked,
                                      });
                                    }}
                                    checked = {this.state.currentPII}
                                  />
                                );
                              } else if (item.key == 'datalakeSLA') {
                                return (
                                  <Input
                                    id={'input:' + item.key}
                                    placeholder='Enter value'
                                    value={this.state.currentDatalakeSLA}
                                    onChange={(e) =>
                                      this.setState({
                                        currentDatalakeSLA: e.detail.value,
                                      })
                                    }
                                  />
                                );
                              } else if (item.key == 'tableState') {
                                return (
                                  <Select
                                    id={'select:' + item.key}
                                    options={this.tableStateOptions}
                                    selectedOption={
                                      this.state.currentTableState == null
                                        ? this.state.activeTableState
                                        : this.state.currentTableState
                                    }
                                    selectedAriaLabel='Selected'
                                    onChange={(e) => {
                                      this.setState({
                                        currentTableState:
                                          e.detail.selectedOption,
                                      });
                                    }}
                                  />
                                );
                              } else if (item.key == 'kmsKey') {
                                return (
                                  <Input
                                    id={'input:' + item.key}
                                    placeholder='Enter value'
                                    value={this.state.currentKmsKey}
                                    onChange={(e) => {
                                      this.setState({
                                        currentKmsKey: e.detail.value,
                                      });
                                    }}
                                  />
                                );
                              } else if (item.key == 'lakeFormationRoleArn') {
                                return (
                                  <Input
                                    id={'input:' + item.key}
                                    placeholder='Enter value'
                                    value={
                                      this.state.currentLakeFormationRoleARN
                                    }
                                    onChange={(e) => {
                                      this.setState({
                                        currentLakeFormationRoleARN:
                                          e.detail.value,
                                      });
                                    }}
                                  />
                                );
                              }
                            },
                          },
                        ]}
                        addButtonText='Add dataset'
                        disableAddButton={this.disableAddButton()}
                        onAddButtonClick={this.addSingleDataSet}
                        isItemRemovable={() => false}
                        removeButtonText={'Remove'}
                      />
                </ColumnLayout>
              </Container>
            </Form>
          )}
      </div>
    );
  }
}
