import * as React from 'react';
import {
  Button,
  Flashbar,
  Form,
  Header,
  FormField,
  Textarea,
  Select,
  Multiselect,
  ColumnLayout,
  Toggle,
  Input,
  Container,
} from '@amzn/awsui-components-react-v3';
import {
  listDatasets,
} from '../../api/subscriptions';
import {getGroups, getGroupsConsumingDataset} from '../../api/permissions';

import {Link, Redirect} from 'react-router-dom';
import {scrollUp} from '../utils/navigation';
import {Page} from '../../routes/Paths';
import {
  convertGroupsToOption,
  convertTemplatesToOptions,
  createNotification,
  getNotificationTemplates,
  convertDataSetsToOptions,
} from '../../api/notifications';
import {getRegion, getStage} from 'src/api/config';
import {useEffect, useState} from "react";

export interface CreateNotificationFormProps {
  setContentType: any;
  activeGroup: string;
  username: string;
}


export const CreateNotificationForm = (props: CreateNotificationFormProps,) => {

  const [candidateGroupId, setCandidateGroupId] = useState(undefined);
  const [notifications, setNotifications] = useState([]);
  const [datasetsStatusType,] = useState(null);
  const [tables, setTables] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [redirect, setRedirect] = useState(undefined);
  const [content, setContent] = useState(null);
  const [priorityLevel, setPriorityLevel] = useState(null);
  const [sendEmail, setSendEmail] = useState(false);
  const [subject, setSubject] = useState(null);
  const [selectedGroupSet, setSelectedGroupSet] = useState(undefined);
  const [selectedGroupId, setSelectedGroupId] = useState(undefined);
  const [selectedDatasets, setSelectedDatasets] = useState( undefined);
  const [templates, setTemplates] = useState(undefined);
  const [selectedTemplate, setSelectedTemplate] = useState(undefined);

  //create notification
  const handleCreate = async () => {
    setButtonLoading(true);
    let response;
    try {
      response = await createNotification({
        SenderGroupId: props.activeGroup,
        ReceiverGroupIdList:
          selectedGroupId.map((item) => item['id']).length == 0
            ? ['AWSDL_Services']
            : selectedGroupId.map((item) => item['id']),
        PriorityLevel: priorityLevel.value,
        Subject: subject,
        Content: content,
        EmailNeeded: sendEmail,
      });
    } catch (e) {
      console.log(e);
      setButtonLoading(false);
      setNotifications([
        {
          type: 'error',
          content: `Notification create failed.`,
          dismissible: true,
          dismiss: () => setNotifications([]),
        },
      ]);
    }
    setButtonLoading(false);

    if (response.Message == "create success") {
      setNotifications([
        {
          type: 'success',
          content: `Notification created.`,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    } else {
      setNotifications([
        {
          type: 'error',
          content: `Notification create failed.` + response.Message,
          dismissible: true,
          dismiss: () => setNotifications([]),
        },
      ]);
    }

    scrollUp();
  };

  useEffect(() => {
    props.setContentType('form');
    fetchTemplates();
    fetchDatasets();
  }, []);


  useEffect(() => {
    fetchGroupsAccordingToDataset();
  }, [selectedDatasets]);


  const fetchGroupsAccordingToDataset = async () => {
    let groupIdList = [];
    if (selectedDatasets == null) {
      return;
    }
    let candidateIds = selectedDatasets.map((item) => item['id']);
    for (let datasetId of candidateIds) {
      const groups = await getGroupsConsumingDataset({
        datasetId: datasetId,
      });
      let groupOptions = convertGroupsToOption(groups);
      for (let groupOption of groupOptions) {
        groupIdList.push(groupOption);
      }
    }
    groupIdList = groupIdList.filter(
      (option, index, self) =>
        index ===
        self.findIndex(
          (t) => t.id === option.id && t.description == option.description,
        ),
    );
    setCandidateGroupId(groupIdList);
    setSelectedGroupId(groupIdList);
    setSelectedGroupSet([]);
  };

  const fetchAllGroups = async () => {
    const groups = await getGroups({});
    setCandidateGroupId(convertGroupsToOption(groups));
    setSelectedGroupId(convertGroupsToOption(groups));
  };

  const fetchDatasets = async () => {
    const datasets = await listDatasets({
      ownerId: props.activeGroup,
      type: 'HYBRID_CATALOG',
    });
    setTables(convertDataSetsToOptions(datasets));
  };

  const fetchTemplates = async () => {
    const templates = await getNotificationTemplates({
      GroupId: props.activeGroup,
    });
    setTemplates(convertTemplatesToOptions(templates));
  };

  const setContentAccordingToTemplate = async (e) => {
    setSelectedTemplate(e.detail.selectedOption)
    setContent(e.detail.selectedOption.templateContent);
  };

  if (redirect) return <Redirect push to={redirect}/>;


  return (
    <div>
      <Flashbar items={notifications}></Flashbar>
      <Form
        header={
          <Header description='You can create and send notifications here.' variant="h1">
            Create new notification
          </Header>
        }
        actions={
          <div>
            <Button
              variant='link'
              onClick={() => setRedirect(Page.NOTIFICATIONS)}
            >
              Cancel
            </Button>
            <Button
              variant='primary'
              onClick={handleCreate}
              loading={buttonLoading}
              disabled={content == null || selectedGroupId == null ||selectedGroupId.length == 0 || subject == null || priorityLevel == null}
            >
              Create
            </Button>
          </div>
        }
      >
        <Container
          className='custom-screenshot-hide'
          header={<h2>Notification details</h2>}
        >
          <ColumnLayout>
            <div data-awsui-column-layout-root={true}>
              <>
                <FormField
                  label={<div>Subject</div>}
                  description='The subject of the notification.'
                >
                  <Input
                    onChange={(event) => setSubject(event.detail.value)}
                    placeholder='Please input notification subject'
                    ariaRequired={true}
                    value={subject}/>
                </FormField>
                {' '}
                <FormField
                  label={<div>Notification template</div>}
                  description='Please select notification template to use.'
                >
                  <Select
                    selectedAriaLabel='Selected'
                    options={templates}
                    onChange={ ((e) => setContentAccordingToTemplate(e))}
                    placeholder='Please select the notification content template that you want to use'
                    errorText='Error fetching type.'
                    recoveryText='Retry'
                    empty={'No types available'}
                    filteringType='auto'
                    filteringAriaLabel='Filter origins'
                    ariaRequired={true}
                    statusType={datasetsStatusType}
                    loadingText='Loading templates...'
                    selectedOption={selectedTemplate}/>
                </FormField>
                <Link to={Page.NOTIFICATION_TEMPLATES}>
                  Manage notification templates
                </Link>
                {' '}

                <FormField
                  label={<div>Content</div>}
                  description='The content of the notification to be sent.'
                >
                  <Textarea
                    name='content'
                    placeholder='Please input notification content'
                    value={content}
                    ariaRequired={true}
                    onChange={(e) => setContent(e.detail.value)}
                    rows={10}
                  />
                </FormField>

                {' '}

                <FormField
                  label={<div>Priority</div>}
                  description='Notification priority level'
                >
                  <Select
                    selectedAriaLabel='Selected'
                    options={[
                      {
                        value: 'LOW',
                        label: 'Low',
                      },
                      {
                        value: 'MEDIUM',
                        label: 'Medium',
                      },
                      {
                        value: 'HIGH',
                        label: 'High',
                      },
                    ]}
                    onChange={(event) =>
                      setPriorityLevel(event.detail.selectedOption)
                    }
                    placeholder='Please select notification priority'
                    errorText='Error fetching type.'
                    recoveryText='Retry'
                    empty={'No types available'}
                    filteringType='auto'
                    filteringAriaLabel='Filter origins'
                    ariaRequired={true}
                    selectedOption={priorityLevel}/>
                </FormField>
                {' '}

                {props.activeGroup == 'AWSDL_Services' && (
                  <FormField
                    label={<div>Group Set</div>}
                    description='Please select receiver group set.'
                  >
                    <Select
                      selectedOption={selectedGroupSet}
                      selectedAriaLabel='Selected'
                      options={[
                        {
                          value: 'allGroups',
                          label: 'All Groups',
                        },
                      ]}
                      onChange={(event) => {
                        setSelectedGroupSet(event.detail.selectedOption);
                        fetchAllGroups();
                      }}
                      placeholder='Please select receivers through group set'
                      errorText='Error fetching type.'
                      recoveryText='Retry'
                      empty={'No types available'}
                      filteringType='auto'
                      filteringAriaLabel='Filter origins'
                      ariaRequired={true}
                      statusType={datasetsStatusType}
                      loadingText='Loading tables...'
                    />
                  </FormField>
                )}
                {' '}

                <FormField
                  label={<div>Get Receiver Groups through Datasets</div>}
                  description='Please select the Data Lake dataset(s) you own to get the consuming groups.'
                >
                  <Multiselect
                    selectedOptions={selectedDatasets}
                    onChange={({ detail }) =>
                      setSelectedDatasets(detail.selectedOptions)

                    }
                    deselectAriaLabel={e => `Remove ${e.label}`}
                    options={tables}
                    placeholder='Please select receivers who consumed the dataset'
                    selectedAriaLabel="Selected"
                  />
                </FormField>
                {' '}
                {((selectedDatasets != undefined && selectedDatasets.length != 0) ||
                  selectedGroupSet != null) && (
                  <>
                    <FormField
                      label={<div>Select receiver group(s)</div>}
                      description='Receiver group(s) of the notification'
                    >
                      <Multiselect
                        selectedAriaLabel='Selected'
                        options={candidateGroupId}
                        selectedOptions={selectedGroupId}
                        onChange={({detail}) => {
                          setSelectedGroupId(detail.selectedOptions)
                        }}
                        placeholder='Select the group(s) you want to send notifications to'
                        errorText='Error fetching type.'
                        recoveryText='Retry'
                        empty={'No types available'}
                        filteringType='auto'
                        filteringAriaLabel='Filter origins'
                        ariaRequired
                        keepOpen
                        statusType={datasetsStatusType}
                        loadingText='Loading tables...'
                      />
                    </FormField>

                    <FormField
                      label='Send Email'
                      description={
                        'Send email(s) to the selected group(s) from email ' +
                        `"omni-notification-${getStage()}-${getRegion()}@no-reply.datalake.aws.a2z.com"`
                      }
                    >
                      <Toggle
                        onChange={(e) => setSendEmail(e.detail.checked)}
                        checked={sendEmail}
                      />
                    </FormField>
                  </>
                )}
              </>
            </div>
          </ColumnLayout>
        </Container>
      </Form>
    </div>
  );
}



