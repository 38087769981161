import _ from 'lodash';

export const handleSelectedItem = (items, selectedItems) => {
  if (selectedItems.length == 0) {
    return [];
  } else {
    var realSelectedItems = [];
    for (var i = 0; i < selectedItems.length; i++) {
      for (var j = 0; j < items.length; j++) {
        if (_.isEqual(selectedItems[i], items[j])) {
          realSelectedItems.push(items[j]);
        }
      }
    }
    return realSelectedItems;
  }
};

export const getFieldOnChange =
  (fieldKey, onChangeFn) =>
  ({ detail: { selectedItems } }) => {
    onChangeFn({
      [fieldKey]: selectedItems,
    });
  };

export const getFilterCounterText = (count) =>
  `${count} ${count === 1 ? 'match' : 'matches'}`;
