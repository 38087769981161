import {
  Box,
  Table,
  Button,
  Header,
  TableProps,
  Pagination,
  TextFilter,
  SpaceBetween,
  Link as Info,
  CollectionPreferences,
  CollectionPreferencesProps,
} from '@amzn/awsui-components-react-v3';
import { listDatasets } from '../../../api/ingestion';

import { DataSet } from 'aws-sdk/clients/tethyscontractservicelambda';
import { iIngestionComponentProps } from '../registerContract';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { gotoMonitoring } from '../common/navigation';
import React, { useEffect, useState } from 'react';
import { UX_WRITING } from '../common/uxWriting';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Page } from 'src/routes/Paths';

const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<DataSet>[] = [
  {
    id: 'DataSetId',
    header: 'Name',
    cell: (item) => (
      <Link
        to={(l) => ({ l, pathname: `${l.pathname}/dataset/${item.DataSetId}` })}
      >
        {item.TableName}
      </Link>
    ),
    minWidth: '180px',
    sortingField: 'DataSetId',
  },
  {
    id: 'LatestVersionId',
    header: <div style={{ textAlign: 'center' }}> Database </div>,
    cell: (item) => (
      <div style={{ textAlign: 'center' }}> {item.DatabaseName} </div>
    ),
    minWidth: '100px',
    maxWidth: '200px',
    sortingField: 'LatestVersionId',
  },
  {
    id: 'LastUpdatedAt',
    header: <div style={{ textAlign: 'center' }}> AWS account ID </div>,
    cell: (item) => (
      <div style={{ textAlign: 'center' }}> {item.CreatedAt} </div>
    ),
    minWidth: '160px',
    sortingField: 'LastUpdatedAt',
  },
];

const PAGE_SELECTOR_OPTIONS: CollectionPreferencesProps.PageSizeOption[] = [
  { value: 25, label: '25 Datasets' },
  { value: 50, label: '50 Datasets' },
  { value: 100, label: '100 Datasets' },
];

const DEFAULT_PREFERENCES = {
  pageSize: 25,
  wraplines: false,
};

export const ManageDatasets = ({
  toggleHelp,
  activeGroup,
  setContentType,
}: iIngestionComponentProps) => {
  const [preferences, setPreferences] =
    useState<CollectionPreferencesProps.Preferences<any>>(DEFAULT_PREFERENCES);
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState<string>();
  const [datasets, setDatasets] = useState<DataSet[]>([]);
  const [hasError, setError] = useState<boolean>();

  useEffect(() => {
    setContentType('table');
    fetchDatasets();
  }, []);

  const fetchDatasets = async () => {
    setLoading(true);
    try {
      const { DataSets } = await listDatasets({ PrimaryOwner: activeGroup });
      setDatasets(DataSets);
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  const {
    items,
    actions,
    collectionProps,
    filterProps,
    paginationProps,
    filteredItemsCount,
  } = useCollection(datasets, {
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
    filtering: {
      noMatch: (
        <Box textAlign='center' color='inherit'>
          <b> No matches </b>
          <Box color='inherit' margin={{ top: 'xxs', bottom: 's' }}>
            No results match your query
          </Box>
          <Button onClick={() => actions.setFiltering('')}>Clear filter</Button>
        </Box>
      ),
    },
  });

  function filterCounter(count) {
    return `${count} ${count === 1 ? 'match' : 'matches'}`;
  }

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <>
      <Table
        {...collectionProps}
        columnDefinitions={COLUMN_DEFINITIONS}
        selectionType='single'
        items={items}
        loading={loading}
        loadingText='Loading resources'
        empty={
          hasError && (
            <Box textAlign='center' color='inherit'>
              <b>Unexpected error.</b>
              <Box padding={{ bottom: 's' }} variant='p' color='inherit'>
                Please try again later or report the problem.
              </Box>
            </Box>
          )
        }
        header={
          <Header
            variant='h2'
            counter={`(${datasets.length})`}
            description={'CSV datasets ingested through Tethys.'}
            info={<Info variant='info' onFollow={toggleHelp}> Feedback </Info>}
            actions={
              <SpaceBetween direction='horizontal' size={'s'}>
                <Button
                  disabled={!collectionProps.selectedItems.length}
                  variant='normal'
                  onClick={() => {
                    const [selectedDataset] = collectionProps.selectedItems
                    const monitoringRoute = gotoMonitoring(
                      activeGroup, 
                      selectedDataset.DatabaseName,
                      selectedDataset.DataSetId
                    )
                    setRedirect(monitoringRoute)
                  }}
                >
                  Monitor dataset
                </Button>
                <Button
                  variant='primary'
                  onClick={() => setRedirect(Page.INGESTION.REGISTER_CONTRACT)}
                >
                  { UX_WRITING.REGISTER_DATASET }
                </Button>
              </SpaceBetween>
            }
          >
            S3 datasets
          </Header>
        }
        preferences={
          <CollectionPreferences
            title='Preferences'
            confirmLabel='Confirm'
            cancelLabel='Cancel'
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={{
              title: 'Page size',
              options: PAGE_SELECTOR_OPTIONS,
            }}
            wrapLinesPreference={{
              label: 'Wrap lines',
              description: 'Check to see all the text and wrap the lines',
            }}
          />
        }
        wrapLines={preferences.wrapLines}
        pagination={<Pagination {...paginationProps} />}
        filter={
          <TextFilter
            {...filterProps}
            countText={filterCounter(filteredItemsCount)}
            filteringPlaceholder='Search datasets'
          />
        }
      />
    </>
  );
};
