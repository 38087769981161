import * as React from 'react';
import { Component } from 'react';

import {
  Header,
  Container,
  ExpandableSection,
} from '@amzn/awsui-components-react-v3/polaris';
import ColumnLayout from '@amzn/awsui-components-react-v3/polaris/column-layout';

export interface DetailsContainerProps {
  title: string;
  items: any;
  moreItems?: any;
}

export interface DetailsContainerState {
  redirect: any;
}

export class DetailsContainer extends Component<
  DetailsContainerProps,
  DetailsContainerState
> {
  static defaultProps = {
    title: 'Details',
  };

  state = {
    redirect: undefined,
  };

  render() {
    if (this.state.redirect) {
    }
    return (
      <Container header={<Header variant='h2'>{this.props.title}</Header>}>
        <ColumnLayout columns={3} borders={'horizontal'}>
          {this.props.items.map((item) => (
            <div>{item}</div>
          ))}
        </ColumnLayout>
        {this.props.moreItems && (
          <ExpandableSection
            header={'More details'}
            disableContentPaddings={true}
          >
            <ColumnLayout columns={3} borders={'horizontal'}>
              {this.props.moreItems.map((item) => (
                <div>{item}</div>
              ))}
            </ColumnLayout>
          </ExpandableSection>
        )}
      </Container>
    );
  }
}
