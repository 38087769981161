import * as React from 'react';
import { Component } from 'react';
import { Redirect } from 'react-router';
import {
  Flashbar,
  Select,
  Spinner,
  Table,
  Box,
} from '@amzn/awsui-components-react-v3';
import { DropdownStatusProps } from '@amzn/awsui-components-react-v3/polaris/internal/components/dropdown-status';
import {
  dataSetDetail,
  listCatalogs,
  listDataSets,
} from '../../../src/api/catalog';
import {
  editDataLakeRole,
  getDataSetSharesFiltered,
  listDataLakeRoleProperty,
} from '../../../src/api/permissions';
import { DetailsPageHeader } from './common';
import { flattenItem } from 'src/components/catalog/browsedatasets';
import { DATA_LAKE_CTI_URL } from 'src/commons/constants';
import { Page } from 'src/routes';
import { getCtiUrlFromCti } from 'src/commons/common';
import { DetailsContainer } from 'src/components/catalog/datasetdetail/detailsContainer';
import { Header, Tabs } from '@amzn/awsui-components-react-v3/polaris';
import {
  getImportantMetadata,
  getMoreMetadata,
} from 'src/components/catalog/datasetdetail/utils';
import { DatasetDetailsEditModal } from 'src/components/catalog/datasetdetail/editModal';
import IamConsumersTable from 'src/components/catalog/datasetdetail/iamConsumers';
import LineageTable from 'src/components/catalog/datasetdetail/lineageDetails';

export interface DataSetDetailProps {
  setContentType: any;
  setActiveDatasetName: any;
  activeGroup: string;
  groupInfo: any;
  username: string;
  location: any;
  match: any;
  addToCart: any;
  cartItemIds: string[];
}

export interface DataSetDetailState {
  options: object[];
  option: object;
  mostRecentOption: object;
  status: DropdownStatusProps.StatusType;
  notifications: object[];

  roleProperties: object;
  requestSending: boolean;

  dataSetShare: object;

  redirect: object;
  dataset: object;

  curNextToken: string;

  catalog: object;
  catalogLoading: boolean;

  datasetDetailsLoading: boolean;

  editDataSetModalVisible: boolean;
}

export default class DataSetDetail extends Component<
  DataSetDetailProps,
  DataSetDetailState
> {
  state = {
    editDataSetModalVisible: false,
    datasetDetailsLoading: true,
    catalogLoading: true,
    catalog: null,
    options: [],
    option: null,
    mostRecentOption: null,
    status: 'loading' as DropdownStatusProps.StatusType,
    notifications: [],
    roleProperties: undefined,
    requestSending: false,
    dataSetShare: undefined,
    redirect: undefined,
    curNextToken: null,

    dataset: {
      catalogInfo: undefined,
      LakeFormationRoleARN: undefined,
      KmsKey: undefined,
      DataClassification: undefined,
      Owners: undefined,
      DataPreview: undefined,
      IdInfo: {
        TableName: undefined,
        CatalogId: undefined,
        DatabaseName: undefined,
        Region: undefined,
      },
      RefreshCadence: undefined,
      WheeljackConfidenceFileLocation: undefined,
      PII: undefined,
      SLA: undefined,
      TableState: undefined,
      DataSetName: undefined,
      Id: undefined,
      SupportedAccessTypes: undefined,
    },
  };

  columnDefinitions = [
    {
      id: 'name',
      header: 'Name',
      cell: (item) => item.Name,
      minWidth: '200px',
    },
    {
      id: 'type',
      header: 'Type',
      cell: (item) => item.Type,
      minWidth: '200px',
    },
    {
      id: 'description',
      header: 'Description',
      cell: (item) => item.Comment,
      minWidth: '200px',
    },
  ];
  private LOAD_MORE_DATASETS_OPTION_ID: string = 'load-more-datasets';
  // display "Details" section on UI
  getDetailsContainer(version) {
    const metadata = this.getMetadata(version);

    return <DetailsContainer items={metadata} title={'Dataset details'} />;
  }
  // display "Columns" section on UI
  getColumnsTable(version) {
    return (
      <Table
        loadingText='Loading resources'
        columnDefinitions={this.columnDefinitions}
        items={version?.Columns || []}
        resizableColumns
        header={
          <Header
            counter={
              version?.Columns?.length
                ? '(' + version.Columns.length + ')'
                : '0'
            }
          >
            Columns
          </Header>
        }
        empty={
          <Box textAlign='center' variant='p'>
            <div className='awsui-util-pt-s awsui-util-mb-xs'>
              <b>No columns</b>
            </div>
            <p className='awsui-util-mb-s'>No columns to display.</p>
          </Box>
        }
        loading={this.state.status === 'loading'}
      />
    );
  }
  // display "Partition Keys" section on UI
  getPartitionKeysTable(version) {
    return (
      <Table
        loadingText='Loading resources'
        columnDefinitions={this.columnDefinitions}
        items={version?.PartitionKeys || []}
        resizableColumns
        header={
          <Header
            counter={
              version?.PartitionKeys?.length
                ? `(${version.PartitionKeys.length})`
                : '(0)'
            }
          >
            Partition keys
          </Header>
        }
        empty={
          <Box textAlign='center' variant='p'>
            <div className='awsui-util-pt-s awsui-util-mb-xs'>
              <b>No partition keys</b>
            </div>
            <p className='awsui-util-mb-s'>No partition keys to display.</p>
          </Box>
        }
        loading={this.state.status === 'loading'}
      />
    );
  }
  // display "IAM Consumers" section on UI
  getIamAccessTable() {
    return (
      <IamConsumersTable
        dataset={this.state.dataset}
        datasetId={this.props.match.params.id}
        activeGroup={this.props.activeGroup}
        onSuccess={this.iamAccessNotifySuccess}
        onFailure={this.iamAccessNotifyFailure}
      />
    );
  }
  // display "Lineage" section on UI
  getLineage() {
    return (
      <LineageTable
        dataset={this.state.dataset}
        datasetId={this.props.match.params.id}
        activeGroup={this.props.activeGroup}
      />
    );
  }

  // after revocation successfully goes through, create flashbar notification
  // indicating success
  iamAccessNotifySuccess() {
    this.setState({
      notifications: [
        {
          type: 'success',
          content: 'Successfully revoked the access.',
          dismissible: true,
          dismiss: () => this.setState({ notifications: [] }),
        },
      ],
    });
  }
  // similarly, create a flashbar notifying failure
  iamAccessNotifyFailure() {
    this.setState({
      notifications: [
        {
          type: 'error',
          content: 'Failed to revoke the access.',
          dismissible: true,
          dismiss: () => this.setState({ notifications: [] }),
        },
      ],
    });
  }

  versionDetail(version) {
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }

    let tabs = [
      {
        label: 'Details',
        id: 'Details',
        content: this.getDetailsContainer(version),
      },
      {
        label: 'Columns',
        id: 'Columns',
        content: this.getColumnsTable(version),
      },
      {
        label: 'Partition keys',
        id: 'Partition keys',
        content: this.getPartitionKeysTable(version),
      },
      {
        label: 'Lineage',
        id: 'Lineage',
        content: this.getLineage(),
      },
    ];
    if (
      this.state.dataset?.SupportedAccessTypes?.includes('IAM') &&
      this.userOwnsDataset()
    ) {
      tabs.push({
        label: 'IAM consumers',
        id: 'IAM consumers',
        content: this.getIamAccessTable(),
      });
    }

    return (
      <div>
        <Tabs tabs={tabs} />
      </div>
    );
  }

  // Collects and returns all important metadata in an array
  getMetadata(version) {
    if (this.state.datasetDetailsLoading || this.state.catalogLoading) {
      const metadata = [];
      for (let i = 0; i < 13; i++) {
        metadata.push(<Spinner size='normal' />);
      }
      return metadata;
    }
    const importantMetadata = getImportantMetadata(
      this.state.dataset,
      this.state.catalog,
      version,
    );
    const moreMetadata = getMoreMetadata(this.state.dataset, version);

    const metadata = importantMetadata.concat(moreMetadata);
    return metadata;
  }

  componentDidMount = async () => {
    this.props.setContentType('table');
    this.loadRoleProperties = this.loadRoleProperties.bind(this);

    this.loadTableDataSetShare = this.loadTableDataSetShare.bind(this);
    // This is for Edit button, when clicked it opens a form with editable parameters
    this.editDataset = this.editDataset.bind(this);
    this.closeEditModal = this.closeEditModal.bind(this);
    this.iamAccessNotifySuccess = this.iamAccessNotifySuccess.bind(this);
    this.iamAccessNotifyFailure = this.iamAccessNotifyFailure.bind(this);

    const dataSet = await listDataSets({
      Filter: {
        IdList: [this.props.match.params.id],
      },
    });
    console.assert(
      dataSet.DataSetList.length != 0,
      'Entry not found with ID: ' + this.props.match.params.id,
    );
    console.assert(
      !(dataSet.DataSetList.length > 1),
      'Multiple entries found with ID: ' + this.props.match.params.id,
    );
    this.setState({
      dataset: dataSet.DataSetList[0],
      catalogLoading: true,
      datasetDetailsLoading: true,
    });
    this.fetchCatalog();
    this.fetchDetails();

    this.props.setActiveDatasetName(this.state.dataset.DataSetName);
    await this.loadRoleProperties();
    await this.loadTableDataSetShare();
  };

  // Second parameter can be previous state if needed
  componentDidUpdate = async (prevProps, _) => {
    if (prevProps.activeGroup !== this.props.activeGroup) {
      await this.loadRoleProperties();
      await this.loadTableDataSetShare();
    }
  };

  getFormattedOptions(listOfDataSetDetailResponses) {
    const mappedList = listOfDataSetDetailResponses.map((i) => ({
      id: i.VersionId.toString(),
      label: 'Version ' + i.VersionId,
      item: i,
    }));
    mappedList.sort(function (a, b) {
      return b.item.VersionId - a.item.VersionId;
    });
    return mappedList;
  }

  loadRoleProperties = async () => {
    const roleProperties = await listDataLakeRoleProperty({
      groupId: this.props.activeGroup,
    });
    this.setState({
      roleProperties,
    });
  };

  loadTableDataSetShare = async () => {
    if (!this.props.activeGroup) {
      return;
    }
    const dataSetShares = await getDataSetSharesFiltered({
      groupId: this.props.activeGroup,
      status: 'Active',
      option: 'Consumer',
      type: 'LF',
      nextToken: null,
    });
    const dataSetShare = dataSetShares.dataSetShareList.filter(
      (dataSetShare) =>
        dataSetShare.catalogId === this.state.dataset?.IdInfo.CatalogId &&
        dataSetShare.databaseName === this.state.dataset?.IdInfo.DatabaseName &&
        (dataSetShare.tableName === this.state.dataset?.IdInfo.TableName ||
          dataSetShare.tableName === 'ALL_TABLES'),
    );
    this.setState({
      dataSetShare,
    });
  };

  // Get the catalog for the current dataset
  fetchCatalog = async () => {
    let catalog;
    try {
      catalog = await listCatalogs({
        Filter: {
          CatalogKeyList: [
            {
              CatalogId: this.state.dataset.IdInfo.CatalogId,
              Region: this.state.dataset.IdInfo.Region,
            },
          ],
        },
      });
    } catch (e) {
      console.log('Could not load catalog for dataset: ' + this.state.dataset);
      catalog = null;
    }
    this.setState({
      catalog: catalog?.CatalogInfoList[0],
      catalogLoading: false,
    });
  };

  fetchDetails = async () => {
    const dataSetDetails = await dataSetDetail({
      Filter: {
        IdList: [this.props.match.params.id],
      },
    });
    // we reverse the version list so that the newest versions show first
    const formattedOptions = this.getFormattedOptions(
      dataSetDetails.DataSetDetailList,
    );

    const noError = formattedOptions.length > 0;

    if (noError) {
      formattedOptions[0].label = formattedOptions[0].label + ' (latest)';
    }

    if (dataSetDetails.NextToken != null) {
      formattedOptions.push({
        id: this.LOAD_MORE_DATASETS_OPTION_ID,
        label: 'Load more...',
        item: null,
      });
    }

    if (!noError) {
      this.setState({
        datasetDetailsLoading: false,
        notifications: [
          {
            type: 'error',
            content: `Failed to load dataset details.`,
            dismissible: true,
            dismiss: () => this.setState({ notifications: [] }),
          },
        ],
      });
    }

    const newStatus = noError ? 'finished' : 'error';
    const newOption = noError ? formattedOptions[0] : null;
    this.setState({
      datasetDetailsLoading: false,
      options: formattedOptions,
      mostRecentOption: newOption,
      status: newStatus,
      option: null,
      curNextToken: dataSetDetails.NextToken,
    });
  };

  getDropdownOptions() {
    if (!(this.state.roleProperties && this.state.dataSetShare)) {
      return [
        {
          text: 'Loading...',
          disabled: true,
          loading: true,
        },
      ];
    }
    let dropdowns = [];
    if (this.state.dataset?.SupportedAccessTypes.includes('IAM')) {
      dropdowns = dropdowns.concat(this.getIamDropdown());
    }
    if (this.state.dataset?.SupportedAccessTypes.includes('LakeFormation')) {
      dropdowns = dropdowns.concat(this.getLakeFormationDropdown());
    }
    return dropdowns;
  }

  getIamDropdown() {
    return [
      {
        text: 'IAM access',
        loading: this.state.catalogLoading || this.state.datasetDetailsLoading,
        onItemClick: this.handleAction,
        items: [
          {
            text: 'Add to cart',
            disabled: this.props.cartItemIds.includes(this.state.dataset?.Id),
            variant: 'normal',
            id: 'IAM',
          },
        ],
      },
    ];
  }
  getLakeFormationDropdown() {
    const lakeFormationAccess = !(
      this.state.dataSetShare === undefined ||
      this.state.dataSetShare.length === 0
    );
    return [
      {
        text: 'Lake Formation access',
        loading: this.state.catalogLoading || this.state.datasetDetailsLoading,
        onItemClick: this.handleAction,
        items: [
          {
            text: 'Request access',
            disabled: lakeFormationAccess,
            variant: 'normal',
            id: 'LakeFormation',
          },
          {
            text: 'View dataset share',
            disabled: !lakeFormationAccess,
            variant: 'normal',
            id: 'LakeFormation',
          },
        ],
      },
    ];
  }

  async handleIamRequest() {
    const flattenedDataset = flattenItem(
      this.state.dataset,
      null,
      this.state.catalog,
    );
    this.props.addToCart([flattenedDataset]);
  }

  async handleLakeFormationRequest() {
    if (this.state.dataSetShare?.length !== 0) {
      this.redirectViewDataSetShares();
    }
    // otherwise, request access
    else {
      this.redirectLakeFormationRequest();
    }
  }

  handleAction = async (e) => {
    if (e.detail.id === 'IAM') {
      await this.handleIamRequest();
    } else if (e.detail.id === 'LakeFormation') {
      this.handleLakeFormationRequest();
    }
  };

  async submitAccessRequest() {
    this.setState({ requestSending: true });

    try {
      await editDataLakeRole({
        groupId: this.props.activeGroup,
        datasetsToAdd: [this.props.match.params.id],
      });
      await this.loadRoleProperties();
    } catch (err) {
      this.setState({
        notifications: [
          {
            type: 'error',
            content: `Failed to submit the access request.`,
            dismissible: true,
            dismiss: () => this.setState({ notifications: [] }),
          },
        ],
        requestSending: false,
      });
      return;
    }

    this.setState({
      notifications: [
        {
          type: 'success',
          content: `Successfully submitted the access request. This will remain pending until the data owners approve or deny the request.`,
          dismissible: true,
          dismiss: () => this.setState({ notifications: [] }),
        },
      ],
      requestSending: false,
    });
  }

  // Note that the option to load more datasets will not be listed if the
  // NextToken is null.
  handleSelectChange = async (e) => {
    const selectedOption = e.detail.selectedOption;
    if (
      selectedOption != null &&
      selectedOption.id == this.LOAD_MORE_DATASETS_OPTION_ID
    ) {
      const combinedOptions = [];
      for (let i = 0; i < this.state.options.length - 1; i++) {
        combinedOptions.push(this.state.options[i]);
      }

      const response = await dataSetDetail({
        NextToken: this.state.curNextToken,
      });

      combinedOptions.push(
        ...this.getFormattedOptions(response.DataSetDetailList),
      );

      if (response.NextToken != null) {
        combinedOptions.push(this.state.options[this.state.options.length - 1]);
      }

      // Note that option should remain unchanged
      this.setState({
        options: combinedOptions,
        curNextToken: response.NextToken,
        option: combinedOptions[this.state.options.length - 1],
      });
    } else {
      this.setState({ option: e.detail.selectedOption });
    }
  };

  redirectViewDataSetShares() {
    let to: {};
    if (
      this.state.dataSetShare == undefined ||
      this.state.dataSetShare?.length > 1
    ) {
      to = {
        pathname: Page.MY_DATASETS,
      };
    } else {
      to = {
        pathname:
          Page.MY_DATASETS + '/' + this.state.dataSetShare[0]?.dataSetShareId,
      };
    }
    this.setState({ redirect: to });
  }

  redirectLakeFormationRequest() {
    const id = this.props.match.params.id;
    const catalogId = this.state.dataset?.IdInfo.CatalogId;
    const databaseName = this.state.dataset?.IdInfo.DatabaseName;
    const tableName =
      this.state.dataset &&
      this.state.dataset.IdInfo &&
      this.state.dataset.IdInfo.TableName
        ? this.state.dataset.IdInfo.TableName
        : '';
    const to = {
      pathname: Page.CREATE_LAKE_FORMATION_PERMISSIONS,
      state: {
        tableInfo: { id },
        tableName,
        databaseName,
        catalogId,
      },
    };
    this.setState({ redirect: to });
  }

  redirectSubscribe() {
    const id = this.props.match.params.id;
    const dataSetName =
      this.state.dataset && this.state.dataset.DataSetName
        ? this.state.dataset.DataSetName
        : '';
    const tableName =
      this.state.dataset &&
      this.state.dataset.IdInfo &&
      this.state.dataset.IdInfo.TableName
        ? this.state.dataset.IdInfo.TableName
        : '';
    const to = {
      pathname: Page.CREATE_SUBSCRIPTION,
      state: {
        tableInfo: { id },
        tableName,
        selectedTables: [{ id, description: tableName, label: dataSetName }],
      },
    };
    this.setState({ redirect: to });
  }

  // open up the modal view to edit the dataset metadata
  editDataset() {
    this.setState({
      editDataSetModalVisible: true,
    });
  }
  closeEditModal() {
    this.setState({
      editDataSetModalVisible: false,
    });
  }
  notifyEditSuccess = async () => {
    await this.componentDidMount();
    this.setState({
      notifications: [
        {
          type: 'success',
          content: `Successfully edited the dataset.`,
          dismissible: true,
          dismiss: () => this.setState({ notifications: [] }),
        },
      ],
    });
  };
  notifyEditFailure() {
    this.setState({
      notifications: [
        {
          type: 'error',
          content: `Failed to edit the dataset.`,
          dismissible: true,
          dismiss: () => this.setState({ notifications: [] }),
        },
      ],
    });
  }

  render() {
    // if the dataset does not exist, OR
    // if the dataset is private and the active user is not the owner,
    // then show an error.
    if (
      !this.state.dataset ||
      (this.state.dataset.DataClassification == 'Private' &&
        !this.userOwnsDataset())
    ) {
      return (
        <>
          <h2>Dataset not found</h2>
          The given dataset ID {this.props.match.params.id} is not valid. Please
          check the URL for mistakes and try again.
        </>
      );
    }

    let header;
    if (this.state.dataset.DataSetName) {
      header = this.state.dataset.DataSetName;
    } else {
      header = <Spinner size='big' />;
    }

    let version;
    if (this.state.status === 'error') {
      version = 'No metadata available';
    } else if (this.state.option == null) {
      if (this.state.mostRecentOption == null) {
        // If everything is not loaded yet
        version = <Spinner size='normal' />;
      } else {
        // If we have not selected an option.  Default to most recent
        version = 'Metadata version ' + this.state.mostRecentOption.id;
      }
    } else {
      version = 'Metadata version ' + this.state.option.id;
    }

    return (
      <>
        <Flashbar items={this.state.notifications} />

        {this.state.editDataSetModalVisible && (
          <DatasetDetailsEditModal
            visible={this.state.editDataSetModalVisible}
            dismiss={this.closeEditModal}
            notifySuccess={this.notifyEditSuccess}
            notifyFailure={this.notifyEditFailure}
            dataset={this.state.dataset}
            activeGroup={this.props.activeGroup}
          />
        )}

        <DetailsPageHeader
          header={header}
          buttons={this.getDropdownOptions()}
          editButton={
            this.state?.dataset?.Owners
              ? {
                  text: 'Edit',
                  hidden: !this.userOwnsDataset(),
                  onItemClick: this.editDataset,
                  loading: false,
                }
              : {
                  text: 'Loading...',
                  hidden: true,
                  loading: true,
                }
          }
          versionHeader={version}
          leftSubheader={
            this.state.dataset.TableState
              ? 'Table status: ' + this.state.dataset.TableState
              : ''
          }
          rightSubheader={this.getCti()}
          versionSelect={
            <Select
              options={this.state.options}
              selectedOption={this.state.option}
              loadingText='Loading...'
              placeholder='Metadata history'
              statusType={this.state.status}
              errorText='Error: Unable to load metadata history.'
              onChange={this.handleSelectChange}
              ariaRequired={true}
            />
          }
        />
        <div>
          {(this.state.option == null &&
            this.state.mostRecentOption &&
            this.versionDetail(this.state.mostRecentOption?.item)) ||
            this.versionDetail(this.state.option?.item)}
        </div>
      </>
    );
  }

  // is the current user a member of a group that owns the dataset?
  userOwnsDataset() {
    return this.state.dataset.Owners.includes(this.props.activeGroup);
  }

  private getCti() {
    if (this.state.catalogLoading) {
      return <Spinner size='normal' />;
    } else if (this.state.catalog == null) {
      return (
        <>
          {'Catalog CTI: '}
          <a href={DATA_LAKE_CTI_URL}>AWS/DataLake/Catalog Issues</a>
        </>
      );
    } else {
      return (
        <>
          {'Catalog CTI: '}
          <a href={getCtiUrlFromCti(this.state.catalog?.CTI)}>
            {this.state.catalog?.CTI}
          </a>
        </>
      );
    }
  }
}
