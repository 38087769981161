import * as React from 'react';
import {
  Button,
  ButtonDropdown,
  ColumnLayout, Header,
  Icon,
  SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import {parseDatasetId} from '../../api/subscriptions';

export const PageHeader = ({buttons, header}) => {
  return (
        <Header actions = {
            <SpaceBetween direction="horizontal" size="s">
              {buttons.map((button, key) =>
                !button.items ? (
                  <Button
                    href={button.href || ''}
                    disabled={button.disabled || false}
                    iconName={button.icon}
                    onClick={button.onItemClick}
                    key={key}
                    loading={button.loading || false}
                    variant={button.variant || 'normal'}
                  >
                    {button.text}
                  </Button>
                ) : (
                  <ButtonDropdown
                    variant={button.variant}
                    items={button.items}
                    key={key}
                    onItemClick={button.onItemClick}
                    loading={button.loading}
                  >
                    {button.text}
                  </ButtonDropdown>
                ),
              )}
            </SpaceBetween>
        }>{header}</Header>
  );
};

export const SubscriptionDetails = ({subscription}) => (
  <div className='awsui-util-container'>
    <div className='awsui-util-container-header'>
      <h2>Subscription details</h2>
    </div>
    <ColumnLayout columns={4} variant='text-grid'>
      <div>
        <div className='awsui-util-label'>Source account</div>
        <div>{parseDatasetId(subscription.datasetId).account}</div>
      </div>
      <div>
        <div className='awsui-util-label'>Source database</div>
        <div>{parseDatasetId(subscription.datasetId).database}</div>
      </div>
      <div>
        <div className='awsui-util-label'>Source table</div>
        <div>{parseDatasetId(subscription.datasetId).table}</div>
      </div>
      <div>
        <div className='awsui-util-label'>Status</div>
        <div className='awsui-util-status-positive'>
          <Icon variant='success' name='status-positive'></Icon> Enabled
        </div>
      </div>
      <div>
        <div className='awsui-util-label'>Target connection</div>
        <div>{subscription.targetId}</div>
      </div>
      <div>
        <div className='awsui-util-label'>Target schema</div>
        <div>{subscription.schema}</div>
      </div>
      <div>
        <div className='awsui-util-label'>Target table name</div>
        <div>{subscription.table}</div>
      </div>
      <div>
        <div className='awsui-util-label'>Attached IAM role</div>
        <div>{subscription.iamRole}</div>
      </div>
    </ColumnLayout>
  </div>
);

export const ConnectionDetailsComponent = ({target}) => (
  <div className='awsui-util-container'>
    <div className='awsui-util-container-header'>
      <h2>Connection details</h2>
    </div>
    <ColumnLayout columns={4} variant='text-grid'>
      <div>
        <div className='awsui-util-label'>Owner</div>
        <div> {target.ownerId}</div>
      </div>
      <div>
        <div className='awsui-util-label'>Connection ID</div>
        <div> {target.targetId}</div>
      </div>
      <div>
        <div className='awsui-util-label'>Connection name</div>
        <div> {target.name}</div>
      </div>
      <div>
        <div className='awsui-util-label'>IAM role to connect to it</div>
        <div> {target.IamRole}</div>
      </div>
      <div>
        <div className='awsui-util-label'>
          Default IAM role for data access
        </div>
        <div> {target.defaultAttachedRole}</div>
      </div>
      <div>
        <div className='awsui-util-label'>Region</div>
        <div> {target.region}</div>
      </div>
      <div>
        <div className='awsui-util-label'>Create date</div>
        <div> {target.createDate}</div>
      </div>
      <div>
        <div className='awsui-util-label'>Created by</div>
        <div> {target.createdBy}</div>
      </div>
    </ColumnLayout>
  </div>
);
