import { IAMAccess } from 'src/components/permissions/myBaselining/stepComponents/IAMAccess';
import { LFAccessConsumer } from 'src/components/permissions/myBaselining/stepComponents/LFAccessConsumer';
import { LFAccessOwner } from 'src/components/permissions/myBaselining/stepComponents/LFAccessOwner';
import { Review } from 'src/components/permissions/myBaselining/stepComponents/Review';
import { IAMPrincipal } from 'src/components/permissions/myBaselining/stepComponents/IAMPrincipal';
import React, { useCallback, useEffect, useState } from 'react';
import {
  DEFAULT_STEP_INFO,
  TOOLS_CONTENT,
} from 'src/components/permissions/myBaselining/steps-config';
import {
  Box,
  Button,
  ColumnLayout,
  Container,
  Flashbar,
  Header,
  Modal,
  Spinner,
  Wizard,
} from '@amzn/awsui-components-react-v3';
import StatusIndicator from '@amzn/awsui-components-react-v3/polaris/status-indicator';
import {
  getDataSetSharesByGroupId,
  listDataLakeRoleProperty,
} from 'src/api/permissions';
import { Page } from 'src/routes';
import { Redirect } from 'react-router-dom';
import { StartPage } from 'src/components/permissions/myBaselining/stepComponents/StartPage';
import { isValidAccoundId, isValidRegion } from 'src/commons/validationUtils';
import { listCatalogs, listDataSets } from 'src/api/catalog';
import { submitBaseline } from 'src/api/notifications';

export function Baselining(props) {
  const [roleArns, setRoleArns] = useState([]);
  const [approvedPermissions, setApprovedPermissions] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [redirect, setRedirect] = useState(false);

  //IAM Access
  const [tableItems, setTableItems] = useState([]);
  const [dataSetsLoading, setDataSetsLoading] = useState(true);
  const [catalogsLoading, setCatalogsLoading] = useState(true);
  const [catalogsMap, setCatalogsMap] = useState(new Map());

  //IAM Principal
  const [trustedEntities, setTrustedEntities] = useState([]);
  const [trustedEntitiesError, setTrustedEntitiesError] = useState([]);

  //LF Access Consumer
  const [loadingDataSetsConsumer, setLoadingDataSetsConsumer] = useState(true);
  const [datasetItemsConsumer, setDatasetItemsConsumer] = useState([]);
  const [datasetItemsConsumerError, setDatasetItemsConsumerError] = useState(
    [],
  );

  //LF Access Owner
  const [loadingDataSetsOwner, setLoadingDataSetsOwner] = useState(true);
  const [datasetItemsOwner, setDatasetItemsOwner] = useState([]);
  const [datasetItemsOwnerError, setDatasetItemsOwnerError] = useState([]);

  const [submittedBaseline, setSubmittedBaseline] = useState(false);
  const [submittedBaselineError, setSubmittedBaselineError] =
    useState(undefined);

  const steps = [
    {
      title: 'Start your baselining',
      stateKey: 'StartPage',
      StepContent: StartPage,
    },
    {
      title: 'Baseline IAM access',
      stateKey: 'BaselineIAMAccess',
      StepContent: IAMAccess,
    },
    {
      title: 'Baseline IAM principals',
      stateKey: 'BaselineIAMPrincipal',
      StepContent: IAMPrincipal,
    },
    {
      title: 'Baseline Lake Formation access as consumer',
      stateKey: 'BaselineLFAccessConsumer',
      StepContent: LFAccessConsumer,
    },
    {
      title: 'Baseline Lake Formation access as owner',
      stateKey: 'BaselineLFAccessOwner',
      StepContent: LFAccessOwner,
    },
    {
      title: 'Review and baseline',
      stateKey: 'Review',
      StepContent: Review,
    },
  ];

  const i18nStrings = {
    stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
    collapsedStepsLabel: (stepNumber, stepsCount) =>
      `Step ${stepNumber} of ${stepsCount}`,
    cancelButton: 'Cancel',
    previousButton: 'Previous',
    nextButton: 'Next',
    submitButton: 'Submit',
    optional: 'optional',
  };

  const useWizard = () => {
    const [activeStepIndex, setActiveStepIndex] = useState(0);
    const [stepsInfo, setStepsInfo] = useState(DEFAULT_STEP_INFO);

    const onStepInfoChange = useCallback(
      (stateKey, newStepState) => {
        setStepsInfo({
          ...stepsInfo,
          [stateKey]: {
            ...stepsInfo[stateKey],
            ...newStepState,
          },
        });
      },
      [stepsInfo],
    );

    const setActiveStepIndexAndCloseTools = (index) => {
      setActiveStepIndex(index);
    };

    const onNavigate = (evt) => {
      setActiveStepIndexAndCloseTools(evt.detail.requestedStepIndex);
    };

    const onCancel = () => {
      cleanState();
      // here to return to baselinign page
    };

    const onSubmit = () => {
      setModalVisible(true);
      handleSubmitBaseline();
    };

    const cleanState = () => {
      setModalVisible(false);
      setActiveStepIndex(0);
      setStepsInfo(DEFAULT_STEP_INFO);
    };

    return {
      activeStepIndex,
      stepsInfo,
      setActiveStepIndexAndCloseTools,
      onStepInfoChange,
      onNavigate,
      onCancel,
      onSubmit,
    };
  };

  const closeModal = () => {
    setModalVisible(false);
    //window.location.reload();
    setRedirect(true);
  };

  const {
    activeStepIndex,
    stepsInfo,
    setActiveStepIndexAndCloseTools,
    onStepInfoChange,
    onNavigate,
    onCancel,
    onSubmit,
  } = useWizard();

  useEffect(() => {
    (async () => {
      props.setContentType('table');
      if (props.activeGroup) {
        await fetchDatasets();
        await fetchCatalogsForDataSets();
        await fetchDatasetSharesForConsumer();
        await fetchDatasetSharesForOwner();
      }
    })();
  }, []);

  const fetchCatalogsForDataSets = async () => {
    setCatalogsLoading(true);

    // Use a set to make them unique
    const catalogIdRegions = new Set<string>();
    for (const item of tableItems) {
      if (
        item.IdInfo != null &&
        item.IdInfo.CatalogId != null &&
        item.IdInfo.Region != null
      ) {
        const key: string = item.IdInfo.CatalogId + ':' + item.IdInfo.Region;
        catalogIdRegions.add(key);
      }
    }

    // Start to build a list of catalog keys for the request
    const catalogKeys = [];
    for (const idRegion of catalogIdRegions) {
      const catalogIdAndRegion = idRegion.split(':');
      const catalogId = catalogIdAndRegion[0];
      const region = catalogIdAndRegion[1];
      // Non-null if there's a match
      if (isValidAccoundId(catalogId) && isValidRegion(region)) {
        catalogKeys.push({
          CatalogId: catalogId,
          Region: region,
        });
      }
    }
    const request = {
      Filter: {
        CatalogKeyList: catalogKeys,
      },
    };
    const result = await listCatalogs(request);

    // Populate a map with our new catalogs
    setCatalogsMap(new Map());
    for (let i = 0; i < result.CatalogInfoList.length; i++) {
      addCatalogItemToMap(result.CatalogInfoList[i]);
    }
    setCatalogsLoading(false);
  };

  const addCatalogItemToMap = (item) => {
    const catalogId: string = item.CatalogId;
    const region: string = item.Region;
    setCatalogsMap(catalogsMap.set(catalogId + ':' + region, item));
  };

  const fetchDatasets = async () => {
    setDataSetsLoading(true);
    try {
      const roleProperties = await listDataLakeRoleProperty({
        groupId: props.activeGroup,
      });
      setApprovedPermissions(roleProperties.approvedPermissions);
      setRoleArns(roleProperties.roleArns);
      setTrustedEntities(roleProperties.trustedEntities);

      let request = {};
      if (roleProperties.approvedPermissions) {
        request = { Filter: { IdList: roleProperties.approvedPermissions } };
      }

      const dataSets = await listDataSets(request);

      const dataSetList = dataSets.DataSetList;

      // here, datasets are filtered by "public" and "restricted" only,
      // The "private" datasets will be visible only to owners
      let publicAndRestrictedDataSets = [];
      let privateDataSets = [];
      for (const dataSet of dataSetList) {
        if (
          dataSet.DataClassification === 'Public' ||
          dataSet.DataClassification === 'Restricted'
        ) {
          publicAndRestrictedDataSets.push(dataSet);
        } else {
          if (dataSet.Owners.includes(props.activeGroup)) {
            privateDataSets.push(dataSet);
          }
        }

        // Apply valid Primary owner to datasets without one so it can be filterable
        dataSet.PrimaryOwner =
          dataSet.PrimaryOwner != null
            ? dataSet.PrimaryOwner
            : 'No primary owner';
      }
      setTableItems(publicAndRestrictedDataSets.concat(privateDataSets));
      setDataSetsLoading(false);
    } catch (error) {
      console.log(error);
      setTrustedEntitiesError([
        {
          type: 'error',
          content: `There was an error '${error.message}' while loading IAM principals and datasets`,
          dismissible: true,
          dismiss: () => this.setState({ notifications: [] }),
        },
      ]);
      setDataSetsLoading(false);
    }
  };

  const fetchDatasetSharesForConsumer = async () => {
    if (!props.activeGroup) return;
    setLoadingDataSetsConsumer(true);
    try {
      const dataSetShares = await getDataSetSharesByGroupId({
        groupId: props.activeGroup,
        statusOptionType: 'Active#',
        isPrefix: true,
        nextToken: null,
      });
      setDatasetItemsConsumer(
        dataSetShares.dataSetShareList.filter(
          (item) => item.option == 'Consumer',
        ),
      );
      setLoadingDataSetsConsumer(false);
    } catch (err) {
      setDatasetItemsConsumerError([
        {
          type: 'error',
          content: `There was an error '${err.message}' while Lake Formation access as consumer`,
          dismissible: true,
          dismiss: () => this.setState({ notifications: [] }),
        },
      ]);
      setLoadingDataSetsConsumer(false);
    }
  };

  const fetchDatasetSharesForOwner = async () => {
    if (!props.activeGroup) return;
    setLoadingDataSetsOwner(true);
    try {
      const dataSetShares = await getDataSetSharesByGroupId({
        groupId: props.activeGroup,
        statusOptionType: 'Active#',
        isPrefix: true,
        nextToken: null,
      });
      setDatasetItemsOwner(
        dataSetShares.dataSetShareList.filter(
          (item) => item.option == 'Publisher',
        ),
      );
      setLoadingDataSetsOwner(false);
    } catch (err) {
      setDatasetItemsOwnerError([
        {
          type: 'error',
          content: `There was an error '${err.message}' while Lake Formation access as owner`,
          dismissible: true,
          dismiss: () => this.setState({ notifications: [] }),
        },
      ]);
      setLoadingDataSetsOwner(false);
    }
  };

  var newProps = {
    ...props,
    roleArns: roleArns,
    approvedPermissions: approvedPermissions,
    tableItems: tableItems,
    catalogsMap: catalogsMap,
    trustedEntities: trustedEntities,
    datasetItemsConsumer: datasetItemsConsumer,
    datasetItemsOwner: datasetItemsOwner,
  };

  const handleSubmitBaseline = async () => {
    var datasetsId = [];
    for (
      var i = 0;
      i < stepsInfo.BaselineIAMAccess.selectedIamAccess.length;
      i++
    ) {
      datasetsId.push(stepsInfo.BaselineIAMAccess.selectedIamAccess[i].id);
    }

    var datasetSharesConsumer = [];
    for (
      var i = 0;
      i < stepsInfo.BaselineLFAccessConsumer.selectedLFConsumer.length;
      i++
    ) {
      datasetSharesConsumer.push(
        stepsInfo.BaselineLFAccessConsumer.selectedLFConsumer[i].dataSetShareId,
      );
    }

    var datasetSharesOwner = [];
    for (
      var i = 0;
      i < stepsInfo.BaselineLFAccessOwner.selectedLFOwner.length;
      i++
    ) {
      datasetSharesOwner.push(
        stepsInfo.BaselineLFAccessOwner.selectedLFOwner[i].dataSetShareId,
      );
    }
    try {
      await submitBaseline({
        GroupId: props.activeGroup,
        IamDatasetsToDelete: datasetsId,
        IamPrincipalToDelete:
          stepsInfo.BaselineIAMPrincipal.selectedIAMPrinciples,
        DataSetShareToDeactivateAsConsumer: datasetSharesConsumer,
        DataSetShareToDeactivateAsOwner: datasetSharesOwner,
      });
    } catch (err) {
      setSubmittedBaselineError(err.message);
    }

    setSubmittedBaseline(true);
  };

  const getWizardSteps = () => {
    var curSteps = steps.slice();
    var index = 0;
    if (tableItems.length == 0) {
      curSteps.splice(1, 1);
      index = index + 1;
    }

    if (trustedEntities.length == 0) {
      curSteps.splice(2 - index, 1);
      index = index + 1;
    }

    if (datasetItemsConsumer.length == 0) {
      curSteps.splice(3 - index, 1);
      index = index + 1;
    }

    if (datasetItemsOwner.length == 0) {
      curSteps.splice(4 - index, 1);
      index = index + 1;
    }

    return curSteps.map(({ title, stateKey, StepContent }) => ({
      title,
      description: TOOLS_CONTENT[stateKey].default.content,
      content: (
        <StepContent
          info={stepsInfo}
          props={newProps}
          onChange={(newStepState) => onStepInfoChange(stateKey, newStepState)}
          setActiveStepIndex={setActiveStepIndexAndCloseTools}
        />
      ),
    }));
  };

  if (redirect) {
    return (
      <Redirect
        push
        to={{
          pathname: Page.HOME,
        }}
      />
    );
  }

  return (
    <>
      {trustedEntitiesError.length > 0 && (
        <Flashbar items={trustedEntitiesError} />
      )}
      {datasetItemsConsumerError.length > 0 && (
        <Flashbar items={datasetItemsConsumerError} />
      )}
      {datasetItemsOwnerError.length > 0 && (
        <Flashbar items={datasetItemsOwnerError} />
      )}

      <Modal
        visible={modalVisible}
        header={[`Baselining in progress`]}
        onDismiss={closeModal}
        footer={
          <span className='awsui-util-f-r'>
            <Button
              variant='primary'
              loading={!submittedBaseline}
              onClick={closeModal}
            >
              Finished
            </Button>
          </span>
        }
      >
        <div>
          <p>
            {!submittedBaseline && (
              <>
                <StatusIndicator type='loading'>
                  Submitting your baselining request
                </StatusIndicator>
              </>
            )}
            {submittedBaseline && submittedBaselineError != undefined && (
              <>
                <StatusIndicator type='error'>
                  {submittedBaselineError} when submitting your baselining
                  request
                </StatusIndicator>
              </>
            )}
            {submittedBaseline && submittedBaselineError == undefined && (
              <>
                <StatusIndicator type='success'>
                  Submitted your baselining request.
                </StatusIndicator>
              </>
            )}
          </p>
        </div>
      </Modal>
      {loadingDataSetsConsumer == false &&
        loadingDataSetsConsumer == false &&
        loadingDataSetsOwner == false &&
        (tableItems.length != 0 ||
          trustedEntities.length != 0 ||
          datasetItemsConsumer.length != 0 ||
          datasetItemsOwner.length != 0) && (
          <Wizard
            steps={getWizardSteps()}
            activeStepIndex={activeStepIndex}
            i18nStrings={i18nStrings}
            onNavigate={onNavigate}
            onCancel={onCancel}
            onSubmit={onSubmit}
          />
        )}
      {loadingDataSetsConsumer == false &&
        loadingDataSetsConsumer == false &&
        loadingDataSetsOwner == false &&
        tableItems.length == 0 &&
        trustedEntities.length == 0 &&
        datasetItemsConsumer.length == 0 &&
        datasetItemsOwner.length == 0 && (
          <Container
            header={
              <Header variant='h2'>Welcome to the Omni baselining tool</Header>
            }
          >
            <ColumnLayout columns={1}>
              You have no dataset permission at this moment. Explore and
              discover more available datasets on Omni.
            </ColumnLayout>
          </Container>
        )}
      {(dataSetsLoading ||
        catalogsLoading ||
        loadingDataSetsConsumer ||
        loadingDataSetsOwner) && (
        <div>
          <Box textAlign='center' variant='p'>
            <Spinner size='big' />
            <h5> Loading your permissions, this might take a moment...</h5>
          </Box>
        </div>
      )}
    </>
  );
}
