import awsdatalakeresourcesmanagerlambda, {
  CreateBootstrapActionRequest,
  CreateResourceGroupRequest,
  DeleteBootstrapActionRequest,
  DeleteBootstrapActionResponse,
  DeleteResourceGroupRequest,
  DeleteResourceGroupResponse,
  GetBootstrapActionRequest,
  GetResourceGroupInfoRequest,
  GetResourceGroupRequest,
  ListBootstrapActionsResponse,
  ListItemsRequest,
  ListResourceGroupsInfoResponse,
  ListResourceGroupsResponse,
  UpdateBootstrapActionRequest,
  UpdateResourceGroupRequest,
  RefreshResourceRequest,
  RefreshResourceResponse,
  BootstrapAction,
  ResourceGroup,
  ResourceGroupInfo,
  Resource,
  ListResourcesRequest,
  ListResourcesResponse,
  GetResourceRequest,
  CreateTemplateRequest,
  DeleteTemplateRequest,
  DeleteTemplateResponse,
  GetTemplateRequest,
  ListTemplatesResponse,
  UpdateTemplateRequest,
  Template,
} from 'aws-sdk/clients/awsdatalakeresourcesmanagerlambda';
import basicCallWrapper from './client_call';
import { getResourcesManagerConfig } from './config';
import { getCredentialProvider } from './auth';

const config = getResourcesManagerConfig();
const credentials = getCredentialProvider();

const resourcesManagerClientConfig: awsdatalakeresourcesmanagerlambda.ClientConfiguration =
  {
    region: config.region,
    endpoint: config.endpoint,
    credentials: credentials,
  };

const resourcesmanager = new awsdatalakeresourcesmanagerlambda(
  resourcesManagerClientConfig,
);

// CREATE

export const createResourceGroup = (input: CreateResourceGroupRequest) => {
  return basicCallWrapper<CreateResourceGroupRequest, ResourceGroup>(
    resourcesmanager,
    resourcesmanager.createResourceGroup,
  )(input);
};

export const createBootstrapAction = (input: CreateBootstrapActionRequest) => {
  return basicCallWrapper<CreateBootstrapActionRequest, BootstrapAction>(
    resourcesmanager,
    resourcesmanager.createBootstrapAction,
  )(input);
};

export const createTemplate = (input: CreateTemplateRequest) => {
  return basicCallWrapper<CreateTemplateRequest, Template>(
    resourcesmanager,
    resourcesmanager.createTemplate,
  )(input);
};

// RETRIEVE / GET

export const getResourceGroup = (input: GetResourceGroupRequest) => {
  return basicCallWrapper<GetResourceGroupRequest, ResourceGroup>(
    resourcesmanager,
    resourcesmanager.getResourceGroup,
  )(input);
};

export const getResourceGroupInfo = (input: GetResourceGroupInfoRequest) => {
  return basicCallWrapper<GetResourceGroupInfoRequest, ResourceGroupInfo>(
    resourcesmanager,
    resourcesmanager.getResourceGroupInfo,
  )(input);
};

export const getBootstrapAction = (input: GetBootstrapActionRequest) => {
  return basicCallWrapper<GetBootstrapActionRequest, BootstrapAction>(
    resourcesmanager,
    resourcesmanager.getBootstrapAction,
  )(input);
};

export const getTemplate = (input: GetTemplateRequest) => {
  return basicCallWrapper<GetTemplateRequest, Template>(
    resourcesmanager,
    resourcesmanager.getTemplate,
  )(input);
};

export const getResource = (input: GetResourceRequest) => {
  return basicCallWrapper<GetResourceRequest, Resource>(
    resourcesmanager,
    resourcesmanager.getResource,
  )(input);
};

// UPDATE

export const updateResourceGroup = (input: UpdateResourceGroupRequest) => {
  return basicCallWrapper<UpdateResourceGroupRequest, ResourceGroup>(
    resourcesmanager,
    resourcesmanager.updateResourceGroup,
  )(input);
};

export const updateBootstrapAction = (input: UpdateBootstrapActionRequest) => {
  return basicCallWrapper<UpdateBootstrapActionRequest, BootstrapAction>(
    resourcesmanager,
    resourcesmanager.updateBootstrapAction,
  )(input);
};

export const updateTemplate = (input: UpdateTemplateRequest) => {
  return basicCallWrapper<UpdateTemplateRequest, Template>(
    resourcesmanager,
    resourcesmanager.updateTemplate,
  )(input);
};

// DELETE

export const deleteResourceGroup = (input: DeleteResourceGroupRequest) => {
  return basicCallWrapper<
    DeleteResourceGroupRequest,
    DeleteResourceGroupResponse
  >(
    resourcesmanager,
    resourcesmanager.deleteResourceGroup,
  )(input);
};

export const deleteBootstrapAction = (input: DeleteBootstrapActionRequest) => {
  return basicCallWrapper<
    DeleteBootstrapActionRequest,
    DeleteBootstrapActionResponse
  >(
    resourcesmanager,
    resourcesmanager.deleteBootstrapAction,
  )(input);
};

export const deleteTemplate = (input: DeleteTemplateRequest) => {
  return basicCallWrapper<DeleteTemplateRequest, DeleteTemplateResponse>(
    resourcesmanager,
    resourcesmanager.deleteTemplate,
  )(input);
};

// LIST

export const listResourceGroups = (input: ListItemsRequest) => {
  return basicCallWrapper<ListItemsRequest, ListResourceGroupsResponse>(
    resourcesmanager,
    resourcesmanager.listResourceGroups,
  )(input);
};

export const listResourceGroupsInfo = (input: ListItemsRequest) => {
  return basicCallWrapper<ListItemsRequest, ListResourceGroupsInfoResponse>(
    resourcesmanager,
    resourcesmanager.listResourceGroupsInfo,
  )(input);
};

export const listBootstrapActions = (input: ListItemsRequest) => {
  return basicCallWrapper<ListItemsRequest, ListBootstrapActionsResponse>(
    resourcesmanager,
    resourcesmanager.listBootstrapActions,
  )(input);
};

export const listTemplates = (input: ListItemsRequest) => {
  return basicCallWrapper<ListItemsRequest, ListTemplatesResponse>(
    resourcesmanager,
    resourcesmanager.listTemplates,
  )(input);
};

export const listResources = (input: ListResourcesRequest) => {
  return basicCallWrapper<ListResourcesRequest, ListResourcesResponse>(
    resourcesmanager,
    resourcesmanager.listResources,
  )(input);
};

export const refreshResource = (input: RefreshResourceRequest) => {
  return basicCallWrapper<RefreshResourceRequest, RefreshResourceResponse>(
    resourcesmanager,
    resourcesmanager.refreshResource,
  )(input);
};
