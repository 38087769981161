import awsdatalakegladstonelambda, {
  CreateLakeFormationRequest,
  CreateLakeFormationResult,
  EditDataLakeRoleRequest,
  EditDataLakeRoleResult,
  GetRequestsRequest,
  GetRequestsResult,
  GetRequestRequest,
  GetRequestResult,
  CreateGroupRequest,
  CreateGroupResult,
  CreateUsersRequest,
  CreateUsersResult,
  EditUsersRequest,
  EditUsersResult,
  EditGroupRequest,
  EditGroupResult,
  GetGroupRequest,
  GetGroupResult,
  GetGroupMembersRequest,
  GetGroupMembersResult,
  GetUserRequest,
  GetUserResult,
  ApproveRequest,
  ApproveResult,
  DenyRequest,
  DenyResult,
  CancelRequest,
  CancelResult,
  ListDataLakeRolePropertyRequest,
  ListDataLakeRolePropertyResult,
  AuditDataSetShareRequest,
  AuditDataSetShareResult,
  CreateDataSetShareRequest,
  CreateDataSetShareResult,
  DeactivateDataSetShareRequest,
  DeactivateDataSetShareResult,
  GetDataSetShareRequest,
  GetDataSetShareResult,
  GetDataSetSharesByDataIdRequest,
  GetDataSetSharesByGroupIdRequest,
  GetDataSetSharesFilteredRequest,
  GetDataSetSharesResult,
  ListPermissionsRequest,
  ListPermissionsResult,
  BatchApproveRequest,
  BatchApproveResult,
  BatchDenyRequest,
  BatchDenyResult,
  GetGroupsConsumingDatasetRequest,
  GetGroupsConsumingDatasetResult,
  GetGroupsRequest,
  GetGroupsResult,
  IsMemberOfGroupingMechanismIdRequest,
  IsMemberOfGroupingMechanismIdResponse,
  GetGroupBaselineInfoRequest,
  GetGroupBaselineInfoResult,
} from 'aws-sdk/clients/awsdatalakegladstonelambda';
import basicCallWrapper from './client_call';
import { getGladstoneConfig } from './config';
import { getCredentialProvider } from './auth';

const config = getGladstoneConfig();
const credentials = getCredentialProvider();

const gladstoneClientConfig: awsdatalakegladstonelambda.ClientConfiguration = {
  region: config.region,
  endpoint: config.endpoint,
  credentials: credentials,
};

const gladstone = new awsdatalakegladstonelambda(gladstoneClientConfig);

export const createLakeFormationPermission = (
  input: CreateLakeFormationRequest,
) => {
  return basicCallWrapper<
    CreateLakeFormationRequest,
    CreateLakeFormationResult
  >(
    gladstone,
    gladstone.createLakeFormationPermission,
  )(input);
};

export const getRequest = (input: GetRequestRequest) => {
  return basicCallWrapper<GetRequestRequest, GetRequestResult>(
    gladstone,
    gladstone.getRequest,
  )(input);
};

export const getRequestedByRequests = (input: GetRequestsRequest) => {
  return basicCallWrapper<GetRequestsRequest, GetRequestsResult>(
    gladstone,
    gladstone.getRequestedByRequests,
  )(input);
};

export const getRequestedToRequests = (input: GetRequestsRequest) => {
  return basicCallWrapper<GetRequestsRequest, GetRequestsResult>(
    gladstone,
    gladstone.getRequestedToRequests,
  )(input);
};

export const getRequestedByRequestsHistory = (input: GetRequestsRequest) => {
  return basicCallWrapper<GetRequestsRequest, GetRequestsResult>(
    gladstone,
    gladstone.getRequestedByRequestsHistory,
  )(input);
};

export const getRequestedToRequestsHistory = (input: GetRequestsRequest) => {
  return basicCallWrapper<GetRequestsRequest, GetRequestsResult>(
    gladstone,
    gladstone.getRequestedToRequestsHistory,
  )(input);
};

export const editDataLakeRole = (input: EditDataLakeRoleRequest) => {
  return basicCallWrapper<EditDataLakeRoleRequest, EditDataLakeRoleResult>(
    gladstone,
    gladstone.editDataLakeRole,
  )(input);
};

export const listDataLakeRoleProperty = (
  input: ListDataLakeRolePropertyRequest,
) => {
  return basicCallWrapper<
    ListDataLakeRolePropertyRequest,
    ListDataLakeRolePropertyResult
  >(
    gladstone,
    gladstone.listDataLakeRoleProperty,
  )(input);
};

export const approveRequest = (input: ApproveRequest) => {
  return basicCallWrapper<ApproveRequest, ApproveResult>(
    gladstone,
    gladstone.approve,
  )(input);
};

export const denyRequest = (input: DenyRequest) => {
  return basicCallWrapper<DenyRequest, DenyResult>(
    gladstone,
    gladstone.deny,
  )(input);
};

export const cancelRequest = (input: CancelRequest) => {
  return basicCallWrapper<CancelRequest, CancelResult>(
    gladstone,
    gladstone.cancel,
  )(input);
};

export const getUserInfo = (input: GetUserRequest) => {
  return basicCallWrapper<GetUserRequest, GetUserResult>(
    gladstone,
    gladstone.getUser,
  )(input);
};

export const getGroupInfo = (input: GetGroupRequest) => {
  return basicCallWrapper<GetGroupRequest, GetGroupResult>(
    gladstone,
    gladstone.getGroup,
  )(input);
};

export const getGroupMembers = (input: GetGroupMembersRequest) => {
  return basicCallWrapper<GetGroupMembersRequest, GetGroupMembersResult>(
    gladstone,
    gladstone.getGroupMembers,
  )(input);
};

export const createGroup = (input: CreateGroupRequest) => {
  return basicCallWrapper<CreateGroupRequest, CreateGroupResult>(
    gladstone,
    gladstone.createGroup,
  )(input);
};

export const createUsers = (input: CreateUsersRequest) => {
  return basicCallWrapper<CreateUsersRequest, CreateUsersResult>(
    gladstone,
    gladstone.createUsers,
  )(input);
};

export const listPermissions = (input: ListPermissionsRequest) => {
  return basicCallWrapper<ListPermissionsRequest, ListPermissionsResult>(
    gladstone,
    gladstone.listPermissions,
  )(input);
};

export const auditDataSetShare = (input: AuditDataSetShareRequest) => {
  return basicCallWrapper<AuditDataSetShareRequest, AuditDataSetShareResult>(
    gladstone,
    gladstone.auditDataSetShare,
  )(input);
};

export const createDataSetShare = (input: CreateDataSetShareRequest) => {
  return basicCallWrapper<CreateDataSetShareRequest, CreateDataSetShareResult>(
    gladstone,
    gladstone.createDataSetShare,
  )(input);
};

export const deactivateDataSetShare = (
  input: DeactivateDataSetShareRequest,
) => {
  return basicCallWrapper<
    DeactivateDataSetShareRequest,
    DeactivateDataSetShareResult
  >(
    gladstone,
    gladstone.deactivateDataSetShare,
  )(input);
};

export const getDataSetShare = (input: GetDataSetShareRequest) => {
  return basicCallWrapper<GetDataSetShareRequest, GetDataSetShareResult>(
    gladstone,
    gladstone.getDataSetShare,
  )(input);
};

export const getDataSetSharesByGroupId = (
  input: GetDataSetSharesByGroupIdRequest,
) => {
  return basicCallWrapper<
    GetDataSetSharesByGroupIdRequest,
    GetDataSetSharesResult
  >(
    gladstone,
    gladstone.getDataSetSharesByGroupId,
  )(input);
};

export const getDataSetSharesByDataId = (
  input: GetDataSetSharesByDataIdRequest,
) => {
  return basicCallWrapper<
    GetDataSetSharesByDataIdRequest,
    GetDataSetSharesResult
  >(
    gladstone,
    gladstone.getDataSetSharesByDataId,
  )(input);
};

export const getDataSetSharesFiltered = (
  input: GetDataSetSharesFilteredRequest,
) => {
  return basicCallWrapper<
    GetDataSetSharesFilteredRequest,
    GetDataSetSharesResult
  >(
    gladstone,
    gladstone.getDataSetSharesFiltered,
  )(input);
};

export const batchApprove = (input: BatchApproveRequest) => {
  return basicCallWrapper<BatchApproveRequest, BatchApproveResult>(
    gladstone,
    gladstone.batchApprove,
  )(input);
};

export const batchDeny = (input: BatchDenyRequest) => {
  return basicCallWrapper<BatchDenyRequest, BatchDenyResult>(
    gladstone,
    gladstone.batchDeny,
  )(input);
};

export const getGroupsConsumingDataset = (
  input: GetGroupsConsumingDatasetRequest,
) => {
  return basicCallWrapper<
    GetGroupsConsumingDatasetRequest,
    GetGroupsConsumingDatasetResult
  >(
    gladstone,
    gladstone.getGroupsConsumingDataset,
  )(input);
};

export const getGroups = (input: GetGroupsRequest) => {
  return basicCallWrapper<GetGroupsRequest, GetGroupsResult>(
    gladstone,
    gladstone.getGroups,
  )(input);
};

export const editUsers = (input: EditUsersRequest) => {
  return basicCallWrapper<EditUsersRequest, EditUsersResult>(
    gladstone,
    gladstone.editUsers,
  )(input);
};

export const editGroup = (input: EditGroupRequest) => {
  return basicCallWrapper<EditGroupRequest, EditGroupResult>(
    gladstone,
    gladstone.editGroup,
  )(input);
};

export const isMemberOfGroupingMechanismId = (
  input: IsMemberOfGroupingMechanismIdRequest,
) => {
  return basicCallWrapper<
    IsMemberOfGroupingMechanismIdRequest,
    IsMemberOfGroupingMechanismIdResponse
  >(
    gladstone,
    gladstone.isMemberOfGroupingMechanismId,
  )(input);
};

export const getGroupBaselineInfo = (input: GetGroupBaselineInfoRequest) => {
  return basicCallWrapper<
    GetGroupBaselineInfoRequest,
    GetGroupBaselineInfoResult
  >(
    gladstone,
    gladstone.getGroupBaselineInfo,
  )(input);
};
