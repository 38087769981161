import { Link } from '@amzn/awsui-components-react-v3';
import { UX_WRITING } from '../../common/uxWriting';
import * as React from 'react';

export const stepsHeader = {
  step1: {
    title: UX_WRITING.STEP_ONE_TITLE,
    description:
      'Create a data contract to ingest your S3 data into AWS Data Lake.',
  },
  step2:{
    title: UX_WRITING.STEP_TWO_TITLE,
    description:
      'Provide details on the state of your data before ingested into AWS Data Lake.',
  },
  step3:{
    title: UX_WRITING.STEP_THREE_TITLE,
    description: (
      <>
        Use 
        <Link
          external
          href='https://docs.aws.amazon.com/glue/latest/dg/schema-registry.html'
        > {' '}
          AWS Glue Schema Registry
        </Link>
        { ' ' } to define, control, and evolve the schema of your data.
      </> 
    ),
  },
  step4:{
    title: UX_WRITING.STEP_FOUR_TITLE,
    description:
      'Define how your data is ingested, and deduplicated.',
  },
  step5:{
    title: 'Review and submit',
    description:
      'Please validate the information before registering your data contract.',      
  }
};
