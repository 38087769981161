export function isValidPrincipal(principal) {
  return /^arn:(aws|aws-cn|aws-iso(-b)?):iam::\d{12}:(?:root|user\/[\w+=,.@\-/]+|role\/[\w+=,.@\-/]+)$/.test(
    principal,
  );
}

export function isValidRole(role) {
  return /^arn:(aws|aws-cn|aws-iso(-b)?):iam::\d{12}:role\/[\w+=,.@\-/]+$/.test(
    role,
  );
}

export function isValidS3Path(path) {
  return /^s3:\/\/.+$/.test(path);
}

export function isValidKmsKey(key) {
  return /^(arn:(aws|aws-cn):kms:(\w+|-)+:\d{12}:key\/((\w|\d)+|-)+)?$/.test(
    key,
  );
}

export function isValidAccount(account) {
  return /^\d{12}$/.test(account);
}

export function isValidGroup(group) {
  return /^[\w-]{1,64}$/.test(group);
}

export function isValidUser(user) {
  return /^[\w-]{1,64}$/.test(user);
}

export function isValidMidwayUser(user) {
  return /^[a-z]{1,64}$/.test(user);
}

export function isValidAmazonEmail(email) {
  return /^([\w\.\-\+])+\@amazon.com$/.test(email);
}

export function isValidUrl(urlString) {
  let url;
  try {
    url = new URL(urlString);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
}

export function isNotEmpty(value) {
  return !!value;
}

export function isEmpty(value) {
  return !isNotEmpty(value);
}

export function isPositiveInteger(number) {
  return parseInt(number) > 0;
}

export function isValidDataSetIdForAccountId(datasetId, accountId) {
  const regex = new RegExp(
    '^DS-.*\\|A-' + accountId + '\\|DN-.*\\|TN-.*\\|R-.*$',
  );
  return regex.test(datasetId);
}

export function isValidDataSetIdFormat(datasetId) {
  const regex = new RegExp('^DS-.*\\|A-.*\\|DN-.*\\|TN-.*\\|R-.*$');
  return regex.test(datasetId);
}

export function isValidAccoundId(accountId) {
  return /^\d{12}$/.test(accountId);
}

export function isValidRegion(region) {
  return /^\w(\w|-)*$/.test(region);
}

export function isValidTimeFormat(dataLakeSLA) {
  return /^([01]?[0-9]|2[0-3]):[0-5][0-9](.*)$/.test(dataLakeSLA);
}

export function isValidSubnetId(subnetId) {
  return /^subnet\-.{5,17}$/.test(subnetId);
}

export function isValidSubnetIdList(subnetIdList) {
  return subnetIdList
    .split(',')
    .map((x) => x.trim())
    .filter((x) => !!x)
    .every(isValidSubnetId);
}

export function isValidEmrReleaseLabel(emrReleaseLabel) {
  return /^emr\-\d{1,2}\.\d{1,2}\.\d{1,2}[\w\-]*$/.test(emrReleaseLabel);
}

export function isValidIntegerList(integerList) {
  return !integerList.some(isNaN);
}

export function isValidJSONString(jsonString) {
  try {
    const obj = JSON.parse(jsonString);
    return obj.constructor == Object;
  } catch (err) {
    return false;
  }
}

export const hasNoSpaces = (str:string) => /^\S*$/.test(str)
