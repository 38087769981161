import { Link } from 'react-router-dom';
import { StatusIcon } from 'src/components/permissions/myDatasets/statusIcon';
import * as React from 'react';
import { PageHeader } from 'src/components/permissions/myDatasets/common';
import {
  Box,
  Pagination,
  Table,
  TextFilter,
} from '@amzn/awsui-components-react-v3';
import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  getFieldOnChange,
  getFilterCounterText,
  handleSelectedItem,
} from 'src/components/permissions/myBaselining/baseliningUtils';

export function LFAccessOwner({ info, props, onChange }) {
  const columnDefinitions = [
    {
      id: 'dataSetShareId',
      header: 'Dataset share ID',
      cell: (item) => (
        <Link to={`/mydatasets/${item.dataSetShareId}`}>
          {item.dataSetShareId}
        </Link>
      ),
      minWidth: '200px',
    },
    {
      id: 'databaseName',
      header: 'Database',
      cell: (item) => (
        <Link to={`/databases/${item.catalogId}/${item.databaseName}`}>
          {item.databaseName}
        </Link>
      ),
      minWidth: '150px',
    },
    {
      id: 'tableName',
      header: 'Table',
      cell: (item) => item.tableName,
      minWidth: '150px',
    },
    {
      id: 'option',
      header: 'Option',
      cell: (item) => item.option,
      minWidth: '100px',
    },
    {
      id: 'audit',
      header: 'Audit status',
      cell: (item) => <StatusIcon status={item.auditStatus} />,
      minWidth: '100px',
    },
    {
      id: 'lastAudit',
      header: 'Last audit',
      cell: (item) => item.dateOfLastAudit,
      minWidth: '100px',
    },
  ];
  const tableMessage = 'No datasets';
  const { selectedLFOwner } = info.BaselineLFAccessOwner;
  const selectedLFOwnerChange = getFieldOnChange('selectedLFOwner', onChange);

  const {
    items,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
  } = useCollection(props.datasetItemsOwner, {
    filtering: {
      empty: (
        <Box textAlign='center' variant='p'>
          <div className='awsui-util-pt-s awsui-util-mb-xs'>
            <b>No dataset shares</b>
          </div>
          <p className='awsui-util-mb-s'>
            No dataset shares were found in the catalog.
          </p>
        </Box>
      ),
      noMatch: (
        <Box textAlign='center' variant='p'>
          <div className='awsui-util-pt-s awsui-util-mb-xs'>
            <b>No matches</b>
          </div>
          <p className='awsui-util-mb-s'>We can’t find a match.</p>
        </Box>
      ),
    },
    pagination: { pageSize: 20 },
    sorting: {},
    selection: {},
  });

  return (
    <>
      <Table
        {...collectionProps}
        loadingText='Loading dataset shares...'
        columnDefinitions={columnDefinitions}
        items={items}
        resizableColumns
        header={
          <>
            <PageHeader
              buttons={[]}
              header={
                <>
                  Dataset shares
                  <span className='awsui-util-header-counter'>
                    {` (${items.length})`}
                  </span>
                </>
              }
            />
          </>
        }
        empty={
          <Box textAlign='center' variant='p'>
            <div className='awsui-util-pt-s awsui-util-mb-xs'>
              <b>{tableMessage}</b>
            </div>
            <p className='awsui-util-mb-s'>No dataset shares to display.</p>
          </Box>
        }
        filter={
          <TextFilter
            {...filterProps}
            countText={getFilterCounterText(filteredItemsCount)}
          />
        }
        pagination={<Pagination {...paginationProps} />}
        selectionType='multi'
        selectedItems={handleSelectedItem(items, selectedLFOwner)}
        onSelectionChange={selectedLFOwnerChange}
      ></Table>
    </>
  );
}
