import * as React from 'react';
import {
  Box,
  Header,
  Button,
  Container,
  SpaceBetween,
  ColumnLayout,
} from '@amzn/awsui-components-react-v3';
import { SchemaText } from '../../common/schemaText';
import { RegisterDataSetRequest } from 'aws-sdk/clients/tethyscontractservicelambda';
import { PrimaryKeys } from '../../common/primaryKeys';
import { UX_WRITING } from '../../common/uxWriting';

const ValueWithLabel = ({ label, children }) => (
  <div>
    <Box variant='awsui-key-label'> {label} </Box>
    <div>{children}</div>
  </div>
);

interface iReviewStep {
  request: RegisterDataSetRequest;
  setActiveStepIndex(step: number): void;
}

export const ReviewStep = ({ request, setActiveStepIndex }: iReviewStep) => (
  <SpaceBetween size='xxl'>
    <SpaceBetween size='xs'>
      <Header
        variant='h3'
        actions={<Button onClick={() => setActiveStepIndex(0)}>Edit</Button>}
      >
        Step 1: { UX_WRITING.STEP_ONE_TITLE }
      </Header>
      <Container header={<Header variant='h2'>Dataset Properties</Header>}>
        <ColumnLayout columns={2} variant='text-grid'>
          <div>
            <Box variant='awsui-key-label'>Dataset name</Box>
            <div> {request.TableName} </div>
          </div>

          <div>
            <Box variant='awsui-key-label'>Database</Box>
            <div>{request.DatabaseName}</div>
          </div>

          <div>
            <Box variant='awsui-key-label'>Catalog</Box>
            <div>{request.CatalogId}</div>
          </div>
        </ColumnLayout>
      </Container>
    </SpaceBetween>

    <SpaceBetween size='xs'>
      <Header
        variant='h3'
        actions={<Button onClick={() => setActiveStepIndex(1)}>Edit</Button>}
      >
        Step 2: { UX_WRITING.STEP_TWO_TITLE }
      </Header>
      <Container header={<Header variant='h2'>File format</Header>}>
        <ColumnLayout columns={3} variant='text-grid'>
          <ValueWithLabel label={'Skipped header lines'}>
            {request.DataContract.FileProperties.HeaderLines}
          </ValueWithLabel>

          <ValueWithLabel label={'Field delimeter'}>
            {request.DataContract.FileProperties.FieldDelimiter}
          </ValueWithLabel>

          <ValueWithLabel label={'Compression Type'}>
            {request.DataContract.DataProperties.CompressionFormat || 'None'}
          </ValueWithLabel>
        </ColumnLayout>
      </Container>
    </SpaceBetween>

    <SpaceBetween size='xs'>
      <Header
        variant='h3'
        actions={<Button onClick={() => setActiveStepIndex(2)}>Edit</Button>}
      >
        Step 3: { UX_WRITING.STEP_THREE_TITLE }
      </Header>
      <Container header={<Header variant='h2'>First Schema Version</Header>}>
        <SchemaText
          text={request.DataContract.DataProperties.SchemaDefinition}
        />
      </Container>
    </SpaceBetween>

    <SpaceBetween size='s'>
      <Header
        variant='h3'
        actions={<Button onClick={() => setActiveStepIndex(3)}>Edit</Button>}
      >
        Step 4: { UX_WRITING.STEP_FOUR_TITLE }
      </Header>
      <Container header={<Header variant='h2'>Data Deduplication</Header>}>
        <ColumnLayout columns={2} variant='text-grid'>
          <ValueWithLabel label={'Primary key'}>
            <PrimaryKeys keys={request.DataContract.DataProperties.PrimaryKeyColumns} />
          </ValueWithLabel>
          <ValueWithLabel label={'Dedup column'}>
            {request.DataContract.DataProperties.DeDupKeyColumn}
          </ValueWithLabel>
        </ColumnLayout>
      </Container>


      <Container header={<Header variant='h2'>SLA </Header>}>
        <ColumnLayout columns={2} variant='text-grid'>
          <ValueWithLabel label={'IAM Role'}>
            {request.DataContract.ServiceLevelAgreement.IAMRole}
          </ValueWithLabel>

          <ValueWithLabel label={'Publish Type'}>
            {request.DataContract.ServiceLevelAgreement.PublishType.toLowerCase()}
          </ValueWithLabel>
        </ColumnLayout>
      </Container>
    </SpaceBetween>
  </SpaceBetween>
);
