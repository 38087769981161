import { AWSError } from 'aws-sdk/lib/error';
import { Request } from 'aws-sdk/lib/request';

export default function basicCallWrapper<I, O, E = AWSError>(
  client: any,
  apiFunc: (input: I, callback?: (err: E, data: O) => void) => Request<O, E>,
) {
  return (input: I) => {
    return new Promise<O>((resolve, reject) => {
      apiFunc.call(client, input, (err, createData: O) => {
        if (err) {
          reject(err);
        } else {
          resolve(createData);
        }
      });
    });
  };
}
