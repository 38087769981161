import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { Component } from 'react';
import {
  Button,
  ColumnLayout,
  Flashbar,
  FlashbarProps,
  FormField,
  Input,
  Modal,
  Toggle,
} from '@amzn/awsui-components-react-v3';
import { coloredStatus } from 'src/components/resourcesmanager/components';
import {
  editDatabase,
  listDatabases,
  listDatasetsForDatabase,
} from '../../api/catalog';
import { scrollUp } from '../utils/navigation';
import { PageHeader } from '../subscriptions/common';
import { CopiableText } from './common';
import BrowseTable from './browsedatasets';
import RegisterTable from './register';
import * as validate from 'src/commons/validationUtils';

export interface DatabaseDetailProps {
  setContentType: any;
  setActiveDatabaseName: any;
  match: any;
  activeGroup: string;
  cartItemIds: string[];
  setItemsInCart: any;
  addToCart: any;
}

export interface DatabaseDetailState {
  notifications: FlashbarProps.MessageDefinition[];
  loading: boolean;
  database: object;
  datasetIds: string[];
  redirect: string;
  contentType: string;
  editDatabaseModalVisible: boolean;
  saveEditDatabaseLoading: boolean;
  editableOwners: string;
  editableDescription: string;
  editableAutoTableOnboard: Boolean;
  editDatabaseSuccess: boolean;
}

export default class DatabaseDetail extends Component<
  DatabaseDetailProps,
  DatabaseDetailState
> {
  state = {
    loading: true,
    database: null,
    datasetIds: [],
    redirect: undefined,
    contentType: undefined,
    notifications: [],
    editDatabaseButton: undefined,
    editDatabaseModalVisible: false,
    saveEditDatabaseLoading: false,
    editableOwners: '',
    editableDescription: undefined,
    editableAutoTableOnboard: false,
    editDatabaseSuccess: true,
  };

  componentDidMount = async () => {
    this.props.setContentType('table');
    // This is for Save button, after editing the Database on clicking this will invoke api
    this.handleEditDatabase = this.handleEditDatabase.bind(this);
    await this.handleRefresh();
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.activeGroup !== this.props.activeGroup) {
      await this.handleRefresh();
    }
  };

  handleRefresh = async () => {
    this.setState({ loading: true });

    // pull the specific database
    try {
      const databases = await listDatabases({
        DatabaseKeyList: [
          {
            CatalogId: this.props.match.params.catalogid,
            DatabaseName: this.props.match.params.databasename,
          },
        ],
      });
      this.setState({
        database: databases.DatabaseInfoList[0],
      });
    } catch (err) {
      this.setState({ database: null });
    }

    // pull all datasets belonging to this database
    let nextToken = '';
    let datasetIds = [];
    while (nextToken != null) {
      try {
        let datasets;
        if (nextToken != '') {
          datasets = await listDatasetsForDatabase({
            CatalogId: this.props.match.params.catalogid,
            DatabaseName: this.props.match.params.databasename,
            NextToken: nextToken,
          });
        } else {
          datasets = await listDatasetsForDatabase({
            CatalogId: this.props.match.params.catalogid,
            DatabaseName: this.props.match.params.databasename,
          });
        }

        const newIds = datasets.DataSetList.map((item) => item.Id);
        datasetIds = datasetIds.concat(newIds);
        nextToken = datasets.NextToken;
      } catch (err) {
        this.setState({ datasetIds: [] });
        break;
      }
    }
    this.setState({
      datasetIds: datasetIds,
    });
    if (this.state.database) {
      this.props.setActiveDatabaseName(this.state.database.DatabaseName);
    }

    this.setState({ loading: false });
  };

  setNotifications = (value) => {
    this.handleRefresh();
    scrollUp();
    this.setState({ notifications: value });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }
    if (!this.state.database && !this.state.loading) {
      return (
        <>
          <h2>Database not found</h2>
          The given database {this.props.match.params.databasename} is not
          valid, or you do not have permission to view it. Please check the URL
          for mistakes and try again.
        </>
      );
    }
    return (
      <>
        <Flashbar items={this.state.notifications} />
        <PageHeader
          buttons={[]}
          header={`${this.props.match.params.databasename}`}
        />
        <div className='awsui-util-container'>
          <div className='awsui-util-container-header'>
            <div className='awsui-util-action-stripe'>
              <div className='awsui-util-action-stripe-title'>
                <h2>Database details</h2>
              </div>
              <div className='awsui-util-action-stripe-group'>
                <div
                  style={{ paddingTop: 5, paddingRight: 10 }}
                  hidden={this.hideEditDatabaseButton()}
                >
                  <Button
                    variant='normal'
                    onClick={() => {
                      this.editDatabaseForm();
                    }}
                  >
                    Edit
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <ColumnLayout columns={3} borders='horizontal'>
              {this.getDatabaseDetailsMetadata()}
          </ColumnLayout>
        </div>
        {/*This is needed to avoid rendering in the normal tables might need a glueLF for Databases*/}
        {this.state.database &&
          this.state.database.DataAccessRole !== null &&
          this.state.database?.Owners.includes(this.props.activeGroup) && (
            <>
              <RegisterTable
                setContentType={(type) => this.setState({ contentType: type })}
                title='Register datasets'
                onNotificationChange={this.setNotifications}
                extraFeatures={true}
                databaseDetail={this.state.database}
                idFilter={this.state.datasetIds}
                onlyShowDatasetName={false}
                loading={this.state.loading}
                activeGroup={this.props.activeGroup}
              />
              <br />
            </>
          )}
        <BrowseTable
          setContentType={(type) => this.setState({ contentType: type })}
          title='Datasets'
          extraFeatures={true}
          idFilter={this.state.datasetIds}
          onlyShowDatasetName={true}
          loading={this.state.loading}
          activeGroup={this.props.activeGroup}
          cartItemIds={this.props.cartItemIds}
          addToCart={this.props.addToCart}
        />
        <Modal
          onDismiss={() => {
            this.setState({ editDatabaseModalVisible: false });
          }}
          visible={this.state.editDatabaseModalVisible}
          size='medium'
          footer={
            <span className='awsui-util-f-r'>
              <Button
                variant='link'
                onClick={() => {
                  this.setState({ editDatabaseModalVisible: false });
                }}
              >
                Cancel
              </Button>
              <Button
                variant='primary'
                disabled={this.disableEditDatabaseSaveButton()}
                loading={this.state.saveEditDatabaseLoading}
                onClick={this.handleEditDatabase}
              >
                Save
              </Button>
            </span>
          }
          header='Edit database'
        >
          <ColumnLayout>
              <FormField label='Catalog ID'>
                <Input disabled={true} value={this.state.database?.CatalogId} />
              </FormField>
              <FormField label='Database name'>
                <Input
                  disabled={true}
                  value={this.state.database?.DatabaseName}
                />
              </FormField>
              <FormField label='Description' description=''>
                <Input
                  ariaRequired={true}
                  placeholder={'Description for the database.'}
                  value={this.state.editableDescription}
                  onChange={(e) =>
                    this.setState({
                      editableDescription: e.detail.value,
                    })
                  }
                />
              </FormField>

              <FormField
                label='Owners'
                description='Comma-separated Omni Group IDs.'
                errorText={
                  validate.isEmpty(this.state.editableOwners)
                    ? 'Owners field cannot be empty.'
                    : this.isActiveGroupExists()
                    ? false
                    : 'Cannot delete the group you are currently logged in with.'
                }
              >
                <Input
                  ariaRequired={true}
                  placeholder={'GroupId1,GroupId2'}
                  value={this.state.editableOwners}
                  onChange={(e) =>
                    this.setState({
                      editableOwners: e.detail.value,
                    })
                  }
                />
              </FormField>

              <FormField
                label='Auto-onboard'
                description='Enable to allow the created tables to onboard automatically.'
              >
                <Toggle
                  checked={this.state.editableAutoTableOnboard}
                  onChange={(e) =>
                    this.setState({
                      editableAutoTableOnboard: e.detail.checked,
                    })
                  }
                />
              </FormField>
          </ColumnLayout>
        </Modal>
      </>
    );
  }

  getDatabaseDetailsMetadata() {
    const database_metadata = [];

    if (this.state.database?.DatabaseName) {
      database_metadata.push(
        <CopiableText
          name={'Database name'}
          key={'Database name'}
          value={this.state.database?.DatabaseName}
        />,
      );
    }
    if (this.state.database?.Description) {
      database_metadata.push(
        <CopiableText
          name={'Description'}
          key={'Description'}
          value={this.state.database?.Description}
        />,
      );
    }
    if (this.state.database?.Region) {
      database_metadata.push(
        <CopiableText
          name={'Region'}
          key={'Region'}
          value={this.state.database?.Region}
        />,
      );
    }
    if (this.state.database?.CatalogId) {
      database_metadata.push(
        <CopiableText
          name={'Catalog ID'}
          key={'Catalog ID'}
          value={this.state.database?.CatalogId}
          url={
            '/catalogs/' +
            this.state.database?.CatalogId +
            '/' +
            this.state.database?.Region
          }
        />,
      );
    }
    if (this.state.database?.CreatedBy) {
      database_metadata.push(
        <CopiableText
          name={'Created by'}
          key={'Created by'}
          value={this.state.database?.CreatedBy}
        />,
      );
    }
    if (this.state.database?.Owners) {
      database_metadata.push(
        <CopiableText
          name={'Owners'}
          key={'Owners'}
          value={
            this.state.database?.Owners
              ? this.state.database.Owners.join(', ')
              : 'None'
          }
        />,
      );
    }
    if (this.state.database?.UpdatedBy) {
      database_metadata.push(
        <CopiableText
          name={'Updated by'}
          key={'Updated by'}
          value={this.state.database.UpdatedBy}
        />,
      );
    }
    if (this.state.database?.UpdatedOn) {
      database_metadata.push(
        <CopiableText
          name={'Updated on'}
          key={'Updated on'}
          value={this.state.database.UpdatedOn}
        />,
      );
    }
    if (this.state.database?.AutoTableOnboard !== null) {
      database_metadata.push(
        <CopiableText
          name={'Auto-onboard'}
          key={'Auto-onboard'}

          value={coloredStatus(
            this.state.database?.AutoTableOnboard ? 'ENABLED' : 'DISABLED',
          )}
        />,
      );
    }
    return database_metadata;
  }




  // make sures that it is visible only for the owners
  hideEditDatabaseButton() {
    return !this.state.database?.Owners.includes(this.props.activeGroup);
  }

  // on clicking Edit button this method is triggered
  editDatabaseForm() {
    this.clearEditableParametersForDatabase();
    this.initializeEditableParametersForDatabase();
    this.setState({ editDatabaseModalVisible: true });
  }

  // clear all the editable fields before assigning
  clearEditableParametersForDatabase() {
    this.setState({
      editableAutoTableOnboard: false,
      editableDescription: undefined,
      editableOwners: '',
    });
  }

  // this will initialize editable fields with the current fields, so they can be prefilled in form
  initializeEditableParametersForDatabase() {
    // converting Array type of Owners to comma seperated GroupIds
    for (const item of this.state.database.Owners) {
      if (this.state.editableOwners === '') {
        this.state.editableOwners = this.state.editableOwners + item;
      } else {
        this.state.editableOwners = this.state.editableOwners + ',' + item;
      }
    }
    this.setState({
      editDatabaseSuccess: true,
      editableDescription: this.state.database.Description,
      editableAutoTableOnboard: this.state.database.AutoTableOnboard
        ? this.state.database.AutoTableOnboard
        : false,
    });
  }

  // will validate Editable parameters provided and changes the visibility of Save Button
  disableEditDatabaseSaveButton() {
    return !this.isActiveGroupExists();
  }

  // make sures that the current ActiveGroup is not edited in the Edit Form
  isActiveGroupExists() {
    return !!this.state.editableOwners
      .split(',')
      .includes(this.props.activeGroup);
  }

  // On clicking Save Database this will make an api call and stores the Data
  async handleEditDatabase() {
    this.setState({
      saveEditDatabaseLoading: true,
    });
    // converting string type into Array to pass through Edit Database API
    let ownersArray = this.state.editableOwners
      .split(',')
      .map((item) => item.trim());
    ownersArray = ownersArray.filter((a, b) => ownersArray.indexOf(a) === b);
    try {
      let editDatabaseResponse = await editDatabase({
        CatalogId: this.state.database.CatalogId,
        DatabaseName: this.state.database.DatabaseName,
        Owners: ownersArray,
        Description: this.state.editableDescription,
        AutoTableOnboard: this.state.editableAutoTableOnboard,
      });
      if (!editDatabaseResponse.Message.includes('Success')) {
        this.setState({
          editDatabaseSuccess: false,
        });
      }
    } catch (e) {
      this.setState({
        editDatabaseSuccess: false,
      });
      console.log(e);
    } finally {
      if (this.state.editDatabaseSuccess) {
        // to get the edited details displayed on the page without refresh
        await this.handleRefresh();
        this.setState({
          notifications: [
            {
              type: 'success' as FlashbarProps.Type,
              content: `Successfully edited the database.`,
              dismissible: true,
              onDismiss: () => this.setState({ notifications: [] }),
            },
          ],
        });
      } else {
        this.setState({
          notifications: [
            {
              type: 'error' as FlashbarProps.Type,
              content: `Failed to edit the database.`,
              dismissible: true,
              onDismiss: () => this.setState({ notifications: [] }),
            },
          ],
        });
      }
      this.setState({
        saveEditDatabaseLoading: false,
        editDatabaseModalVisible: false,
      });
    }
  }
}
