import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { useState, useEffect } from 'react';

import {
  defaultPageSizePreference,
  defaultWrapLinesPreference,
  paginationLabels,
} from 'src/commons/tables';


import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Pagination,
  TextFilter,
  Table,
} from '@amzn/awsui-components-react-v3';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';

import {
  Alert,
  Popover,
} from '@amzn/awsui-components-react';
import {
  describeSubscription,
  deleteSubscription,
  listExecutions,
  syncSubscription,
} from '../../api/subscriptions';
import { StatusIcon } from './statusIcon';
import { PageHeader, SubscriptionDetails } from './common';
import { ConfirmModal } from '../utils/modal';

export interface ExecutionsTableProps {
  setContentType: any;
  match: any;
  activeGroup: string;
}

export const ExecutionsTable = (props: ExecutionsTableProps) => {
  const [allItems, setItems] = useState([]);
  const [subscription, setSubscription] = useState({ datasetId: '||||' });
  const [alertVisible, setAlertVisible] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [executionsLoading, setExecutionsLoading] = useState(false);
  const [, setDeleteLoading] = useState(false);
  const [redirect, setRedirect] = useState(undefined);
  const [modalVisible, setModalVisible] = useState(false);
  const [deleteText, setDeleteText] = useState(undefined);
  const [preferences, setPreferences] =
    useState<CollectionPreferencesProps.Preferences>({
      wrapLines: false,
      pageSize: 10,
    });

  const columnDefinitions:TableProps.ColumnDefinition<any>[] = [
    {
      id: 'executionId',
      header: 'Execution ID',
      cell: (item) => item.executionId,
      minWidth:200,
      sortingField:'executionId'
    },
    {
      id: 'database',
      header: 'Database',
      cell: (item) => item.database,
      minWidth: 120,
    },
    {
      id: 'schema',
      header: 'Schema',
      cell: (item) => item.schema,
      minWidth: 150,
    },
    {
      id: 'table',
      header: 'Table',
      cell: (item) => item.table,
      minWidth: 150,
    },
    {
      id: 'iamRole',
      header: 'IAM Role',
      cell: (item) => item.iamRole,
      minWidth: 210,
    },
    {
      id: 'createDate',
      header: 'Create Date',
      cell: (item) => item.createDate,
      minWidth: 210,
      sortingField:'createDate'
    },
    {
      id: 'status',
      header: 'Status',
      cell: (item) => <StatusIcon status={item.status} />,
      minWidth: 210,
    },
    {
      id: 'error',
      header: 'Errors',
      cell: (item) => (
        <Popover triggerType='text' position='top' content={item.errorMessage}>
          {item.errorMessage?.substring(0, 80)}
        </Popover>
      ),
      minWidth: 210,
    },
  ];

  useEffect(() => {
    props.setContentType('table');
    handleRefresh();
  }, []);

  const handleRefresh = async () => {
    setExecutionsLoading(true);

    try {
      const response = await listExecutions({
        subscriptionId: props.match.params.id,
      });
      const { subscription } = await describeSubscription({
        ownerId: props.activeGroup,
        subscriptionId: props.match.params.id,
      });
      setItems(response.executions);
      setSubscription({ datasetId: subscription.datasetId });
    } catch (err) {
      setItems(null);
      setSubscription(null);
    }
    setExecutionsLoading(false);
  };

  const handleDelete = async () => {
    setDeleteLoading(true);
    await deleteSubscription({
      ownerId: props.activeGroup,
      subscriptionId: props.match.params.id,
    });
    setDeleteLoading(false);
    setRedirect('/subscriptions');
  };

  const handleAction = async (e) => {
    if (e.detail.id === 'sync') {
      setActionLoading(true);

      await syncSubscription({
        subscriptionId: props.match.params.id,
      });

      await handleRefresh();
      setAlertVisible(true);
      setActionLoading(false);
    }
    if (e.detail.id === 'delete') {
      setDeleteText(`Subscription ${props.match.params.id}`);
      setModalVisible(true);
    }
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const {
    items,
    collectionProps,
    paginationProps,
    filterProps,
    filteredItemsCount,
  } = useCollection(allItems, {
    filtering: {
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties: [
      ],
    },
  });

  if (redirect) return <Redirect push to={redirect} />;

  if (!subscription)
    return (
      <>
        <h2>Subscription not found</h2>
        The given subscription ID {props.match.params.id} is not valid, or you
        do not have permission to view it. Please check the URL for mistakes and
        try again.
      </>
    );

  return (
    <>
      <ConfirmModal
        visible={modalVisible}
        onClose={closeModal}
        onConfirm={handleDelete}
        variant={'delete'}
        content={deleteText}
      ></ConfirmModal>
      <div style={{ marginBottom: '10px' }}>
        <Alert
          type='success'
          dismissible={true}
          visible={alertVisible}
          onDismiss={() => setAlertVisible(false)}
        >
          Sync started successfully.
        </Alert>
      </div>
      <PageHeader
        buttons={[
          {
            text: '',
            icon: 'refresh',
            onItemClick: handleRefresh,
          },
          {
            text: 'Actions',
            onItemClick: handleAction,
            items: [
              {
                text: 'Sync now',
                id: 'sync',
              },
              {
                text: 'Pause',
                id: 'pause',
                disabled: true,
              },
              {
                text: 'Delete',
                id: 'delete',
              },
            ],
            loading: actionLoading,
          },
        ]}
        header={`${props.match.params.id}`}
      />
      <SubscriptionDetails subscription={subscription} />
      <Table
        {...collectionProps}
        loadingText='Loading resources...'
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        header={
          <h2>
            Executions
            <span className='awsui-util-header-counter'>
              {` (${items.length})`}
            </span>
          </h2>
        }
        empty={
          <div className='awsui-util-t-c'>
            <p className='awsui-util-mb-s'>No resources to display.</p>
          </div>
        }
        loading={executionsLoading}

        pagination={
          <Pagination {...paginationProps} ariaLabels={paginationLabels} />
        }

        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel='Filter resources'
            filteringPlaceholder='Find resources'
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }

        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={defaultPageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }

      />
    </>
  );
};
