import * as React from 'react';
import { Component } from 'react';
import {
  ColumnLayout,
  Container,
  Header,
  SpaceBetween,
} from '@amzn/awsui-components-react-v3';

import { getTemplate, deleteTemplate } from '../../../api/resourcesmanager';
import {
  LabeledText,
  labeledTextFromList,
  RMPageHeader,
  ErrorAlert,
  Guardrail_UNBOUND,
} from '../components';
import { Redirect } from 'react-router-dom';
import { Page } from '../../../routes/Paths';
import { dateString } from '../helpers';

export interface templateDetailProps {
  setContentType: any;
  match: any;
  activeGroup: string;
}

export interface templateDetailState {
  loading: boolean;
  template: object;
  error: object;
  redirect: string;
  contentType: string;
  redirectParams: object;
  guardrail: any;
}

export class TemplateDetails extends Component<
  templateDetailProps,
  templateDetailState
> {
  state = {
    loading: true,
    error: undefined,
    template: null,
    redirect: undefined,
    contentType: undefined,
    redirectParams: {},
    guardrail: null,
  };

  componentDidMount = async () => {
    this.props.setContentType('cards');
    await this.handleRefresh();
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.activeGroup !== this.props.activeGroup) {
      await this.handleRefresh();
    }
  };

  /**
   * Asynchronously fetches the templates from the RM API and updates the state.
   */
  handleRefresh = async () => {
    this.setState({ loading: true });

    try {
      const template = await getTemplate({
        id: this.props.match.params.id,
      });
      try {
        template.templateJsonString = JSON.stringify(
          JSON.parse(template.templateJsonString),
          null,
          2,
        );
      } catch (err) {
        //Do nothing
        console.log(
          'Quiet JSON parse error for JSON',
          template.templateJsonString,
        );
      }
      this.setState({
        template: template,
        loading: false,
      });
    } catch (err) {
      this.setState({
        error: { ...err, while: 'LOADING TEMPLATE' },
        template: null,
      });
    }
  };

  /**
   * [UNGUARDED]: Asynchronously calls the DELETE API for the given Template.
   *
   * @param {Event} e - The event which triggered the functin call, if it exists.
   */
  deleteCT_UNGUARDED = async () => {
    try {
      const response = await deleteTemplate({
        id: this.props.match.params.id,
      });

      if (response.successful) {
        this.setState({ redirect: Page.RESOURCEGROUPS });
      } else {
        this.setState({
          error: {
            ...response,
            code: 'ResponseNotSuccessful',
            while: 'DELETING TEMPLATE',
          },
        });
      }
    } catch (err) {
      console.error(err);
      this.setState({ error: { ...err, while: 'DELETING TEMPLATE' } });
    }
  };

  /**
   * A wrapper which requires the user to confirm before submitting a DeleteTemplate request to the API.
   *
   * @param {Event} e - The event which triggered the functin call, if it exists.
   */
  deleteCT = async () => {
    this.setState({
      guardrail: {
        header: `DELETE ${this.props.match.params.id}?`,
        action: this.deleteCT_UNGUARDED,
      },
    });
  };

  /**
   * Redirects the user to the Update Template page.
   */
  updateCT = () => {
    this.setState({
      redirect: Page.UPDATE_TEMPLATE.replace(':id', this.props.match.params.id),
    });
  };

  /**
   * Redirects the user to the Create Template page, prefilled with the configuration of the current Template.
   */
  cloneCT = () => {
    this.setState({
      redirect: Page.CREATE_TEMPLATE,
      redirectParams: { values: this.state.template },
    });
  };

  createResourceFromCT = () => {
    if (this.state.template.type === 'EMR_GROUP') {
      this.setState({
        redirect: Page.CREATE_RESOURCEGROUP,
        redirectParams: {
          values: JSON.parse(this.state.template.templateJsonString),
        },
      });
    } else {
      this.setState({
        error: {
          while: 'ATTEMPTING TO CREATE RESOURCE FROM TEMPLATE',
          code: 'custom_error',
          message: `Attempted to create resource from Template. The Type ${this.state.template.type} is not supported`,
        },
      });
    }
  };

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.redirect,
            state: this.state.redirectParams,
          }}
        />
      );
    }

    return (
      <>
        {ErrorAlert(this.state.error)}
        {Guardrail_UNBOUND.bind(this)()}

        <SpaceBetween size='m'>
          <RMPageHeader
            buttons={[
              {
                text: 'Delete',
                onItemClick: this.deleteCT,
              },
              {
                text: 'Edit',
                onItemClick: this.updateCT,
              },
              {
                text: 'Clone',
                onItemClick: this.cloneCT,
              },
              {
                text: 'Create',
                onItemClick: this.createResourceFromCT,
                variant: 'primary',
              },
            ]}
            subheader={this.state.template?.name}
          />
          <Container header={<Header variant='h2'> Template Details </Header>}>
            <ColumnLayout columns={2} borders='horizontal'>
              {labeledTextFromList(
                [
                  {
                    label: 'Template ID',
                    value: this.state.template?.id,
                    copiable: true,
                  },
                  {
                    label: 'Name',
                    value: this.state.template?.name,
                  },
                  {
                    label: 'Owner (Group ID)',
                    value: this.state.template?.groupId,
                    copiable: true,
                  },
                  {
                    label: 'Description',
                    value: this.state.template?.description,
                  },
                  {
                    label: 'Created By',
                    value: this.state.template?.createdBy,
                    copiable: true,
                  },
                  {
                    label: 'Create Date',
                    value: dateString(this.state.template?.createDate),
                  },
                  {
                    label: 'Updated By',
                    value: this.state.template?.updatedBy,
                    copiable: true,
                  },
                  {
                    label: 'Update Date',
                    value: dateString(this.state.template?.updateDate),
                  },
                ],
                this.state.loading,
              )}
            </ColumnLayout>
            <pre>
              <LabeledText
                label={'Template JSON'}
                value={this.state.template?.templateJsonString}
                loading={this.state.loading}
                copiable
              />
            </pre>
            <br />
          </Container>
        </SpaceBetween>
      </>
    );
  }
}
