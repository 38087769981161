import * as React from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { CopiableText } from 'src/components/catalog/common';
import {
  Box,
  Pagination,
  PropertyFilter,
  Table,
} from '@amzn/awsui-components-react-v3';


import { PageHeader } from 'src/components/subscriptions/common';
import {
  getFieldOnChange,
  getFilterCounterText,
  handleSelectedItem,
} from 'src/components/permissions/myBaselining/baseliningUtils';

export function IAMAccess({ info, props, onChange }) {
  const tableItems = props.tableItems;
  const catalogsMap = props.catalogsMap;
  const columnDefinitions = [
    {
      id: 'catalogDisplayName',
      header: 'Catalog name',
      cell: (item) => (
        <Link to={`catalogs/${item.catalogId}/${item.region}`}>
          {getCatalogNameUnlessLoading(item.catalogId, item.region)}
        </Link>
      ),
      minWidth: '100px',
      sortingField: 'catalogDisplayName',
    },
    {
      id: 'databaseName',
      header: 'Database name',
      cell: (item) => (
        <Link to={`databases/${item.catalogId}/${item.databaseName}`}>
          {item.databaseName}
        </Link>
      ),
      minWidth: '200px',
      sortingField: 'databaseName',
    },
    {
      id: 'tableName',
      header: 'Table name',
      cell: (item) => <Link to={`datasets/${item.id}`}>{item.tableName}</Link>,
      minWidth: '200px',
      sortingField: 'tableName',
    },
    {
      id: 'roleArn',
      header: 'Role ARN',
      cell: (item) => (
        <div style={{ paddingBottom: 6 }}>
          <CopiableText loading={false} value={item.roleArn} name={''} />
        </div>
      ),
      minWidth: '200px',
      sortingField: 'roleArn',
    },
  ];
  const { selectedIamAccess } = info.BaselineIAMAccess;
  const selectedIamAccessChange = getFieldOnChange(
    'selectedIamAccess',
    onChange,
  );

  const fetchAllData = async () => {};

  const componentDidMount = async () => {
    await fetchAllData();
  };

  useEffect(() => {
    (async () => {
      await componentDidMount();
    })();
  }, []);

  // useEffect(() => {(async()=> {
  //     await fetchAllData();
  // })()}, [props.approvedPermissions]);


  const getCatalogNameUnlessLoading = (catalogId: string, region: string) => {
    return getCatalogName(catalogId, region);
  };

  const getCatalogName = (catalogId: string, region: string) => {
    const catalog = getCatalogFromMap(catalogId, region);
    if (catalog == null) {
      return String(catalogId);
    } else {
      return String(catalog.Name);
    }
  };

  const getCatalogFromMap = (catalogId: string, region: string) => {
    return catalogsMap.get(catalogId + ':' + region);
  };

  const flattenItems = (items: object[], roleArns) => {
    return items.map((item) => flattenItem(item, roleArns, null));
  };

  const flattenItem = (item: object, roleArns, catalog) => {
    return {
      dataSetName: item['DataSetName'],
      databaseName: item['IdInfo']['DatabaseName'],
      tableName: item['IdInfo']['TableName'],
      dataSourceId: item['IdInfo']['DataSourceId'],
      catalogId: item['IdInfo']['CatalogId'],
      region: item['IdInfo']['Region'],
      catalogInfo: item['catalogInfo'] || catalog,
      catalogDisplayName: item['catalogDisplayName'],
      id: item['Id'],
      roleArn: findRoleArn(item, roleArns),
      supportedAccessTypes: item['SupportedAccessTypes'],
      owners: item['Owners'],
      dataClassificationOption:
        item['DataClassification'] === 'Private'
          ? item['DataClassification']
          : 'Public',
      primaryOwner: item['PrimaryOwner'],
    };
  };

  const findRoleArn = (item, roleArns) => {
    if (roleArns == null) return null;

    for (const roleArn of roleArns) {
      if (roleArn.split(':')[4] == item.IdInfo.CatalogId) {
        return roleArn;
      }
    }
  };

  const {
    items,
    filteredItemsCount,
    collectionProps,
    paginationProps,
    propertyFilterProps,
  } = useCollection(flattenItems(tableItems, props.roleArns), {
    propertyFiltering: {
      filteringProperties: [],
      empty: (
        <Box textAlign='center' variant='p'>
          <div className='awsui-util-pt-s awsui-util-mb-xs'>
            <b>No datasets</b>
          </div>
          <p className='awsui-util-mb-s'>
            No datasets were found in the catalog.
          </p>
        </Box>
      ),
      noMatch: (
        <Box textAlign='center' variant='p'>
          <div className='awsui-util-pt-s awsui-util-mb-xs'>
            <b>No matches</b>
          </div>
          <p className='awsui-util-mb-s'>We can’t find a match.</p>
        </Box>
      ),
    },
    pagination: { pageSize: 15 },
    sorting: {},
    selection: {},
  });
  return (
    <>
      <Table
        {...collectionProps}
        loadingText='Loading datasets...'
        columnDefinitions={columnDefinitions}
        items={items}
        resizableColumns
        header={
          <>
            <PageHeader
              buttons={[]}
              header={
                <>
                  Datasets
                  <span className='awsui-util-header-counter'>
                    {` (${items.length})`}
                  </span>
                </>
              }
            />
          </>
        }
        empty={
          <Box textAlign='center' variant='p'>
            <div className='awsui-util-pt-s awsui-util-mb-xs'>
              <b>No datasets</b>
            </div>
            <p className='awsui-util-mb-s'>
              No datasets were found in the catalog.
            </p>
          </Box>
        }
        loading={props.loading}
        filter={
          <PropertyFilter
            i18nStrings={{
              filteringAriaLabel: 'your choice',
              dismissAriaLabel: 'Dismiss',

              filteringPlaceholder: 'Find resources',
              groupValuesText: 'Values',
              groupPropertiesText: 'Properties',
              operatorsText: 'Operators',

              operationAndText: 'and',
              operationOrText: 'or',

              operatorLessText: 'Less than',
              operatorLessOrEqualText: 'Less than or equal',
              operatorGreaterText: 'Greater than',
              operatorGreaterOrEqualText: 'Greater than or equal',
              operatorContainsText: 'Contains',
              operatorDoesNotContainText: 'Does not contain',
              operatorEqualsText: 'Equals',
              operatorDoesNotEqualText: 'Does not equal',

              editTokenHeader: 'Edit filter',
              propertyText: 'Property',
              operatorText: 'Operator',
              valueText: 'Value',
              cancelActionText: 'Cancel',
              applyActionText: 'Apply',
              allPropertiesLabel: 'All properties',

              tokenLimitShowMore: 'Show more',
              tokenLimitShowFewer: 'Show fewer',
              clearFiltersText: 'Clear filters',
              removeTokenButtonAriaLabel: () => 'Remove token',
              enteredTextLabel: (text) => `Use: "${text}"`,
            }}
            {...propertyFilterProps}
            countText={getFilterCounterText(filteredItemsCount)}
          />
        }
        pagination={<Pagination {...paginationProps} />}
        trackBy='id'
        selectionType='multi'
        selectedItems={handleSelectedItem(items, selectedIamAccess)}
        onSelectionChange={selectedIamAccessChange}
      />
    </>
  );
}
