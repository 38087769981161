import * as React from 'react';
import { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Pagination,
  PropertyFilter,
  Table,
} from '@amzn/awsui-components-react-v3';
import { useCollection } from '@amzn/awsui-collection-hooks';

import {
  largePageSizePreference,
  defaultWrapLinesPreference,
  i18nStrings,
  paginationLabels,
} from 'src/commons/tables';

import { listCatalogs } from '../../../src/api/catalog';
import { Link } from 'react-router-dom';

export interface BrowseCatalogsTableProps {
  setContentType: any;
  title: string;
  loading?: boolean;
}

const BrowseCatalogsTable = (props: BrowseCatalogsTableProps) => {
  const [allItems, setItems] = useState([]);
  const [preferences, setPreferences] =
    useState<CollectionPreferencesProps.Preferences>({
      wrapLines: false,
      pageSize: 25,
    });

  const [redirect] = useState(undefined);
  const [catalogsLoading, setCatalogsLoading] = useState(true);

  const columnDefinitions = [
    {
      id: 'catalogName',
      header: 'Catalog name',
      cell: (item) => (
        <Link to={`/catalogs/${item.catalogId}/${item.region}`}>
          {item.catalogName}
        </Link>
      ),
      minWidth: 200,
      sortingField: 'catalogName',
    },
    {
      id: 'catalogId',
      header: 'Catalog ID',
      cell: (item) => item.catalogId,
      minWidth: 200,
    },
    {
      id: 'owner',
      header: 'Owner',
      cell: (item) => item.owner,
      minWidth: 200,
      sortingField: 'owner',
    },
    {
      id: 'description',
      header: 'Description',
      cell: (item) => item.description,
      minWidth: 200,
    },
  ];

  // simplifies the catalog objects in order to make the rest of the code a bit cleaner
  const flatten = (items) => {
    return items.map((item) => ({
      catalogName: item.Name,
      catalogId: item.CatalogId,
      region: item.Region,
      description: item.Description,
      owner: item.Owner,
    }));
  };

  useEffect(() => {
    props.setContentType('table');
    fetchAllData();
  }, []);

  // Fetch databases and then their catalogs
  const fetchAllData = async () => {
    await fetchCatalogs();
  };

  const fetchCatalogs = async () => {
    setCatalogsLoading(true);
    let request = {};

    const catalogs = await listCatalogs(request);
    const catalogList = catalogs.CatalogInfoList;

    setItems(catalogList);
    setCatalogsLoading(false);
  };

  const {
    items,
    collectionProps,
    paginationProps,
    propertyFilterProps,
    filteredItemsCount,
  } = useCollection(flatten(allItems), {
    filtering: {
      noMatch: (
        <div className='awsui-util-t-c'>
          <div className='awsui-util-pt-s awsui-util-mb-xs'>
            <b>No matches</b>
          </div>
          <p className='awsui-util-mb-s'>We can’t find a match.</p>
        </div>
      ),
      empty: (
        <div className='awsui-util-t-c'>
          <div className='awsui-util-pt-s awsui-util-mb-xs'>
            <b>No catalogs</b>
          </div>
          <p className='awsui-util-mb-s'>No catalogs were found.</p>
        </div>
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties: [
        {
          propertyLabel: 'Catalog name',
          key: 'catalogName',
          groupValuesLabel: 'Catalog names',
        },
        {
          propertyLabel: 'Owner',
          key: 'owner',
          groupValuesLabel: 'Owners',
        },
      ],
    },
  });

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <>
      <Table
        {...collectionProps}
        loadingText='Loading catalogs...'
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        header={
          <h2>
            {props.title}
            <span className='awsui-util-header-counter'>
              {` (${items.length})`}
            </span>
          </h2>
        }
        loading={props.loading || catalogsLoading}
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            i18nStrings={i18nStrings}
            countText={`${filteredItemsCount} ${
              filteredItemsCount === 1 ? 'match' : 'matches'
            }`}
          />
        }
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={largePageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        pagination={
          <Pagination {...paginationProps} ariaLabels={paginationLabels} />
        }
      />
    </>
  );
};

export default BrowseCatalogsTable;
