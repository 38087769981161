import {
  Input,
  Header,
  Select,
  Container,
  FormField,
  SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import { iStep } from '../index';
import * as React from 'react';
import { validateStepTwo } from '../utils/validation';

export const DataPropsStep = ({ request, setRequest, validate }: iStep) => {
  const [validated, setValidated] = React.useState(false);
  React.useEffect(() => {
    setValidated(validate);
  }, [validate]);

  return (
    <SpaceBetween direction='vertical' size='xl'>
      <Container header={<Header variant='h2'>File format</Header>}>
        <SpaceBetween direction='vertical' size='m'>
          <FormField
            label='Skipped header rows'
            description='Are there any header lines in each file which will need to be skipped? If so, how many?'
          >
            <Input
              type='number'
              value={request.DataContract.FileProperties.HeaderLines.toString()}
              placeholder='0'
              onChange={({ detail }) =>
                setRequest({
                  ...request,
                  DataContract: {
                    ...request.DataContract,
                    FileProperties: {
                      ...request.DataContract.FileProperties,
                      HeaderLines: Number(detail.value),
                    },
                  },
                })
              }
            />
          </FormField>

          <FormField
            label='Field delimeter'
            description="What delimiter will the CSV files use?"
            errorText={
              validated && !validateStepTwo(request).FieldDelimiter
                ? 'Field delimeter is required.'
                : ''
            }
          >
            <Input
              value={request.DataContract.FileProperties.FieldDelimiter}
              placeholder=','
              onChange={({ detail }) =>
                setRequest({
                  ...request,
                  DataContract: {
                    ...request.DataContract,
                    FileProperties: {
                      ...request.DataContract.FileProperties,
                      FieldDelimiter: detail.value,
                    },
                  },
                })
              }
            />
          </FormField>

          <FormField
            label='Compression method'
            description='Are data files compressed?'
          >
            <Select
              selectedAriaLabel='Selected'
              options={[
                { label: 'None', value: null },
                { label: 'GZIP', value: 'GZIP' },
              ]}
              selectedOption={{
                label: request.DataContract.DataProperties.CompressionFormat,
                value: request.DataContract.DataProperties.CompressionFormat,
              }}
              onChange={({ detail }) =>
                setRequest({
                  ...request,
                  DataContract: {
                    ...request.DataContract,
                    DataProperties: {
                      ...request.DataContract.DataProperties,
                      CompressionFormat: detail.selectedOption.value,
                    },
                  },
                })
              }
            />
          </FormField>
        </SpaceBetween>
      </Container>
    </SpaceBetween>
  );
};
