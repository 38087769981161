import * as React from 'react';
import {
  Button,
  ButtonDropdown,
  Icon,
  Popover,
  Spinner,
} from '@amzn/awsui-components-react-v3';
import { Link } from 'react-router-dom';

export const CopiableText = ({
  name = '',
  value = '',
  loading = false,
  url = null,
}) => {
  value ? value + ' ' : '';
  return (
    <div>
      <div className='awsui-util-label'>{name}</div>
      {loading ? (
        <Spinner />
      ) : (
        <div className='awsui-util-copy-text'>
          {url == null ? value : <Link to={`${url}`}>{value}</Link>}
          {value && (
            <Popover
              size='small'
              position='top'
              dismissButton={false}
              triggerType='custom'
              content={
                <span className='awsui-util-status-positive'>
                  <Icon variant='success' name='status-positive' /> Copied
                </span>
              }
            >
              <Button
                variant='icon'
                iconName='copy'
                iconAlt='Copy'
                ariaLabel='Copy ARN'
                onClick={() => {
                  navigator.clipboard.writeText(value);
                }}
              />
            </Popover>
          )}
        </div>
      )}
    </div>
  );
};

export const DetailsPageHeader = ({
  header,
  buttons,
  versionHeader,
  leftSubheader,
  rightSubheader,
  versionSelect,
  editButton,
}) => {
  return (
    <div className='awsui-util-mb-m awsui-util-mt-xs'>
      <div className='awsui-util-action-stripe'>
        <div className='awsui-util-action-stripe-title'>
          <h1>{header}</h1>
          <br />
        </div>
      </div>
      <div className='awsui-util-action-stripe'>
        <div className='awsui-util-action-stripe-title'>
          <h2>{versionHeader}</h2>
        </div>
        <div className='awsui-util-action-stripe-group awsui-util-pv-n'>
          <div
            style={{ paddingTop: 10, paddingRight: 12 }}
            hidden={editButton.hidden}
          >
            <Button
              href={editButton.href || ''}
              iconName={editButton.icon}
              onClick={editButton.onItemClick}
              key={0}
              loading={editButton.loading}
              variant={editButton.variant || 'normal'}
            >
              {editButton.text}
            </Button>
          </div>
          <div style={{ width: 160, paddingTop: 10 }}>{versionSelect}</div>
          <div style={{ paddingTop: 10, paddingLeft: 12 }}>
            {buttons.map((button, key) =>
              !button.items ? (
                <Button
                  href={button.href || ''}
                  disabled={button.disabled || false}
                  iconName={button.icon}
                  onClick={button.onItemClick}
                  key={key}
                  loading={button.loading || false}
                  variant={button.variant || 'normal'}
                >
                  {button.text}
                </Button>
              ) : (
                <ButtonDropdown
                  items={button.items}
                  key={key}
                  onItemClick={button.onItemClick}
                  loading={button.loading}
                >
                  {button.text}
                </ButtonDropdown>
              ),
            )}
          </div>
        </div>
      </div>
      {(rightSubheader || leftSubheader) && (
        <div className='awsui-util-action-stripe'>
          <div className='awsui-util-action-stripe-title'>
            {leftSubheader && <h5>{leftSubheader}</h5>}
          </div>
          {rightSubheader && (
            <div className='awsui-util-action-stripe-title'>
              <h5>{rightSubheader}</h5>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
