import {useState} from 'react';
import * as React from 'react';
import { FormField, Input, Select } from '@amzn/awsui-components-react-v3';

export interface NotificationDestinationProps {
  setNotificationType: any;
  setS3Path: any;
}


export const NotificationDestination = (props: NotificationDestinationProps) => {
  const [notificationType, setNotificationType] = useState('S3');
  const [s3Path, setS3Path] = useState(undefined);
  const [s3PathPlactHolder,] = useState(undefined);
  const [selectedNotificaitonType, setSelectedNotificaitonType] = useState({
    value: 'S3',
    label: 'S3',
  });

  return (
    <>
      <FormField label={<div>Notification type</div>}>
        <Select
          selectedAriaLabel='Selected'
          selectedOption={selectedNotificaitonType}
          onChange={({ detail }) => {
            const type = detail.selectedOption.value;
            props.setNotificationType(type);
            setNotificationType(type);
            setSelectedNotificaitonType({value: detail.selectedOption.value, label: detail.selectedOption.label})
          }}
          options={[
            {
              value: 'S3',
              label: 'S3',
            }
          ]}
          placeholder='Please select the notification type.'
          empty={'No types available'}
          ariaRequired={true}
        />
      </FormField>
      {notificationType == 'S3' && (
        <>
          <FormField
            label={<div>S3 location</div>}
            description='Please set the S3 location to store the confidence file in.'
          >
            <Input
              name='S3-path'
              placeholder={s3PathPlactHolder}
              value={s3Path}
              ariaRequired={true}
              onChange={(e) => {
                const path = e.detail.value.trim();
                props.setS3Path(path);
                setS3Path(path);
              }}
            />
          </FormField>
        </>
      )}
    </>
  );
}


