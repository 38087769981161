import {
  RegisterDataSetRequest,
  RegisterContractVersionRequest,
} from 'aws-sdk/clients/tethyscontractservicelambda';
import { getDataset, updateDataset } from '../../../api/ingestion';
import { validatePayload } from './utils/validation';
import { RegisterStep } from './steps/1-Register';
import { DataPropsStep } from './steps/2-Data';
import { SchemaStep } from './steps/3-Schema';
import { IngestionStep } from './steps/4-Ingestion';
import { ReviewStep } from './steps/5-Review';
import { iRegisterDataset } from './index';

import { Wizard, Link, Button } from '@amzn/awsui-components-react-v3';
import { scrollUp } from '../../utils/navigation';
import { Redirect } from 'react-router-dom';
import { Page } from 'src/routes';
import { useState } from 'react';
import * as React from 'react';
import { stepsHeader } from './utils/content';

const region = 'us-east-1';
const i18nStrings = {
  stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
  collapsedStepsLabel: (stepNumber, stepsCount) =>
    `Step ${stepNumber} of ${stepsCount}`,
  skipToButtonLabel: (step) => `Skip to ${step.title}`,
  cancelButton: 'Cancel',
  previousButton: 'Previous',
  nextButton: 'Next',
  submitButton: 'Update contract',
  optional: 'optional',
};

export interface iStep {
  validate: boolean;
  request: RegisterDataSetRequest;
  setRequest(request: RegisterDataSetRequest): void;
}

interface iUpdateContract extends iRegisterDataset {
  params: { arn: string; dataset: string };
}

export const UpdateContract = ({
  params,
  username,
  activeGroup,
  toggleHelp,
  setContentType,
  setNotifications,
}: iUpdateContract) => {
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [validationStep, setValidationStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState<string>();
  const [ id ] = useState(`${params.arn}/${params.dataset}`)

  React.useEffect(() => {
    setContentType('wizard');
    fetchDataset();
  }, []);

  const fetchDataset = async () => {
    const dataset = await getDataset({ DataSetId: id });
    setRequest({
      TableName: dataset.TableName,
      DataSetName: dataset.TableName,
      DatabaseName: dataset.DatabaseName || 'Database',
      CatalogId: dataset.CatalogId,
      DataContract: dataset.DataContract,
      PrimaryOwner: dataset.PrimaryOwner,
      CreatedBy: dataset.CreatedBy,
      Region: region,
    });
  };

  const [request, setRequest] = useState<RegisterDataSetRequest>({
    TableName: null, // Step 1
    DataSetName: null, // Step 1
    DatabaseName: null, // Step 1
    CatalogId: null, // Step 1
    DataContract: {
      DataFormat: 'FILE', // Omited
      FileProperties: {
        FileType: 'delimited', // Omited. Should be CSV.
        HeaderLines: 0, // Step 2-A
        FieldDelimiter: null, // Step 2-A # ',' | ';' | '|'
      },
      DataProperties: {
        CompressionFormat: null, // Step 2-A # 'GZIP' // Missing on docs.
        SchemaFormat: 'AVRO', // Ommited
        SchemaDefinition: '', // Step 3
        PrimaryKeyColumns: [], // Step 4
        DeDupKeyColumn: null, // Step 4
      },
      ServiceLevelAgreement: {
        IAMRole: null, // Step 2-B
        PublishType: '', // Step 2-B # 'INCREMENTAL' | 'OVERWRITE'
      },
    },
    Region: region, // This should be defaulted on the back-end.
    CreatedBy: username, // This should be fetched from Gladstone.
    PrimaryOwner: activeGroup, // This should be fetched from Gladstone. Docs need update.
  });

  const validate = (step: number) => {
    setValidationStep(step);
    const validatedRequest = validatePayload(request);

    const validateStep = (step: any) => {
      const isStepValid = !Object.values(step).some((v) => !v);
      return isStepValid;
    };

    if (step === 1) {
      const isStepOneValid = validateStep(validatedRequest.step1);
      if (!isStepOneValid) return; // Watch out: Triple negation!
    }

    if (step === 2) {
      const isStepTwoValid = validateStep(validatedRequest.step2);
      if (!isStepTwoValid) return;
    }

    if (step === 3) {
      const isStepThreeValid = validateStep(validatedRequest.step3);
      if (!isStepThreeValid) return;
    }

    if (step === 4) {
      const isStepFourValid = validateStep(validatedRequest.step4);
      if (!isStepFourValid) return;
    }

    setActiveStepIndex(step);
  };

  const navigate = () => {
    setNotifications([]);
    setRedirect(Page.INGESTION.MANAGE_DATASETS);
  };

  const submit = async () => {
    setLoading(true);
    const payload: RegisterContractVersionRequest = {
      DataSetId: id,
      DataContract: request.DataContract,
    };

    try {
      await updateDataset(payload);
      setNotifications([
        {
          type: 'success',
          dismissible: true,
          onDismiss: () => setNotifications([]),
          content: 'Data contract updated successfully.',
          action: <Button onClick={navigate}>Visit your datasets</Button>,
        },
      ]);
    } catch (e) {
      setNotifications([
        {
          type: 'error',
          dismissible: true,
          onDismiss: () => setNotifications([]),
          content: e.message,
        },
      ]);
      setLoading(false);
    }
    scrollUp();
  };

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <Wizard
      isLoadingNextStep={loading}
      i18nStrings={i18nStrings}
      steps={[
        {
          ...stepsHeader.step1,
          content: (
            <RegisterStep
              isUpdate
              request={request}
              setRequest={setRequest}
              validate={validationStep > 0}
            />
          ),
        },
        {
          ...stepsHeader.step2,
          content: (
            <DataPropsStep
              request={request}
              setRequest={setRequest}
              validate={validationStep > 1}
            />
          ),
        },
        {
          ...stepsHeader.step3,
          content: (
            <SchemaStep
              request={request}
              setRequest={setRequest}
              validate={validationStep > 2}
            />
          ),
        },
        {
          ...stepsHeader.step4,
          content: (
            <IngestionStep
              request={request}
              setRequest={setRequest}
              validate={validationStep > 3}
            />
          ),
        },
        {
          ...stepsHeader.step5,
          content: (
            <ReviewStep
              request={request}
              setActiveStepIndex={setActiveStepIndex}
            />
          ),
        },
      ].map((step) => ({
        ...step,
        info: (
          <Link variant='info' onFollow={toggleHelp}>
            Feedback
          </Link>
        ),
      }))}
      onSubmit={submit}
      activeStepIndex={activeStepIndex}
      onCancel={() => setRedirect(Page.INGESTION.MANAGE_DATASETS)}
      onNavigate={({ detail }) => validate(detail.requestedStepIndex)}
    />
  );
};
