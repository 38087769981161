import * as React from 'react';
import {
  Form,
  Button,
  ColumnLayout,
  Flashbar,
  FormField,
  Container,
  Input,
  Textarea,
  Header,
} from '@amzn/awsui-components-react-v3';

import { Redirect } from 'react-router-dom';
import { scrollUp } from '../../utils/navigation';
import { Page } from '../../../routes/Paths';

import { createNotificationTemplate } from '../../../api/notifications';
import { useEffect, useState } from "react";

export interface NotificationTemplateCreateFormProps {
  setContentType: any;
  activeGroup: string;
  username: string;
}

export const NotificationTemplateCreateForm = (props: NotificationTemplateCreateFormProps) => {
  const [notifications, setNotifications] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [redirect, setRedirect] = useState(undefined);
  const [buttonText,] = useState('Create notification template');
  const [content, setContent] = useState(null);
  const [notificationTemplateName, setNotificationTemplateName] = useState(undefined);
  const [isPublic, ] = useState(false);




  const handleCreate = async () => {
    setButtonLoading(true);
    await createNotificationTemplate({
      NotificationTemplateName: notificationTemplateName,
      Content: content,
      OwnerGroupId: props.activeGroup,
      IsPublic: isPublic,
    });

    setNotifications([
      {
        type: 'success',
        content: `Notification template created.`,
        dismissible: true,
        action: <Button onClick={() => setRedirect(Page.CREATE_NOTIFICATION)}>Back</Button>,
        onDismiss: () => setNotifications([]),
      },
    ])
    setButtonLoading(false);

    scrollUp();
  };


  useEffect(() => {
    props.setContentType('form');
  }, []);


  if (redirect) return <Redirect push to={redirect}/>;

  return (
    <div>
      <Flashbar items={notifications}></Flashbar>

      <Form
        header={
          <Header    description='You can create a notification template here.'>
              <h1 className='awsui-util-d-ib'>
                Create new notification template
              </h1>
          </Header>
        }
        actions={
          <div>
            <Button
              variant='link'
              onClick={() => setRedirect(Page.NOTIFICATIONS)}
            >
              Cancel
            </Button>
            <Button
              variant='primary'
              onClick={handleCreate}
              loading={buttonLoading}
              disabled={content == null || notificationTemplateName == null || isPublic == null}
            >
              {buttonText}
            </Button>
          </div>
        }
      >
        <Container
          className='custom-screenshot-hide'
          header={<h2>Create notification template</h2>}
        >
          <ColumnLayout>
            <div data-awsui-column-layout-root={true}>
              <>
                <FormField
                  label={<div>Notification template name</div>}
                  description='The name of the notification template.'
                >
                  <Input
                    onChange={(event) => setNotificationTemplateName(event.detail.value)}
                    value = {notificationTemplateName}
                    ariaRequired={true}
                  />
                </FormField>

                <FormField
                  label={<div>Content</div>}
                  description='The default content of the notification.'
                >
                  <Textarea
                    name='content'
                    placeholder='content'
                    value={content}
                    ariaRequired={true}
                    onChange={(e) => setContent(e.detail.value)}
                    rows={10}
                  />
                </FormField>
              </>
            </div>
          </ColumnLayout>
        </Container>
      </Form>
    </div>
  );



};












