import * as React from 'react';
import { Component } from 'react';

import { Button, Modal } from '@amzn/awsui-components-react';

export interface ConfirmModalProps {
  visible: boolean;
  variant: 'create' | 'delete';
  onClose: () => void;
  onConfirm: () => void;
  content: any;
}

export interface ConfirmModalState {
  loading: boolean;
}

export class ConfirmModal extends Component<
  ConfirmModalProps,
  ConfirmModalState
> {
  state = { loading: false };

  handleClick = () => {
    this.setState({ loading: true });
    this.props.onConfirm();
    this.setState({ loading: false });
  };

  static defaultProps = {
    variant: 'create',
  };

  data = {
    create: {
      buttonText: 'Create',
      headerText: 'Confirm creation of the resource',
    },
    delete: {
      buttonText: 'Delete',
      headerText: 'Are you sure you want to delete this resource?',
    },
  };

  render() {
    return (
      <Modal
        visible={this.props.visible}
        header={this.data[this.props.variant]['headerText']}
        onDismiss={this.props.onClose}
        footer={
          <span className='awsui-util-f-r'>
            <Button variant='link' onClick={this.props.onClose}>
              Cancel
            </Button>
            <Button
              variant='primary'
              onClick={this.handleClick}
              loading={this.state.loading}
            >
              {this.data[this.props.variant]['buttonText']}
            </Button>
          </span>
        }
      >
        {this.props.content}
      </Modal>
    );
  }
}
