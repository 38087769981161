import * as React from 'react';
import { useState, useEffect } from 'react';
import { PageHeader } from '../common';
import { Link } from 'react-router-dom';
import { NotificationEntry } from 'aws-sdk/clients/awsdlomni'

import {
  getNotifications,
  updateNotifications,
} from '../../../api/notifications';
import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Pagination,
  TextFilter,
  Table,
  Icon,
  Badge
} from '@amzn/awsui-components-react-v3';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';

import {defaultWrapLinesPreference, notificationsPageSizePreference, paginationLabels} from "src/commons/tables";

export interface InboxNotificationsProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  setSelectedNotification: any;
  setActiveTabId: any;
  refreshNotificationNum: any;
  refreshUnreadNotifications: any;
}

export const InboxNotifications = (props: InboxNotificationsProps) => {
  const [allItems, setItems] = useState([]);
  const [loadingNotifications, setLoadingNotifications] = useState(true);
  const [tableMessage, setTableMessage] = useState('No notifications');
  const [actionLoading, setActionLoading] = useState(false);
  const [nextToken, setNextToken] = React.useState("");


  const columnDefinitions:TableProps.ColumnDefinition<NotificationEntry>[] = [
    {
      id: 'Subject',
      header: 'Subject',
      cell: (item) => renderSubject(item, item.Subject),
      minWidth: 200,
    },
    {
      id: 'Content',
      header: 'Content',
      cell: (item) => item.Content,
      minWidth: 300,
    },

    {
      id: 'SenderGroupId',
      header: 'Sender group ID',
      cell: (item) => item.SenderGroupId,
    },
    {
      id: 'PriorityLevel',
      header: 'Priority level',
      cell: (item) => item.PriorityLevel,
    },
    {
      id: 'ReceiverGroupId',
      header: 'Receiver group ID',
      cell: (item) => item.ReceiverGroupId,
    },
    {
      id: 'ArchiveTime',
      header: 'Archive time',
      cell: (item) => item.ArchiveTime,
    },
    {
      id: 'CreateTime',
      header: 'Create time',
      cell: (item) => new Date(item.CreateTime).toLocaleString(),

      minWidth: 150,
    },
    {
      id: 'ReadHistory',
      header: 'Read time',
      cell: (item) => item.ReadHistory,
    },

    {
      id: 'SenderUserId',
      header: 'Sender',
      cell: (item) => item.SenderUserId,
    },
    {
      id: 'ArchivedBy',
      header: 'Archived by',
      cell: (item) => item.ArchivedBy,
    },
    {
      id: 'EmailSent',
      header: 'Email sent',
      cell: (item) => item.EmailSent,
    },
    {
      id: 'NotificationBatchId',
      header: 'Notification batch Id',
      cell: (item) => item.NotificationBatchId,
    },
    {
      id: 'MyReadTime',
      header: 'Read by you',
      cell: (item) =>
        item.ReadHistory == null ? '' : item.ReadHistory[props.username],
    },
  ];

  const contentSelectorOptions:CollectionPreferencesProps.VisibleContentOptionsGroup[] = [{
    label: 'Inbox notifications',
    options: columnDefinitions.map((column) => ({
      id:column.id,
      label: column.header as string,
      editable:true
    }))
  }]


  const renderSubject = (item, subject) => {
    let haveRead =
      item.ReadHistory != null && item.ReadHistory[props.username] != null;
    let important = item.PriorityLevel != null && item.PriorityLevel == 'HIGH';
    if (!haveRead && important) {
      return (
        <span className='awsui-util-status-info'>
          <Badge color='blue'>new</Badge>
          <Icon size='normal' variant='warning' name='status-warning' />
          <Link to={`notifications/${item.NotificationId}`}>{subject}</Link>
        </span>
      );
    } else if (haveRead && important) {
      return (
        <span>
          <Icon size='normal' name='status-warning' />
          <Link to={`notifications/${item.NotificationId}`}>{subject}</Link>
        </span>
      );
    } else if (!haveRead) {
      return (
        <span className='awsui-util-status-info'>
          <Badge
            color='blue'
            className='awsui-util-font-size-1'
          >new</Badge>
          <Link to={`notifications/${item.NotificationId}`}>{subject}</Link>
        </span>
      );
    }
    return (
      <span>
        <Link to={`notifications/${item.NotificationId}`}>{subject}</Link>
      </span>
    );
  };



  const markRead = async (notification) => {
    await updateNotifications({
      GroupId: props.activeGroup,
      NotificationIdList: [notification.NotificationId],
      MarkRead: true,
    });
    if (notification.ReadHistory == null) {
      notification.ReadHistory = new Map;
    }
    notification.ReadHistory[props.username] = "Read just now";
    props.refreshUnreadNotifications(notification.NotificationId);
  }

  const handleRefresh = async () => {
    props.setActiveTabId('inbox');
    setLoadingNotifications(true);
    if (!props.activeGroup) return;
    try {
      let request = {
        ReceiverGroupId: props.activeGroup,
        Archive: false,
        NextToken: nextToken,
      };
      let response = await getNotifications(request);
      let notificationList = [...response.NotificationList];
      while (response.NextToken != null) {
        request = {
          ReceiverGroupId: props.activeGroup,
          Archive: false,
          NextToken: response.NextToken,
        };
        response = await getNotifications(request);
        notificationList.push(...response.NotificationList);
      }
      allItems.push(...notificationList);
      setNextToken(response.NextToken);
      setItems(allItems)
    } catch (err) {
      setLoadingNotifications( false );
      setTableMessage(`Unable to load notifications. ${err.message}`);
    }
    setLoadingNotifications( false );
  };


  const [preferences, setPreferences] =
    useState<CollectionPreferencesProps.Preferences>({
      wrapLines: false,
      pageSize: 10,
      visibleContent:[
        'Subject',
        'Content',
        'SenderGroupId',
        'CreateTime'
      ]
    });

  useEffect(() => {
    props.setContentType('table');
  }, [props.activeGroup]);

  useEffect(() => {
    handleRefresh();
  }, []);

  const handleAction = async (e) => {
    if (e.detail.id === 'archive') {
      setActionLoading(true);
      await updateNotifications({
        GroupId: props.activeGroup,
        NotificationIdList: collectionProps.selectedItems.map(({ NotificationId }) => NotificationId),
        MarkArchive: true,
      });
      setItems(allItems.filter((item) => item.NotificationId != collectionProps.selectedItems[0].NotificationId));
      setActionLoading(false);
    }
  };

  const {
    items,
    collectionProps,
    paginationProps,
    filterProps,
    filteredItemsCount,
  } = useCollection(allItems, {
    filtering: {
      empty:
        <div className='awsui-util-t-c'>
          <div className='awsui-util-pt-s awsui-util-mb-xs'>
            <b>{tableMessage}</b>
          </div>
          <p className='awsui-util-mb-s'>No notifications to display.</p>
        </div>,
    },
    pagination: { pageSize: preferences.pageSize},
    sorting: {
      defaultState:{
        isDescending:true,
        sortingColumn:{
          sortingField:'CreateTime'
        }
      }
    },
    selection: {},
    propertyFiltering: {
      filteringProperties: [
      ],
    },

  });

  useEffect(() => {
    const { selectedItems } = collectionProps
    if(!selectedItems.length) return props.setSelectedNotification({})
    const selectedNotification:NotificationEntry = selectedItems[selectedItems.length - 1]
    props.setSelectedNotification({
      selectedNotificationId: selectedNotification.NotificationId,
      selectedContent: selectedNotification.Content,
      selectedPriorityLevel: selectedNotification.PriorityLevel,
      selectedSubject: selectedNotification.Subject,
      selectedCreateTime: selectedNotification.CreateTime,
      selectedArchiveTime: selectedNotification.ArchiveTime,
      selectedReadHistory: selectedNotification.ReadHistory,
      selectedSenderGroupId: selectedNotification.SenderGroupId,
      selectedSenderUserId: selectedNotification.SenderUserId,
      selectedArchivedBy: selectedNotification.ArchivedBy,
      selectedEmailSent: selectedNotification.EmailSent,
      selectedReceiverGroupId: selectedNotification.ReceiverGroupId,
      selectedNotificationBatchId: selectedNotification.NotificationBatchId,
    })
    if (collectionProps.selectedItems[0]['ReadHistory'] == undefined|| collectionProps.selectedItems[0]['ReadHistory'][props.username] == undefined) {
      markRead(collectionProps.selectedItems[0])
    }

  }, [collectionProps.selectedItems])

  return (
    <>
      <Table
        {...collectionProps}
        selectionType={'single'}
        loadingText='Loading notifications...'
        loading={loadingNotifications}
        columnDefinitions={columnDefinitions}
        visibleColumns={preferences.visibleContent}
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        header={
          <>
            <PageHeader
              buttons={[
                {
                  text: 'Actions',
                  onItemClick: handleAction,
                  items: [
                    {
                      text: 'Archive',
                      id: 'archive',
                      disabled: !collectionProps.selectedItems.length,
                    },
                  ],
                  loading: actionLoading,
                },
              ]}
              header={
                <>
                    All notifications
                  <span className='awsui-util-header-counter'>
                    {` (${allItems.length})`}
                  </span>
                </>
              }
            />
          </>
        }
        empty={
          <div className='awsui-util-t-c'>
            <div className='awsui-util-pt-s awsui-util-mb-xs'>
              <b>{tableMessage}</b>
            </div>
            <p className='awsui-util-mb-s'>No notifications to display.</p>
          </div>
        }

        filter={
        <TextFilter
          {...filterProps}
          filteringAriaLabel='Filter notifications'
          filteringPlaceholder='Find notifications'
          countText={`${filteredItemsCount} ${
            filteredItemsCount === 1 ? 'match' : 'matches'
          }`}
        />
      }
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={notificationsPageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
            visibleContentPreference={{
              title:'Visible content',
              options:contentSelectorOptions
            }}
          />
        }

        pagination={
          <Pagination {...paginationProps} ariaLabels={paginationLabels} />
        }

        sortingDescending={true}
        />
    </>
  );
}
