import {
  Header,
  Container,
  FormField,
//  CodeEditor,
  SpaceBetween,
  Textarea,
} from '@amzn/awsui-components-react-v3';
import * as React from 'react';
import { useEffect, useState } from 'react';

import 'ace-builds/css/ace.css';
import 'ace-builds/css/theme/dawn.css';
import 'ace-builds/css/theme/tomorrow_night_bright.css';
import { iStep } from '..';
import { validateStepThree } from '../utils/validation';

/*
const i18nStrings = {
  loadingState: 'Loading code editor',
  errorState: 'There was an error loading the code editor.',
  errorStateRecovery: 'Retry',
  editorGroupAriaLabel: 'Code editor',
  statusBarGroupAriaLabel: 'Status bar',
  cursorPosition: (row, column) => `Ln ${row}, Col ${column}`,
  errorsTab: 'Errors',
  warningsTab: 'Warnings',
  preferencesButtonAriaLabel: 'Preferences',
  paneCloseButtonAriaLabel: 'Close',
  preferencesModalHeader: 'Preferences',
  preferencesModalCancel: 'Cancel',
  preferencesModalConfirm: 'Confirm',
  preferencesModalWrapLines: 'Wrap lines',
  preferencesModalTheme: 'Theme',
  preferencesModalLightThemes: 'Light themes',
  preferencesModalDarkThemes: 'Dark themes',
};
*/

export const SchemaStep = ({ request, setRequest, validate }: iStep) => {
  const [validated, setValidated] = React.useState(false);
  React.useEffect(() => {
    setValidated(validate);
  }, [validate]);

//  const [preferences, setPreferences] = useState(undefined);
  const [, setLoading] = useState(true);
  const [, setAce] = useState<any>();

  useEffect(() => {
    import('ace-builds')
      .then((ace) =>
        import('ace-builds/webpack-resolver')
          .then(() => {
            ace.config.set('useStrictCSP', true);
            ace.config.set('loadWorkerFromBlob', false);
            setAce(ace);
            setLoading(false);
          })
          .catch(() => setLoading(false)),
      )
      .catch(() => setLoading(false));
  }, []);

  return (
    <SpaceBetween size='xl'>
      <Container header={<Header variant='h2'>AWS Glue Schema Registry</Header>}>
        <SpaceBetween size='l'>
          <FormField
            errorText={
              validated && !validateStepThree(request).SchemaDefinition
                ? !request.DataContract.DataProperties.SchemaDefinition
                  ? 'AWS Glue Schema Registry is required.'
                  : 'Invalid Schema: no fields detected in your AWS Glue Schema Registry.'
                : ''
            }
          >
            <Textarea
              placeholder='Add your AWS Glue Schema here...'
              rows={24}
              value={request.DataContract.DataProperties.SchemaDefinition}
              onChange={({ detail }) =>
                setRequest({
                  ...request,
                  DataContract: {
                    ...request.DataContract,
                    DataProperties: {
                      ...request.DataContract.DataProperties,
                      SchemaDefinition: detail.value,
                    },
                  },
                })
              }
            />
            {
              /*
            <CodeEditor
              ace={ace}
              loading={loading}
              language='json'
              preferences={preferences}
              i18nStrings={i18nStrings}
              value={request.DataContract.DataProperties.SchemaDefinition}
              onChange={({ detail }) =>
                setRequest({
                  ...request,
                  DataContract: {
                    ...request.DataContract,
                    DataProperties: {
                      ...request.DataContract.DataProperties,
                      SchemaDefinition: detail.value,
                    },
                  },
                })
              }
              onPreferencesChange={(e) => setPreferences(e.detail)}
            />
              */
            }
          </FormField>
        </SpaceBetween>
      </Container>
    </SpaceBetween>
  );
};
