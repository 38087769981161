import React from 'react';

import {
  Header,
  Textarea,
  Container,
  ColumnLayout
} from '@amzn/awsui-components-react-v3';
interface useCaseProps {
  value: string
  onChange?: (event: string | undefined) => void;
}

const UseCaseComponent = (props: useCaseProps) => {
  const { onChange, value } = props;

  const handleUseCaseChange = (event: CustomEvent | undefined) => {
    if (onChange) onChange(event['detail'].value);
  };

  return (
    <Container
      header={
        <Header
          variant="h2"
          description="Please enter a Use Case"
        >
          Use Case - optional
        </Header>
      }
    >
      <ColumnLayout>
        <div data-awsui-column-layout-root={true}>
          <Textarea
            value={value}
            onChange={handleUseCaseChange}
            placeholder="Enter use case"
          />
        </div>
      </ColumnLayout>
    </Container>
  );
};

export default UseCaseComponent;