import {
  Box,
  Button,
  Modal,
  ModalProps,
  SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import React from 'react';
import { pop } from './helpers';

interface iClosingModal extends ModalProps {
  onClose(): void;
  action?():void
}

export const UnauthorizedModal = ({
  header = 'Insufficient permission',
  closeAriaLabel = 'Close modal',
  ...props
}: iClosingModal) => {
  const content = pop(
    props,
    'content',
    [
      'You are not authorized to perform the operations on this service! Please onboard with Data Lake Resource Manager.',
    ].map((t) => <p>{t}</p>),
  );

  const footer = (
    <Box float='right'>
      <SpaceBetween direction='horizontal' size='xs'>
        <Button variant='primary' onClick={props.onClose}>
          Okay
        </Button>
      </SpaceBetween>
    </Box>
  );

  return (
    <Modal
      footer={footer}
      header={header}
      closeAriaLabel={closeAriaLabel}
      onDismiss={props.onClose}
      {...props}
    >
      {content}{' '}
      <a href='https://w.amazon.com/bin/view/AWSDataLake/ResourceManager#HOnboarding'>
        Onboarding steps
      </a>
    </Modal>
  );
};

export const GuardrailUnbound = ({
  header = 'PLEASE CONFIRM ACTION:',
  closeAriaLabel = 'Close modal',
  ...props
}: iClosingModal) => {
  const content = pop(
    props,
    'content',
    [
      'Are you sure you want to continue with the above action?',
      'If so, please click "OK" to proceed.',
      'Otherwise close this box by clicking "Cancel" below or "X" above.',
    ].map((t) => <p>{t}</p>),
  );

  const footer = (
    <Box float='right'>
      <SpaceBetween direction='horizontal' size='xs'>
        <Button variant='link' onClick={props.onClose}>
          Cancel
        </Button>

        <Button variant='primary' onClick={props.action}>
          Okay
        </Button>
      </SpaceBetween>
    </Box>
  );

  return (
    <Modal
      footer={footer}
      header={header}
      closeAriaLabel={closeAriaLabel}
      onDismiss={props.onClose}
      {...props}
    >
      {content}
    </Modal>
  );
};
