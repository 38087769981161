/**
 * Sets the values of a of a (sub)key defined in the given object, and then returns the updated object.
 * @param {object} obj - The object to update
 * @param {array<string>} subkeys - An ordered list of strings matching the keys nested within an object
 * @param {any} value - The new value to update the defined key within the object.
 * @returns {object} - Updated object with the new value
 */
import { ALLOW_ACTION, DENY_ACTION } from './constants';

export function set(obj, subkeys, value) {
  if (obj == undefined) {
    return undefined;
  }
  var sks = [...subkeys];
  const nextKey = sks.shift();
  if (nextKey == undefined) return;

  if (sks.length == 0) {
    obj[nextKey] = value;
    return obj;
  } else {
    obj[nextKey] = set(obj[nextKey], sks, value);
    return obj;
  }
}

/**

 * Gets the value(s) located at given (sub)key(s).
 * @param {object} obj - The oject containing the nested subkeys
 * @param {array<string | array<string>} subkeys - An ordered list of strings matching the keys nested within an object. NOTE: The last value within this array can be an array of strings in order to return multiple keys from the object.
 * @returns {any | array<any>} - The value(s) located at the given (sub)key(s)
 *
 * USAGE:
 * obj = {
 *    a: 1,
 *    b: 2,
 *    c: {
 *        d:{
 *            e: 5,
 *            f: 6,
 *            g: 7,
 *        },
 *    },
 * };
 *
 * e.g. get(obj, ["a"]) returns 1
 * e.g. get(obj, ["a", "c", "d"]) returns {e:5, f:6, g:7}
 * e.g. get(obj, ["a", "c", "d", ["e", "f", "g"]]) returns [5,6,7]
 */
export function get(obj, subkeys) {
  if (obj == undefined) {
    return undefined;
  }
  var sks = [...subkeys];
  const nextKey = sks.shift();
  if (nextKey == undefined) return obj;

  if (sks.length == 0) {
    if (Array.isArray(nextKey)) {
      return nextKey.map((key) => obj[key]);
    } else {
      return obj[nextKey];
    }
  } else {
    return get(obj[nextKey], sks);
  }
}

/**
 * Returns the value at a key from a given object and then deletes that key in the object, or if the key doesn't exist returns a user-defined default-value.
 * @param {object} obj
 * @param {string} key
 * @param {any} defaultVal
 * @returns {any} value of obj[key] or defaultValue, if the key doesn't exist
 */
export function pop(obj, key, defaultVal = undefined) {
  if (obj == undefined) {
    return undefined;
  }

  if (key in obj) {
    const val = obj[key];
    delete obj[key];
    return val;
  } else if (typeof defaultVal !== 'undefined') {
    return defaultVal;
  }
  throw `Key (${key}) not in object (${Object.keys(
    obj,
  )}), no defaultVal defined`;
}

const oneDay = 24 * 60 * 60 * 1000;
/**
 * Determines whether the input item is empty
 * @param {any} x - Some item
 * @returns {boolean} - Whether x is empty
 */
export const isEmpty = (x) =>
  [[], '', null, undefined, NaN].includes(x) ||
  (x instanceof Object && Object.keys(x).length == 0);

/**
 * Removes the empty values from an object.
 * @param {object} obj - Some object to prune
 * @returns {object} the input object with all the empty keys removed
 */
export const pruneEmpty = (obj) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([, value]) => !isEmpty(value)),
  );
};

/**
 * Helper function which creates a deep recursive copy of the given object.
 * @param {object} obj - Some JSON object to be copied
 * @returns {object} a recursively deep-copied clone of the input object
 */
export const deepCopy = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

/**
 * Helper function which returns a flattened list of the recursively nested entries in an object.
 */
export const flattenObjEntries = (obj) => {
  return Object.entries(obj).reduce((keys, [key, value]) => {
    if (value.constructor === Object) {
      keys = keys.concat(flattenObjEntries(value));
    } else {
      keys.push([key, value]);
    }
    return keys;
  }, []);
};

/**
 * Helper function which returns a flattened version of an object, returning all the leaf nodes.
 */
export const flattenObj = (obj) => {
  return Object.fromEntries(flattenObjEntries(obj));
};

/**
 * Helper function which takes an array of integers and returns a list of continuous sequences ('chunks') within that sequence.
 *
 * USAGE:
 * e.g. chunk([1,2,3,4]) returns [[1,4]]
 * e.g. chunk([1,2,3,5]) returns [[1,3], 5]
 * e.g. chunk([0,2,3,4,6,7,8,10]) returns [0,[2,4],[6,8], 10]
 */
export const chunk = (array) => {
  if (
    array.some(
      (x) => parseInt(x) != x || [null, NaN, undefined].includes(parseInt(x)),
    )
  ) {
    throw `Input array must be entirely integers, was ${array}`;
  }

  const sorted = [...array].sort((a, b) => a - b);
  var chunkArray = [];

  if (sorted) {
    chunkArray.push([sorted[0], sorted[0]]);
    for (var i = 1; i < sorted.length; i++) {
      if (sorted[i] === chunkArray[chunkArray.length - 1][1]) {
        throw `Repeat elements in sorted array at index ${i - 1}, ${i + 1}`;
      } else if (sorted[i] === chunkArray[chunkArray.length - 1][1] + 1) {
        chunkArray[chunkArray.length - 1][1]++;
      } else {
        if (
          chunkArray[chunkArray.length - 1][0] ===
          chunkArray[chunkArray.length - 1][1]
        ) {
          chunkArray[chunkArray.length - 1] =
            chunkArray[chunkArray.length - 1][0];
        }
        chunkArray.push([sorted[i], sorted[i]]);
      }
    }

    if (
      chunkArray[chunkArray.length - 1][0] ===
      chunkArray[chunkArray.length - 1][1]
    ) {
      chunkArray[chunkArray.length - 1] = chunkArray[chunkArray.length - 1][0];
    }

    return chunkArray;
  } else {
    return [];
  }
};

/**
 * Returns a stringified version of the given chunks
 * @param {array} chunks - The output of a chunking function, as defined above
 * @param {object} convertObj - A map from values that might be contained in the chunk to some corresponding string.
 * @returns {string}
 */
export const chunksToString = (chunks, convertObj = {}) => {
  var stringArray = [];
  for (var c = 0; c < chunks.length; c++) {
    const chunk = chunks[c];
    var val = '';

    if (Array.isArray(chunk)) {
      val += convertObj[chunk[0]] || chunk[0];
      val += '-';
      val += convertObj[chunk[1]] || chunk[1];
    } else {
      val += convertObj[chunk] || chunk;
    }
    stringArray.push(val);
  }
  return stringArray.join(', ');
};

/**
 * Given a date object, returns the rounded number of days since Today.
 */
export const sinceToday = (date) => {
  var today;
  today = new Date();
  return Math.round((today - date) / oneDay);
};

export const dateString = (date) => {
  return new Date(date).toString().replace(/ (\(.*\))$/, '');
};

export const permissionToRM = (whiteListedGroupsInfo, groupId, operation) => {
  if (whiteListedGroupsInfo[groupId] == undefined) {
    return false;
  }
  var servicePermissionInfo = whiteListedGroupsInfo[groupId];
  console.log(
    'servicePermissionInfo: ' + JSON.stringify(servicePermissionInfo),
  );
  for (var i = 0; i < servicePermissionInfo.length; i++) {
    if (servicePermissionInfo[i].effect == DENY_ACTION) {
      for (var j = 0; j < servicePermissionInfo[i].action.length; j++) {
        if (regexMatch(servicePermissionInfo[i].action[j], operation)) {
          return false;
        }
      }
    }
  }
  for (var i = 0; i < servicePermissionInfo.length; i++) {
    if (servicePermissionInfo[i].effect == ALLOW_ACTION) {
      for (var j = 0; j < servicePermissionInfo[i].action.length; j++) {
        if (regexMatch(servicePermissionInfo[i].action[j], operation)) {
          return true;
        }
      }
    }
  }
  return false;
};

const regexMatch = (expression, value) => {
  var regex = '';
  for (var i = 0; i < expression.length; i++) {
    switch (expression[i]) {
      case '?':
        regex += '.';
        break;
      case '*':
        regex += '.*';
        break;
      default:
        regex += expression[i];
    }
  }
  return value.match(regex);
};
