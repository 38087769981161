import * as React from 'react';
import { useState, useEffect } from 'react';

import { listSubscriptions, parseDatasetId } from '../../api/subscriptions';
import { Link } from 'react-router-dom';
import { PageHeader } from './common';
import { Redirect } from 'react-router-dom';

import {
  defaultPageSizePreference,
  defaultWrapLinesPreference,
  paginationLabels,
} from 'src/commons/tables';

import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Pagination,
  TextFilter,
  Table,
} from '@amzn/awsui-components-react-v3';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';

export interface SubscriptionsTableProps {
  setContentType: any;
  activeGroup: string;
}

export const SubscriptionsTable = (props: SubscriptionsTableProps) => {
  const [allItems, setItems] = useState([]);
  const [redirect, setRedirect] = useState(undefined);
  const [loadingSubscriptions, setLoadingSubscriptions] = useState(true);
  const [tableMessage, setTableMessage] = useState('No subscriptions');
  const [preferences, setPreferences] =
    useState<CollectionPreferencesProps.Preferences>({
      wrapLines: false,
      pageSize: 10,
    });

  const columnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'subscriptionId',
      header: 'Subscription ID',
      cell: (item) => (
        <Link to={`subscriptions/${item.subscriptionId}`}>
          {item.subscriptionId}
        </Link>
      ),
      minWidth: 200,
      sortingField: 'subscriptionId',
    },
    {
      id: 'sourceTable',
      header: 'Source table',
      cell: (item) => parseDatasetId(item.datasetId).table,
      minWidth: 150,
    },
    {
      id: 'targetSchema',
      header: 'Target schema',
      cell: (item) => item.schema,
      minWidth: 150,
    },
    {
      id: 'targetTable',
      header: 'Target table',
      cell: (item) => item.table,
      minWidth: 150,
    },
    {
      id: 'targetConnection',
      header: 'Connection ID',
      cell: (item) => (
        <Link to={`connections/${item.targetId}`}>{item.targetId}</Link>
      ),
      minWidth: 210,
    },
    {
      id: 'createDate',
      header: 'Create data',
      cell: (item) => item.createDate,
      minWidth: 210,
      sortingField: 'createDate',
    },
    {
      id: 'lastSync',
      header: 'Last sync',
      cell: (item) => item.lastSync,
      minWidth: 210,
      sortingField: 'lastSync',
    },
  ];

  useEffect(() => {
    props.setContentType('table');
    handleRefresh();
  }, []);

  useEffect(() => {
    handleRefresh();
  }, [props.activeGroup]);

  const handleRefresh = async () => {
    if (!props.activeGroup) return;
    setLoadingSubscriptions(true);
    try {
      const subscriptions = await listSubscriptions({
        ownerId: props.activeGroup,
      });
      setItems(subscriptions.subscriptions);
      setLoadingSubscriptions(false);
    } catch (err) {
      setTableMessage(`Unable to load subscriptions. ${err.message}`);
      setLoadingSubscriptions(false);
    }
  };

  const {
    items,
    collectionProps,
    paginationProps,
    filterProps,
    filteredItemsCount,
  } = useCollection(allItems, {
    filtering: {
      empty: (
        <div className='awsui-util-t-c'>
          <div className='awsui-util-pt-s awsui-util-mb-xs'>
            <b>{tableMessage}</b>
          </div>
          <p className='awsui-util-mb-s'>No subscriptions to display.</p>
        </div>
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties: [],
    },
  });

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <>
      <Table
        {...collectionProps}
        loadingText='Loading subscriptions...'
        loading={loadingSubscriptions}
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        header={
          <>
            <PageHeader
              buttons={[
                {
                  text: '',
                  icon: 'refresh',
                  onItemClick: handleRefresh,
                },
                {
                  text: 'Create subscription',
                  variant: 'primary',
                  onItemClick: () => {
                    setRedirect('subscriptions/new');
                  },
                },
              ]}
              header={
                <>
                  Subscriptions
                  <span className='awsui-util-header-counter'>
                    {` (${items.length})`}
                  </span>
                </>
              }
            />
          </>
        }
        selectionType='single' // Why is this used?
        pagination={
          <Pagination {...paginationProps} ariaLabels={paginationLabels} />
        }
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={defaultPageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel='Filter resources'
            filteringPlaceholder='Find resources'
            countText={`${filteredItemsCount} ${
              filteredItemsCount === 1 ? 'match' : 'matches'
            }`}
          />
        }
      />
    </>
  );
};
