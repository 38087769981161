import React, { Component } from 'react';
import {
  Button,
  Flashbar,
  Form,
  Container,
  Spinner,
  TokenGroup,
  Header,
  FlashbarProps,
  SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import { Redirect } from 'react-router-dom';
import { Page } from 'src/routes';
import { editDataLakeRole } from '../../../src/api/permissions';
import {
  getPendingDataAccessRequestContent,
  PENDING_DATA_ACCESS_REQUEST_SUBJECT,
} from 'src/components/utils/emails';
import { createNotification } from '../../../src/api/notifications';

export interface CheckoutProps {
  cartItems: object[];
  setContentType: any;
  activeGroup: string;
  removeFromCart: any;
  setItemsInCart: any;
  cartItemIds: string[];
  username: string;
}

export interface CheckoutState {
  redirect: string;
  catalogToItemsMap: Map<string, object[]>;
  requestSending: boolean;
  notifications: FlashbarProps.MessageDefinition[];
}

export class Checkout extends Component<CheckoutProps, CheckoutState> {
  state = {
    redirect: undefined,
    catalogToItemsMap: new Map(),
    requestSending: false,
    notifications: [],
  };

  MAX_DATASETS_PER_REQUEST = 500;

  componentDidMount() {
    this.props.setContentType('form');
    this.submitAccessRequest = this.submitAccessRequest.bind(this);
    this.sendNotificationToDataProviders = this.sendNotificationToDataProviders.bind(
      this,
    );
    this.handleAccessRequest = this.handleAccessRequest.bind(this);
    this.setCatalogToItemsMap();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.cartItemIds.length != this.props.cartItemIds.length) {
      this.setCatalogToItemsMap();
    }
  }

  // We are storing a catalog -> itemsInCatalog map in the state.
  // This populates that map.
  setCatalogToItemsMap() {
    const catalogToItemsMap = new Map();
    for (const item of this.props.cartItems) {
      const catalogName = item['catalogDisplayName']
        ? item['catalogDisplayName']
        : item['catalogInfo']['Name'];
      if (!catalogToItemsMap.has(catalogName)) {
        catalogToItemsMap.set(catalogName, []);
      }
      catalogToItemsMap.get(catalogName).push(item);
    }

    this.setState({
      catalogToItemsMap: catalogToItemsMap,
    });
  }

  // Retrieve token groups, split up by catalogs (if there are multiple)
  getTokenGroupsByCatalog() {
    const tokenGroups = [];
    // Sorting the catalogs
    const sortedCatalogs = Array.from(
      this.state.catalogToItemsMap.keys(),
    ).sort();
    for (const catalog of sortedCatalogs) {
      tokenGroups.push(
        this.getTokenGroupForItems(
          this.state.catalogToItemsMap.get(catalog),
          catalog,
        ),
      );
    }
    return <div>{tokenGroups}</div>;
  }

  // Converting items into something more helpful for token groups
  convertItemsForTokenGroup(items: object[]) {
    const toReturn = [];
    // Can't use iterator because we need to maintain order
    for (const item of items) {
      toReturn.push({
        label: item['tableName'],
        labelTag: item['databaseName'],
        dismissLabel: 'Remove ' + item['tableName'],
        description: `This table is owned by ${item['owners'].join(', ')}`,
        key: item['id'],
      });
    }
    return toReturn;
  }

  // Retrieve the token group structure for items of the same catalog
  getTokenGroupForItems(items, catalog) {
    const sortedItems = items.sort((a, b) =>
      a['tableName'].localeCompare(b['tableName']),
    );
    const convertedItems = this.convertItemsForTokenGroup(sortedItems);
    return (
      <div className='awsui-util-mb-m awsui-util-mt-xs' key={catalog}>
        <div className='awsui-util-action-stripe'>
          <div className='awsui-util-action-stripe-title'>
            <h3>
              {`Datasets in catalog "${catalog}"`}
              {/*We don't display the number twice if the number of items in catalog */}
              {/*same as total number of items*/}
              {convertedItems.length !== this.props.cartItems.length && (
                <span className='awsui-util-header-counter'>
                  {` (${convertedItems.length})`}
                </span>
              )}
            </h3>
          </div>
        </div>
        <div className='awsui-util-action-stripe-group awsui-util-pv-n'>
          <TokenGroup
            alignment='vertical'
            items={convertedItems}
            onDismiss={({ detail: { itemIndex } }) => {
              const removedDataSet = sortedItems[itemIndex];
              this.props.removeFromCart([removedDataSet]);
            }}
          />
        </div>
      </div>
    );
  }

  // Submitting request for access to items in the cart
  async submitAccessRequest() {
    const failMessage = 'Failed to submit the access request.';
    const successMessage = `Successfully submitted the access request. This will remain pending until the data owners approve or deny the request.`;
    const numCartItems = this.props.cartItemIds.length;
    try {
      for (let i = 0; i < numCartItems; i += this.MAX_DATASETS_PER_REQUEST) {
        const endIndex = Math.min(
          i + this.MAX_DATASETS_PER_REQUEST,
          numCartItems,
        );
        await editDataLakeRole({
          groupId: this.props.activeGroup,
          datasetsToAdd: this.props.cartItemIds.slice(i, endIndex),
        });
      }
    } catch (err) {
      this.setState({
        notifications: [
          ...this.state.notifications,
          {
            type: 'error',
            content: failMessage,
            dismissible: true,
            dismiss: () => this.dismissNotification(failMessage),
          },
        ],
      });
      return;
    }

    this.setState({
      notifications: [
        ...this.state.notifications,
        {
          type: 'success',
          content: successMessage,
          dismissible: true,
          dismiss: () => this.dismissNotification(successMessage),
        },
      ],
    });
  }

  // Sends a notification telling the data provider they have pending access requests
  async sendNotificationToDataProviders() {
    function failMessage(provider) {
      return `Failed to send a notification to the data provider, ${provider}, about your access request.`;
    }
    function successMessage(provider) {
      return `Successfully sent a notification to the data provider, ${provider}, about your access request.`;
    }
    const dataProviderToDatasets = new Map<string, object[]>();
    for (const item of this.props.cartItems) {
      if (item['primaryOwner'] != undefined) {
        const currentNotificationRecipientGroup = item['primaryOwner'];
        if (!dataProviderToDatasets.has(currentNotificationRecipientGroup)) {
          dataProviderToDatasets.set(currentNotificationRecipientGroup, []);
        }
        let currentItems = dataProviderToDatasets.get(
          currentNotificationRecipientGroup,
        );
        currentItems.push(item);
      }
    }

    for (const dataProviderRecipient of dataProviderToDatasets.keys()) {
      const items = dataProviderToDatasets.get(dataProviderRecipient);
      try {
        await createNotification({
          SenderGroupId: this.props.activeGroup,
          ReceiverGroupIdList: [dataProviderRecipient],
          PriorityLevel: 'MEDIUM',
          Subject: PENDING_DATA_ACCESS_REQUEST_SUBJECT,
          Content: getPendingDataAccessRequestContent(
            items,
            this.props.activeGroup,
            dataProviderRecipient,
          ),
          EmailNeeded: true,
        });
      } catch (err) {
        this.setState({
          notifications: [
            ...this.state.notifications,
            {
              type: 'error',
              content: failMessage(dataProviderRecipient),
              dismissible: true,
              dismiss: () =>
                this.dismissNotification(failMessage(dataProviderRecipient)),
            },
          ],
        });
        return;
      }
      this.setState({
        notifications: [
          ...this.state.notifications,
          {
            type: 'success',
            content: successMessage(dataProviderRecipient),
            dismissible: true,
            dismiss: () =>
              this.dismissNotification(successMessage(dataProviderRecipient)),
          },
        ],
      });
    }
  }

  // Submits access requests and send notification of the access request to the data provider
  async handleAccessRequest() {
    this.setState({ requestSending: true });

    await this.submitAccessRequest();

    await this.sendNotificationToDataProviders();

    // Remove all items from the cart
    this.props.setItemsInCart([]);

    this.setState({ requestSending: false });
  }

  dismissNotification(messageString) {
    this.setState({
      notifications: this.state.notifications.filter(
        (notificationItem) => notificationItem.content !== messageString,
      ),
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }

    if (!this.state.catalogToItemsMap) {
      return (
        <>
          <Flashbar items={this.state.notifications} />
          <Spinner size='large' />;
        </>
      );
    }

    return (
      <>
        <Flashbar items={this.state.notifications} />
        <Form


          header={<Header
            variant='h1'

            description='Please review the tables in your cart.
          Once finished, click on "Request access" to submit your request
          to the data providers who will review and approve/deny your request shortly.'>
            Request access</Header>}
          actions={[
            <SpaceBetween direction='horizontal' size='xs'>
              <Button
                variant='normal'
                onClick={() => {
                  this.props.setItemsInCart([]);
                }}
                disabled={this.props.cartItems.length === 0}
              >
                {'Clear cart'}
              </Button>
              <Button
                variant='primary'
                onClick={this.handleAccessRequest}
                loading={this.state.requestSending}
                disabled={this.props.cartItems.length === 0}
              >
                {'Request access'}
              </Button>
            </SpaceBetween>
          ]}
        >
          <Container
            className='custom-screenshot-hide'
            header={
              <Header variant='h2'>
                Items in cart
                <span className='awsui-util-header-counter'>
                    {` (${this.props.cartItems.length})`}
                  </span>
              </Header>
            }
          >
            {this.props.cartItems.length === 0 ? (
              <div>
                <Button
                  variant={'link'}
                  onClick={() =>
                    this.setState({ redirect: Page.BROWSE_DATASETS })
                  }
                >
                  You have no items in your cart. Please visit our catalog to
                  request access to tables.
                </Button>
              </div>
            ) : (
              this.getTokenGroupsByCatalog()
            )}
          </Container>
        </Form>
      </>
    );
  }
}
