import * as React from 'react';
import { FormField, Input, Button, InputProps, ButtonProps } from '@amzn/awsui-components-react-v3';
import { Token } from './interface';
import '../common.css';

interface TokenInputProps {
  onBlur?: (event: CustomEvent) => void;
  onInput: (event: CustomEvent<InputProps.ChangeDetail>) => void;
  onKeypress: (event: CustomEvent<InputProps.KeyDetail>) => void;
  onTokenClosed: (
    index: number,
  ) => (event: CustomEvent<ButtonProps.ClickDetail>) => void;
  label: string;
  description: string;
  errorText?: string;
  id: string;
  tokens: Token[];
  value?: string;
  placeholder: string;
  secondaryControl?: React.ReactNode;
  tokenDescription?: string;
  disabled?: boolean;
}

class TokenInput extends React.Component<TokenInputProps, {}> {
  constructor(props: TokenInputProps) {
    super(props);
  }

  renderToken = (token: Token, index: number) => (
    <div className='custom-awsui-token'>
      <div className='awsui-select-option'>
        <div className='awsui-select-option-content'>
          <div className='awsui-select-option-label-content'>
            <span className='awsui-select-option-label'>{token.label}</span>
          </div>
          <div className='awsui-select-option-description'>
            {token.description}
          </div>
        </div>
      </div>
      <Button
        variant='icon'
        iconName='close'
        onClick={this.props.onTokenClosed(index)}
      />
    </div>
  );

  renderTokens = () =>
    this.props.tokens.map((token, index) => (
      <React.Fragment key={token.label}>
        {this.renderToken(token, index)}
      </React.Fragment>
    ));

  render() {
    const {
      onBlur,
      onInput,
      onKeypress,
      label,
      description,
      id,
      value,
      placeholder,
      disabled,
      errorText,
      secondaryControl,
    } = this.props;
    return (
      <FormField
        label={label}
        description={description}
        secondaryControl={secondaryControl}
        errorText={errorText}
      >
        <Input
          id={id}
          onBlur={onBlur}
          value={value}
          onChange={onInput}
          onKeyDown={onKeypress}
          placeholder={placeholder}
          disabled={disabled}
        />
        {this.renderTokens()}
      </FormField>
    );
  }
}

export default TokenInput;
