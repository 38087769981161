import { Redirect } from 'react-router-dom';
import { Page } from 'src/routes/Paths';
import React from 'react';

export const gotoMonitoring = (team: string, database:string, datasetId: string) =>
  Page.INGESTION.MONITORING.replace(':team', team)
    .replace(':database', database)
    .replace(':arn/:dataset', datasetId);

export const UpdateRedirect = ({ id }: { id: string }) => (
  <Redirect push to={Page.INGESTION.UPDATE_CONTRACT.replace(':arn/:dataset', id)} />
);
