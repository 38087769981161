import { CopiableText } from 'src/components/catalog/common';
import * as React from 'react';

export function getImportantMetadata(dataset, catalog, version) {
  const importantMetadata = [];
  // Adding all the metadata we would like to display
  importantMetadata.push(
    <CopiableText
      key='Database name'
      name='Database name'
      value={dataset?.IdInfo.DatabaseName}
      url={
        '/databases/' +
        dataset?.IdInfo.CatalogId +
        '/' +
        dataset?.IdInfo.DatabaseName
      }
    />,
  );
  importantMetadata.push(
    <CopiableText
      key={'Table name'}
      name='Table name'
      value={dataset?.IdInfo.TableName}
    />,
  );
  importantMetadata.push(
    <CopiableText
      key='S3 location'
      name='S3 location'
      value={version?.Location}
    />,
  );
  importantMetadata.push(
    <CopiableText
      key={'Catalog name'}
      name={'Catalog name'}
      value={catalog ? catalog.Name : 'Not found'}
      url={
        catalog
          ? '/catalogs/' +
            dataset?.IdInfo.CatalogId +
            '/' +
            dataset?.IdInfo.Region
          : null
      }
    />,
  );
  importantMetadata.push(
    <CopiableText
      key={'Catalog region'}
      name={'Catalog region'}
      value={catalog ? catalog.Region : dataset?.IdInfo.Region}
    />,
  );
  importantMetadata.push(
    <CopiableText
      key='Catalog ID'
      name='Catalog ID'
      value={dataset?.IdInfo.CatalogId}
    />,
  );
  return importantMetadata;
}

export function getMoreMetadata(dataset, version) {
  const updateTimeStr = version?.SourceUpdateTime?.toString();

  const moreMetadata = [];

  // Handling the case where Owners is not yet instantiated in database
  let owners;
  owners = dataset?.Owners ? dataset.Owners : [];
  // Doing a case insensitive sort on the Owners array
  owners.sort(function (a, b) {
    return a.localeCompare(b, 'en', { sensitivity: 'base' });
  });
  moreMetadata.push(
    <CopiableText
      key={'Owners'}
      name={owners.length > 1 ? 'Owners' : 'Owner'}
      value={owners.join(', ')}
    />,
  );
  moreMetadata.push(<CopiableText key='ID' name='ID' value={dataset?.Id} />);
  moreMetadata.push(
    <CopiableText
      key='Input format'
      name='Input format'
      value={version?.InputFormat}
    />,
  );
  moreMetadata.push(
    <CopiableText
      key='Output format'
      name='Output format'
      value={version?.OutputFormat}
    />,
  );
  moreMetadata.push(
    <CopiableText key='Update time' name='Update time' value={updateTimeStr} />,
  );
  if (dataset.WheeljackConfidenceFileLocation) {
    moreMetadata.push(
      <CopiableText
        key='Confidence file location'
        name='Confidence file location'
        value={dataset?.WheeljackConfidenceFileLocation}
      />,
    );
  }
  if (dataset?.SLA) {
    moreMetadata.push(
      <CopiableText key='SLA' name='SLA' value={dataset?.SLA} />,
    );
  }
  if (dataset?.PII !== null) {
    // Note that isPII is a string not a boolean
    moreMetadata.push(
      <CopiableText
        key='Has PII'
        name='Has PII'
        value={dataset?.PII ? 'True' : 'False'}
      />,
    );
  }
  if (dataset?.RefreshCadence) {
    moreMetadata.push(
      <CopiableText
        key='Refresh cadence'
        name='Refresh cadence'
        value={dataset?.RefreshCadence}
      />,
    );
  }
  return moreMetadata;
}

export function getLineageNodeInfo(nodeInfo) {
  const lineageNodeInfo = [];

  // Adding all the node properties we would like to display
  if (!nodeInfo?.NodeId?.includes('N/A')) {
    lineageNodeInfo.push(
      <CopiableText key='Node id' name='Node ID' value={nodeInfo?.NodeId} />,
    );
  }

  if (!nodeInfo?.NodeLabel?.includes('N/A')) {
    lineageNodeInfo.push(
      <CopiableText key='Owner' name='Owner' value={nodeInfo?.NodeLabel} />,
    );
  }

  if (!nodeInfo?.NodeName?.includes('N/A')) {
    lineageNodeInfo.push(
      <CopiableText
        key='Node name'
        name='Node name'
        value={nodeInfo?.NodeName}
      />,
    );
  }

  if (!nodeInfo?.NodeDescription?.includes('N/A')) {
    lineageNodeInfo.push(
      <CopiableText
        key='Description'
        name='Description'
        value={nodeInfo?.NodeDescription}
      />,
    );
  }

  if (!nodeInfo?.NodeDetails?.includes('N/A')) {
    lineageNodeInfo.push(
      <CopiableText
        key='Details'
        name='Detail links'
        value={nodeInfo?.NodeDetails}
      />,
    );
  }

  if (!nodeInfo?.NodeFileFormat?.includes('N/A')) {
    lineageNodeInfo.push(
      <CopiableText
        key='Format'
        name='Format'
        value={nodeInfo?.NodeFileFormat}
      />,
    );
  }

  if (!nodeInfo?.NodeFilePath?.includes('N/A')) {
    lineageNodeInfo.push(
      <CopiableText key='Path' name='Path' value={nodeInfo?.NodeFilePath} />,
    );
  }

  if (!nodeInfo?.NodeJobId?.includes('N/A')) {
    lineageNodeInfo.push(
      <CopiableText key='Job id' name='Job ID' value={nodeInfo?.NodeJobId} />,
    );
  }

  if (!nodeInfo?.NodeJobName?.includes('N/A')) {
    lineageNodeInfo.push(
      <CopiableText
        key='Job name'
        name='Job name'
        value={nodeInfo?.NodeJobName}
      />,
    );
  }

  if (!nodeInfo?.NodeJobDescription?.includes('N/A')) {
    lineageNodeInfo.push(
      <CopiableText
        key='Job description'
        name='Job description'
        value={nodeInfo?.NodeJobDescription}
      />,
    );
  }

  if (!nodeInfo?.NodeJobFlowId?.includes('N/A')) {
    lineageNodeInfo.push(
      <CopiableText
        key='Job Flow'
        name='Flow ID'
        value={
          nodeInfo?.NodeJobFlowId?.includes('NULL')
            ? 'N/A'
            : nodeInfo?.NodeJobFlowId
        }
      />,
    );
  }

  if (!nodeInfo?.NodeJobScheduler?.includes('N/A')) {
    lineageNodeInfo.push(
      <CopiableText
        key='Job Scheduler'
        name='Job scheduler'
        value={nodeInfo?.NodeJobScheduler}
      />,
    );
  }

  if (!nodeInfo?.NodeDatabaseName?.includes('N/A')) {
    lineageNodeInfo.push(
      <CopiableText
        key='Database name'
        name='Database name'
        value={nodeInfo?.NodeDatabaseName}
      />,
    );
  }

  if (!nodeInfo?.NodeTableName?.includes('N/A')) {
    lineageNodeInfo.push(
      <CopiableText
        key='Table name'
        name='Table name'
        value={nodeInfo?.NodeTableName}
      />,
    );
  }

  if (!nodeInfo?.NodeParents?.includes('N/A')) {
    lineageNodeInfo.push(
      <CopiableText
        key='Parent nodes'
        name='Parent nodes'
        value={listToCommaSeperatedValues(nodeInfo?.NodeParents)}
      />,
    );
  }
  return lineageNodeInfo;
}

// takes the list as input and gives comma seperated values
export function listToCommaSeperatedValues(listValues) {
  return listValues != null ? listValues.join(', ') : 'N/A';
}
