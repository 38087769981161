import { PropertyFilterProps } from '@amzn/awsui-components-react-v3';

export const paginationLabels = {
  nextPageLabel: 'Next page',
  previousPageLabel: 'Previous page',
  pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
};

export const defaultPageSizePreference = {
  title: 'Page size',
  options: [
    { value: 2, label: '2 items' },
    { value: 4, label: '4 items' },
    { value: 6, label: '6 items' },
  ],
};

export const notificationsPageSizePreference = {
  title: 'Page size',
  options: [
    { value: 6, label: '6 items' },
    { value: 10, label: '10 items' },
    { value: 14, label: '14 items' },
  ],
};

export const mediumPageSizePreference = {
  title: 'Page size',
  options:   [
    { value: 15, label: '15 templates' },
    { value: 25, label: '25 templates' },
    { value: 50, label: '50 templates' },
  ],
}

export const largePageSizePreference = {
  title: 'Page size',
  options: [
    { value: 25, label: '25 items' },
    { value: 50, label: '50 items' },
    { value: 100, label: '100 items' },
  ],
};

export const defaultWrapLinesPreference = {
  label: 'Wrap lines',
  description: 'Enable to wrap table cell content, disable to truncate text.',
};

export const i18nStrings: PropertyFilterProps.I18nStrings = {
  filteringAriaLabel: 'your choice',
  dismissAriaLabel: 'Dismiss',

  filteringPlaceholder: 'Find resources',
  groupValuesText: 'Values',
  groupPropertiesText: 'Properties',
  operatorsText: 'Operators',

  operationAndText: 'and',
  operationOrText: 'or',

  operatorLessText: 'Less than',
  operatorLessOrEqualText: 'Less than or equal',
  operatorGreaterText: 'Greater than',
  operatorGreaterOrEqualText: 'Greater than or equal',
  operatorContainsText: 'Contains',
  operatorDoesNotContainText: 'Does not contain',
  operatorEqualsText: 'Equals',
  operatorDoesNotEqualText: 'Does not equal',

  editTokenHeader: 'Edit filter',
  propertyText: 'Property',
  operatorText: 'Operator',
  valueText: 'Value',
  cancelActionText: 'Cancel',
  applyActionText: 'Apply',
  allPropertiesLabel: 'All properties',

  tokenLimitShowMore: 'Show more',
  tokenLimitShowFewer: 'Show fewer',
  clearFiltersText: 'Clear filters',
  removeTokenButtonAriaLabel: () => 'Remove token',
  enteredTextLabel: (text) => `Use: "${text}"`,
};
