import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { Component } from 'react';
import { 
  Button,
  Flashbar,
  Modal,
  Spinner,
  Textarea,
  TableProps, 
  FlashbarProps
} from '@amzn/awsui-components-react-v3';

import {
  auditDataSetShare,
  deactivateDataSetShare,
  getDataSetShare,
  getDataSetSharesFiltered,
} from '../../../api/permissions';
import { StatusIcon } from './statusIcon';
import { Link } from 'react-router-dom';
import {
  PageHeader,
  DataSetShareInformation,
  DataSetShareRAMResourceShare,
  DataSetShareRAMResources,
  DataSetShareLakeFormation,
  DataSetShareConsumers,
} from './common';
import { Page } from 'src/routes';

export interface DatasetsDetailsTableProps {
  groups: any;
  setContentType: any;
  match: any;
  activeGroup: string;
}

export interface DatasetsDetailsTableState {
  notifications:FlashbarProps.MessageDefinition[]
  reasonOfAction: string
  modalVisible: boolean
  buttonLoading: boolean
  isInvalid: boolean
  dataSetShareId: any
  dataSetShareItem: any
  dataSetShares: any
  actionLoading: boolean
  dataSetShareLoading: boolean
  dataSetSharesLoading: boolean
  deactivateLoading: boolean
  redirect: any
  deactivateText: string
  selected: any
  textAreaPlaceholder: any
}

export class DatasetsDetailsTable extends Component<
  DatasetsDetailsTableProps,
  DatasetsDetailsTableState
> {
  state = {
    notifications: [],
    reasonOfAction: undefined,
    modalVisible: false,
    buttonLoading: false,
    isInvalid: false,
    dataSetShareId: this.props.match.params.id,
    dataSetShareItem: {},
    dataSetShares: [],
    actionLoading: false,
    dataSetShareLoading: false,
    dataSetSharesLoading: false,
    deactivateLoading: false,
    redirect: undefined,
    deactivateText: 'Deactivated',
    selected: undefined,
    textAreaPlaceholder: undefined,
  };

  columnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'dataSetShareId',
      header: 'Dataset Share ID',
      cell: (item) => (
        <Link to={`/mydatasets/${item.dataSetShareId}`}>
          {item.dataSetShareId}
        </Link>
      ),
      minWidth: 200,
    },
    {
      id: 'dataLakePrincipal',
      header: 'DataLake Principal',
      cell: (item) => item.dataLakePrincipal,
      minWidth: 120,
      sortingField: 'dataLakePrincipal',
    },
    {
      id: 'groupId',
      header: 'Group Id',
      cell: (item) => item.groupId,
      minWidth: 120,
      sortingField: 'groupId',
    },
    {
      id: 'table',
      header: 'Table',
      cell: (item) => item.tableName,
      minWidth: 120,
      sortingField: 'tableName',
    },
    {
      id: 'activatedDate',
      header: 'Activated Date',
      cell: (item) => item.dateActive,
      minWidth: 210,
    },
    {
      id: 'status',
      header: 'Status',
      cell: (item) => <StatusIcon status={item.status} />,
      minWidth: 100,
    },
    {
      id: 'auditDate',
      header: 'Audit Date',
      cell: (item) => item.dateOfLastAudit,
      minWidth: 210,
    },
    {
      id: 'auditStatus',
      header: 'Audit Status',
      cell: (item) => <StatusIcon status={item.auditStatus} />,
      minWidth: 100,
    },
  ];

  componentDidMount = async () => {
    this.props.setContentType('table');
    await this.handleRefresh();
  };

  componentDidUpdate = async () => {
    if (this.props.match.params.id !== this.state.dataSetShareId) {
      await this.setState({ dataSetShareId: this.props.match.params.id });
      await this.handleRefresh();
    }
  };

  handleRefresh = async () => {
    this.setState({
      dataSetShareLoading: true,
      dataSetSharesLoading: true,
      actionLoading: false,
    });
    //Get Share
    const dataSetShareResult = await getDataSetShare({
      dataSetShareId: this.state.dataSetShareId,
    });
    await this.setState({
      dataSetShareItem: dataSetShareResult.DataSetShareItem,
      dataSetShareLoading: false,
    });
    //If Publisher get Consumers
    if (dataSetShareResult.DataSetShareItem.option === 'Publisher') {
      //TODO: loop and get all consumers
      const dataSetSharesFilteredResult = await getDataSetSharesFiltered({
        groupId: this.props.activeGroup,
        dataId: dataSetShareResult.DataSetShareItem.dataId,
        status: dataSetShareResult.DataSetShareItem.status,
        tableName:
          dataSetShareResult.DataSetShareItem.tableName === 'ALL_TABLES'
            ? null
            : dataSetShareResult.DataSetShareItem.tableName,
        option: 'Consumer',
        type: dataSetShareResult.DataSetShareItem.type,
        nextToken: null,
      });
      this.setState({
        dataSetShares: dataSetSharesFilteredResult.dataSetShareList,
        dataSetSharesLoading: false,
      });
    }
  };

  handleDeactivate = async () => {
    if (!this.state.reasonOfAction) {
      this.setState({ isInvalid: true });
      return;
    }
    this.setState({ buttonLoading: true });
    try {
      await deactivateDataSetShare({
        groupId: this.props.activeGroup,
        dataSetShareId: this.props.match.params.id,
        reasonOfAction: this.state.reasonOfAction.replace(/\n/g, ''),
      });

      // Notification is not rendered because of the redirect. 
      // The notification could be displayed at an app level.
      this.successMessage(this.props.match.params.id, 'deactivated');
    } catch (err) {
      console.log(err);
      this.errorMessage(err.message, 'deactivating');
    }
    this.closeModal();
    this.setState({
      redirect: Page.MY_DATASETS,
    });
    await this.handleRefresh();
  };

  handleAction = async (e) => {
    if (e.detail.id === 'audit') {
      try {
        this.setState({ actionLoading: true });
        const response = await auditDataSetShare({
          groupId: this.props.activeGroup,
          dataSetShareId: this.state.dataSetShareItem['dataSetShareId'],
        });
        await this.handleRefresh();
        response.auditStatus === 'SUCCEEDED'
          ? this.successMessage(
              'Audit of: ' + response.dataSetShareId,
              response.auditStatus,
            )
          : this.errorMessage(
              'Audit of: ' + response.dataSetShareId,
              response.auditStatus,
            );
      } catch (err) {
        console.log(err);
        this.errorMessage(
          'Audit of: ' + this.state.selected + ' : ' + err.message,
          'FAILED',
        );
      }
    }
    if (e.detail.id === 'deactivate') {
      this.openModal();
    }
  };

  openModal = () => {
    this.setState({
      deactivateText: `Dataset Share ID: ${this.state.dataSetShareId}`,
      modalVisible: true,
    });
  };

  closeModal = () => {
    this.setState({ buttonLoading: false, modalVisible: false });
  };

  successMessage(message, action) {
    this.setState({
      notifications: [
        {
          type: 'success',
          content: `Dataset Share ID: '${message}' was successfully '${action}'`,
          dismissible: true,
          onDismiss: () => this.setState({ notifications: [] }),
        },
      ],
    });
  }

  errorMessage(message, action) {
    this.setState({
      notifications: [
        {
          type: 'error',
          content: `There was an error '${action}' while '${message}'`,
          dismissible: true,
          onDismiss: () => this.setState({ notifications: [] }),
        },
      ],
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }
    return (
      <div>
        <Flashbar items={this.state.notifications} />
        <br />
        <Modal
          visible={this.state.modalVisible}
          header={[
            `Enter reason for deactivating`,
            <br />,
            `Dataset Share ID: ${this.state.dataSetShareItem['dataSetShareId']}`,
            <br />,
            `Database: ${this.state.dataSetShareItem['databaseName']}`,
            <br />,
            `Table: ${this.state.dataSetShareItem['tableName']}`,
          ]}
          onDismiss={() => this.closeModal()}
          footer={
            <span className='awsui-util-f-r'>
              <Button variant='link' onClick={() => this.closeModal()}>
                No
              </Button>
              <Button
                variant='primary'
                loading={this.state.buttonLoading}
                onClick={() => this.handleDeactivate()}
              >
                Yes
              </Button>
            </span>
          }
        >
          <Textarea
            value={this.state.reasonOfAction}
            onChange={({ detail }) =>
              this.setState({ isInvalid: false, reasonOfAction: detail.value })
            }
            placeholder={this.state.textAreaPlaceholder}
            invalid={this.state.isInvalid}
          />
          Are you sure you want to deactivate this Dataset Share?
        </Modal>
        <PageHeader
          buttons={[
            {
              text: '',
              icon: 'refresh',
              onItemClick: this.handleRefresh,
            },
            {
              text: 'Actions',
              onItemClick: this.handleAction,
              items: [
                {
                  text: 'Audit',
                  id: 'audit',
                  disabled:
                    this.state.dataSetShareItem['status'] === 'InActive',
                },
                {
                  text: 'Deactivate',
                  id: 'deactivate',
                  disabled:
                    this.state.dataSetShareItem['status'] === 'InActive',
                },
              ],
              loading: this.state.actionLoading,
            },
          ]}
          header={`Dataset Share ID: ${this.props.match.params.id}`}
        />

        {this.state.dataSetShareLoading === true && <Spinner size='large' />}

        {this.state.dataSetShareLoading === false && (
          <>
            <DataSetShareInformation
              dataSetShareItem={this.state.dataSetShareItem}
            />

            {this.state.dataSetShareItem['option'] === 'Consumer' && (
              <>
                <DataSetShareLakeFormation
                  dataSetShareItem={this.state.dataSetShareItem}
                />

                <DataSetShareRAMResourceShare
                  ramResourceShare={
                    this.state.dataSetShareItem['audit']['RamResourceShare'] !==
                    undefined
                      ? JSON.parse(
                          this.state.dataSetShareItem['audit'][
                            'RamResourceShare'
                          ],
                        )[0]
                      : undefined
                  }
                />
                <DataSetShareRAMResources
                  ramResources={
                    this.state.dataSetShareItem['audit']['RamResources'] !==
                    undefined
                      ? JSON.parse(
                          this.state.dataSetShareItem['audit']['RamResources'],
                        )
                      : []
                  }
                />
              </>
            )}

            {this.state.dataSetShareItem['option'] === 'Publisher' && (
              <DataSetShareConsumers
                dataSetShares={this.state.dataSetShares}
                columnDefinitions={this.columnDefinitions}
                dataSetSharesLoading={this.state.dataSetSharesLoading}
              />
            )}
          </>
        )}
      </div>
    );
  }
}
