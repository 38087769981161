export const SUBSCRIPTION_CLIENT_CONFIG = {
  beta: {
    endpoint: 'https://api.us-east-1.beta.subscription.datalake.aws.a2z.com',
    region: 'us-east-1',
  },
  prod: {
    endpoint: 'https://api.us-east-1.prod.subscription.datalake.aws.a2z.com',
    region: 'us-east-1',
  },
  local: {
    // Use this endpoint if you have your subscription service running locally
    // endpoint: 'http://localhost:8080',
    endpoint: 'https://api.us-east-1.beta.subscription.datalake.aws.a2z.com',
    region: 'us-east-1',
  },
};

export const CATALOG_CLIENT_CONFIG = {
  beta: {
    endpoint: 'https://usqdb01vx1.execute-api.us-east-1.amazonaws.com/beta',
    region: 'us-east-1',
  },
  prod: {
    endpoint: 'https://9jvry818ee.execute-api.us-east-1.amazonaws.com/prod',
    region: 'us-east-1',
  },
  local: {
    endpoint: 'https://usqdb01vx1.execute-api.us-east-1.amazonaws.com/beta',
    region: 'us-east-1',
  },
};

export const GLADSTONE_CLIENT_CONFIG = {
  beta: {
    endpoint: 'https://api.us-east-1.beta.gladstone.datalake.aws.a2z.com',
    region: 'us-east-1',
  },
  prod: {
    endpoint: 'https://api.us-east-1.prod.gladstone.datalake.aws.a2z.com',
    region: 'us-east-1',
  },
  local: {
    endpoint: 'https://api.us-east-1.beta.gladstone.datalake.aws.a2z.com',
    region: 'us-east-1',
  },
};

export const PUBLISHER_CLIENT_CONFIG = {
  beta: {
    endpoint: 'https://twcx7nfaw1.execute-api.us-east-1.amazonaws.com/beta',
    region: 'us-east-1',
  },
  prod: {
    endpoint: 'https://zc955e0fp2.execute-api.us-east-1.amazonaws.com/prod',
    region: 'us-east-1',
  },
  local: {
    endpoint: 'https://twcx7nfaw1.execute-api.us-east-1.amazonaws.com/beta',
    region: 'us-east-1',
  },
};

export const DEPENDENCY_CLIENT_CONFIG = {
  beta: {
    endpoint:
      'https://api.us-east-1.beta.dependencyservice.datalake.aws.a2z.com',
    region: 'us-east-1',
  },
  prod: {
    endpoint:
      'https://api.us-east-1.prod.dependencyservice.datalake.aws.a2z.com',
    region: 'us-east-1',
  },
  local: {
    endpoint:
      'https://api.us-east-1.beta.dependencyservice.datalake.aws.a2z.com',
    region: 'us-east-1',
  },
};

export const RESOURCESMANAGER_CLIENT_CONFIG = {
  prod: {
    endpoint: 'https://api.us-east-1.prod.resourcemanager.datalake.aws.a2z.com',
    region: 'us-east-1',
  },
  beta: {
    endpoint: 'https://api.us-east-1.beta.resourcemanager.datalake.aws.a2z.com',
    region: 'us-east-1',
  },
  local: {
    endpoint: 'https://api.us-east-1.beta.resourcemanager.datalake.aws.a2z.com',
    region: 'us-east-1',
  },
};

export const NOTIFICATION_CLIENT_CONFIG = {
  prod: {
    endpoint: 'https://2kc147jnh6.execute-api.us-east-1.amazonaws.com/Prod', // Prod us-east-1
    region: 'us-east-1',
  },
  beta: {
    endpoint: 'https://lgnkl1vil3.execute-api.us-east-1.amazonaws.com/Beta', // Beta us-east-1
    region: 'us-east-1',
  },
  local: {
    endpoint: 'https://lgnkl1vil3.execute-api.us-east-1.amazonaws.com/Beta', // Beta us-east-1
    region: 'us-east-1',
  },
};

export const INGESTION_SERVICE_CONFIG = {
  beta: {
    endpoint: 'https://ixhzlyaiqh.execute-api.us-east-1.amazonaws.com/prod',
    region: 'us-east-1',
  },
  prod: {
    endpoint: 'https://ixhzlyaiqh.execute-api.us-east-1.amazonaws.com/prod',
    region: 'us-east-1',
  },
  local: {
    endpoint: 'https://ixhzlyaiqh.execute-api.us-east-1.amazonaws.com/prod',
    region: 'us-east-1',
  },
};

export const AUTH_CONFIG = {
  beta: {
    identityPoolId: 'us-east-1:a520fe3c-e368-46ef-aef4-c653ac004ef5',
    region: 'us-east-1',
  },
  prod: {
    identityPoolId: 'us-east-1:34149c48-2cd6-4924-8d85-ea46f31d8f35',
    region: 'us-east-1',
  },
  local: {
    identityPoolId: 'us-east-1:a520fe3c-e368-46ef-aef4-c653ac004ef5',
    region: 'us-east-1',
  },
};

// We should replace this in the future with something that can scale
export const DATA_ACCESS_CONFIG = {
  beta: {
    accountId: '773135466957',
    groupId: 'AWSDL_HybridCatalog',
    dataAccessRoleArn:
      'arn:aws:iam::773135466957:role/GlueAndLakeFormationAdmin',
    ramRoleArn: 'arn:aws:iam::773135466957:role/RamAdmin',
    region: 'us-east-1',
  },
  prod: {
    accountId: '316343021201',
    groupId: 'AWSDL_HybridCatalog',
    dataAccessRoleArn:
      'arn:aws:iam::316343021201:role/GlueAndLakeFormationAdmin',
    ramRoleArn: 'arn:aws:iam::316343021201:role/RamAdmin',
    region: 'us-east-1',
  },
  local: {
    accountId: '773135466957',
    groupId: 'AWSDL_HybridCatalog',
    dataAccessRoleArn:
      'arn:aws:iam::773135466957:role/GlueAndLakeFormationAdmin',
    ramRoleArn: 'arn:aws:iam::773135466957:role/RamAdmin',
    region: 'us-east-1',
  },
};

export function getStage() {
  const url = window.location.hostname;
  if (url === undefined) {
    return 'local';
  } else if (url.indexOf('beta.') != -1) {
    return 'beta';
  } else if (url.indexOf('omni.') != -1) {
    return 'prod';
  } else {
    return 'local';
  }
}

export function getSubscriptionConfig() {
  return SUBSCRIPTION_CLIENT_CONFIG[getStage()];
}

export function getCatalogConfig() {
  return CATALOG_CLIENT_CONFIG[getStage()];
}

export function getGladstoneConfig() {
  return GLADSTONE_CLIENT_CONFIG[getStage()];
}

export function getDependencyConfig() {
  return DEPENDENCY_CLIENT_CONFIG[getStage()];
}

export function getPublisherConfig() {
  return PUBLISHER_CLIENT_CONFIG[getStage()];
}

export function getNotificationConfig() {
  return NOTIFICATION_CLIENT_CONFIG[getStage()];
}

export function getResourcesManagerConfig() {
  return RESOURCESMANAGER_CLIENT_CONFIG[getStage()];
}

export function getDatasetServiceConfig() {
  return INGESTION_SERVICE_CONFIG[getStage()];
}

export function getAuthConfig() {
  return AUTH_CONFIG[getStage()];
}

export function getDataAccessConfig() {
  return DATA_ACCESS_CONFIG[getStage()];
}

export function getRegion() {
  const url = window.location.hostname;
  const stage = getStage();
  const prefix = url.substr(0, url.indexOf('.'));
  if (stage === 'local' || prefix == 'beta' || prefix == 'omni') {
    return 'us-east-1';
  } else {
    return prefix;
  }
}

export function getURL() {
  return 'https://' + window.location.hostname;
}
