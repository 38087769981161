import * as React from 'react';
import { useState, useEffect } from 'react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Table,
  TextFilter,
  CollectionPreferences,
  Pagination,
  CollectionPreferencesProps,
} from '@amzn/awsui-components-react-v3/polaris';

import { Link } from 'react-router-dom';
import { PageHeader } from '../common';
import { Redirect } from 'react-router-dom';
import { getNotificationTemplates } from 'src/api/notifications';
import { Page } from 'src/routes';
import {
  defaultPageSizePreference,
  defaultWrapLinesPreference,
  paginationLabels,
} from 'src/commons/tables';

export interface NotificationTemplateTableProps {
  setContentType: any;
  activeGroup: string;
}

export interface NotificationTemplateTableState {}

export const NotificationTemplateTable = (
  props: NotificationTemplateTableProps,
) => {
  const [templates, setTemplates] = useState([]);
  const [redirect, setRedirect] = useState(undefined);
  const [loadingNotificationTemplate, setLoadingNotificationTemplate] =
    useState(true);
  const [tableMessage, setTableMessage] = useState('No notification templates');
  const [preferences, setPreferences] =
    useState<CollectionPreferencesProps.Preferences>({
      wrapLines: false,
      pageSize: 10,
    });

  const columnDefinitions = [
    {
      id: 'notificationTemplateName',
      header: 'Template name',
      cell: (item) => (
        <Link to={`/notificationtemplates/${item.NotificationTemplateId}`}>
          {item.NotificationTemplateName}
        </Link>
      ),
      minWidth: 200,
    },
    {
      id: 'notificationTemplateContent',
      header: 'Template content',
      cell: (item) => item.Content,
      minWidth: 200,
    },
    {
      id: 'createTime',
      header: 'Create time',
      cell: (item) => item.CreateTime,
      minWidth: 200,
      sortingField: 'CreateTime',
    },
  ];

  useEffect(() => {
    props.setContentType('table');
    handleRefresh();
  }, []);

  useEffect(() => {
    handleRefresh();
  }, [props.activeGroup]);

  const handleRefresh = async () => {
    if (!props.activeGroup) return;
    setLoadingNotificationTemplate(true);

    try {
      const templates = await getNotificationTemplates({
        GroupId: props.activeGroup,
      });

      setTemplates(templates.NotificationTemplates);
      setLoadingNotificationTemplate(false);
    } catch (err) {
      setTableMessage(`Unable to load templates. ${err.message}`);
      setLoadingNotificationTemplate(false);
    }
  };

  const {
    items,
    filterProps,
    filteredItemsCount,
    collectionProps,
    paginationProps,
  } = useCollection(templates, {
    filtering: {
      empty: (
        <div className='awsui-util-t-c'>
          <div className='awsui-util-pt-s awsui-util-mb-xs'>
            <b>{tableMessage}</b>
          </div>
          <p className='awsui-util-mb-s'>No templates to display.</p>
        </div>
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
  });

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <>
      <Table
        {...collectionProps}
        loadingText='Loading templates...'
        loading={loadingNotificationTemplate}
        columnDefinitions={columnDefinitions}
        selectionType='single' // Is this unused?
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        header={
          <>
            <PageHeader
              buttons={[
                {
                  text: '',
                  icon: 'refresh',
                  onItemClick: handleRefresh,
                },

                {
                  text: 'Create notification template',
                  variant: 'primary',
                  onItemClick: () => {
                    setRedirect(Page.CREATE_NOTIFICATION_TEMPLATE);
                  },
                },
              ]}
              header={
                <>
                  Notification templates
                  <span className='awsui-util-header-counter'>
                    {` (${templates.length})`}
                  </span>
                </>
              }
            />
          </>
        }
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel='Filter resources'
            filteringPlaceholder='Find resources'
            countText={`${filteredItemsCount} ${
              filteredItemsCount === 1 ? 'match' : 'matches'
            }`}
          />
        }
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={defaultPageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        pagination={
          <Pagination {...paginationProps} ariaLabels={paginationLabels} />
        }
      />
    </>
  );
};
