import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
    BreadcrumbsBootstrapActionDetails,
    BreadcrumbsBootstrapActionFormNew,
    BreadcrumbsBootstrapActionFormUpdate,
    BreadcrumbsBrowseCatalogs,
    BreadcrumbsBrowseDatabases,
    BreadcrumbsBrowseDatasets,
    BreadcrumbsCatalogDetails,
    BreadcrumbsConnectionDetails,
    BreadcrumbsCreateBackfill,
    BreadcrumbsCreateConnection,
    BreadcrumbsCreateDependency,
    BreadcrumbsCreateNotifications,
    BreadcrumbsCreateNotificationTemplate,
    BreadcrumbsCreateSubscription,
    BreadcrumbsDatabaseDetails,
    BreadcrumbsDatasetDetails,
    BreadcrumbsDependencyDetails,
    BreadcrumbsMyBootstrapActions,
    BreadcrumbsMyConnections,
    BreadcrumbsMyGroup,
    BreadcrumbsMyGroups,
    BreadcrumbsCreateLakeFormationPermission,
    BreadcrumbsMyDatasets,
    BreadcrumbsMyDatasetsDetails,
    BreadcrumbsMyDependencies,
    BreadcrumbsPublisherAccounts,
    BreadcrumbsPublisherRegisterAccount,
    BreadcrumbsPublish,
    BreadcrumbsPublishedTables,
    BreadcrumbsPublishRegisterTable,
    BreadcrumbsPublishTableDetails,
    BreadcrumbsMyNotifications,
    BreadcrumbsMyRequests,
    BreadcrumbsMyResourceGroups,
    BreadcrumbsMySubscriptions,
    BreadcrumbsNotificationBatchDetail,
    BreadcrumbsNotificationDetail,
    BreadcrumbsNotificationTemplateDetail,
    BreadcrumbsNotificationTemplates,
    BreadcrumbsPermissionRequestDetails,
    BreadcrumbsPostDedupe,
    BreadcrumbsResourceDetails,
    BreadcrumbsResourceGroupDetails,
    BreadcrumbsResourceGroupFormNew,
    BreadcrumbsResourceGroupFormUpdate,
    BreadcrumbsSubscriptionDetails,
    BreadcrumbsTemplateDetails,
    BreadcrumbsTemplateNew,
    BreadcrumbsTemplateUpdate,
    BreadcrumbsUpdateNotificationTemplate,
    BreadcrumbsMyBaselining,
    BreadcrumbsNotificationConfiguration,
    BreadcrumbsRegisterContract,
    BreadcrumbsUpdateContract,
    BreadcrumbsContractDetail,
    BreadcrumbsMonitoringDataset
} from '../commons/breadcrumbs';
import { Page } from './Paths';

export const Breadcrumbs = (props) => (
  <Switch>
    <Route
      path={Page.BROWSE_CATALOGS}
      exact
      render={(route_props) => (
        <BreadcrumbsBrowseCatalogs {...route_props} {...props} />
      )}
    />
    <Route
      path={Page.CATALOG_DETAILS}
      exact
      render={(route_props) => (
        <BreadcrumbsCatalogDetails {...route_props} {...props} />
      )}
    />

    <Route
      path={Page.BROWSE_DATABASES}
      exact
      render={(route_props) => (
        <BreadcrumbsBrowseDatabases {...route_props} {...props} />
      )}
    />
    <Route
      path={Page.DATABASE_DETAILS}
      exact
      render={(route_props) => (
        <BreadcrumbsDatabaseDetails {...route_props} {...props} />
      )}
    />

    <Route
      path={Page.BROWSE_DATASETS}
      exact
      render={(route_props) => (
        <BreadcrumbsBrowseDatasets {...route_props} {...props} />
      )}
    />
    <Route
      path={Page.DATASET_DETAILS}
      exact
      render={(route_props) => (
        <BreadcrumbsDatasetDetails {...route_props} {...props} />
      )}
    />

    <Route
      path={Page.CONNECTIONS}
      exact
      render={(route_props) => (
        <BreadcrumbsMyConnections {...route_props} {...props} />
      )}
    />
    <Route
      path={Page.CREATE_CONNECTION}
      exact
      render={(route_props) => (
        <BreadcrumbsCreateConnection {...route_props} {...props} />
      )}
    />
    <Route
      path={Page.CONNECTION_DETAILS}
      exact
      render={(route_props) => (
        <BreadcrumbsConnectionDetails {...route_props} {...props} />
      )}
    />

    <Route
      path={Page.SUBSCRIPTIONS}
      exact
      render={(route_props) => (
        <BreadcrumbsMySubscriptions {...route_props} {...props} />
      )}
    />
    <Route
      path={Page.CREATE_SUBSCRIPTION}
      exact
      render={(route_props) => (
        <BreadcrumbsCreateSubscription {...route_props} {...props} />
      )}
    />
    <Route
      path={Page.SUBSCRIPTION_DETAILS}
      exact
      render={(route_props) => (
        <BreadcrumbsSubscriptionDetails {...route_props} {...props} />
      )}
    />

    <Route
      path={Page.PUBLISH}
      exact
      render={(route_props) => (
        <BreadcrumbsPublish {...route_props} {...props} />
      )}
    />

    <Route
      path={Page.PUBLISHER_ACCOUNTS}
      exact
      render={(route_props) => (
        <BreadcrumbsPublisherAccounts {...route_props} {...props} />
      )}
    />
    <Route
      path={Page.PUBLISHER_REGISTER_ACCOUNT}
      exact
      render={(route_props) => (
        <BreadcrumbsPublisherRegisterAccount {...route_props} {...props} />
      )}
    />

    <Route
      path={Page.PUBLISHER_TABLES}
      exact
      render={(route_props) => (
        <BreadcrumbsPublishedTables {...route_props} {...props} />
      )}
    />
    <Route
      path={Page.CREATE_PUBLISHER_TABLE}
      exact
      render={(route_props) => (
        <BreadcrumbsPublishRegisterTable {...route_props} {...props} />
      )}
    />
    <Route
      path={Page.PUBLISHER_CREATE_BACKFILL}
      exact
      render={(route_props) => (
        <BreadcrumbsCreateBackfill {...route_props} {...props} />
      )}
    />
    <Route
      path={Page.PUBLISHER_POST_DEDUPE}
      exact
      render={(route_props) => (
        <BreadcrumbsPostDedupe {...route_props} {...props} />
      )}
    />
    <Route
      path={Page.PUBLISHER_TABLE_DETAILS}
      exact
      render={(route_props) => (
        <BreadcrumbsPublishTableDetails {...route_props} {...props} />
      )}
    />

    <Route path={Page.REQUESTS} exact component={BreadcrumbsMyRequests} />
    <Route path={Page.BASELINING} exact component={BreadcrumbsMyBaselining} />
    <Route
      path={Page.CREATE_LAKE_FORMATION_PERMISSIONS}
      exact
      render={(route_props) => (
        <BreadcrumbsCreateLakeFormationPermission {...route_props} {...props} />
      )}
    />
    <Route
      path={Page.REQUEST_DETAILS}
      exact
      render={(route_props) => (
        <BreadcrumbsPermissionRequestDetails {...route_props} {...props} />
      )}
    />

    <Route
      path={Page.MY_DATASETS}
      exact
      render={(route_props) => (
        <BreadcrumbsMyDatasets {...route_props} {...props} />
      )}
    />
    <Route
      path={Page.DATASETSHARE_DETAILS}
      exact
      render={(route_props) => (
        <BreadcrumbsMyDatasetsDetails {...route_props} {...props} />
      )}
    />

    <Route
      path={Page.DEPENDENCIES}
      exact
      render={(route_props) => (
        <BreadcrumbsMyDependencies {...route_props} {...props} />
      )}
    />

    <Route
      path={Page.CREATE_DEPENDENCY}
      exact
      render={(route_props) => (
        <BreadcrumbsCreateDependency {...route_props} {...props} />
      )}
    />
    <Route
      path={Page.DEPENDENCY_DETAILS}
      exact
      render={(route_props) => (
        <BreadcrumbsDependencyDetails {...route_props} {...props} />
      )}
    />

    <Route
      path={Page.GROUP}
      exact
      render={(route_props) => (
        <BreadcrumbsMyGroup {...route_props} {...props} />
      )}
    />
    <Route
      path={Page.GROUPS}
      exact
      render={(route_props) => (
        <BreadcrumbsMyGroups {...route_props} {...props} />
      )}
    />

    <Route
      path={Page.NOTIFICATIONS}
      exact
      component={BreadcrumbsMyNotifications}
    />
    <Route
      path={Page.CREATE_NOTIFICATION}
      exact
      component={BreadcrumbsCreateNotifications}
    />
    <Route
      path={Page.NOTIFICATION_DETAILS}
      exact
      component={BreadcrumbsNotificationDetail}
    />
    <Route
      path={Page.NOTIFICATION_BATCH_DETAILS}
      exact
      component={BreadcrumbsNotificationBatchDetail}
    />

    <Route
      path={Page.NOTIFICATION_TEMPLATES}
      exact
      component={BreadcrumbsNotificationTemplates}
    />
    <Route
      path={Page.CREATE_NOTIFICATION_TEMPLATE}
      exact
      component={BreadcrumbsCreateNotificationTemplate}
    />
    <Route
      path={Page.UPDATE_NOTIFICATION_TEMPLATE}
      exact
      component={BreadcrumbsUpdateNotificationTemplate}
    />
    <Route
      path={Page.NOTIFICATION_TEMPLATE_DETAILS}
      exact
      component={BreadcrumbsNotificationTemplateDetail}
    />
    <Route
      path={Page.NOTIFICATION_CONFIGURATION}
      exact
      component={BreadcrumbsNotificationConfiguration}
    />

    <Route
      path={Page.RESOURCEGROUPS}
      exact
      component={(route_props) => (
        <BreadcrumbsMyResourceGroups {...route_props} {...props} />
      )}
    />
    <Route
      path={Page.CREATE_RESOURCEGROUP}
      exact
      component={(route_props) => (
        <BreadcrumbsResourceGroupFormNew {...route_props} {...props} />
      )}
    />
    <Route
      path={Page.UPDATE_RESOURCEGROUP}
      exact
      component={(route_props) => (
        <BreadcrumbsResourceGroupFormUpdate {...route_props} {...props} />
      )}
    />
    <Route
      path={Page.RESOURCEGROUP_DETAILS}
      exact
      component={(route_props) => (
        <BreadcrumbsResourceGroupDetails {...route_props} {...props} />
      )}
    />
    <Route
      path={Page.RESOURCE_DETAILS}
      exact
      component={(route_props) => (
        <BreadcrumbsResourceDetails {...route_props} {...props} />
      )}
    />

    <Route
      path={Page.BOOTSTRAPACTIONS}
      exact
      component={(route_props) => (
        <BreadcrumbsMyBootstrapActions {...route_props} {...props} />
      )}
    />
    <Route
      path={Page.CREATE_BOOTSTRAPACTION}
      exact
      component={(route_props) => (
        <BreadcrumbsBootstrapActionFormNew {...route_props} {...props} />
      )}
    />
    <Route
      path={Page.UPDATE_BOOTSTRAPACTION}
      exact
      component={(route_props) => (
        <BreadcrumbsBootstrapActionFormUpdate {...route_props} {...props} />
      )}
    />
    <Route
      path={Page.BOOTSTRAPACTION_DETAILS}
      exact
      component={(route_props) => (
        <BreadcrumbsBootstrapActionDetails {...route_props} {...props} />
      )}
    />

    <Route
      path={Page.CREATE_TEMPLATE}
      exact
      component={(route_props) => (
        <BreadcrumbsTemplateNew {...route_props} {...props} />
      )}
    />
    <Route
      path={Page.UPDATE_TEMPLATE}
      exact
      component={(route_props) => (
        <BreadcrumbsTemplateUpdate {...route_props} {...props} />
      )}
    />
    <Route
      path={Page.TEMPLATE_DETAILS}
      exact
      component={(route_props) => (
        <BreadcrumbsTemplateDetails {...route_props} {...props} />
      )}
    />
    <Route
      path={Page.INGESTION.REGISTER_CONTRACT}
      exact
      component={(route_props) => (
        <BreadcrumbsRegisterContract {...route_props} {...props} />
      )}
    />
    <Route
      path={Page.INGESTION.UPDATE_CONTRACT}
      component={(route_props) => (
        <BreadcrumbsUpdateContract {...route_props} {...props} />
      )}
    />
    <Route
      path={Page.INGESTION.DETAIL}
      component={(route_props) => (
        <BreadcrumbsContractDetail {...route_props} {...props} />
      )}
    />
    <Route
      path={Page.INGESTION.MONITORING}
      component={(route_props) => (
        <BreadcrumbsMonitoringDataset {...route_props} {...props} />
      )}
    />
  </Switch>
);

export default Breadcrumbs;
