import * as React from 'react';
import {
  AppLayout,
  AppLayoutProps,
  Button,
  ColumnLayout,
  Flashbar,
  FlashbarProps,
  Form,
  FormField,
  Container,
  Input,
  RadioGroup,
  Select,
  SideNavigation,
  Textarea,
  Header,
  SideNavigationProps,
  SelectProps,
} from '@amzn/awsui-components-react-v3';
import TopNavigation from "@amzn/awsui-components-react-v3/polaris/top-navigation/1.0-beta";

import {
  createGroup,
  isMemberOfGroupingMechanismId,
} from '../../api/permissions';
import { getUser } from '../../api/auth';
import { Home } from '../home/home';
import {
  appLayoutLabels,
  navItemsNewCustomers,
} from '../../commons/navigationData';
import { helpContentNewCustomers } from '../../routes/helpData';

import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import * as validate from '../../commons/validationUtils';
import {
  DATACONSUMER,
  DATAPROVIDER,
  DATAPROVIDER_AND_CONSUMER,
  WEBSITE_NAME,
} from '../../commons/constants';
import { getActiveHref, isHomePage } from '../utils/page';

export interface OnboardingComponentProps {}

export interface OnboardingComponentState {
  groupId: string;
  teamName: string;
  teamWiki: string;
  teamEmail: string;
  category: string;
  item: string;
  type: string;
  useCase: string;
  existingUseCase: string;
  dataLatency: string;
  dataAvailabilitySLA: string;
  dataAccessAndProcessing: string;
  onboardTimeline: string;
  principalsToAdd: string[];
  userName: string;
  customerType: string;
  groupIdValidation: boolean;
  groupIdErrorText: string;
  teamNameValidation: boolean;
  teamWikiValidation: boolean;
  teamEmailValidation: boolean;
  categoryValidation: boolean;
  itemValidation: boolean;
  typeValidation: boolean;
  teamEmailErrorText: string;
  useCaseValidation: boolean;
  dataAccessAndProcessingValidation: boolean;
  dataAccessAndProcessingSelectValidation: boolean;
  dataAccessAndProcessingErrorText: string;
  iamPrincipalsValidation: boolean;
  iamPrincipalsErrorText: string;
  userIdValidation: boolean;
  userIdErrorText: string;

  buttonLoading: boolean;
  buttonText: string;
  notifications: FlashbarProps.MessageDefinition[];
  optionLabel: string;
  navOpen: boolean;
  contentType: AppLayoutProps.ContentType;
  redirect: string;
  active: string;
  groupingMechanismId: string;
  groupingMechanismIdValidation: boolean;
  groupingMechanismIdErrorText: string;
  iamPrinciple: string;
  selectedDataAccessOption: SelectProps.Option;
  disableContentPaddings: boolean
}

export default class HomeOnboardingComponent extends React.Component<
  OnboardingComponentProps,
  OnboardingComponentState
> {
  state = {
    groupId: '',
    teamName: '',
    teamWiki: '',
    teamEmail: '',
    category: '',
    type: '',
    item: '',
    useCase: '',
    existingUseCase: '',
    dataLatency: 'N/A',
    dataAvailabilitySLA: 'N/A',
    dataAccessAndProcessing: '',
    onboardTimeline: 'N/A',
    principalsToAdd: [],
    groupInfo: undefined,
    groupIdValidation: false,
    groupIdErrorText: '',
    customerType: DATACONSUMER,
    teamNameValidation: false,
    teamWikiValidation: false,
    teamEmailValidation: false,
    categoryValidation: false,
    itemValidation: false,
    typeValidation: false,
    teamEmailErrorText: '',
    useCaseValidation: false,
    dataAccessAndProcessingValidation: false,
    dataAccessAndProcessingSelectValidation: false,
    dataAccessAndProcessingErrorText: '',
    iamPrincipalsValidation: false,
    iamPrincipalsErrorText: '',
    userIdValidation: false,
    userIdErrorText: '',

    buttonLoading: false,
    buttonText: 'Submit Request',
    notifications: [],
    optionLabel: '',

    toolsOpen: false,
    active: '/onboarding',
    userName: '',
    groups: [],
    activeGroup: '',
    contentType: 'form' as AppLayoutProps.ContentType,
    navOpen: true,
    redirect: undefined,
    groupingMechanismId: '',
    groupingMechanismIdValidation: false,
    groupingMechanismIdErrorText: '',
    iamPrinciple: '',
    selectedDataAccessOption: undefined,
    disableContentPaddings: true,
  };

  componentDidMount = async () => {
    const user = await getUser();
    console.log('Logged in as: ' + user);
    this.setState({
      active: getActiveHref(),
      userName: user,
    });
  };

  async componentDidUpdate() {
    const href = getActiveHref();
    if (this.state.active != href) {
      this.setState({ active: href });
    }
    // Once we move the onboarding form into its own component we can handle this there
    if (href == '/onboarding' && this.state.contentType != 'form') {
      this.setState({ disableContentPaddings: false })
      this.setState({ contentType: 'form' });
    }
  }

  submitCreateGroupRequest = async () => {
    await createGroup({
      groupId: this.state.groupId,
      teamName: this.state.teamName,
      teamWiki: this.state.teamWiki,
      primaryCTI: {
        category: this.state.category,
        type: this.state.type,
        item: this.state.item,
      },
      teamDistributionList: this.state.teamEmail,
      useCase: this.state.useCase,
      existingUseCase: this.state.existingUseCase,
      dataLatency: this.state.dataLatency,
      dataAvailabilitySLA: this.state.dataAvailabilitySLA,
      dataAccessAndProcessing: this.state.dataAccessAndProcessing,
      onboardTimeline: this.state.onboardTimeline,
      customerType: this.state.customerType,
      principalsToAdd: [...new Set(this.state.principalsToAdd)],
      groupingMechanismId: this.state.groupingMechanismId,
    });
  };

  handleConfirm = async () => {
    this.setState({ buttonLoading: true });
    const validated = await this.validateOnboardingRequest();
    if (!validated) {
      this.setNotification(
        'Some of the required fields are missing or invalid',
        '',
      );
      const target = document.getElementById('flash-bar');
      target.scrollIntoView(true);
      this.setState({ buttonLoading: false });
      return;
    }

    try {
      await this.submitCreateGroupRequest();
      this.setNotification(
        '',
        'The onboarding request was submitted successfully. Data Lake oncall has been notified and will process it within a business day.',
      );
    } catch (e) {
      let errorMessage =
        'Please contact Data Lake oncall to help you with your onboarding request';

      // TO DO with error handling from backend

      this.setNotification(
        'Failed to submit your onboarding request',
        errorMessage,
      );
    }

    const target = document.getElementById('flash-bar');
    target.scrollIntoView(true);
    this.setState({ buttonLoading: false });
  };

  setNotification(header, message) {
    if (header === '') {
      this.setState({
        notifications: [
          {
            type: 'success' as FlashbarProps.Type,
            content: message,
            dismissible: true,
            onDismiss: () => this.setState({ notifications: [] }),
          },
        ],
      });
    } else {
      this.setState({
        notifications: [
          {
            header: header,
            type: 'error' as FlashbarProps.Type,
            content: message,
            dismissible: true,
            onDismiss: () => this.setState({ notifications: [] }),
          },
        ],
      });
    }
  }

  async validateOnboardingRequest() {
    const invalidIamPrincipals = this.state.principalsToAdd.filter(
      (principal) => !validate.isValidPrincipal(principal),
    );
    const validGroupId = validate.isValidGroup(this.state.groupId);
    const validTeamEmail = validate.isValidAmazonEmail(this.state.teamEmail);

    let isGoodRequest = true;
    // Group ID
    if (this.state.groupId.length === 0 || !this.state.groupId.trim()) {
      this.setState({
        groupIdValidation: true,
        groupIdErrorText: 'Group name must not be empty',
      });
      isGoodRequest = false;
    } else if (!validGroupId) {
      this.setState({
        groupIdValidation: true,
        groupIdErrorText: 'Invalid group name found',
      });
      isGoodRequest = false;
    }

    // Team email
    if (this.state.teamEmail.length === 0 || !this.state.teamEmail.trim()) {
      this.setState({
        teamEmailValidation: true,
        teamEmailErrorText: 'Team email must not be empty',
      });
      isGoodRequest = false;
    } else if (!validTeamEmail) {
      this.setState({
        teamEmailValidation: true,
        teamEmailErrorText: 'Invalid team email found',
      });
      isGoodRequest = false;
    }

    // Team name
    if (this.state.teamName.length === 0 || !this.state.teamName.trim()) {
      this.setState({ teamNameValidation: true });
      isGoodRequest = false;
    }

    // Team wiki
    if (this.state.teamWiki.length === 0 || !this.state.teamWiki.trim()) {
      this.setState({ teamWikiValidation: true });
      isGoodRequest = false;
    }

    // Category
    if (this.state.category.length === 0 || !this.state.category.trim()) {
      this.setState({ categoryValidation: true });
      isGoodRequest = false;
    }

    // Type
    if (this.state.type.length === 0 || !this.state.type.trim()) {
      this.setState({ typeValidation: true });
      isGoodRequest = false;
    }

    // Item
    if (this.state.item.length === 0 || !this.state.item.trim()) {
      this.setState({ itemValidation: true });
      isGoodRequest = false;
    }

    if (this.state.customerType == DATACONSUMER) {
      // Use case
      if (this.state.useCase.length === 0 || !this.state.useCase.trim()) {
        this.setState({ useCaseValidation: true });
        isGoodRequest = false;
      }

      // Data access and processing
      if (this.state.optionLabel.length === 0) {
        this.setState({
          dataAccessAndProcessingSelectValidation: true,
          dataAccessAndProcessingErrorText: 'Please select an option',
        });
        isGoodRequest = false;
      } else if (
        this.state.dataAccessAndProcessing.length === 0 ||
        !this.state.dataAccessAndProcessing.trim()
      ) {
        this.setState({
          dataAccessAndProcessingValidation: true,
          dataAccessAndProcessingErrorText:
            'Data access and processing description must not be empty',
        });
        isGoodRequest = false;
      }

      // IAM principal
      if (this.state.principalsToAdd.length === 0) {
        this.setState({
          iamPrincipalsValidation: true,
          iamPrincipalsErrorText: 'IAM principals must not be empty',
        });
        isGoodRequest = false;
      } else if (invalidIamPrincipals.length > 0) {
        this.setNotification(
          'Invalid IAM principal found',
          invalidIamPrincipals.join(' : '),
        );
        this.setState({
          iamPrincipalsValidation: true,
          iamPrincipalsErrorText: 'Invalid IAM principal found',
        });
        isGoodRequest = false;
      }
    }

    if (this.state.groupingMechanismId.length === 0) {
      this.setState({
        groupingMechanismIdValidation: true,
        groupingMechanismIdErrorText: 'Grouping mechanism ID must not be empty',
      });
      isGoodRequest = false;
    } else {
      const result = await isMemberOfGroupingMechanismId({
        groupingMechanismId: this.state.groupingMechanismId,
      });
      if (!result.memberOf) {
        this.setState({
          groupingMechanismIdValidation: true,
          groupingMechanismIdErrorText:
            'You are not a member of the provided grouping ID',
        });
        isGoodRequest = false;
      } else {
        this.setState({
          groupingMechanismIdValidation: false,
        });
      }
    }
    console.log('After alias good request?', isGoodRequest);
    return isGoodRequest;
  }

  iamPrincipalsStringToArray(textAreaString) {
    const iamPrincipalsArray = textAreaString
      .split('\n')
      .filter((principal) => principal != '');
    this.setState({
      principalsToAdd: iamPrincipalsArray.map((principal) =>
        principal.replace(/\s/g, ''),
      ),
      iamPrincipalsValidation: false,
    });
  }

  dataAccessOptions = [
    {
      label: 'Amazon EMR',
      id: '1',
    },
    {
      label: 'Amazon Redshift',
      id: '2',
    },
    {
      label: 'Amazon Redshift Spectrum',
      id: '3',
    },
    {
      label: 'Other',
      id: '4',
    },
  ];

  radioButtonSelection = (event) => {
    const value = event.detail.value;
    this.setState({ customerType: value });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }
    return (
      <>
        <TopNavigation
          id = 'top-navbar'
          className={'awsui-button-icon'}
          identity={{
            href: "/",
            title: WEBSITE_NAME,
          }}
          utilities={[
            {
              type: "button",
              iconUrl: require('./cart_white.png')
            },
            {
              type: "menu-dropdown",
              iconName: "notification",
              ariaLabel: "Notifications",
              items: []
            },
            {
              type: "button",
              iconName: "user-profile",
              text: this.state.userName,
              href: `https://phonetool.amazon.com/users/${this.state.userName}`,
            }
          ]}
          i18nStrings={{
            overflowMenuTriggerText: "More"
          }}
        />
        <AppLayout
          contentType={this.state.contentType}
          className={isHomePage() ? 'awsui-util-no-gutters' : ''}
          disableContentPaddings = {this.state.disableContentPaddings}
          disableBodyScroll
          content={
            <BrowserRouter>
              <Switch>
                <Route exact path='/'>
                  <Route exact path='/'>
                    <Home
                      setContentType={(type) =>
                        this.setState({ contentType: type })
                      }
                      setNavigationOpen={(open) =>
                        this.setState({ navOpen: open })
                      }
                      getStartedTarget='/onboarding'
                      getStartedBtnText='Onboard'
                      allowListed={false}
                    />
                  </Route>
                </Route>
                <Route exact path='/onboarding'>
                  {/* TODO: we should extract this into its own component */}
                  <Form
                    header={
                      <Header
                        variant='h1'
                        description={`${WEBSITE_NAME} is a one-stop portal to discover, manage access, publish and subscribe to data in AWS Data Lake.`}
                      >
                        Welcome to {WEBSITE_NAME}
                      </Header>
                    }
                    actions={
                      <div>
                        <Button
                          variant='link'
                          onClick={() => console.log('clicked')}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant='primary'
                          onClick={this.handleConfirm}
                          loading={this.state.buttonLoading}
                        >
                          {this.state.buttonText}
                        </Button>
                      </div>
                    }
                  >
                    <div id='flash-bar'>
                      <Flashbar items={this.state.notifications} />
                    </div>
                    <Container
                      className='custom-screenshot-hide'
                      header={<h2>Onboarding request</h2>}
                    >
                      <ColumnLayout>
                        <FormField
                          label='Team name'
                          errorText={
                            this.state.teamNameValidation
                              ? 'Team name must not be empty'
                              : false
                          }
                        >
                          <Input
                            ariaRequired={true}
                            onChange={(e) =>
                              this.setState({
                                teamName: e.detail.value,
                                teamNameValidation: false,
                              })
                            }
                            value={this.state.teamName}
                          />
                        </FormField>

                        <FormField
                          label='Team wiki'
                          errorText={
                            this.state.teamWikiValidation
                              ? 'Team wiki must not be empty'
                              : false
                          }
                        >
                          <Input
                            ariaRequired={true}
                            onChange={(e) =>
                              this.setState({
                                teamWiki: e.detail.value,
                                teamWikiValidation: false,
                              })
                            }
                            value={this.state.teamWiki}
                          />
                        </FormField>

                        <FormField
                          label='Team email'
                          errorText={
                            this.state.teamEmailValidation
                              ? this.state.teamEmailErrorText
                              : false
                          }
                        >
                          <Input
                            ariaRequired={true}
                            onChange={(e) =>
                              this.setState({
                                teamEmail: e.detail.value,
                                teamEmailValidation: false,
                              })
                            }
                            value={this.state.teamEmail}
                          />
                        </FormField>

                        <FormField
                          label='Category (C)'
                          errorText={
                            this.state.categoryValidation
                              ? 'CTI must not be empty'
                              : false
                          }
                        >
                          <Input
                            ariaRequired={true}
                            onChange={(e) =>
                              this.setState({
                                category: e.detail.value,
                                categoryValidation: false,
                              })
                            }
                            value={this.state.category}
                          />
                        </FormField>

                        <FormField
                          label='Type (T)'
                          errorText={
                            this.state.typeValidation
                              ? 'CTI must not be empty'
                              : false
                          }
                        >
                          <Input
                            ariaRequired={true}
                            onChange={(e) =>
                              this.setState({
                                type: e.detail.value,
                                typeValidation: false,
                              })
                            }
                            value={this.state.type}
                          />
                        </FormField>

                        <FormField
                          label='Item (I)'
                          errorText={
                            this.state.itemValidation
                              ? 'CTI must not be empty'
                              : false
                          }
                        >
                          <Input
                            ariaRequired={true}
                            onChange={(e) =>
                              this.setState({
                                item: e.detail.value,
                                itemValidation: false,
                              })
                            }
                            value={this.state.item}
                          />
                        </FormField>
                        <RadioGroup
                          onChange={this.radioButtonSelection}
                          value={this.state.customerType}
                          items={[
                            {
                              value: DATACONSUMER,
                              label: "I'm a Data Consumer",
                            },
                            {
                              value: DATAPROVIDER,
                              label: "I'm a Data Provider",
                            },
                            {
                              value: DATAPROVIDER_AND_CONSUMER,
                              label: 'Both',
                            },
                          ]}
                        />
                        {(this.state.customerType == DATACONSUMER ||
                          this.state.customerType ==
                            DATAPROVIDER_AND_CONSUMER) && (
                          <>
                            <FormField
                              label='Data access and processing'
                              description='How are you looking to access and process the data?'
                              errorText={
                                this.state
                                  .dataAccessAndProcessingSelectValidation
                                  ? this.state.dataAccessAndProcessingErrorText
                                  : false
                              }
                            >
                              <Select
                                options={this.dataAccessOptions}
                                placeholder='Select an option'
                                selectedAriaLabel='Selected'
                                onChange={(e) => {
                                  this.setState({
                                    optionLabel: e.detail.selectedOption.label,
                                    dataAccessAndProcessing: '',
                                    dataAccessAndProcessingSelectValidation:
                                      false,
                                    selectedDataAccessOption:
                                      e.detail.selectedOption,
                                  });

                                  if (this.state.optionLabel != 'Other') {
                                    this.setState({
                                      dataAccessAndProcessing:
                                        e.detail.selectedOption.label,
                                    });
                                  }
                                }}
                                selectedOption={
                                  this.state.selectedDataAccessOption
                                }
                              />
                            </FormField>
                            {this.state.optionLabel === 'Other' && (
                              <>
                                <FormField
                                  description='Explain your way of data access and processing.'
                                  errorText={
                                    this.state.dataAccessAndProcessingValidation
                                      ? 'Data access and processing description must not be empty'
                                      : false
                                  }
                                >
                                  <Textarea
                                    ariaRequired={true}
                                    onChange={(e) =>
                                      this.setState({
                                        dataAccessAndProcessing: e.detail.value,
                                        dataAccessAndProcessingValidation:
                                          false,
                                      })
                                    }
                                    value={this.state.dataAccessAndProcessing}
                                  />
                                </FormField>
                              </>
                            )}

                            <FormField
                              label='Use case'
                              description='Explain your use case and the business problem that it is solving.'
                              errorText={
                                this.state.useCaseValidation
                                  ? 'Use case must not be empty'
                                  : false
                              }
                            >
                              <Textarea
                                ariaRequired={true}
                                onChange={(e) =>
                                  this.setState({
                                    useCase: e.detail.value,
                                    useCaseValidation: false,
                                  })
                                }
                                value={this.state.useCase}
                              />
                            </FormField>

                            <FormField
                              label={
                                <div>
                                  Existing use case <i> - optional</i>
                                </div>
                              }
                              description='Explain your existing use case. How is it currently satisfied? What are the challenges with the current approach?'
                            >
                              <Textarea
                                ariaRequired={false}
                                onChange={(e) =>
                                  this.setState({
                                    existingUseCase: e.detail.value,
                                  })
                                }
                                value={this.state.existingUseCase}
                              />
                            </FormField>

                            <FormField
                              label='IAM principals'
                              description='IAM principals that are already created and are going to access the data. You must provide at least one IAM principal.'
                              constraintText='Specify each IAM principal on a new line.'
                              errorText={
                                this.state.iamPrincipalsValidation
                                  ? this.state.iamPrincipalsErrorText
                                  : false
                              }
                            >
                              <Textarea
                                ariaRequired={true}
                                onChange={(e) => {
                                  this.setState({
                                    iamPrinciple: e.detail.value,
                                  });
                                  this.iamPrincipalsStringToArray(
                                    this.state.iamPrinciple,
                                  );
                                }}
                                placeholder={
                                  'arn:aws:iam::AWS-account-ID:root\narn:aws:iam::AWS-account-ID:user/user-name\narn:aws:iam::AWS-account-ID:role/role-name'
                                }
                                value={this.state.iamPrinciple}
                              />
                            </FormField>
                          </>
                        )}
                        <FormField
                          label='Group name'
                          description='A name for the group that will be created for your team.'
                          constraintText="Use alphanumeric and '-_' characters. Maximum 64 characters."
                          errorText={
                            this.state.groupIdValidation
                              ? this.state.groupIdErrorText
                              : false
                          }
                        >
                          <Input
                            ariaRequired={true}
                            onChange={(e) =>
                              this.setState({
                                groupId: e.detail.value,
                                groupIdValidation: false,
                              })
                            }
                            placeholder={'AWSDataLake_Prod'}
                            value={this.state.groupId}
                          />
                        </FormField>

                        <FormField
                          label='Grouping ID'
                          description='Please enter either a POSIX group or a Teams ID. This ID will be used to determine your group members.'
                          constraintText='An example of a POSIX group is "aws-datalake". An example of a Teams ID is "amzn1.abacus.team.t67zmnfvv22qpbubm6fa".'
                          errorText={
                            this.state.groupingMechanismIdValidation
                              ? this.state.groupingMechanismIdErrorText
                              : false
                          }
                        >
                          <Input
                            ariaRequired={true}
                            placeholder={
                              'amzn1.abacus.team.t67zmnfvv22qpbubm6fa'
                            }
                            onChange={(e) => {
                              this.setState({
                                groupingMechanismId: e.detail.value,
                                groupingMechanismIdValidation: false,
                              });
                            }}
                            value={this.state.groupingMechanismId}
                          />
                        </FormField>
                      </ColumnLayout>
                    </Container>
                  </Form>
                  <main className='container' />
                </Route>
                <Route path='*' render={(_) => <Redirect to='/' />} />
              </Switch>
            </BrowserRouter>
          }
          navigation={
            <SideNavigation
              items={navItemsNewCustomers as SideNavigationProps.Item[]}
              activeHref={this.state.active}
              onFollow={(e) => {
                e.preventDefault();
                if (e.detail.external) {
                  window.open(e.detail.href);
                } else {
                  this.setState(
                    {
                      active: e.detail.href,
                      redirect: e.detail.href,
                    },
                    () => this.setState({ redirect: undefined }),
                  );
                }
              }}
            />
          }
          tools={helpContentNewCustomers}
          navigationOpen={this.state.navOpen}
          onNavigationChange={(e) => {
            this.setState({ navOpen: e.detail.open });
          }}
          toolsOpen={false}
          ariaLabels={appLayoutLabels}
          headerSelector='#top-navbar'
        />
      </>
    );
  }
}
