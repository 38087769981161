import { Link } from '@amzn/awsui-components-react-v3';
import React from 'react';

export const TOOLS_CONTENT = {
  StartPage: {
    default: {
      content: '',
    },
  },
  BaselineIAMAccess: {
    default: {
      content:
        'In this step, you can see all the datasets you have IAM permission to. Select datasets you don’t want to have permission to anymore.',
    },
  },
  BaselineLFAccessConsumer: {
    default: {
      content:
        "In this step, you can see all the Lake Formation dataset shares you have as a consumer. Select dataset shares you don't want to have permission to anymore.",
    },
  },
  BaselineLFAccessOwner: {
    default: {
      content:
        "In this step, you can see all the Lake Formation dataset shares you have as an owner. Select dataset shares you don't want to have permission to anymore.",
    },
  },
  BaselineIAMPrincipal: {
    default: {
      content:
        'In this step, you can see all the IAM principals you have. Select IAM principals you want to remove.',
    },
  },
  Review: {
    default: {
      content: '',
    },
  },
};

export const externalLinkProps = {
  external: true,
  externalIconAriaLabel: 'Opens in a new tab',
};

export const DEFAULT_STEP_INFO = {
  BaselineIAMAccess: {
    selectedIamAccess: [],
  },
  BaselineLFAccessConsumer: {
    selectedLFConsumer: [],
  },
  BaselineLFAccessOwner: {
    selectedLFOwner: [],
  },
  BaselineIAMPrincipal: {
    selectedIAMPrinciples: [],
  },
};

export const InfoLink = ({ id, onFollow }) => (
  <Link variant='info' id={id} onFollow={onFollow}>
    Info
  </Link>
);

// a special case of external link, to be used within a link group, where all of them are external
// and we do not repeat the icon
export const ExternalLinkItem = ({ href, text }) => (
  <Link
    href={href}
    ariaLabel={`${text} ${externalLinkProps.externalIconAriaLabel}`}
    target='_blank'
  >
    {text}
  </Link>
);
