import * as React from 'react';
import { UX_WRITING } from 'src/components/ingestion/common/uxWriting';
import { BreadcrumbGroup } from '@amzn/awsui-components-react';
import { WEBSITE_NAME, NONE } from '../commons/constants';
import { Page } from '../routes/Paths';
import {
  createPublisherTableLink,
  createResourceGroupLink,
  createBootstrapActionLink,
  createTemplateLink,
} from '../routes/Paths';

const ROOT_URL = {
  text: WEBSITE_NAME,
  href: Page.HOME,
};

const MANAGE_DATASETS = {
  text: 'Manage datasets',
  href: Page.INGESTION.MANAGE_DATASETS,
};

export const browseCatalogs = [
  ROOT_URL,
  {
    text: 'Browse catalogs',
    href: NONE,
  },
];
export const catalogDetails = (name) => [
  ROOT_URL,
  {
    text: 'Browse catalogs',
    href: Page.BROWSE_CATALOGS,
  },
  {
    text: name,
    href: NONE,
  },
];

export const browseDatabases = [
  ROOT_URL,
  {
    text: 'Browse databases',
    href: NONE,
  },
];
export const databaseDetails = (name) => [
  ROOT_URL,
  {
    text: 'Browse databases',
    href: Page.BROWSE_DATABASES,
  },
  {
    text: name,
    href: NONE,
  },
];

export const browseDatasets = [
  ROOT_URL,
  {
    text: 'Browse datasets',
    href: NONE,
  },
];
export const datasetDetails = (name) => [
  ROOT_URL,
  {
    text: 'Browse datasets',
    href: Page.BROWSE_DATASETS,
  },
  {
    text: name,
    href: NONE,
  },
];

export const myConnections = [
  ROOT_URL,
  {
    text: 'Connections',
    href: NONE,
  },
];
export const createConnection = [
  ROOT_URL,
  {
    text: 'Connections',
    href: Page.CONNECTIONS,
  },
  {
    text: 'Create connection',
    href: NONE,
  },
];
export const connectionDetails = (id) => [
  ROOT_URL,
  {
    text: 'Connections',
    href: Page.CONNECTIONS,
  },
  {
    text: id,
    href: NONE,
  },
];

export const mySubscriptions = [
  ROOT_URL,
  {
    text: 'Subscriptions',
    href: NONE,
  },
];
export const createSubscription = [
  ROOT_URL,
  {
    text: 'Subscriptions',
    href: Page.SUBSCRIPTIONS,
  },
  {
    text: 'Create subscription',
    href: NONE,
  },
];
export const subscriptionDetails = (id) => [
  ROOT_URL,
  {
    text: 'Subscriptions',
    href: Page.SUBSCRIPTIONS,
  },
  {
    text: id,
    href: NONE,
  },
];

export const publish = [
  ROOT_URL,
  {
    text: 'Publish',
    href: Page.PUBLISH,
  },
];

export const publisherAccounts = [
  ROOT_URL,
  {
    text: 'Published accounts',
    href: NONE,
  },
];
export const publisherRegisterAccount = [
  ROOT_URL,
  {
    text: 'Published accounts',
    href: Page.PUBLISHER_ACCOUNTS,
  },
  {
    text: 'Register account',
    href: NONE,
  },
];
export const publisherAccountDetails = (id) => [
  ROOT_URL,
  {
    text: 'Published accounts',
    href: Page.PUBLISHER_ACCOUNTS,
  },
  {
    text: id,
    href: NONE,
  },
];

export const publisherTables = [ROOT_URL, MANAGE_DATASETS];
export const publisherRegisterTable = [
  ROOT_URL,
  MANAGE_DATASETS,
  {
    text: 'Register table',
    href: NONE,
  },
];
export const publisherCreateBackfill = (tableName, url) => [
  ROOT_URL,
  MANAGE_DATASETS,
  {
    text: tableName,
    href: url,
  },
  {
    text: 'Create backfill',
    href: NONE,
  },
];
export const publisherDedupe = (tableName, url) => [
  ROOT_URL,
  MANAGE_DATASETS,
  {
    text: tableName,
    href: url,
  },
  {
    text: 'Dedupe',
    href: NONE,
  },
];
export const publisherTableDetails = (tableName) => [
  ROOT_URL,
  MANAGE_DATASETS,
  {
    text: tableName,
    href: NONE,
  },
];

export const createLakeFormationPermission = [
  ROOT_URL,
  {
    text: 'Access requests',
    href: Page.MY_DATASETS,
  },
  {
    text: 'Create Lake Formation permission',
    href: NONE,
  },
];
export const permissionRequestDetails = (name) => [
  ROOT_URL,
  {
    text: 'Access requests',
    href: Page.REQUESTS,
  },
  {
    text: name,
    href: NONE,
  },
];

export const myDatasets = [
  ROOT_URL,
  {
    text: 'Datasets',
    href: NONE,
  },
];
export const myDatasetsDetails = (id) => [
  ROOT_URL,
  {
    text: 'Datasets',
    href: Page.MY_DATASETS,
  },
  {
    text: id,
    href: NONE,
  },
];

export const myDependencies = [
  ROOT_URL,
  {
    text: 'Dependencies',
    href: NONE,
  },
];
export const createDependency = [
  ROOT_URL,
  {
    text: 'Dependencies',
    href: Page.DEPENDENCIES,
  },
  {
    text: 'Create dependency',
    href: NONE,
  },
];
export const dependencyDetails = (id) => [
  ROOT_URL,
  {
    text: 'Dependencies',
    href: Page.DEPENDENCIES,
  },
  {
    text: id,
    href: NONE,
  },
];
export const myRequests = [
  ROOT_URL,
  {
    text: 'Access requests',
    href: NONE,
  },
];

export const myBaselining = [
  ROOT_URL,
  {
    text: 'Baseline permissions',
    href: NONE,
  },
];

export const myResourceGroups = [
  ROOT_URL,
  {
    text: 'Resource groups',
    href: NONE,
  },
];
export const reosurcegroupFormNew = [
  ROOT_URL,
  {
    text: 'Resource groups',
    href: Page.RESOURCEGROUPS,
  },
  {
    text: 'Create resource group',
    href: NONE,
  },
];
export const reosurcegroupFormUpdate = (id) => [
  ROOT_URL,
  {
    text: 'Resource groups',
    href: Page.RESOURCEGROUPS,
  },
  {
    text: id,
    href: createResourceGroupLink(id),
  },
  {
    text: 'Edit',
    href: NONE,
  },
];
export const resourcegroupDetails = (id) => [
  ROOT_URL,
  {
    text: 'Resource groups',
    href: Page.RESOURCEGROUPS,
  },
  {
    text: id,
    href: NONE,
  },
];
export const resourceDetails = (rgid, id) => [
  ROOT_URL,
  {
    text: 'Resource groups',
    href: Page.RESOURCEGROUPS,
  },
  {
    text: rgid,
    href: createResourceGroupLink(rgid),
  },
  {
    text: 'Resources',
    href: createResourceGroupLink(rgid),
  },
  {
    text: id,
    href: NONE,
  },
];

export const myBootstrapActions = [
  ROOT_URL,
  {
    text: 'Bootstrap actions',
    href: NONE,
  },
];
export const bootstrapactionFormNew = [
  ROOT_URL,
  {
    text: 'Bootstrap actions',
    href: Page.BOOTSTRAPACTIONS,
  },
  {
    text: 'Create bootstrap action',
    href: NONE,
  },
];
export const bootstrapactionFormUpdate = (id) => [
  ROOT_URL,
  {
    text: 'Bootstrap actions',
    href: Page.BOOTSTRAPACTIONS,
  },
  {
    text: id,
    href: createBootstrapActionLink(id),
  },
  {
    text: 'Edit',
    href: NONE,
  },
];
export const bootstrapactionDetails = (id) => [
  ROOT_URL,
  {
    text: 'Bootstrap actions',
    href: Page.BOOTSTRAPACTIONS,
  },
  {
    text: id,
    href: NONE,
  },
];

export const templateNew = () => [
  ROOT_URL,
  {
    text: 'Resource groups',
    href: Page.RESOURCEGROUPS,
  },
  {
    text: 'Templates',
    href: Page.RESOURCEGROUPS,
  },
  {
    text: 'Create template',
    href: NONE,
  },
];
export const templateUpdate = (id) => [
  ROOT_URL,
  {
    text: 'Resource groups',
    href: Page.RESOURCEGROUPS,
  },
  {
    text: 'Templates',
    href: Page.RESOURCEGROUPS,
  },
  {
    text: id,
    href: createTemplateLink(id),
  },
  {
    text: 'Edit',
    href: NONE,
  },
];
export const templateDetails = (id) => [
  ROOT_URL,
  {
    text: 'Resource groups',
    href: Page.RESOURCEGROUPS,
  },
  {
    text: 'Templates',
    href: Page.RESOURCEGROUPS,
  },
  {
    text: id,
    href: NONE,
  },
];

export const myGroup = [
  ROOT_URL,
  {
    text: 'My group',
    href: Page.GROUP,
  },
];

export const myGroups = (id) => [
  ROOT_URL,
  {
    text: 'Groups',
    href: Page.GROUPS,
  },
  {
    text: id,
    href: NONE,
  },
];

export const myNotifications = [
  ROOT_URL,
  {
    text: 'Notifications',
    href: NONE,
  },
];
export const createNotification = [
  ROOT_URL,
  {
    text: 'Notifications',
    href: Page.NOTIFICATIONS,
  },
  {
    text: 'Create notification',
    href: NONE,
  },
];
export const notificationDetail = [
  ROOT_URL,
  {
    text: 'Notifications',
    href: Page.NOTIFICATIONS,
  },
  {
    text: 'Notification details',
    href: NONE,
  },
];
export const notificationBatchDetail = [
  ROOT_URL,
  {
    text: 'Notifications',
    href: Page.NOTIFICATIONS,
  },
  {
    text: 'Notification batch detail',
    href: NONE,
  },
];

export const notificationTemplates = [
  ROOT_URL,
  {
    text: 'Notifications',
    href: Page.NOTIFICATIONS,
  },
  {
    text: 'Notification templates',
    href: NONE,
  },
];
export const notificationConfiguration = [
  ROOT_URL,
  {
    text: 'Notifications',
    href: Page.NOTIFICATIONS,
  },
  {
    text: 'Notification configuration',
    href: NONE,
  },
];
export const createNotificationTemplate = [
  ROOT_URL,
  {
    text: 'Notifications',
    href: Page.NOTIFICATIONS,
  },
  {
    text: 'Notification templates',
    href: Page.NOTIFICATION_TEMPLATES,
  },
  {
    text: 'Create notification template',
    href: NONE,
  },
];
export const updateNotificationTemplate = [
  ROOT_URL,
  {
    text: 'Notifications',
    href: Page.NOTIFICATIONS,
  },
  {
    text: 'Notification templates',
    href: Page.NOTIFICATION_TEMPLATES,
  },
  {
    text: 'Edit',
    href: NONE,
  },
];

export const notificationTemplateDetail = [
  ROOT_URL,
  {
    text: 'Notifications',
    href: Page.NOTIFICATIONS,
  },
  {
    text: 'Notification template',
    href: Page.NOTIFICATION_TEMPLATES,
  },
  {
    text: 'Notification template details',
    href: NONE,
  },
];

const registerContractBreadcrumbs = [
  ROOT_URL,
  MANAGE_DATASETS,
  {
    text: UX_WRITING.REGISTER_DATASET,
    href: Page.INGESTION.REGISTER_CONTRACT,
  },
];

export const getDatasetName = (id: string) => {
  try {
    const [, , , dataset] = id.split('|');
    return dataset.substring(3);
  } catch (e) {
    return id;
  }
};

const updateContractBreadcrumbs = (id: string) => [
  ROOT_URL,
  MANAGE_DATASETS,
  {
    text: 'Update dataset',
    href: Page.INGESTION.UPDATE_CONTRACT,
  },
  {
    text: getDatasetName(id),
    href: NONE,
  },
];

const monitorDatasetBreadcrumbs = (dataset: string) => [
  ROOT_URL,
  MANAGE_DATASETS,
  {
    text: 'Monitoring',
    href: null,
  },
  {
    text: dataset,
    href: NONE,
  },
];

const contractDetailBreadcrumbs = (dataset: string) => [
  ROOT_URL,
  MANAGE_DATASETS,
  {
    text: dataset,
    href: NONE,
  },
];

export const BreadcrumbsBrowseCatalogs = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={browseCatalogs}
    label='Breadcrumbs'
  />
);
export const BreadcrumbsCatalogDetails = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={catalogDetails(props.activeCatalogName)}
    label='Breadcrumbs'
  />
);

export const BreadcrumbsBrowseDatabases = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={browseDatabases}
    label='Breadcrumbs'
  />
);
export const BreadcrumbsDatabaseDetails = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={databaseDetails(props.activeDatabaseName)}
    label='Breadcrumbs'
  />
);

export const BreadcrumbsBrowseDatasets = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={browseDatasets}
    label='Breadcrumbs'
  />
);
export const BreadcrumbsDatasetDetails = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={datasetDetails(props.activeDatasetName)}
    label='Breadcrumbs'
  />
);

export const BreadcrumbsMyConnections = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={myConnections}
    label='Breadcrumbs'
  />
);
export const BreadcrumbsCreateConnection = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={createConnection}
    label='Breadcrumbs'
  />
);
export const BreadcrumbsConnectionDetails = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={connectionDetails(props.match.params.id)}
    label='Breadcrumbs'
  />
);

export const BreadcrumbsMySubscriptions = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={mySubscriptions}
    label='Breadcrumbs'
  />
);
export const BreadcrumbsCreateSubscription = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={createSubscription}
    label='Breadcrumbs'
  />
);
export const BreadcrumbsSubscriptionDetails = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={subscriptionDetails(props.match.params.id)}
    label='Breadcrumbs'
  />
);

export const BreadcrumbsPublish = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={publish}
    label='Breadcrumbs'
  />
);

export const BreadcrumbsPublishedTables = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={publisherTables}
    label='Breadcrumbs'
  />
);
export const BreadcrumbsPublishRegisterTable = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={publisherRegisterTable}
    label='Breadcrumbs'
  />
);
export const BreadcrumbsCreateBackfill = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={publisherCreateBackfill(
      props.match.params.TableName,
      createPublisherTableLink(
        props.match.params.TableId,
        props.match.params.TableName,
      ),
    )}
    label='Breadcrumbs'
  />
);
export const BreadcrumbsPostDedupe = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={publisherDedupe(
      props.match.params.TableName,
      createPublisherTableLink(
        props.match.params.TableId,
        props.match.params.TableName,
      ),
    )}
    label='Breadcrumbs'
  />
);
export const BreadcrumbsPublishTableDetails = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={publisherTableDetails(props.match.params.TableName)}
    label='Breadcrumbs'
  />
);

export const BreadcrumbsPublisherAccounts = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={publisherAccounts}
    label='Breadcrumbs'
  />
);
export const BreadcrumbsPublisherRegisterAccount = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={publisherRegisterAccount}
    label='Breadcrumbs'
  />
);
export const BreadcrumbsPublishAccountDetails = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={publisherAccountDetails(props.match.params.id)}
    label='Breadcrumbs'
  />
);

export const BreadcrumbsCreateLakeFormationPermission = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={createLakeFormationPermission}
    label='Breadcrumbs'
  />
);
export const BreadcrumbsPermissionRequestDetails = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={permissionRequestDetails(props.match.params.id)}
    label='Breadcrumbs'
  />
);

export const BreadcrumbsMyDatasets = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={myDatasets}
    label='Breadcrumbs'
  />
);
export const BreadcrumbsMyDatasetsDetails = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={myDatasetsDetails(props.match.params.id)}
    label='Breadcrumbs'
  />
);

export const BreadcrumbsMyDependencies = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={myDependencies}
    label='Breadcrumbs'
  />
);
export const BreadcrumbsCreateDependency = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={createDependency}
    label='Breadcrumbs'
  />
);
export const BreadcrumbsDependencyDetails = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={dependencyDetails(props.match.params.id)}
    label='Breadcrumbs'
  />
);

export const BreadcrumbsMyGroup = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={myGroup}
    label='Breadcrumbs'
  />
);

export const BreadcrumbsMyGroups = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={myGroups(props.match.params.id)}
    label='Breadcrumbs'
  />
);

export const BreadcrumbsMyRequests = () => (
  <BreadcrumbGroup items={myRequests} label='Breadcrumbs' />
);

export const BreadcrumbsMyBaselining = () => (
  <BreadcrumbGroup items={myBaselining} label='Breadcrumbs' />
);

export const BreadcrumbsMyNotifications = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={myNotifications}
    label='Breadcrumbs'
  />
);
export const BreadcrumbsCreateNotifications = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={createNotification}
    label='Breadcrumbs'
  />
);
export const BreadcrumbsNotificationDetail = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={notificationDetail}
    label='Breadcrumbs'
  />
);
export const BreadcrumbsNotificationBatchDetail = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={notificationBatchDetail}
    label='Breadcrumbs'
  />
);

export const BreadcrumbsNotificationTemplates = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={notificationTemplates}
    label='Breadcrumbs'
  />
);
export const BreadcrumbsCreateNotificationTemplate = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={createNotificationTemplate}
    label='Breadcrumbs'
  />
);
export const BreadcrumbsUpdateNotificationTemplate = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={updateNotificationTemplate}
    label='Breadcrumbs'
  />
);
export const BreadcrumbsNotificationTemplateDetail = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={notificationTemplateDetail}
    label='Breadcrumbs'
  />
);

export const BreadcrumbsNotificationConfiguration = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={notificationConfiguration}
    label='Breadcrumbs'
  />
);

export const BreadcrumbsMyResourceGroups = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={myResourceGroups}
    label='Breadcrumbs'
  />
);
export const BreadcrumbsResourceGroupFormNew = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={reosurcegroupFormNew}
    label='Breadcrumbs'
  />
);
export const BreadcrumbsResourceGroupFormUpdate = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={reosurcegroupFormUpdate(props.match.params.id)}
    label='Breadcrumbs'
  />
);
export const BreadcrumbsResourceGroupDetails = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={resourcegroupDetails(props.match.params.id)}
    label='Breadcrumbs'
  />
);
export const BreadcrumbsResourceDetails = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={resourceDetails(props.match.params.rgid, props.match.params.id)}
    label='Breadcrumbs'
  />
);

export const BreadcrumbsMyBootstrapActions = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={myBootstrapActions}
    label='Breadcrumbs'
  />
);
export const BreadcrumbsBootstrapActionFormNew = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={bootstrapactionFormNew}
    label='Breadcrumbs'
  />
);
export const BreadcrumbsBootstrapActionFormUpdate = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={bootstrapactionFormUpdate(props.match.params.id)}
    label='Breadcrumbs'
  />
);
export const BreadcrumbsBootstrapActionDetails = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={bootstrapactionDetails(props.match.params.id)}
    label='Breadcrumbs'
  />
);

export const BreadcrumbsTemplateNew = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={templateNew()}
    label='Breadcrumbs'
  />
);
export const BreadcrumbsTemplateUpdate = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={templateUpdate(props.match.params.id)}
    label='Breadcrumbs'
  />
);
export const BreadcrumbsTemplateDetails = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={templateDetails(props.match.params.id)}
    label='Breadcrumbs'
  />
);

export const BreadcrumbsRegisterContract = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={registerContractBreadcrumbs}
    label='Breadcrumbs'
  />
);

export const BreadcrumbsUpdateContract = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={updateContractBreadcrumbs(props.match.params.id)}
    label='Breadcrumbs'
  />
);

export const BreadcrumbsContractDetail = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={contractDetailBreadcrumbs(props.match.params.dataset)}
    label='Breadcrumbs'
  />
);

export const BreadcrumbsMonitoringDataset = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={monitorDatasetBreadcrumbs(props.match.params.dataset)}
    label='Breadcrumbs'
  />
);
