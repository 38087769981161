import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Page } from './Paths';
import {
  subscriptionHelp,
  catalogHelp,
  catalogDetailsHelp,
  connectionsHelp,
  permissionsHelp,
  publisherHelp,
  bootstrapactionsHelp,
  resourcegroupsHelp,
  notificationHelp,
  groupsHelp,
  myDatasetsHelp,
  createLakeFormationRequestHelp,
  publishDataSetShareHelp,
  createNotificationHelp,
  tethysHelp,
} from './helpData';

export interface HelpProps {
  setToolsHide: any;
  content?: React.ReactNode;
}
export interface HelpState {}

export class WrapHelp extends React.Component<HelpProps, HelpState> {
  componentDidMount() {
    this.props.setToolsHide(false);
  }
  render() {
    return this.props.content;
  }
}

export class NoHelp extends React.Component<HelpProps, HelpState> {
  componentDidMount() {
    this.props.setToolsHide(true);
  }
  render() {
    return '';
  }
}

export const Help = (props) => (
  <Switch>
    <Route
      path={[Page.SUBSCRIPTIONS]}
      render={() => <WrapHelp {...props} content={subscriptionHelp} />}
    />
    <Route
      path={[Page.CONNECTIONS]}
      render={() => <WrapHelp {...props} content={connectionsHelp} />}
    />
    <Route
      path={[Page.BROWSE_DATASETS, Page.BROWSE_DATABASES, Page.BROWSE_CATALOGS]}
      exact
      render={() => <WrapHelp {...props} content={catalogHelp} />}
    />
    <Route
      path={[Page.DATASET_DETAILS]}
      render={() => <WrapHelp {...props} content={catalogDetailsHelp} />}
    />
    <Route
      path={[Page.MY_DATASETS]}
      exact
      render={() => <WrapHelp {...props} content={permissionsHelp} />}
    />
    <Route
      path={[Page.PUBLISH]}
      exact
      render={() => <WrapHelp {...props} content={publishDataSetShareHelp} />}
    />
    <Route
      path={[Page.MY_DATASETS]}
      exact
      render={() => <WrapHelp {...props} content={myDatasetsHelp} />}
    />
    <Route
      path={[Page.CREATE_LAKE_FORMATION_PERMISSIONS]}
      exact
      render={() => (
        <WrapHelp {...props} content={createLakeFormationRequestHelp} />
      )}
    />
    <Route
      path={[Page.DATASETSHARE_DETAILS]}
      exact
      render={() => <WrapHelp {...props} content={myDatasetsHelp} />}
    />
    <Route
      path={[Page.PUBLISHER_TABLE_DETAILS]}
      exact
      render={() => <WrapHelp {...props} content={publisherHelp} />}
    />
    <Route
      path={[Page.NOTIFICATIONS]}
      exact
      render={() => <WrapHelp {...props} content={notificationHelp} />}
    />
    <Route
      path={[Page.CREATE_NOTIFICATION]}
      exact
      render={() => <WrapHelp {...props} content={createNotificationHelp} />}
    />
    <Route
      path={[Page.GROUP]}
      exact
      render={() => <WrapHelp {...props} content={groupsHelp} />}
    />
    <Route
      path={[Page.RESOURCEGROUPS]}
      exact
      render={() => <WrapHelp {...props} content={resourcegroupsHelp} />}
    />
    <Route
      path={[Page.BOOTSTRAPACTIONS]}
      exact
      render={() => <WrapHelp {...props} content={bootstrapactionsHelp} />}
    />
    <Route
      path={[Page.INGESTION.REGISTER_CONTRACT]}
      exact
      render={() => <WrapHelp {...props} content={tethysHelp} />}
    />
    <Route
      path={[Page.INGESTION.MANAGE_DATASETS]}
      exact
      render={() => <WrapHelp {...props} content={tethysHelp} />}
    />
    <Route
      path={[Page.INGESTION.UPDATE_CONTRACT]}
      render={() => <WrapHelp {...props} content={tethysHelp} />}
    />
    <Route
      path={[Page.INGESTION.DETAIL]}
      render={() => <WrapHelp {...props} content={tethysHelp} />}
    />
    <Route
      path={[Page.INGESTION.MONITORING]}
      render={() => <WrapHelp {...props} content={tethysHelp} />}
    />
    <Route path={['*']} render={() => <NoHelp {...props} />} />
  </Switch>
);

export default Help;
