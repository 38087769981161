import * as React from 'react';
import { Component } from 'react';

import { getDataSetLineage } from 'src/api/catalog';
import {
  Alert,
  Container,
  Table,
  Button,
  Link,
  ColumnLayout,
  Modal,
  Header,
  Box,
} from '@amzn/awsui-components-react-v3/polaris';

import {
  getLineageNodeInfo,
  listToCommaSeperatedValues,
} from 'src/components/catalog/datasetdetail/utils';
import { getAccountIdFromDatasetId } from 'src/commons/common';
import { DJS_JOB_URL_PREFIX } from 'src/commons/constants';
import { isValidDataSetIdFormat } from 'src/commons/validationUtils';

export interface LineageDetailProps {
  dataset: any;
  datasetId: string;
  activeGroup: string;
}

export interface LineageDetailState {
  datasetItems: any[];
  jobItems: any[];
  fileItems: any[];
  selectedItemDataset: any[];
  selectedItemJob: any[];
  selectedItemFile: any[];
  selectedItemForModal: any;
  loading: boolean;
  viewLineageModalVisible: boolean;
}

export default class LineageTable extends Component<
  LineageDetailProps,
  LineageDetailState
> {
  state = {
    datasetItems: [],
    jobItems: [],
    fileItems: [],
    selectedItemDataset: [],
    selectedItemJob: [],
    selectedItemFile: [],
    selectedItemForModal: undefined,
    loading: true,
    viewLineageModalVisible: false,
  };

  columnDefinitionDataSet = [
    {
      id: 'nodeName',
      header: 'Node name',
      cell: (item) => item.NodeName,
      minWidth: '100px',
    },
    {
      id: 'tableName',
      header: 'Table name',
      cell: (item) =>
        isValidDataSetIdFormat(item?.NodeId) ? (
          <Link href={'/datasets/' + item.NodeId}>{item.NodeTableName}</Link>
        ) : (
          item?.NodeTableName
        ),
      minWidth: '100px',
    },
    {
      id: 'databaseName',
      header: 'Database name',
      cell: (item) =>
        isValidDataSetIdFormat(item?.NodeId) ? (
          <Link
            href={
              '/databases/' +
              getAccountIdFromDatasetId(item.NodeId) +
              '/' +
              item.NodeDatabaseName
            }
          >
            {item.NodeDatabaseName}
          </Link>
        ) : (
          item?.NodeDatabaseName
        ),
      minWidth: '100px',
    },
    {
      id: 'parentInfo',
      header: 'Parent nodes',
      cell: (item) => listToCommaSeperatedValues(item?.NodeParents),
      minWidth: '200px',
    },
  ];
  columnDefinitionJob = [
    {
      id: 'nodeName',
      header: 'Node name',
      cell: (item) => item.NodeName,
      minWidth: '100px',
    },
    {
      id: 'jobId',
      header: 'Job ID',
      cell: (item) =>
        item ? (
          <Link
            external={true}
            href={
              item?.NodeJobScheduler.includes('djs')
                ? DJS_JOB_URL_PREFIX + item.NodeJobId
                : ''
            }
          >
            {item.NodeJobId}
          </Link>
        ) : (
          item?.NodeJobId
        ),
      minWidth: '200px',
    },
    {
      id: 'jobType',
      header: 'Scheduler',
      cell: (item) => item.NodeJobScheduler,
      minWidth: '50px',
    },
    {
      id: 'parentInfo',
      header: 'Parent nodes',
      cell: (item) => listToCommaSeperatedValues(item?.NodeParents),
      minWidth: '200px',
    },
  ];
  columnDefinitionFile = [
    {
      id: 'nodeName',
      header: 'Node name',
      cell: (item) => item.NodeName,
      minWidth: '100px',
    },
    {
      id: 'fieldDescription',
      header: 'Description',
      cell: (item) => item.NodeDescription,
      minWidth: '200px',
    },
    {
      id: 'filePath',
      header: 'File',
      cell: (item) => item.NodeFilePath,
      minWidth: '200px',
    },
    {
      id: 'fileFormat',
      header: 'Format',
      cell: (item) => item.NodeFileFormat,
      minWidth: '100px',
    },
    {
      id: 'parentInfo',
      header: 'Parent nodes',
      cell: (item) => listToCommaSeperatedValues(item?.NodeParents),
      minWidth: '200px',
    },
  ];

  // call the getLineage api
  getLineage = async () => {
    const lineageInfo = await getDataSetLineage({
      Id: this.props.datasetId,
    });
    this.setState({
      datasetItems: lineageInfo.DataSetNodeList,
      jobItems: lineageInfo.JobNodeList,
      fileItems: lineageInfo.FileNodeList,
      loading: false,
    });
  };
  // open view info modal
  openModal() {
    this.setState({ viewLineageModalVisible: true });
  }
  // close view info modal
  closeModal() {
    this.setState({
      viewLineageModalVisible: false,
      selectedItemForModal: null,
    });
  }
  // return true view button for dataset nodes is disabled
  viewInfoForDatasetDisabled() {
    return this.state?.selectedItemDataset.length == 0;
  }
  // return true view button for job nodes is disabled
  viewInfoForJobDisabled() {
    return this.state?.selectedItemJob.length == 0;
  }
  // return true view button for file nodes is disabled
  viewInfoForFileDisabled() {
    return this.state?.selectedItemFile.length == 0;
  }

  componentDidMount = async () => {
    this.getLineage();
  };

  // returns true if there is no lineage info present
  isLineageEmpty() {
    return (
      this.state.loading == false &&
      this.state.datasetItems?.length +
        this.state.jobItems?.length +
        this.state.fileItems?.length ==
        0
    );
  }
  // returns true if there are values with links in it
  valueWithLinks(item) {
    return item?.props?.name?.includes('Detail links');
  }
  // takes list of values and input and give them as links
  getValueAsLink(items) {
    let output = [];
    if (items?.length == 1) {
      output.push(
        <Link external={true} href={items[0]}>
          link
        </Link>,
      );
    } else {
      items?.forEach((item, index) => {
        output.push(
          <Link external={true} href={item}>
            link{index + 1}
          </Link>,
        );
        output.push(<br></br>);
      });
    }

    return output;
  }

  render() {
    if (this.isLineageEmpty()) {
      return (
        <Alert header='No Lineage Found'>
          Lineage information is not available for this dataset.
        </Alert>
      );
    } else {
      return (
        <>
          <Modal
            onDismiss={() => {
              this.closeModal();
            }}
            visible={this.state.viewLineageModalVisible}
            header='Node Details'
          >
            <div className={'awsui'}>
              <Container>
                <ColumnLayout columns={2} borders={'horizontal'}>
                  {getLineageNodeInfo(this.state.selectedItemForModal).map(
                    (item) =>
                      this.valueWithLinks(item) ? (
                        <div className='awsui-util-label'>
                          {item?.props?.name}
                          <br></br>
                          {this.getValueAsLink(item?.props?.value)}
                        </div>
                      ) : (
                        <div>{item}</div>
                      ),
                  )}
                </ColumnLayout>
              </Container>
            </div>
          </Modal>

          <Table
            loadingText='Loading Lineage'
            loading={this.state.loading}
            columnDefinitions={this.columnDefinitionDataSet}
            items={this.state.datasetItems}
            wrapLines={true}
            header={
              <Header
                counter={`(${this.state.datasetItems.length})`}
                actions={
                  <Button
                    onClick={() => {
                      this.setState({
                        selectedItemForModal: this.state.selectedItemDataset[0],
                      });
                      this.openModal();
                    }}
                    disabled={this.viewInfoForDatasetDisabled()}
                  >
                    View info
                  </Button>
                }
              >
                Dataset
              </Header>
            }
            empty={
              <Box textAlign='center' color='inherit'>
                <b>Empty</b>
                <Box padding={{ bottom: 's' }} variant='p' color='inherit'>
                  No dataset information available in this lineage
                </Box>
              </Box>
            }
            selectionType='single'
            selectedItems={this.state.selectedItemDataset}
            onSelectionChange={({ detail }) =>
              this.setState({ selectedItemDataset: detail?.selectedItems })
            }
          />

          <Table
            loadingText='Loading Lineage'
            loading={this.state.loading}
            columnDefinitions={this.columnDefinitionJob}
            items={this.state.jobItems}
            wrapLines={true}
            header={
              <Header
                counter={`(${this.state.jobItems.length})`}
                actions={
                  <Button
                    onClick={() => {
                      this.setState({
                        selectedItemForModal: this.state.selectedItemJob[0],
                      });
                      this.openModal();
                    }}
                    disabled={this.viewInfoForJobDisabled()}
                  >
                    View info
                  </Button>
                }
              >
                Jobs
              </Header>
            }
            empty={
              <Box textAlign='center' color='inherit'>
                <b>Empty</b>
                <Box padding={{ bottom: 's' }} variant='p' color='inherit'>
                  No job information available in this lineage
                </Box>
              </Box>
            }
            selectionType='single'
            selectedItems={this.state.selectedItemJob}
            onSelectionChange={({ detail }) =>
              this.setState({ selectedItemJob: detail?.selectedItems })
            }
          />

          <Table
            loadingText='Loading Lineage'
            loading={this.state.loading}
            columnDefinitions={this.columnDefinitionFile}
            items={this.state.fileItems}
            wrapLines={true}
            header={
              <Header
                counter={`(${this.state.fileItems.length})`}
                actions={
                  <Button
                    onClick={() => {
                      this.setState({
                        selectedItemForModal: this.state.selectedItemFile[0],
                      });
                      this.openModal();
                    }}
                    disabled={this.viewInfoForFileDisabled()}
                  >
                    View info
                  </Button>
                }
              >
                Data feed
              </Header>
            }
            empty={
              <Box textAlign='center' color='inherit'>
                <b>Empty</b>
                <Box padding={{ bottom: 's' }} variant='p' color='inherit'>
                  No file information available in this lineage
                </Box>
              </Box>
            }
            selectionType='single'
            selectedItems={this.state.selectedItemFile}
            onSelectionChange={({ detail }) =>
              this.setState({ selectedItemFile: detail?.selectedItems })
            }
          />
        </>
      );
    }
  }
}
