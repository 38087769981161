import * as React from 'react';
import { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Pagination,
  PropertyFilter,
  Table,
  Spinner,
} from '@amzn/awsui-components-react-v3';
import { useCollection } from '@amzn/awsui-collection-hooks';

import {
  largePageSizePreference,
  defaultWrapLinesPreference,
  i18nStrings,
  paginationLabels,
} from 'src/commons/tables';

import { listCatalogs, listDatabases } from '../../../src/api/catalog';
import { Link } from 'react-router-dom';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';
import { compareBy } from '../utils/sorting';

export interface BrowseDatabasesTableProps {
  setContentType: any;
  title: string;
  loading?: boolean;
  filter?: object[];
  doNotLinkCatalogs?: boolean;
}


const BrowseDatabasesTable = (props: BrowseDatabasesTableProps) => {
  const [allItems, setItems] = useState([]);
  const [, setCatalogs] = useState([]);
  const [catalogsMap] = useState(new Map()); // Will use <catalogId>:<region> -> catalogInfo

  const [redirect] = useState(undefined);
  const [databasesLoading, setDatabasesLoading] = useState(true);
  const [catalogsLoading, setCatalogsLoading] = useState(true);

  const [preferences, setPreferences] =
    useState<CollectionPreferencesProps.Preferences>({
      wrapLines: false,
      pageSize: 25,
    });

  const columnDefinitions:TableProps.ColumnDefinition<any>[] = [
    {
      id: 'catalogDisplayName',
      header: 'Catalog name',
      cell: (item) =>
        props.doNotLinkCatalogs ? (
          getCatalogNameUnlessLoading(item.catalogId, item.region)
        ) : (
          <Link to={`/catalogs/${item.catalogId}/${item.region}`}>
              {item.catalogDisplayName}
          </Link>
        ),
      minWidth: 100,
      sortingField:'catalogDisplayName',
      sortingComparator:compareBy('catalogDisplayName', 'databaseName')
    },
    {
      id: 'databaseName',
      header: 'Database name',
      cell: (item) => (
        <Link to={`/databases/${item.catalogId}/${item.databaseName}`}>
          {item.databaseName}
        </Link>
      ),
      minWidth: 200,
    },
    {
      id: 'description',
      header: 'Description',
      cell: (item) => item.description,
      minWidth: 200,
    },
  ];

  // simplifies the database objects in order to make the rest of the code a bit cleaner
  const flatten = (items) => {
    const flat = items.map((item) => ({
      databaseName: item.DatabaseName,
      catalogId: item.CatalogId,
      region: item.Region,
      catalogDisplayName: getCatalogName(item.CatalogId, item.Region),
      description: item.Description,
    }));
    return flat
  };

  useEffect(() => {
    props.setContentType('table');
    fetchAllData();
  }, []);

  useEffect(() => {
    fetchAllData();
  }, [props.filter]);

  // Fetch databasaes and then their catalogs
  const fetchAllData = async () => {
    await fetchDatabases();
    await fetchCatalogsForDatabases();
  };

  // Gets the catalogs relevant to the databases currently in state.items
  const fetchCatalogsForDatabases = async () => {
    setCatalogsLoading(true);

    // Use a set to make them unique
    const catalogIdRegions = new Set<string>();
    for (const item of allItems) {
      if (item != null && item.CatalogId != null && item.Region != null) {
        const key: string = item.CatalogId + ':' + item.Region;
        catalogIdRegions.add(key);
      }
    }

    // Start to build a list of catalog keys for the request
    const catalogKeys = [];
    for (const idRegion of catalogIdRegions) {
      const catalogIdAndRegion = idRegion.split(':');
      const catalogId = catalogIdAndRegion[0];
      const region = catalogIdAndRegion[1];
      // Non-null if there's a match
      if (
        /^\d{12}$/.exec(catalogId) != null &&
        /^\w(\w|-)*$/.exec(region) != null
      ) {
        catalogKeys.push({
          CatalogId: catalogId,
          Region: region,
        });
      }
    }
    const request = {
      Filter: {
        CatalogKeyList: catalogKeys,
      },
    };
    const result = await listCatalogs(request);

    // Populate a map with our new catalogs
    catalogsMap.clear();
    for (let i = 0; i < result.CatalogInfoList.length; i++) {
      addCatalogItemToMap(result.CatalogInfoList[i]);
    }

    setCatalogs(result.CatalogInfoList);
    setCatalogsLoading(false);
  };

  const fetchDatabases = async () => {
    setDatabasesLoading(true);

    let request = {};
    if (props.filter) {
      request = {
        DatabaseKeyList: props.filter,
      };
    }

    const databases = await listDatabases(request);
    const databaseList = databases.DatabaseInfoList;

    setItems(databaseList);
    setDatabasesLoading(false);
  };

  const getCatalogNameUnlessLoading = (catalogId: string, region: string) => {
    if (catalogsLoading) {
      return <Spinner size='normal' />;
    } else {
      return getCatalogName(catalogId, region);
    }
  };

  const getCatalogName = (catalogId: string, region: string) => {
    const catalog = getCatalogFromMap(catalogId, region);
    if (catalog == null) {
      return String(catalogId);
    } else {
      return String(catalog.Name);
    }
  };

  const addCatalogItemToMap = (item) => {
    const catalogId: string = item.CatalogId;
    const region: string = item.Region;
    catalogsMap.set(catalogId + ':' + region, item);
  };

  const getCatalogFromMap = (catalogId: string, region: string) => {
    return catalogsMap.get(catalogId + ':' + region);
  };

  const {
    items,
    collectionProps,
    paginationProps,
    propertyFilterProps,
    filteredItemsCount,
  } = useCollection(flatten(allItems), {
    filtering: {
      noMatch: (
        <div className='awsui-util-t-c'>
          <div className='awsui-util-pt-s awsui-util-mb-xs'>
            <b>No matches</b>
          </div>
          <p className='awsui-util-mb-s'>We can’t find a match.</p>
        </div>
      ),
      empty: (
        <div className='awsui-util-t-c'>
          <div className='awsui-util-pt-s awsui-util-mb-xs'>
            <b>No databases</b>
          </div>
          <p className='awsui-util-mb-s'>
            No databases were found in the catalog.
          </p>
        </div>
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties: [
        {
          propertyLabel: 'Catalog name',
          key: 'catalogDisplayName',
          groupValuesLabel: 'Catalog names',
        },
      ],
    },
  });

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <>
      <Table
        {...collectionProps}
        loadingText='Loading databases...'
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        header={
          <h2>
            {props.title}
            <span className='awsui-util-header-counter'>
              {` (${items.length})`}
            </span>
          </h2>
        }
        loading={props.loading || databasesLoading || catalogsLoading}
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            i18nStrings={i18nStrings}
            countText={`${filteredItemsCount} ${
              filteredItemsCount === 1 ? 'match' : 'matches'
            }`}
          />
        }
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={largePageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        pagination={
          <Pagination {...paginationProps} ariaLabels={paginationLabels} />
        }
      />
    </>
  );
};

export default BrowseDatabasesTable
