import React, { useEffect, useState } from 'react';
import {
  StatusIndicator,
  AppLayoutProps,
  SpaceBetween,
  Header,
  Button,
  Link,
} from '@amzn/awsui-components-react-v3';
import { Dashboard, DashboardModel } from '@amzn/cloudwatchdashboards-inside';
import { metricsChart, recordsChart } from './widgets/charts';
import { ingestionKpis } from './widgets/kpis';
import { ingestionLogs } from './widgets/logs';
import { Redirect } from 'react-router-dom';
import { TicketModal } from './ticketModal';
import { Page } from 'src/routes';
import { UX_WRITING } from '../common/uxWriting';
import { getDatasetName } from 'src/commons/breadcrumbs';

const config = {
  displayMode: 'static',
  heightUnit: 24,
  widgetMarginX: 20,
  widgetMarginY: 20,
  style: 'polarisCard',
  internal: {
    accountId: '997545651165',
  },
};


interface iRouteParams {
  arn: string
  team: string
  dataset: string
  database: string
}

const definition = ({ arn, team, dataset, database}: iRouteParams): DashboardModel => {
  const namespace = `Tethys/${team}`;
  const datasetArn = `${arn}/${dataset}`
  const logGroup = `${database}-${dataset}`

  return {
    widgets: [
      ...ingestionKpis(namespace, datasetArn),
      recordsChart(namespace, datasetArn),
      metricsChart(namespace, datasetArn),
      ingestionLogs(team, logGroup),
    ],
  };
};

interface IDashboardComponentProps {
  config: any;
  definition: any;
}
interface IDashboardComponentState {
  loading: boolean;
}

export class DashboardComponent extends React.Component<
  IDashboardComponentProps,
  IDashboardComponentState
> {
  private dashboard;
  private dashboardContainer;

  constructor(props) {
    super(props);
    this.state = { loading: true };
    this.displayDashboard = this.displayDashboard.bind(this);
  }

  componentDidMount() {
    this.dashboard = new Dashboard(
      this.dashboardContainer,
      'Vasya-test-dashboard',
      'us-east-1',
      this.props.config,
    );
    this.dashboard.on('ready', this.displayDashboard);
  }

  componentWillUnmount() {
    this.dashboard.release();
  }

  render() {
    const { loading } = this.state;

    return (
      <div>
        {loading && <StatusIndicator type='loading'>Loading</StatusIndicator>}
        <div ref={(div) => (this.dashboardContainer = div)} />
      </div>
    );
  }

  displayDashboard() {
    this.dashboard.update(this.props.definition);
    this.setState({ loading: false });
  }
}


interface iMonitoring {
  params: iRouteParams;
  toggleHelp(): void;
  setContentType(contentType: AppLayoutProps.ContentType): void;
}

export const Monitoring = ({
  params:{ arn, team, dataset, database },
  toggleHelp,
  setContentType,
}: iMonitoring) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  useEffect(() => {
    setContentType('table');
  }, []);

  const [redirect, setRedirect] = useState(null);
  if (redirect) return <Redirect push to={redirect} />;

  return (
    <>
      <TicketModal visible={visible} setVisible={setVisible} />
      <SpaceBetween size='xxxs'>
        <Header
          variant='h1'
          info={<Link variant='info' onFollow={toggleHelp}> Feedback </Link>}
          actions={
            <SpaceBetween direction='horizontal' size='s'>
              <Button variant='normal' onClick={() => setVisible(true)}>
                Create ticket
              </Button>
              <Button
                variant='primary'
                onClick={() => setRedirect(Page.INGESTION.REGISTER_CONTRACT)}
              >
                { UX_WRITING.REGISTER_DATASET }
              </Button>
            </SpaceBetween>
          }
        >
          Ingestion Monitoring - { getDatasetName(dataset) }
        </Header>
        <DashboardComponent
          definition={definition({arn, team, dataset, database})}
          config={config}
        />
      </SpaceBetween>
    </>
  );
};
