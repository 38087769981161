import React, { useState } from 'react';
import {
  Container,
  FormField,
  Header,
  SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import DatabaseSelector from './databaseSelectorComponent';
import TableSelector from './tableSelectorComponent';
import { Database } from 'aws-sdk/clients/glue';
import { HybridCatalogDataSet } from 'aws-sdk/clients/awsdlhybridcatalogservicelambda';

interface ResourceSelectorProps {
  onDatabaseChange?: (database: Database | undefined) => void;
  onTableChange?: (table: HybridCatalogDataSet | undefined) => void;
  databaseName?: string;
  tableName?: string;
  catalogId?: string;
  activeGroup?: string;
}

const ResourceSelectorComponent = (props: ResourceSelectorProps) => {
  const {
    onDatabaseChange,
    onTableChange,
    databaseName,
    tableName,
    catalogId,
    activeGroup,
  } = props;
  const [database, setDatabase] = useState<Database>();

  const handleDatabaseChange = (database: Database | undefined) => {
    setDatabase(database);
    if (onDatabaseChange) {
      onDatabaseChange(database);
      onTableChange(undefined);
    }
  };

  const handleTableChange = (table: HybridCatalogDataSet | undefined) => {
    if (onTableChange) onTableChange(table);
  };

  return (
    <Container
      header={
        <Header
          variant='h2'
          description='Select the databases or tables to grant permissions on.'
        >
          Target resources
        </Header>
      }
    >
      <SpaceBetween size='m'>
        <FormField label='Database' description='Select a Database'>
          <DatabaseSelector
            onChange={handleDatabaseChange}
            initialSelection={databaseName}
            initialCatalogId={catalogId}
          />
        </FormField>
        <FormField label='Table' description='Select a table or All tables.'>
          <TableSelector
            database={database}
            initialSelection={tableName}
            activeGroup={activeGroup}
            onChange={handleTableChange}
          />
        </FormField>
      </SpaceBetween>
    </Container>
  );
};

export default ResourceSelectorComponent;
