import * as React from 'react';
import { useState, useEffect } from 'react';

import {
  editGroup,
  getGroupInfo,
  getGroupMembers,
  isMemberOfGroupingMechanismId,
} from '../../api/permissions';

import {
  Button,
  Select,
  Header,
  Textarea,
  Flashbar,
  ColumnLayout,
  CollectionPreferencesProps,
  Pagination,
  TextFilter,
  TableProps,
  Modal,
  Input,
  Table,
  FormField,
  CollectionPreferences,
  Container,
  SpaceBetween,
  Box,
} from '@amzn/awsui-components-react-v3';
import { useCollection } from '@amzn/awsui-collection-hooks';

import { PageHeader } from '../subscriptions/common';
import { CopiableText } from '../catalog/common';
import * as validate from '../../commons/validationUtils';
import { isValidUser } from '../../commons/validationUtils';
import {
  getGroupingMechanismType,
  getPhoneToolLink,
  getWikiLink,
} from 'src/commons/common';
import {
  defaultWrapLinesPreference,
  paginationLabels,
} from 'src/commons/tables';

export interface MyGroupsProps {
  username: string;
  setContentType: any;
  match: any;
  activeGroup: string;
}

const MyGroups = (props: MyGroupsProps) => {
  const [teamWiki, setTeamWiki] = useState('');
  const [teamEmail, setTeamEmail] = useState('');
  const [category, setCategory] = useState('');
  const [type, setType] = useState('');
  const [item, setItem] = useState('');
  const [useCase, setUseCase] = useState('');
  const [groupId, setGroupId] = useState(undefined);
  const [isOwner, setIsOwner] = useState(false);
  const [dataAccessAndProcessing, setDataAccessAndProcessing] = useState('');
  const [optionLabel, setOptionLabel] = useState('');
  const [customerType, setCustomerType] = useState('');
  const [, setCustomerTypeOptionLabel] = useState('');
  const [activeGroupInfo, setActiveGroupInfo] = useState(undefined);
  const [groupMembers, setGroupMembers] = useState([]);
  const [, setGroupMemberIds] = useState([]);
  const [, setMembersToAdd] = useState([]);
  const [loadingGroupMembers, setLoadingGroupMembers] = useState(true);
  const [, setLoadingGroupInfo] = useState(true);

  const [teamWikiValidation, setTeamWikiValidation] = useState(false);
  const [categoryValidation, setCategoryValidation] = useState(false);
  const [typeValidation, setTypeValidation] = useState(false);
  const [itemValidation, setItemValidation] = useState(false);
  const [teamWikiErrorText, setTeamWikiErrorText] = useState('');
  const [teamEmailValidation, setTeamEmailValidation] = useState(false);
  const [teamEmailErrorText, setTeamEmailErrorText] = useState('');
  const [useCaseValidation, setUseCaseValidation] = useState(false);
  const [dataAccessAndProcessingOption, setDataAccessAndProcessingOption] =
    useState(undefined);
  const [
    dataAccessAndProcessingValidation,
    setDataAccessAndProcessingValidation,
  ] = useState(false);
  const [
    dataAccessAndProcessingErrorText,
    setDataAccessAndProcessingErrorText,
  ] = useState('');
  const [customerTypeOption, setCustomerTypeOption] = useState(undefined);
  const [customerTypeValidation, setCustomerTypeValidation] = useState(false);
  const [customerTypeErrorText] = useState('');
  const [groupingMechanismId, setGroupingMechanismId] = useState('');

  const [editGroupModalVisible, setEditGroupModalVisible] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [buttonText] = useState('Submit request');
  const [notifications, setNotifications] = useState([]);
  const [tableMessage, setTableMessage] = useState('No group members');
  const [
    teamGroupingMechanismIdValidation,
    setTeamGroupingMechanismIdValidation,
  ] = useState(false);
  const [
    teamGroupingMechanismIdErrorText,
    setTeamGroupingMechanismIdErrorText,
  ] = useState('');

  const [preferences, setPreferences] =
    useState<CollectionPreferencesProps.Preferences>({
      wrapLines: false,
      pageSize: 10,
    });

  const columnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'userId',
      header: 'Member ID',
      cell: (item) => {
        if (isValidUser(item.userId)) return getPhoneToolLink(item.userId);
        return item.userId;
      },
      minWidth: '200px',
      sortingField: 'userId',
    },
    {
      id: 'updatedDate',
      header: 'Last updated',
      cell: (item) => item.updatedDate,
      minWidth: '200px',
      sortingField: 'updatedDate',
    },
  ];

  const readableCustomerTypeMap = {
    DATAPROVIDER_AND_DATACONSUMER: 'Data provider and consumer',
    DATAPROVIDER: 'Data provider',
    DATACONSUMER: 'Data consumer',
  };

  useEffect(() => {
    props.setContentType('table');

    const groupId = props.match.params.id
      ? props.match.params.id
      : props.activeGroup;

    setGroupId(groupId);
  }, []);

  useEffect(() => {
    handleRefresh();
  }, [props.activeGroup, groupId]);

  useEffect(() => {
    initializeEditableParameters();
  }, [activeGroupInfo]);

  const handleRefresh = async () => {
    if (!props.activeGroup) return;

    if (
      props.match.params.id === undefined ||
      props.match.params.id === props.activeGroup
    ) {
      setIsOwner(true);
    }

    await fetchGroupInfo();
    await fetchGroupMembers();
  };

  const fetchGroupInfo = async () => {
    setLoadingGroupInfo(true);

    try {
      const activeGroupInfo = await getGroupInfo({
        groupId,
      });

      setActiveGroupInfo(activeGroupInfo);
    } catch (err) {
      setLoadingGroupInfo(false);
    }
  };

  const fetchGroupMembers = async () => {
    setLoadingGroupMembers(true);
    try {
      const groupMembers = await getGroupMembers({
        groupId,
      });
      const existingUserIds = [];
      groupMembers.groupMembers.forEach((member) =>
        existingUserIds.push(member['userId']),
      );

      setGroupMembers(groupMembers.groupMembers);
      setGroupMemberIds(existingUserIds);
      setLoadingGroupMembers(false);
      setMembersToAdd([]);
    } catch (err) {
      setTableMessage('Unable to load group members.');
      setLoadingGroupMembers(false);
    }
  };

  const submitEditGroupRequest = async () => {
    if (validate.isEmpty(teamWiki)) {
      setTeamWiki(undefined);
    }
    if (validate.isEmpty(groupingMechanismId)) {
      setGroupingMechanismId(undefined);
    }
    if (validate.isEmpty(teamEmail)) {
      setTeamEmail(undefined);
    }
    if (validate.isEmpty(useCase)) {
      setUseCase(undefined);
    }
    if (validate.isEmpty(dataAccessAndProcessing)) {
      setDataAccessAndProcessing(undefined);
    }
    if (validate.isEmpty(customerType)) {
      setCustomerType(undefined);
    }
    await editGroup({
      groupId,
      teamWiki,
      groupingMechanismId,
      teamDistributionList: teamEmail,
      useCase,
      dataAccessAndProcessing,
      customerType,
      primaryCTI: {
        category: category,
        type: type,
        item: item,
      },
    });
  };

  const validateEditGroupRequest = async () => {
    if (
      validate.isEmpty(teamWiki) ||
      validate.isEmpty(teamEmail) ||
      validate.isEmpty(useCase) ||
      validate.isEmpty(dataAccessAndProcessing) ||
      validate.isEmpty(customerType) ||
      validate.isEmpty(groupingMechanismId) ||
      validate.isEmpty(category) ||
      validate.isEmpty(type) ||
      validate.isEmpty(item)
    ) {
      setNotification('Empty request fields found', '');
      return false;
    }
    if (validate.isNotEmpty(teamWiki)) {
      const validWiki = validate.isValidUrl(teamWiki);
      if (!validWiki) {
        setTeamWikiValidation(true);
        setTeamWikiErrorText('Invalid URL found');
        return false;
      }
    }
    // If there was a change of ID
    if (
      validate.isNotEmpty(groupingMechanismId) &&
      activeGroupInfo?.groupingMechanismId != groupingMechanismId
    ) {
      // Calling Gladstone to check if current user is members of
      // the given grouping mechanism ID
      const result = await isMemberOfGroupingMechanismId({
        groupingMechanismId,
      });
      // If the current user is not a member of the provided group
      if (!result.memberOf) {
        setTeamGroupingMechanismIdValidation(true);
        setTeamGroupingMechanismIdErrorText(
          'You are not a member of the provided group ID',
        );
        return false;
      }
    }

    if (validate.isNotEmpty(teamEmail)) {
      const validTeamEmail = validate.isValidAmazonEmail(teamEmail);
      if (!validTeamEmail) {
        setTeamEmailValidation(true);
        setTeamEmailErrorText('Invalid team email found');
        return false;
      }
    }

    if (
      optionLabel === 'Other' &&
      (dataAccessAndProcessing.length === 0 || !dataAccessAndProcessing.trim())
    ) {
      setDataAccessAndProcessingValidation(true);
      setDataAccessAndProcessingErrorText(
        'Data access and processing description must not be empty',
      );
      return false;
    }
    return true;
  };

  // this will initialize editable fields with the current fields, so they can be prefilled in form
  const initializeEditableParameters = () => {
    if (!activeGroupInfo) return;
    const { teamInfo } = activeGroupInfo;

    const currentDataAccessAndProcessing = teamInfo.DataAccessAndProcessing;
    const currentCustomerType = teamInfo.CustomerType;
    // setting up the retrieved value for DataClassification to be displayed

    const customerTypeIndex = customerTypeOptions.find(
      (item) => item.id === currentCustomerType,
    );
    const dataAccessId = findDataAccessId(currentDataAccessAndProcessing);
    const dataAccessAndProcessing = dataAccessOptions.find(
      (item) => item.id === dataAccessId,
    );

    setUseCase(teamInfo.UseCase);
    setTeamEmail(teamInfo.DistributionList);
    setTeamWiki(teamInfo.Wiki);
    setGroupingMechanismId(activeGroupInfo?.groupingMechanismId);
    setOptionLabel(dataAccessAndProcessing.label);
    setDataAccessAndProcessingOption(dataAccessAndProcessing);
    setCategory(
      activeGroupInfo.primaryCTI == undefined
        ? ''
        : activeGroupInfo.primaryCTI.category,
    );
    setType(
      activeGroupInfo.primaryCTI == undefined
        ? ''
        : activeGroupInfo.primaryCTI.type,
    );
    setItem(
      activeGroupInfo.primaryCTI == undefined
        ? ''
        : activeGroupInfo.primaryCTI.item,
    );

    setDataAccessAndProcessing(teamInfo.DataAccessAndProcessing);
    setCustomerTypeOption(customerTypeIndex);
    setCustomerType(customerTypeIndex.id);
  };

  const findDataAccessId = (dataAccessAndProcessing) => {
    const option = dataAccessOptions.filter(
      (item) => item.label === dataAccessAndProcessing,
    );

    return option.length !== 0
      ? option[0].id
      : dataAccessOptions.filter((item) => item.label === 'Other')[0].id;
  };

  const handleEditGroup = async () => {
    setButtonLoading(true);
    const validated = await validateEditGroupRequest();
    if (!validated) {
      setButtonLoading(false);
      return;
    }
    try {
      await submitEditGroupRequest();
      setEditGroupModalVisible(false);
      setNotification(
        '',
        'The request was submitted successfully. Data Lake oncall has been notified and will process it within a business day.',
      );
    } catch (e) {
      setEditGroupModalVisible(false);
      let errorMessage =
        'Please contact Data Lake oncall to help you with your request';
      setNotification('Failed to submit your request', errorMessage);
    }
    setButtonLoading(false);
  };

  const getButtonList = () => {
    let buttonsList = [];
    const refreshButton = {
      text: '',
      icon: 'refresh',
      onItemClick: handleRefresh,
    };
    buttonsList.push(refreshButton);
    return buttonsList;
  };

  const setNotification = (header, message) => {
    if (header === '') {
      setNotifications([
        {
          type: 'success',
          content: message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    } else {
      setNotifications([
        {
          header: header,
          type: 'error',
          content: message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }
  };

  const formatCTI = () => {
    if (
      activeGroupInfo?.primaryCTI == undefined ||
      activeGroupInfo?.primaryCTI.category == undefined ||
      activeGroupInfo?.primaryCTI.type == undefined ||
      activeGroupInfo?.primaryCTI.item == undefined
    ) {
      return 'N/A';
    }
    return (
      activeGroupInfo?.primaryCTI.category +
      '/' +
      activeGroupInfo?.primaryCTI.type +
      '/' +
      activeGroupInfo?.primaryCTI.item
    );
  };

  const {
    items,
    collectionProps,
    paginationProps,
    filterProps,
    filteredItemsCount,
  } = useCollection(groupMembers, {
    filtering: {
      noMatch: '',
      empty: (
        <div className='awsui-util-t-c'>
          <div className='awsui-util-pt-s awsui-util-mb-xs'>
            <b>{tableMessage}</b>
          </div>
          <p className='awsui-util-mb-s'>No group members to display.</p>
        </div>
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties: [],
    },
  });

  const dataAccessOptions = [
    {
      label: 'Amazon EMR',
      id: '1',
    },
    {
      label: 'Amazon Redshift',
      id: '2',
    },
    {
      label: 'Amazon Redshift Spectrum',
      id: '3',
    },
    {
      label: 'Other',
      id: '4',
    },
  ];

  const customerTypeOptions = [
    {
      label: "I'm a Data Consumer",
      id: 'DATACONSUMER',
    },
    {
      label: "I'm a Data Provider",
      id: 'DATAPROVIDER',
    },
    {
      label: 'Both',
      id: 'DATAPROVIDER_AND_DATACONSUMER',
    },
  ];

  return (
    <div>
      <div id='flash-bar'>
        <Flashbar items={notifications} />
      </div>
      <PageHeader buttons={[]} header={<h1>{groupId}</h1>} />

      <SpaceBetween size='l'>
        <Container
          header={
            <Header
              variant='h2'
              actions={
                isOwner && (
                  <Button
                    variant='normal'
                    onClick={() => {
                      setEditGroupModalVisible(true);
                    }}
                  >
                    {' '}
                    Edit{' '}
                  </Button>
                )
              }
            >
              {' '}
              Group details{' '}
            </Header>
          }
        >
          <ColumnLayout columns={3} borders='horizontal'>
            <CopiableText
              name='Team name'
              value={activeGroupInfo?.teamInfo.Name}
            />
            <CopiableText
              name='Team email'
              value={activeGroupInfo?.teamInfo.DistributionList}
            />
            {activeGroupInfo?.groupingMechanismId != null ? (
              <CopiableText
                name={`Grouping ID (${getGroupingMechanismType(
                  activeGroupInfo.groupingMechanismId,
                )})`}
                value={activeGroupInfo.groupingMechanismId}
              />
            ) : (
              <div>
                <div className='awsui-util-label'>Grouping ID</div>
                <div> Please enter a Posix or Teams group ID </div>
              </div>
            )}
            <div>
              <div className='awsui-util-label'>Team wiki</div>
              {getWikiLink(activeGroupInfo?.teamInfo.Wiki)}
            </div>
            <CopiableText
              name='Customer type'
              value={
                readableCustomerTypeMap[activeGroupInfo?.teamInfo.CustomerType]
              }
            />
            <CopiableText
              name='Data access & processing'
              value={activeGroupInfo?.teamInfo.DataAccessAndProcessing}
            />

            <CopiableText
              name='Last updated date'
              value={activeGroupInfo?.updatedDate}
            />
            <div>
              <div className='awsui-util-label'>Use case</div>
              <div> {activeGroupInfo?.teamInfo.UseCase}</div>
            </div>
            <div>
              <div className='awsui-util-label'>Existing use case</div>
              <div>{activeGroupInfo?.teamInfo.ExistingUseCase}</div>
            </div>
            <CopiableText name='CTI' value={formatCTI()} />
          </ColumnLayout>
        </Container>

        <Table
          {...collectionProps}
          loadingText='Loading group members...'
          loading={loadingGroupMembers}
          columnDefinitions={columnDefinitions}
          items={items}
          wrapLines={false}
          resizableColumns={true}
          header={
            <>
              <PageHeader
                buttons={getButtonList()}
                header={
                  <>
                    Group members
                    <span className='awsui-util-header-counter'>
                      {` (${groupMembers.length})`}
                    </span>
                  </>
                }
              />
            </>
          }
          pagination={
            <Pagination {...paginationProps} ariaLabels={paginationLabels} />
          }
          filter={
            <TextFilter
              {...filterProps}
              filteringAriaLabel='Filter resources'
              filteringPlaceholder='Find resources'
              countText={`${filteredItemsCount} ${
                filteredItemsCount === 1 ? 'match' : 'matches'
              }`}
            />
          }
          preferences={
            <CollectionPreferences
              title={'Preferences'}
              confirmLabel={'Confirm'}
              cancelLabel={'Cancel'}
              preferences={preferences}
              onConfirm={({ detail }) => setPreferences(detail)}
              pageSizePreference={{
                title: 'Page size',
                options: [
                  { value: 2, label: '2 items' },
                  { value: 4, label: '4 items' },
                  { value: 6, label: '6 items' },
                ],
              }}
              wrapLinesPreference={defaultWrapLinesPreference}
            />
          }
        />

        <Modal
          onDismiss={() => {
            setEditGroupModalVisible(false);
          }}
          visible={editGroupModalVisible}
          size='medium'
          footer={
            <Box float='right'>
              <SpaceBetween direction='horizontal' size='xs'>
                <Button
                  variant='link'
                  onClick={() => {
                    setEditGroupModalVisible(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant='primary'
                  onClick={handleEditGroup}
                  loading={buttonLoading}
                >
                  {buttonText}
                </Button>
              </SpaceBetween>
            </Box>
          }
          header='Edit details'
        >
          <ColumnLayout>
            <FormField
              label='Customer type'
              description='What type of customer do you plan on being?'
              errorText={customerTypeValidation ? customerTypeErrorText : false}
            >
              <Select
                selectedOption={customerTypeOption}
                options={customerTypeOptions}
                selectedAriaLabel='Selected'
                onChange={(e) => {
                  setCustomerTypeOptionLabel(e.detail.selectedOption.label);
                  setCustomerTypeOption(e.detail.selectedOption);
                  setCustomerType(e.detail.selectedOption.value);
                  setCustomerTypeValidation(false);
                }}
              />
            </FormField>
            <FormField
              label={'Grouping ID'}
              description='Please enter either a POSIX group or a Teams ID. This ID will be used to determine your group members.'
              errorText={
                teamGroupingMechanismIdValidation
                  ? teamGroupingMechanismIdErrorText
                  : false
              }
            >
              <Input
                ariaRequired={false}
                value={groupingMechanismId}
                onChange={(e) => {
                  setGroupingMechanismId(e.detail.value);
                  setTeamGroupingMechanismIdValidation(false);
                }}
              />
            </FormField>
            <FormField
              label='Team wiki'
              errorText={teamWikiValidation ? teamWikiErrorText : false}
            >
              <Input
                ariaRequired={true}
                value={teamWiki}
                onChange={(e) => setTeamWiki(e.detail.value)}
              />
            </FormField>

            <FormField
              label='Team email'
              errorText={teamEmailValidation ? teamEmailErrorText : false}
            >
              <Input
                ariaRequired={false}
                value={teamEmail}
                onChange={(e) => {
                  setTeamEmail(e.detail.value);
                  setTeamEmailValidation(false);
                }}
              />
            </FormField>

            <FormField
              label='Category (C)'
              errorText={categoryValidation ? 'Category not found' : false}
            >
              <Input
                ariaRequired={false}
                value={category}
                onChange={(e) => {
                  setCategory(e.detail.value);
                  setCategoryValidation(false);
                }}
              />
            </FormField>

            <FormField
              label='Type (T)'
              errorText={typeValidation ? 'Type not found' : false}
            >
              <Input
                ariaRequired={false}
                value={type}
                onChange={(e) => {
                  setType(e.detail.value);
                  setTypeValidation(false);
                }}
              />
            </FormField>

            <FormField
              label='Item (I)'
              errorText={itemValidation ? 'Item not found' : false}
            >
              <Input
                ariaRequired={false}
                value={item}
                onChange={(e) => {
                  setItem(e.detail.value);
                  setItemValidation(false);
                }}
              />
            </FormField>

            <FormField
              label='Data access and processing'
              description='How are you looking to access and process the data?'
              errorText={
                dataAccessAndProcessingValidation
                  ? dataAccessAndProcessingErrorText
                  : false
              }
            >
              <Select
                options={dataAccessOptions}
                selectedOption={dataAccessAndProcessingOption}
                selectedAriaLabel='Selected'
                onChange={(e) => {
                  setOptionLabel(e.detail.selectedOption.label);
                  setDataAccessAndProcessingOption(e.detail.selectedOption);
                  setDataAccessAndProcessing('');
                  setDataAccessAndProcessingValidation(false);
                  if (optionLabel != 'Other') {
                    setDataAccessAndProcessing(e.detail.selectedOption.label);
                  }
                }}
              />
            </FormField>
            {optionLabel === 'Other' && (
              <>
                <FormField
                  description='Explain your way of data access and processing.'
                  errorText={
                    dataAccessAndProcessingValidation
                      ? dataAccessAndProcessingErrorText
                      : false
                  }
                >
                  <Textarea
                    ariaRequired={true}
                    value={dataAccessAndProcessing}
                    onChange={(e) => {
                      setDataAccessAndProcessing(e.detail.value);
                      setDataAccessAndProcessingValidation(false);
                    }}
                  />
                </FormField>
              </>
            )}

            <FormField
              label='Use case'
              description='Explain your use case and the business problem that it is solving.'
              errorText={useCaseValidation ? 'Use case not found' : false}
            >
              <Textarea
                ariaRequired={false}
                placeholder={'Use case'}
                value={useCase}
                onChange={(e) => {
                  setUseCase(e.detail.value);
                  setUseCaseValidation(false);
                }}
              />
            </FormField>
          </ColumnLayout>
        </Modal>
      </SpaceBetween>
    </div>
  );
};

export default MyGroups;
