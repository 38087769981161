import awsdatalakepublisherlambda from 'aws-sdk/clients/awsdatalakepublisherlambda';
import Types from 'aws-sdk/clients/awsdatalakepublisherlambda';
import basicCallWrapper from './client_call';
import { getPublisherConfig } from './config';
import { getCredentialProvider } from './auth';
import { SelectProps } from '@amzn/awsui-components-react-v3';


const config = getPublisherConfig();
const credentials = getCredentialProvider();

const publisherClientConfig: awsdatalakepublisherlambda.ClientConfiguration = {
  region: config.region,
  endpoint: config.endpoint,
  credentials: credentials,
};

const publisher = new awsdatalakepublisherlambda(publisherClientConfig);

export const getPipelineInfo = (input: Types.GetPipelineInfoRequest) => {
  return basicCallWrapper<
    Types.GetPipelineInfoRequest,
    Types.GetPipelineInfoResponse
  >(
    publisher,
    publisher.getPipelineInfo,
  )(input);
};

export const getTableInfo = (input: Types.GetTableInfoRequest) => {
  return basicCallWrapper<
    Types.GetTableInfoRequest,
    Types.GetTableInfoResponse
  >(
    publisher,
    publisher.getTableInfo,
  )(input);
};

export const initialDump = (input: Types.PostRefreshInfoRequest) => {
  return basicCallWrapper<
    Types.PostRefreshInfoRequest,
    Types.PostRefreshInfoResponse
  >(
    publisher,
    publisher.postRefreshInfo,
  )(input);
};

export const getAccounts = (input: Types.GetAccountsRequest) => {
  return basicCallWrapper<Types.GetAccountsRequest, Types.GetAccountsResponse>(
    publisher,
    publisher.getAccounts,
  )(input);
};

export const createAccount = (input: Types.PostAccountsRequest) => {
  return basicCallWrapper<
    Types.PostAccountsRequest,
    Types.PostAccountsResponse
  >(
    publisher,
    publisher.postAccounts,
  )(input);
};

export const createTable = (input: Types.PostTableInfoRequest) => {
  return basicCallWrapper<
    Types.PostTableInfoRequest,
    Types.PostTableInfoResponse
  >(
    publisher,
    publisher.postTableInfo,
  )(input);
};

export const registerTable = (input: Types.PostResourceInfoRequest) => {
  return basicCallWrapper<
    Types.PostResourceInfoRequest,
    Types.PostResourceInfoResponse
  >(
    publisher,
    publisher.postResourceInfo,
  )(input);
};

export const dedupe = (input: Types.PostDedupeInfoRequest) => {
  return basicCallWrapper<
    Types.PostDedupeInfoRequest,
    Types.PostDedupeInfoResponse
  >(
    publisher,
    publisher.postDedupeInfo,
  )(input);
};

export const describeResource = (input: Types.GetResourceInfoRequest) => {
  return basicCallWrapper<
    Types.GetResourceInfoRequest,
    Types.GetResourceInfoResponse
  >(
    publisher,
    publisher.getResourceInfo,
  )(input);
};

export const getDedupeInfo = (input: Types.GetDedupeInfoRequest) => {
  return basicCallWrapper<
    Types.GetDedupeInfoRequest,
    Types.GetDedupeInfoResponse
  >(
    publisher,
    publisher.getDedupeInfo,
  )(input);
};

export const listResources = (input: Types.PostResourcesRequest) => {
  return basicCallWrapper<
    Types.PostResourcesRequest,
    Types.PostResourcesResponse
  >(
    publisher,
    publisher.postResources,
  )(input);
};

export const getBackFillInfo = (input: Types.GetBackFillInfoRequest) => {
  return basicCallWrapper<
    Types.GetBackFillInfoRequest,
    Types.GetBackFillInfoResponse
  >(
    publisher,
    publisher.getBackFillInfo,
  )(input);
};

export const postBackFillInfo = (input: Types.PostBackFillInfoRequest) => {
  return basicCallWrapper<
    Types.PostBackFillInfoRequest,
    Types.PostBackFillInfoResponse
  >(
    publisher,
    publisher.postBackFillInfo,
  )(input);
};

export const postTableInfo = (input: Types.PostTableInfoRequest) => {
  return basicCallWrapper<
    Types.PostTableInfoRequest,
    Types.PostTableInfoResponse
  >(
    publisher,
    publisher.postTableInfo,
  )(input);
};

export const convertResourcesToOptions = (
  resources: Types.PostResourcesResponse,
): SelectProps.Option[] => {
  const options: SelectProps.Option[] = resources['Resources'][
    'PhysicalTableNames'
  ].map((name: string) => {
    return {
      value: name,
      label: name,
    };
  });
  return options;
};

export const convertDedupeColumnInfoToOptions = (
  resources: Types.GetDedupeInfoResponse,
): SelectProps.Option[] => {
  const options: SelectProps.Option[] = resources['Columns'].map(
    (definition: Types.AttributeDefinition) => {
      console.log(definition);
      return {
        value: definition.AttributeName,
        label: definition.AttributeName,
        description: toTypeName(definition.AttributeType),
      };
    },
  );
  return options;
};

export const parseColumns = (columnMapping) => {
  console.log('Column Mapping', columnMapping);
  const columnItems = [];
  if (columnMapping != undefined) {
    const columnArray = JSON.parse(columnMapping);
    for (let i = 0; i < columnArray.length; i++) {
      console.log('Column Array at index: ', i, ' is: ', columnArray[i]);
      for (let key in columnArray[i]) {
        console.log(columnArray[i][key].mapped_column);
        columnItems.push({
          actualColumn: key,
          mappedColumn: columnArray[i][key].mapped_column,
          type: columnArray[i][key].type,
          keyType: columnArray[i][key].index,
        });
      }
    }

    console.log('Parsed column items', columnItems);
    return columnItems;
  }
};
const toTypeName = (type) => {
  switch (type) {
    case 'S':
      return 'String';
    case 'BOOL':
      return 'Boolean';
    case 'B':
      return 'Binary';
    case 'N':
      return 'Number';
    case 'SS':
      return 'String Set';
    case 'NS':
      return 'Number Set';
    case 'BS':
      return 'Binary Set';
    default:
      return '';
  }
};

export { Types } from 'aws-sdk/clients/awsdatalakepublisherlambda';
