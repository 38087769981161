import React, { Component } from 'react';
import ResourceSelector from './resourceSelector/resourceSelectorComponent';
import RecipientSelector from './recipientSelector/recipientSelectorComponent';
import PermissionSelector from './permissionSelector/permissionSelectorComponent';
import { TablePermissions } from './permissionSelector/tablePermissionsFormFields';
import UseCase from './useCase/useCaseComponent';
import { DataLakePrincipal, Resource } from 'aws-sdk/clients/lakeformation';
import { Database } from 'aws-sdk/clients/glue';
import {
  Flashbar,
  Form,
  Button,
  Header,
  SelectProps,
  SpaceBetween,
  FlashbarProps,
} from '@amzn/awsui-components-react-v3';
import { Redirect } from 'react-router-dom';
import { scrollUp } from '../../utils/navigation';
import * as validate from '../../../commons/validationUtils';
import { createLakeFormationPermission } from '../../../api/permissions';
import {
  createLakeFormationPermissionsPayload,
  capitalize,
} from './tablePermissionsPage.utils';
import { HybridCatalogDataSet } from 'aws-sdk/clients/awsdlhybridcatalogservicelambda';
import { Page } from '../../../routes/Paths';

export interface CreateLakeFormationPermissionComponentProps {
  setContentType: any;
  location: any;
  activeGroup: string;
  permissionType: 'request' | 'revoke';
}

export interface CreateLakeFormationPermissionComponentState {
  notifications: FlashbarProps.MessageDefinition[];
  loading: any;
  redirect: string;
  principal: DataLakePrincipal;
  ramRole: any;
  database: Database;
  databaseName: string;
  table: HybridCatalogDataSet;
  tableName: string;
  tableInfo: SelectProps.Option;
  permissions: TablePermissions;
  resource: Resource;
  useCase: string;
  buttonLoading: boolean;
  catalogId: string;
}

const defaultPermissions = {
  tablePermissions: ['SELECT'],
  grantablePermissions: ['SELECT'],
};
export default class CreateLakeFormationPermissionComponent extends Component<
  CreateLakeFormationPermissionComponentProps,
  CreateLakeFormationPermissionComponentState
> {
  state = {
    notifications: [],
    loading: false,
    redirect: undefined,
    database: undefined,
    databaseName: undefined,
    table: undefined,
    tableName: undefined,
    tableInfo: undefined,
    permissions: defaultPermissions,
    resource: undefined,
    principal: undefined,
    ramRole: undefined,
    groupRequestedBy: this.props.activeGroup,
    groupRequestedTo: undefined,
    dataLocationArn: undefined,
    dataLocationRegion: undefined,
    useCase: undefined,
    buttonLoading: false,
    catalogId: undefined,
  };

  componentDidMount = async () => {
    // if we were redirected here from the catalog page,
    // replace some state values with the props passed in
    if (this.props.location.state) {
      this.setState({
        tableInfo: this.props.location.state.tableInfo,
        tableName: this.props.location.state.tableName,
        databaseName: this.props.location.state.databaseName,
        catalogId: this.props.location.state.catalogId,
      });
    }
    this.props.setContentType('form');
  };

  handleConfirm = async () => {
    this.setState({ loading: true, buttonLoading: true });
    const lakeFormationPermissionsPayload =
      createLakeFormationPermissionsPayload(
        this.state.groupRequestedBy,
        this.state.resource,
        this.state.principal,
        this.state.ramRole,
        this.state.permissions,
        this.state.table,
        this.state.useCase,
      );
    try {
      const response = await createLakeFormationPermission(
        lakeFormationPermissionsPayload,
      );
      this.setState({
        notifications: [
          {
            type: 'success',
            content: `Lake Formation request '${response.requestId}' created.`,
            dismissible: true,
            buttonText: 'View requests',
            onButtonClick: () =>
              this.setState({
                redirect: Page.REQUESTS,
              }),
            onDismiss: () => this.setState({ notifications: [] }),
          },
        ],
      });
    } catch (err) {
      this.setState({
        notifications: [
          {
            type: 'error',
            content: `There was an error creating your Lake Formation request: '${err.message}'`,
            dismissible: true,
            onDismiss: () => this.setState({ notifications: [] }),
          },
        ],
      });
    }
    this.setState({ loading: false });
    scrollUp();
  };

  allFieldsSet() {
    return (
      // Validate Objects
      validate.isNotEmpty(this.state.table) &&
      validate.isNotEmpty(this.state.database) &&
      validate.isNotEmpty(this.state.principal) &&
      validate.isNotEmpty(this.state.ramRole) &&
      validate.isNotEmpty(this.state.permissions) &&
      // Validate Request information
      validate.isValidGroup(this.state.groupRequestedBy) &&
      (validate.isValidGroup(this.state.table.Owners[0]) ||
        validate.isValidGroup(this.state.database.Owners[0])) &&
      validate.isValidRole(this.state.table.LakeFormationRoleARN) &&
      (validate.isNotEmpty(this.state.table.IdInfo.Region) ||
        validate.isNotEmpty(this.state.database.IdInfo.Region)) &&
      validate.isValidAccount(
        this.state.principal.DataLakePrincipalIdentifier,
      ) &&
      validate.isNotEmpty(this.state?.permissions?.tablePermissions?.length) &&
      validate.isNotEmpty(this.state?.permissions?.grantablePermissions?.length)
    );
  }

  setPrincipal = (value) => {
    this.setState({ principal: value });
  };
  setRamRole = (value) => {
    this.setState({ ramRole: value });
  };
  setTable = (table: HybridCatalogDataSet | undefined) => {
    this.setState({ table });
  };
  setDatabase = (database: Database | undefined) => {
    this.setState({ database });
    if (database) {
      this.setState({ catalogId: database['CatalogId'] });
    }
  };
  setResource = (resource: Resource | undefined) => {
    this.setState({ resource, permissions: defaultPermissions });
  };
  setPermissions = (permissions: TablePermissions | undefined) => {
    this.setState({ permissions });
  };
  setUseCase = (value) => {
    this.setState({ useCase: value });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }
    return (
      <div>
        <div style={{ width: '760px' }}>
          <Flashbar items={this.state.notifications} />
          <Form
            header={
              <Header
                variant='h1'
                description={`Choose the access permissions to ${this.props.permissionType.toLowerCase()} to this or an external account.`}
              >
                {`${capitalize(this.props.permissionType)} permissions`}
              </Header>
            }
            actions={
              <div>
                <Button
                  variant='link'
                  onClick={() => this.setState({ redirect: Page.MY_DATASETS })}
                >
                  Cancel
                </Button>
                <Button
                  variant='primary'
                  onClick={this.handleConfirm}
                  loading={this.state.loading}
                  disabled={!this.allFieldsSet()}
                >
                  {capitalize(this.props.permissionType)}
                </Button>
              </div>
            }
          >
            <SpaceBetween size='l'>
              <ResourceSelector
                onTableChange={this.setTable}
                onDatabaseChange={this.setDatabase}
                databaseName={this.state.databaseName}
                tableName={this.state.tableName}
                catalogId={this.state.catalogId}
                activeGroup={this.props.activeGroup}
              />
              <RecipientSelector
                activeGroup={this.props.activeGroup}
                onChange={this.setPrincipal}
                onRamRoleChange={this.setRamRole}
              />
              <PermissionSelector
                table={this.state.table}
                database={this.state.database}
                onChange={this.setResource}
                permissions={this.state.permissions}
                onPermissionsChange={this.setPermissions}
              />
              <UseCase onChange={this.setUseCase} value={this.state.useCase} />
            </SpaceBetween>
          </Form>
        </div>
      </div>
    );
  }
}
