import * as React from 'react';
import {
  ButtonDropdownProps,
} from '@amzn/awsui-components-react-v3';
import TopNavigation from "@amzn/awsui-components-react-v3/polaris/top-navigation/1.0-beta";

import {WEBSITE_NAME} from '../../commons/constants';

import {Link, Redirect} from "react-router-dom";
import {Page} from "src/routes";
import {useEffect, useState} from "react";
import {updateNotificationBatch, updateNotifications} from "src/api/notifications";

export interface HeaderProps {
  user: string;
  groups: Array<ButtonDropdownProps.Item>;
  setGroup: any;
  groupInfo: any;
  activeGroup: string;
  loadingGroup: boolean;
  cartItems: object[];
  unreadNotifications: any;
  unreadNotificationBatches: any;
  removeUnreadNotificationId: any;
  removeUnreadNotificationBatchId: any;
}


const toGroupItems = (groups) => {
  const items: Array<ButtonDropdownProps.Item> = groups.map((item: string) => {
    return { text: item, id: item };
  });
  return items;
};

let cartIconWhite = require('./cart_white.png');
let cartIconOrange = require('./cart_orange.png');

export const PageHeader = (props: HeaderProps) => {
  const [allItems, setItems] = useState(props.unreadNotifications);
  const [redirect, setRedirect] = useState(undefined);

  const onGroupChange = (e) => {
    props.setGroup(e.detail.id);
    setItems([]);
  };

  useEffect(() => {
    if (props.removeUnreadNotificationId !== undefined && allItems != null) {
      setItems(allItems.filter((item) => (item.id != props.removeUnreadNotificationId)));
    }
  }, [props.removeUnreadNotificationId])

  useEffect(() => {
    if (props.removeUnreadNotificationBatchId !== undefined && allItems != null) {
      setItems(allItems.filter((item) => (item.id != props.removeUnreadNotificationBatchId)));
    }
  }, [props.removeUnreadNotificationBatchId])

  useEffect(() => {
    if (props.unreadNotifications !== undefined) {
      transferNotificationToItems(props.unreadNotifications);
    }
  }, [props.unreadNotifications]);

  useEffect(
    () => setRedirect(undefined),
    [redirect]
  )

  const handleClick = async (e) => {
    if (e.detail.id == 'markRead') {
      let notificationIdList = [];
      for (let item of allItems) {
        if (item['id'].startsWith('notif-')) {
          notificationIdList.push(item['id']);
        } else if (item['id'].startsWith('notifbatch-')) {
          await updateNotificationBatch({
            NotificationBatchId: item['id'],
            MarkComment: false,
            GroupId: props.activeGroup,
          })
        }
      }
      await updateNotifications({
        GroupId: props.activeGroup,
        NotificationIdList: notificationIdList,
        MarkRead: true,
      });
      setItems([]);
    } else if (e.detail.id == 'viewAll') {
      setRedirect(Page.NOTIFICATIONS);
    } else if (e.detail.id == 'configuration'){
      setRedirect(Page.NOTIFICATION_CONFIGURATION);
    } else if (e.detail.id == 'create'){
      setRedirect(Page.CREATE_NOTIFICATION);
    } else if (e.detail.id.startsWith('notif-')) {
      let notificationId = e.detail.id;
      setItems(allItems.filter((item) => (item.id != notificationId)))
      setRedirect(`/notifications/`+notificationId);
    } else if (e.detail.id.startsWith('notifbatch-')) {
      let notificationBatchId = e.detail.id;
      setItems(allItems.filter((item) => (item.id != notificationBatchId)))
      setRedirect(`/notification-batch/`+notificationBatchId);
    }
  }

  const transferNotificationToItems = async (items) => {
    if (items.length == 0) {
      return [];
    }
    let notImportantNotificationList = []
    let importnatNotificationList = []
    let commentsReview = []
    for (let notification of items) {
      if (notification['PriorityLevel'] != null && notification['PriorityLevel'] == 'HIGH') {
        importnatNotificationList.push({
          id: notification['NotificationId'],
          text: <Link to = {`/notifications/${notification['NotificationId']}`}>{notification['Subject']}</Link>,
          iconName: 'status-warning'
        })
      } else if (notification['NotificationId'] != null){
        notImportantNotificationList.push({
          id: notification['NotificationId'],
          text: <Link to = {`/notifications/${notification['NotificationId']}`}>{notification['Subject']}</Link>,
        })
      }
    }
    for (let notificationBatch of props.unreadNotificationBatches) {
      if (notificationBatch['ReviewerList'] != null && notificationBatch['ReviewerList'][props.user] != null) {
        commentsReview.push({
          id: notificationBatch['NotificationBatchId'],
          text: <Link
            to={`/notification-batch/${notificationBatch['NotificationBatchId']}`}>{notificationBatch['Subject']}</Link>,
        })
      }
    }
    importnatNotificationList.push(...notImportantNotificationList)
    importnatNotificationList.push(...commentsReview)
    setItems(importnatNotificationList)
  }

  let phoneTool = `https://phonetool.amazon.com/users/${props.user}`;

  if (redirect) {
    return (
      <Redirect push to={redirect}/>
    )
  }

  return (
    <TopNavigation
      className={'awsui-button-icon'}
      identity={{
        href: "/",
        title: WEBSITE_NAME,
      }}
      utilities={[
        {
          type: "button",
          text: '' + ((props.cartItems == undefined || props.cartItems.length == undefined ) ? 0 : props.cartItems.length),
          iconUrl: (props.cartItems !== undefined && props.cartItems.length == 0) ? cartIconWhite : cartIconOrange,
          onClick: () => setRedirect(Page.CHECKOUT),
        },
        {
          type: "menu-dropdown",
          iconName: "notification",
          ariaLabel: "Notifications",
          title: (allItems !== null && allItems !== undefined && allItems.length !== null && allItems.length !== undefined) ? allItems.length + " Unread Notifications" : "Loading...",
          badge: allItems !== null && allItems !== undefined && allItems.length !== null && allItems.length !== undefined && allItems.length > 0,
          onItemClick: (e) => handleClick(e),
          items: [
            {id: "markRead", text: "Mark all notifications as read"},
            {
              id: 'unreadItems',
              text: "Unread notifications",
              items: allItems,
            },
            {
              id: 'configuration',
              text: 'Notification configuration',
              iconName: 'settings'
            },
            {
              id: 'create',
              text: 'Create new notifications',
              iconName: 'add-plus'
            },
            {
              id: "viewAll",
              text: 'View all notifications',
              iconName: 'menu'
            }
          ]
        },
        {
          type: "menu-dropdown",
          text: props.activeGroup,
          onItemClick: (e) => {onGroupChange(e)},
          items: toGroupItems(props.groups),
        },
        {
          type: "button",
          iconName: "user-profile",
          text: props.user,
          href: phoneTool,
        }
      ]}
      i18nStrings={{
        overflowMenuTriggerText: "More"
      }}
    />
  );
}

