import * as React from 'react';
import {
  Button,
  Flashbar,
  Form,
  FormField,
  Container,
  Input,
  Header,
  FlashbarProps,
  SpaceBetween,
} from '@amzn/awsui-components-react-v3';

import { Redirect } from 'react-router-dom';
import {
  getPipelineInfo,
  postBackFillInfo,
  postTableInfo,
} from '../../../src/api/publisher';
import * as validate from '../../commons/validationUtils';
import { any } from 'prop-types';
import { createPublisherTableLink } from '../../routes/Paths';

export interface CreateBackfillProps {
  setContentType: any;
  activeGroup: string;
  match;
}

export interface CreateBackfillState {
  deltaFiles;
  rawGlueTable;
  processedGlueTable;
  previousPartition;
  currentPartition;
  redirect;
  notifications: FlashbarProps.MessageDefinition[];
  buttonLoading: boolean;
  cancelRedirect;
  pipelineInfo: object;
  lastDeltaProcessed;
}

export default class CreateBackfillComponent extends React.Component<
  CreateBackfillProps,
  CreateBackfillState
> {
  state = {
    deltaFiles: '',
    rawGlueTable: '',
    processedGlueTable: '',
    previousPartition: '',
    currentPartition: '',
    notifications: [],
    buttonLoading: false,
    redirect: undefined,
    cancelRedirect: undefined,
    pipelineInfo: any,
    lastDeltaProcessed: '',
  };

  componentDidMount = async () => {
    this.props.setContentType('form');
    const pipelineInfoResponse = await getPipelineInfo({
      GroupId: this.props.activeGroup,
      TableId: this.props.match.params.TableId,
    });
    const getTableDetails = await postTableInfo({
      GroupId: this.props.activeGroup,
      TableId: this.props.match.params.TableId,
    });
    console.log(
      'CreateBackfill page:: Pipeline information: ',
      pipelineInfoResponse.Pipelines,
    );
    this.setState({
      previousPartition: pipelineInfoResponse.Pipelines.PreviousPartition,
      lastDeltaProcessed: getTableDetails.Tables.LastDeltaProcessed,
      cancelRedirect: createPublisherTableLink(
        this.props.match.params.TableId,
        this.props.match.params.TableName,
      ),
    });
  };

  createBackfillJob = async () => {
    const postBackFillInfoRequest = {
      DeltaFiles: this.state.deltaFiles,
      TableId: this.props.match.params.TableId,
      PreviousPartition: this.state.previousPartition,
      GroupId: this.props.activeGroup,
    };
    console.log(
      'CreateBackfill page:: PostBackFillInfoRequest',
      postBackFillInfoRequest,
    );
    let postBackFillInfoResponse: any;
    try {
      postBackFillInfoResponse = await postBackFillInfo(
        postBackFillInfoRequest,
      );
      const status =
        postBackFillInfoResponse.Status == 'Success' ? 'success' : 'error';
      const message =
        postBackFillInfoResponse.Status == 'Success' ? 'Success!' : 'Failed.';
      this.setState({
        notifications: [
          {
            type: status,
            content: ` ${message} ${postBackFillInfoResponse.Message} `,
            dismissible: true,
            onDismiss: () => this.setState({ notifications: [] }),
          },
        ],
      });
    } catch (err) {
      this.setState({
        notifications: [
          {
            type: 'error',
            content: postBackFillInfoResponse.Message,
            dismissible: true,
            onDismiss: () => this.setState({ notifications: [] }),
          },
        ],
      });
      return;
    }
  };

  handleConfirm = async () => {
    this.setState({ buttonLoading: true });
    await this.createBackfillJob();
    this.setState({ buttonLoading: false });
  };

  public areAllFieldsSet() {
    let val: boolean;
    console.log('deltaFiles: ', this.state.deltaFiles);
    console.log('previousPartition: ', this.state.previousPartition);
    val = validate.isNotEmpty(this.state.previousPartition);
    console.log('Are all fields set? ', val);
    return val;
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }
    return (
      <div>
        <Flashbar items={this.state.notifications} />
        <Form
          header={
            <Header
              variant={'h1'}
              description={'You can submit a backfill execution'}
            >
              Submit backfill job
            </Header>
          }
          actions={
            <SpaceBetween size='s' direction='horizontal'>
              <Button
                variant='link'
                onClick={() =>
                  this.setState({ redirect: this.state.cancelRedirect })
                }
              >
                Cancel
              </Button>
              <Button
                variant='primary'
                onClick={this.handleConfirm}
                loading={this.state.buttonLoading}
                disabled={!this.areAllFieldsSet()}
              >
                Submit
              </Button>
            </SpaceBetween>
          }
        >
          <Container
            className='custom-screenshot-hide'
            header={<h2>Backfill job</h2>}
          >
            <SpaceBetween size='m'>
              <FormField
                label={<div>Previous Partition</div>}
                description='Previous snapshot location of the table (eg: "yyyy/mm/dd/hh/")'
              >
                <Input
                  name='PreviousPartition'
                  placeholder={this.state.previousPartition}
                  value={this.state.previousPartition}
                  ariaRequired={true}
                  onChange={(e) =>
                    this.setState({ previousPartition: e.detail.value })
                  }
                />
              </FormField>

              <FormField
                label={<div>Delta Files(Optional)</div>}
                description='Start index of the file for which the backfill process has to begin (eg: "yyyy/mm/dd/hh/")'
              >
                <Input
                  name='DeltaFiles'
                  value={this.state.lastDeltaProcessed}
                  onChange={(e) => {
                    console.log(e.detail.value);
                    this.setState({
                      lastDeltaProcessed: e.detail.value,
                      deltaFiles: e.detail.value,
                    });
                  }}
                />
              </FormField>
            </SpaceBetween>
          </Container>
        </Form>
      </div>
    );
  }
}
