import awsdlomni, {
  GetNotificationsRequest,
  GetNotificationsResponse,
  CreateNotificationRequest,
  CreateNotificationResponse,
  UpdateNotificationsRequest,
  UpdateNotificationsResponse,
  DeleteNotificationsRequest,
  DeleteNotificationsResponse,
  GetNotificationTemplatesResponse,
  GetNotificationTemplatesRequest,
  NotificationTemplateEntry,
  GetNotificationCommentsRequest,
  GetNotificationCommentsResponse,
  GetNotificationTemplateRequest,
  GetNotificationTemplateResponse,
  CreateNotificationTemplateRequest,
  CreateNotificationTemplateResponse,
  CreateNotificationCommentRequest,
  CreateNotificationCommentResponse,
  UpdateNotificationTemplateRequest,
  UpdateNotificationTemplateResponse,
  DeleteNotificationTemplateRequest,
  DeleteNotificationTemplateResponse,
  GetNotificationResponse,
  GetNotificationRequest,
  UpdateNotificationBatchRequest,
  UpdateNotificationBatchResponse,
  GetNotificationBatchesRequest,
  GetNotificationBatchesResponse,
  GetNotificationBatchRequest,
  GetNotificationBatchResponse,
  DeleteNotificationBatchesRequest,
  DeleteNotificationBatchesResponse,
  GetNotificationConfigurationRequest,
  GetNotificationConfigurationResponse,
  UpdateNotificationConfigurationRequest,
  UpdateNotificationConfigurationResponse,
  SubmitBaselineRequest,
  SubmitBaselineResponse,
} from 'aws-sdk/clients/awsdlomni';
import basicCallWrapper from './client_call';
import { getNotificationConfig } from './config';
import { getCredentialProvider } from './auth';
import { SelectProps } from '@amzn/awsui-components-react-v3';
import {
  GetGroupsConsumingDatasetResult,
  GetGroupsListStructure,
} from 'aws-sdk/clients/awsdatalakegladstonelambda';
import {DataSetEntry, ListDataSetsResult} from "aws-sdk/clients/awsdatalakesubscription";

const config = getNotificationConfig();
const credentials = getCredentialProvider();

const notificationClientConfig: awsdlomni.ClientConfiguration = {
  region: config.region,
  endpoint: config.endpoint,
  credentials: credentials,
};

const notifications = new awsdlomni(notificationClientConfig);

export const getNotifications = (input: GetNotificationsRequest) => {
  return basicCallWrapper<GetNotificationsRequest, GetNotificationsResponse>(
    notifications,
    notifications.getNotifications,
  )(input);
};

export const createNotification = (input: CreateNotificationRequest) => {
  return basicCallWrapper<
    CreateNotificationRequest,
    CreateNotificationResponse
  >(
    notifications,
    notifications.createNotification,
  )(input);
};

export const updateNotifications = (input: UpdateNotificationsRequest) => {
  return basicCallWrapper<
    UpdateNotificationsRequest,
    UpdateNotificationsResponse
  >(
    notifications,
    notifications.updateNotifications,
  )(input);
};

export const deleteNotifications = (input: DeleteNotificationsRequest) => {
  return basicCallWrapper<
    DeleteNotificationsRequest,
    DeleteNotificationsResponse
  >(
    notifications,
    notifications.deleteNotifications,
  )(input);
};

export const deleteNotificationBatches = (input: DeleteNotificationBatchesRequest) => {
  return basicCallWrapper<
    DeleteNotificationBatchesRequest,
    DeleteNotificationBatchesResponse
    >(
    notifications,
    notifications.deleteNotificationBatches,
  )(input);
};


export const getNotificationTemplates = (
  input: GetNotificationTemplatesRequest,
) => {
  return basicCallWrapper<
    GetNotificationTemplatesRequest,
    GetNotificationTemplatesResponse
  >(
    notifications,
    notifications.getNotificationTemplates,
  )(input);
};

export const getNotificationComments = (
  input: GetNotificationCommentsRequest,
) => {
  return basicCallWrapper<
    GetNotificationCommentsRequest,
    GetNotificationCommentsResponse
  >(
    notifications,
    notifications.getNotificationComments,
  )(input);
};

export const getNotificationTemplate = (
  input: GetNotificationTemplateRequest,
) => {
  return basicCallWrapper<
    GetNotificationTemplateRequest,
    GetNotificationTemplateResponse
  >(
    notifications,
    notifications.getNotificationTemplate,
  )(input);
};

export const createNotificationTemplate = (
  input: CreateNotificationTemplateRequest,
) => {
  return basicCallWrapper<
    CreateNotificationTemplateRequest,
    CreateNotificationTemplateResponse
  >(
    notifications,
    notifications.createNotificationTemplate,
  )(input);
};

export const updateNotificationTemplate = (
  input: UpdateNotificationTemplateRequest,
) => {
  return basicCallWrapper<
    UpdateNotificationTemplateRequest,
    UpdateNotificationTemplateResponse
  >(
    notifications,
    notifications.updateNotificationTemplate,
  )(input);
};

export const getNotification = (input: GetNotificationRequest) => {
  return basicCallWrapper<GetNotificationRequest, GetNotificationResponse>(
    notifications,
    notifications.getNotification,
  )(input);
};

export const deleteNotificationTemplate = (
  input: DeleteNotificationTemplateRequest,
) => {
  return basicCallWrapper<
    DeleteNotificationTemplateRequest,
    DeleteNotificationTemplateResponse
  >(
    notifications,
    notifications.deleteNotificationTemplate,
  )(input);
};

export const createNotificationComment = (
  input: CreateNotificationCommentRequest,
) => {
  return basicCallWrapper<
    CreateNotificationCommentRequest,
    CreateNotificationCommentResponse
  >(
    notifications,
    notifications.createNotificationComment,
  )(input);
};

export const updateNotificationBatch = (
  input: UpdateNotificationBatchRequest,
) => {
  return basicCallWrapper<
    UpdateNotificationBatchRequest,
    UpdateNotificationBatchResponse
  >(
    notifications,
    notifications.updateNotificationBatch,
  )(input);
};

export const getNotificationBatches = (
  input: GetNotificationBatchesRequest,
) => {
  return basicCallWrapper<
    GetNotificationBatchesRequest,
    GetNotificationBatchesResponse
  >(
    notifications,
    notifications.getNotificationBatches,
  )(input);
};

export const getNotificationBatch = (input: GetNotificationBatchRequest) => {
  return basicCallWrapper<
    GetNotificationBatchRequest,
    GetNotificationBatchResponse
  >(
    notifications,
    notifications.getNotificationBatch,
  )(input);
};

export const getNotificationConfiguration = (input: GetNotificationConfigurationRequest) => {
  return basicCallWrapper<
    GetNotificationConfigurationRequest,
    GetNotificationConfigurationResponse
    >(
    notifications,
    notifications.getNotificationConfiguration,
  )(input);
};

export const updateNotificationConfiguration = (input: UpdateNotificationConfigurationRequest) => {
  return basicCallWrapper<
    UpdateNotificationConfigurationRequest,
    UpdateNotificationConfigurationResponse
    >(
    notifications,
    notifications.updateNotificationConfiguration,
  )(input);
};

export const convertTemplatesToOptions = (
  templates: GetNotificationTemplatesResponse,
): SelectProps.Option[] => {
  const options: SelectProps.Option[] = templates['NotificationTemplates'].map(
    (template: NotificationTemplateEntry) => {
      return {
        id: template.NotificationTemplateId,
        value: template.NotificationTemplateName,
        label: template.NotificationTemplateName,
        description: 'provided by ' + template.OwnerGroupId,
        templateContent: template.Content,
      };
    },
  );
  return options;
};

export const convertGroupsToOption = (
  groups: GetGroupsConsumingDatasetResult,
): SelectProps.Option[] => {
  const options: SelectProps.Option[] = groups['groupsList'].map(
    (group: GetGroupsListStructure) => {
      return {
        id: group.groupId,
        value: group.groupId,
        label: group.groupId,
        description: group.groupId,
      };
    },
  );
  return options;
};


export const submitBaseline = (input: SubmitBaselineRequest) => {
  return basicCallWrapper<SubmitBaselineRequest, SubmitBaselineResponse>(
      notifications,
      notifications.submitBaseline,
  )(input);
};


export const convertDataSetsToOptions = (
  datasets: ListDataSetsResult,
): SelectProps.Option[] => {
  const options: SelectProps.Option[] = datasets['datasets'].map(
    (dataset: DataSetEntry) => {
      return {
        id: dataset.datasetId,
        value: dataset.datasetId,
        label: dataset.name,
        description: dataset.datasetId.split('|')[3].substr(3),
      };
    },
  );
  return options;
};
