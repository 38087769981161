import {
  Box,
  Button,
  ColumnLayout,
  Container,
  Header,
  SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import * as React from 'react';

export function Review({ info, props, setActiveStepIndex }) {
  const getIAMAccessExist = () => {
    if (props.tableItems.length > 0) {
      return 0;
    }
    return 1;
  };

  const getTrustedEntitiesExist = () => {
    if (props.trustedEntities.length > 0) {
      return 0;
    }
    return 1;
  };

  const getDatasetItemsConsumer = () => {
    if (props.datasetItemsConsumer.length > 0) {
      return 0;
    }
    return 1;
  };

  return (
    <>
      <Box margin={{ bottom: 'l' }}>
        <SpaceBetween size='xxl'>
          {props.tableItems.length > 0 && (
            <SpaceBetween size='xs' className='step-1-review'>
              <Header
                variant='h3'
                headingTagOverride='h2'
                actions={
                  <Button
                    className='edit-step-btn'
                    onClick={() => setActiveStepIndex(1)}
                  >
                    Edit
                  </Button>
                }
              >
                Step 2: IAM access
              </Header>
              <Container header={<Header variant='h2'>Datasets</Header>}>
                <ColumnLayout columns={1} variant='text-grid'>
                  {info.BaselineIAMAccess.selectedIamAccess.length > 0 && (
                    <div>
                      {info.BaselineIAMAccess.selectedIamAccess.map((item) => (
                        <li key={item.id}>{item.id}</li>
                      ))}
                    </div>
                  )}
                  {info.BaselineIAMAccess.selectedIamAccess.length == 0 && (
                    <Box textAlign='center' variant='p'>
                      <div className='awsui-util-pt-s awsui-util-mb-xs'>
                        <b>No datasets selected.</b>
                      </div>
                    </Box>
                  )}
                </ColumnLayout>
              </Container>
            </SpaceBetween>
          )}

          {props.trustedEntities.length > 0 && (
            <SpaceBetween size='xs' className='step-2-review'>
              <Header
                variant='h3'
                headingTagOverride='h2'
                actions={
                  <Button
                    className='edit-step-btn'
                    onClick={() => setActiveStepIndex(2 - getIAMAccessExist())}
                  >
                    Edit
                  </Button>
                }
              >
                Step {3 - getIAMAccessExist()}: IAM principals
              </Header>
              <Container
                header={<Header variant='h2'>Trusted IAM principals</Header>}
              >
                <ColumnLayout columns={1} variant='text-grid'>
                  {info.BaselineIAMPrincipal.selectedIAMPrinciples.length >
                    0 && (
                    <div>
                      {info.BaselineIAMPrincipal.selectedIAMPrinciples.map(
                        (item) => (
                          <li key={item}>{item}</li>
                        ),
                      )}
                    </div>
                  )}
                  {info.BaselineIAMPrincipal.selectedIAMPrinciples.length ==
                    0 && (
                    <Box textAlign='center' variant='p'>
                      <div className='awsui-util-pt-s awsui-util-mb-xs'>
                        <b>No principals selected.</b>
                      </div>
                    </Box>
                  )}
                </ColumnLayout>
              </Container>
            </SpaceBetween>
          )}

          {props.datasetItemsConsumer.length > 0 && (
            <SpaceBetween size='xs' className='step-3-review'>
              <Header
                variant='h3'
                headingTagOverride='h2'
                actions={
                  <Button
                    className='edit-step-btn'
                    onClick={() =>
                      setActiveStepIndex(
                        3 - getIAMAccessExist() - getTrustedEntitiesExist(),
                      )
                    }
                  >
                    Edit
                  </Button>
                }
              >
                Step {4 - getIAMAccessExist() - getTrustedEntitiesExist()}: Lake
                Formation access as consumer
              </Header>
              <Container header={<Header variant='h2'>Dataset shares</Header>}>
                <ColumnLayout columns={1} variant='text-grid'>
                  {info.BaselineLFAccessConsumer.selectedLFConsumer.length >
                    0 && (
                    <div>
                      {info.BaselineLFAccessConsumer.selectedLFConsumer.map(
                        (item) => (
                          <li key={item.dataSetShareId}>
                            {item.dataSetShareId}
                          </li>
                        ),
                      )}
                    </div>
                  )}
                  {info.BaselineLFAccessConsumer.selectedLFConsumer.length ==
                    0 && (
                    <Box textAlign='center' variant='p'>
                      <div className='awsui-util-pt-s awsui-util-mb-xs'>
                        <b>No dataset shares selected.</b>
                      </div>
                    </Box>
                  )}
                </ColumnLayout>
              </Container>
            </SpaceBetween>
          )}

          {props.datasetItemsOwner.length > 0 && (
            <SpaceBetween size='xs' className='step-4-review'>
              <Header
                variant='h3'
                headingTagOverride='h2'
                actions={
                  <Button
                    className='edit-step-btn'
                    onClick={() =>
                      setActiveStepIndex(
                        4 -
                          getIAMAccessExist() -
                          getTrustedEntitiesExist() -
                          getDatasetItemsConsumer(),
                      )
                    }
                  >
                    Edit
                  </Button>
                }
              >
                Step{' '}
                {5 -
                  getIAMAccessExist() -
                  getTrustedEntitiesExist() -
                  getDatasetItemsConsumer()}
                : Lake Formation access as owner
              </Header>
              <Container header={<Header variant='h2'>Dataset shares</Header>}>
                <ColumnLayout columns={1} variant='text-grid'>
                  {info.BaselineLFAccessOwner.selectedLFOwner.length > 0 && (
                    <div>
                      {info.BaselineLFAccessOwner.selectedLFOwner.map(
                        (item) => (
                          <li key={item.dataSetShareId}>
                            {item.dataSetShareId}
                          </li>
                        ),
                      )}
                    </div>
                  )}
                  {info.BaselineLFAccessOwner.selectedLFOwner.length == 0 && (
                    <Box textAlign='center' variant='p'>
                      <div className='awsui-util-pt-s awsui-util-mb-xs'>
                        <b>No dataset shares selected.</b>
                      </div>
                    </Box>
                  )}
                </ColumnLayout>
              </Container>
            </SpaceBetween>
          )}
        </SpaceBetween>
      </Box>
    </>
  );
}
