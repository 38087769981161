import * as React from 'react';
import { useState, useEffect } from 'react';
import { NotificationEntry } from 'aws-sdk/clients/awsdlomni'

import {
  notificationsPageSizePreference,
  defaultWrapLinesPreference,
  paginationLabels,
} from 'src/commons/tables';

import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Pagination,
  Table,
  TextFilter,
} from '@amzn/awsui-components-react-v3';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';

import { PageHeader } from '../common';
import {
  deleteNotifications,
  getNotifications,
  updateNotifications,
} from '../../../api/notifications';


export interface ArchivedNotificationsProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  setSelectedNotification: any;
  setActiveTabId: any;
}

const contentSelectorOptions:CollectionPreferencesProps.VisibleContentOptionsGroup[] = [
  {
    label: 'Sent notifications',
    options: [
      {
        id: 'Subject',
        label: 'Subject',
        editable: true,
      },
      {
        id: 'Content',
        label: 'Content',
        editable: true,
      },
      {
        id: 'SenderGroupId',
        label: 'Sender group ID',
        editable: true,
      },
      {
        id: 'CreateTime',
        label: 'Time',
        editable: true,
      },

      {
        id: 'NotificationId',
        label: 'Notification ID',
        editable: true,
      },
    
      {
        id: 'PriorityLevel',
        label: 'Priority',
        editable: true,
      },
      {
        id: 'ArchiveTime',
        label: 'Archive time',
        editable: true,
      },
      {
        id: 'SenderUserId',
        label: 'Sender',
        editable: true,
      },
      {
        id: 'ArchivedBy',
        label: 'Archived by',
        editable: true,
      },
      {
        id: 'NotificationBatchId',
        label: 'Notification batch ID',
        editable: true,
      }],
  },
];


const columnDefinitions: TableProps.ColumnDefinition<NotificationEntry>[] = [
  {
    id: 'Subject',
    header: 'Subject',
    cell: (item) => item.Subject,
  },
  {
    id: 'Content',
    header: 'Content',
    cell: (item) => item.Content,
  },
  {
    id: 'SenderGroupId',
    header: 'Sender group ID',
    cell: (item) => item.SenderGroupId,
  },

  {
    id: 'NotificationId',
    header: 'Notification ID',
    cell: (item) => item.NotificationId,
  },
  {
    id: 'CreateTime',
    header: 'Create time',
    cell: (item) => new Date(item.CreateTime).toLocaleString(),
    minWidth: 200,
    sortingField: 'CreateTime',
  },

  {
    id: 'PriorityLevel',
    header: 'Priority',
    cell: (item) => item.PriorityLevel,
  },
  {
    id: 'ArchiveTime',
    header: 'Archive time',
    cell: (item) => item.ArchiveTime,
  },
  {
    id: 'SenderUserId',
    header: 'Sender',
    cell: (item) => item.SenderUserId,
  },
  {
    id: 'ArchivedBy',
    header: 'Archived by',
    cell: (item) => item.ArchivedBy,
  },
  {
    id: 'NotificationBatchId',
    header: 'Notification batch ID',
    cell: (item) => item.NotificationBatchId,
  },
];

//archived notification tab
export const ArchivedNotifications = (props: ArchivedNotificationsProps) => {
  const [allItems, setItems] = useState([]);
  const [loadingNotifications, setLoadingNotifications] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [tableMessage, setTableMessage] = useState('No notifications');
  const [currentPageIndex, setCurrentPageIndex] = React.useState(1);

  const [preferences, setPreferences] =
    useState<CollectionPreferencesProps.Preferences>({
      wrapLines: false,
      pageSize: 14,
      visibleContent:[
        'Subject',
        'Content',
        'SenderGroupId',
        'CreateTime'
      ]
    });

  useEffect(() => {
    handleRefresh();
    props.setContentType('table');
  }, [props.activeGroup]);

  const handleRefresh = async () => {
    props.setActiveTabId('archive');
    setLoadingNotifications(true);
    if (!props.activeGroup) return;
    try {
      let request = {
        ReceiverGroupId: props.activeGroup,
        Archive: true,
        NextToken: '',
      };
      let response = await getNotifications(request);
      let notificationList = [...response.NotificationList];

      //Loop and get remaining tables
      while (response.NextToken != null) {
        request = {
          ReceiverGroupId: props.activeGroup,
          Archive: true,
          NextToken: response.NextToken,
        };
        response = await getNotifications(request);
        notificationList.push(...response.NotificationList);
      } {
        setItems(notificationList);
      }
    } catch (err) {
      setTableMessage(`Unable to load notifications. ${err.message}`);
    }
    setLoadingNotifications(false);
  };

  const handlePageChange = async (detail) => {
    setCurrentPageIndex(detail.currentPageIndex);
  }

  const handleAction = async (e) => {
    if (e.detail.id === 'delete') {
      setActionLoading(true);
      await deleteNotifications({
        GroupId: props.activeGroup,
        NotificationIdList: collectionProps.selectedItems.map(({ NotificationId }) => NotificationId),
      });
      setActionLoading(false);
      await handleRefresh();
    }
    if (e.detail.id === 'unArchive') {
      setActionLoading(true);
      await updateNotifications({
        GroupId: props.activeGroup,
        MarkArchive: false,
        NotificationIdList: collectionProps.selectedItems.map(({ NotificationId }) => NotificationId),
      });
      setActionLoading(false);
      await handleRefresh();
    }
  };

  const {
    items,
    collectionProps,
    paginationProps,
    filterProps,
    filteredItemsCount,
  } = useCollection(allItems, {
    filtering: {
      empty: (
        <div className='awsui-util-t-c'>
          <div className='awsui-util-pt-s awsui-util-mb-xs'>
            <b>{tableMessage}</b>
          </div>
          <p className='awsui-util-mb-s'>No notifications to display.</p>
        </div>
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties: [],
    },
  });

  useEffect(() => {
    const { selectedItems } = collectionProps
    if(!selectedItems.length) return props.setSelectedNotification({})

    const selectedNotification:NotificationEntry = selectedItems[selectedItems.length - 1]
    props.setSelectedNotification({
      selectedNotificationId: selectedNotification.NotificationId,
      selectedContent: selectedNotification.Content,
      selectedPriorityLevel: selectedNotification.PriorityLevel,
      selectedSubject: selectedNotification.Subject,
      selectedCreateTime: selectedNotification.CreateTime,
      selectedArchiveTime: selectedNotification.ArchiveTime,
      selectedReadTime: selectedNotification.ReadHistory,
      selectedSenderGroupId: selectedNotification.SenderGroupId,
      selectedSenderUserId: selectedNotification.SenderGroupId,
      selectedArchivedBy: selectedNotification.ArchivedBy,
      selectedNotificationBatchId: selectedNotification.NotificationBatchId,
      selectedEmailSent: selectedNotification.EmailSent
    })
  }, [collectionProps.selectedItems])

  return (
    <>
      <Table
        {...collectionProps}
        selectionType='multi'
        loadingText='Loading notifications...'
        loading={loadingNotifications}
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        visibleColumns={preferences.visibleContent}
        header={
          <>
            <PageHeader
              buttons={[
                {
                  text: 'Actions',
                  onItemClick: handleAction,
                  items: [
                    {
                      text: 'Delete',
                      id: 'delete',
                      disabled: !collectionProps.selectedItems.length,
                    },
                    {
                      text: 'UnArchive',
                      id: 'unArchive',
                      disabled: !collectionProps.selectedItems.length,
                    },
                  ],
                  loading: actionLoading,
                },
              ]}
              header={
                <>
                  Archived notifications
                  <span className='awsui-util-header-counter'>
                    {` (${items.length})`}
                  </span>
                </>
              }
            />
          </>
        }
        empty={
          <div className='awsui-util-t-c'>
            <div className='awsui-util-pt-s awsui-util-mb-xs'>
              <b>{tableMessage}</b>
            </div>
            <p className='awsui-util-mb-s'>No notifications to display.</p>
          </div>
        }
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel='Filter notifications'
            filteringPlaceholder='Find notifications'
            countText={`${filteredItemsCount} ${
              filteredItemsCount === 1 ? 'match' : 'matches'
            }`}
          />
        }
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={notificationsPageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
            visibleContentPreference={{
              title:'Visible content',
              options:contentSelectorOptions
            }}
          />
        }
        pagination={
          <Pagination {...paginationProps} ariaLabels={paginationLabels} onChange={({ detail }) => handlePageChange(detail) } currentPageIndex={currentPageIndex}/>
        }
      />
    </>

  );


};
