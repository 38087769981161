import React, { Component } from 'react';
import { SpaceBetween, Tabs } from '@amzn/awsui-components-react-v3';
import { LakeFormationTable } from '../permissions/myPermissions/lakeFormation';
import { BrowseRequestsTable } from './browseRequests';
import { DATACONSUMER } from 'src/commons/constants';

interface MyRequestsProps {
  groupInfo: string | undefined;
  activeGroup: 'outgoingRequests' | 'incomingRequests';
  username: string | undefined;
  setContentType?: (contentType: string | undefined) => void;
  customerType: string;
}

export interface BrowseTableState {
  activeTabId: string | undefined;
}

export default class MyRequests extends Component<
  MyRequestsProps,
  BrowseTableState
> {
  state = {
    // Consumers should default to outgoing requests
    activeTabId:
      this.props.customerType === DATACONSUMER
        ? 'outgoingRequests'
        : 'incomingRequests',
  };

  render() {
    const { setContentType, activeGroup } = this.props;

    let tabs = [
      {
        label: 'Incoming',
        id: 'incomingRequests',
        content: (
          <SpaceBetween size='l'>
            <BrowseRequestsTable
              isIncomingRequests={true}
              setContentType={setContentType}
              activeGroup={activeGroup}
            />

            <LakeFormationTable
              {...this.props}
              setContentType={setContentType}
              activeGroup={activeGroup}
              requestType='CreateLakeFormationGrantPermission'
              requestBoundOutgoing={false}
            />
          </SpaceBetween>
        ),
      },
      {
        label: 'Outgoing',
        id: 'outgoingRequests',
        content: (
          <SpaceBetween size='l'>
            <BrowseRequestsTable
              {...this.props}
              isIncomingRequests={false}
              setContentType={setContentType}
              activeGroup={activeGroup}
            />

            <LakeFormationTable
              {...this.props}
              setContentType={setContentType}
              activeGroup={activeGroup}
              requestType='CreateLakeFormationGrantPermission'
              requestBoundOutgoing={true}
            />
          </SpaceBetween>
        ),
      },
    ];

    if (this.props.customerType === DATACONSUMER) {
      tabs.reverse(); // We want outgoing to be first for consumers
    }

    return (
      <>
        <Tabs
          tabs={tabs}
          activeTabId={this.state.activeTabId}
          variant='default'
          onChange={({ detail }) => {
            this.setState({ activeTabId: detail.activeTabId });
          }}
        />
      </>
    );
  }
}
