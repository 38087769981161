import {
  Input,
  Header,
  Select,
  Container,
  FormField,
  SpaceBetween,
  Multiselect,
} from '@amzn/awsui-components-react-v3';
import * as React from 'react';
import { iStep } from '..';
import { validateStepFour } from '../utils/validation';

export const IngestionStep = ({ request, setRequest, validate }: iStep) => {
  const [validated, setValidated] = React.useState(false);
  React.useEffect(() => {
    setValidated(validate);
  }, [validate]);

  const [columns, setColumns] = React.useState<string[]>([]);

  React.useEffect(() => {
    const { SchemaDefinition } = request.DataContract.DataProperties;
    const jsonSchema = JSON.parse(SchemaDefinition);
    const columns = jsonSchema.fields.map(({ name }) => name);
    setColumns(columns);
  }, []);

  return (
    <SpaceBetween direction='vertical' size='xl'>
      <Container header={<Header variant='h2'>Service level agreement (SLA)</Header>}>
        <SpaceBetween direction='vertical' size='m'>
          <FormField
            label='IAM Role'
            description={
              <>
                Grant Tethys read access permissions to the S3 bucket(s) where
                your data is stored.
              </>
            }
            errorText={
              validated && !validateStepFour(request).IAMRole
                ? !request.DataContract.ServiceLevelAgreement.IAMRole
                  ? 'IAM Role is required.'
                  : 'IAM Role is not valid.'
                : ''
            }
          >
            <Input
              value={request.DataContract.ServiceLevelAgreement.IAMRole}
              onChange={({ detail }) =>
                setRequest({
                  ...request,
                  DataContract: {
                    ...request.DataContract,
                    ServiceLevelAgreement: {
                      ...request.DataContract.ServiceLevelAgreement,
                      IAMRole: detail.value,
                    },
                  },
                })
              }
            />
          </FormField>

          <FormField
            label='Publish Type'
            description='Is your data ingested incrementally or fully replaced after each ingestion event?'
            errorText={
              validated && !validateStepFour(request).PublishType
                ? 'Publish type is required.'
                : ''
            }
          >
            <Select
              selectedAriaLabel='Selected'
              options={[
                { label: 'Incremental', value: 'INCREMENTAL' },
                { label: 'Overwrite', value: 'OVERWRITE' },
              ]}
              selectedOption={{
                label: request.DataContract.ServiceLevelAgreement.PublishType,
                value: request.DataContract.ServiceLevelAgreement.PublishType,
              }}
              onChange={({ detail }) =>
                setRequest({
                  ...request,
                  DataContract: {
                    ...request.DataContract,
                    ServiceLevelAgreement: {
                      ...request.DataContract.ServiceLevelAgreement,
                      PublishType: detail.selectedOption.value,
                    },
                  },
                })
              }
            />
          </FormField>
        </SpaceBetween>
      </Container>

      <Container
        header={
          <Header variant='h2' description={'Define the logic to update or delete duplicated records.'}>
            Data Deduplication
          </Header>
        }
      >
        <SpaceBetween direction='vertical' size='l'>
          <FormField
            label='Primary key'
            description='The attribute(s) that will be used to uniquely identify each record.'
            errorText={
              validated && !validateStepFour(request).PrimaryKeyColumns
                ? 'At least one primary key is required.'
                : ''
            }
          >
            <Multiselect
              options={columns.map((column) => ({
                label: column,
                value: column,
              }))}
              placeholder='Choose option'
              selectedAriaLabel='Selected'
              selectedOptions={request.DataContract.DataProperties.PrimaryKeyColumns.map(
                (column) => ({
                  label: column,
                  value: column,
                }),
              )}
              onChange={({ detail }) =>
                setRequest({
                  ...request,
                  DataContract: {
                    ...request.DataContract,
                    DataProperties: {
                      ...request.DataContract.DataProperties,
                      PrimaryKeyColumns: detail.selectedOptions.map(
                        ({ value }) => value,
                      ),
                    },
                  },
                })
              }
            />
          </FormField>

          <FormField
            label='Dedup column'
            description='Deduplication will be performed on basis on Primary Key. The record with latest Dedup column value will be retained.'
            errorText={
              validated && !validateStepFour(request).DeDupKeyColumn
                ? 'Ordering key column is required to deduplicate data.'
                : ''
            }
          >
            <Select
              options={columns.map((column) => ({
                label: column,
                value: column,
              }))}
              selectedOption={{
                label: request.DataContract.DataProperties.DeDupKeyColumn,
                value: request.DataContract.DataProperties.DeDupKeyColumn,
              }}
              selectedAriaLabel='Selected'
              onChange={({ detail }) =>
                setRequest({
                  ...request,
                  DataContract: {
                    ...request.DataContract,
                    DataProperties: {
                      ...request.DataContract.DataProperties,
                      DeDupKeyColumn: detail.selectedOption.value,
                    },
                  },
                })
              }
            />
          </FormField>
        </SpaceBetween>
      </Container>
    </SpaceBetween>
  );
};
