import {
  Box,
  Tabs,
  Alert,
  Header,
  Button,
  Spinner,
  Container,
  ColumnLayout,
  SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import { Redirect } from 'react-router';
import { SchemaTab } from './tabs/schema';
import { ContractTab } from './tabs/contract';
import { CopyText } from '../common/copyText';
import React, { useEffect, useState } from 'react';
import { getDataset } from '../../../api/ingestion';
import { gotoMonitoring } from '../common/navigation';
import { iIngestionComponentProps } from '../registerContract';
import { GetDataSetResponse } from 'aws-sdk/clients/tethyscontractservicelambda';

interface iContractHeader {
  activeGroup: string;
  dataset: GetDataSetResponse;
}

const ContractHeader = ({ dataset, activeGroup }: iContractHeader) => {
  const [redirect, setRedirect] = useState<string>();
  if (redirect) return <Redirect push to={redirect} />;

  return (
    <>
      <Header
        variant='h1'
        actions={
          <Button
            variant='normal'
            onClick={() => {
              const monitoringRoute = gotoMonitoring(
                activeGroup,
                dataset.DatabaseName,
                dataset.DataSetId,
              );
              setRedirect(monitoringRoute);
            }}
          >
            Monitor dataset
          </Button>
        }
      >
        {dataset.TableName}
      </Header>
      <Container header={<Header variant='h2'>General information</Header>}>
        <ColumnLayout columns={3} variant='text-grid'>
          <div>
            <Box variant='awsui-key-label'> Version </Box>
            <div> {dataset.LatestVersion} </div>
          </div>

          <div>
            <Box variant='awsui-key-label'> Last update </Box>
            <div> {dataset.LastUpdatedAt} </div>
          </div>

          <div>
            <Box variant='awsui-key-label'> Publish endpoint </Box>
            <div>
              <CopyText
                copyText={dataset.PublishEndpoint}
                copyButtonLabel='Copy publish endpoint'
                successText='Publish endpoint copied'
                errorText='Failed to copy publish endpoint'
              />
            </div>
          </div>
        </ColumnLayout>
      </Container>
    </>
  );
};

interface iDatasetDetail extends iIngestionComponentProps {
  params: { arn: string; dataset: string };
}

export const DatasetDetail = ({
  params,
  activeGroup,
  setContentType,
}: iDatasetDetail) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [dataset, setDataset] = useState<GetDataSetResponse>();

  useEffect(() => {
    setContentType('table');
    fetchDataset();
  }, []);

  const fetchDataset = async () => {
    setLoading(true);
    const dataset = await getDataset({
      DataSetId: `${params.arn}/${params.dataset}`,
    });
    setDataset(dataset);
    setLoading(false);
  };

  const tabs = [
    {
      label: 'Data Contract',
      id: 'contract',
      content: <ContractTab dataset={dataset} />,
    },
    {
      label: 'Schema Definition',
      id: 'schema',
      content: <SchemaTab dataset={dataset} />,
    },
  ];

  if (loading) return <Spinner />;
  if (!dataset.DataContract)
    return (
      <Alert visible={true} type='warning'>
        Error loading dataset. Please contact Tethys Support (AWS-DI).
      </Alert>
    );
  return (
    <SpaceBetween size='l'>
      <ContractHeader dataset={dataset} activeGroup={activeGroup} />
      <Tabs tabs={tabs} ariaLabel='Resource details' />
    </SpaceBetween>
  );
};
