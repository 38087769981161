import * as React from 'react';
import {
  Button,
  ButtonDropdown,
  ColumnLayout,
  Header,
  SpaceBetween,
} from '@amzn/awsui-components-react-v3';

export const PageHeader = ({ buttons, header }) => {
  return (
    <Header
      actions={
        <SpaceBetween direction='horizontal' size='s'>
          {buttons.map((button, key) =>
            !button.items ? (
              <Button
                href={button.href || ''}
                disabled={button.disabled || false}
                iconName={button.icon}
                onClick={button.onItemClick}
                key={key}
                loading={button.loading || false}
                variant={button.variant || 'normal'}
              >
                {button.text}
              </Button>
            ) : (
              <ButtonDropdown
                items={button.items}
                key={key}
                onItemClick={button.onItemClick}
                loading={button.loading}
              >
                {button.text}
              </ButtonDropdown>
            ),
          )}
        </SpaceBetween>
      }
    >
      {header}
    </Header>
  );
};

export const RequestInformation = ({ request }) => (
  <div className='awsui-util-container'>
    <div className='awsui-util-container-header'>
      <h2>Request details</h2>
    </div>
    <ColumnLayout columns={4} variant='text-grid'>
      <div>
        <div className='awsui-util-label'>Requested by</div>
        <div> {request.requestedBy}</div>
      </div>
      <div>
        <div className='awsui-util-label'>Request group</div>
        <div> {request.requestGroup}</div>
      </div>
      <div>
        <div className='awsui-util-label'>Decided by</div>
        <div> {request.decidedBy}</div>
      </div>
      <div>
        <div className='awsui-util-label'>Data owner</div>
        <div> {request.owner}</div>
      </div>
      <div>
        <div className='awsui-util-label'>Status</div>
        <div> {request.status}</div>
      </div>
      <div>
        <div className='awsui-util-label'>Request date</div>
        <div> {request.timeOfRequest}</div>
      </div>
      <div>
        <div className='awsui-util-label'>Decision date</div>
        <div> {request.timeOfDecision}</div>
      </div>
      <div>{/*         This is a space */}</div>
      <div>
        <div className='awsui-util-label'>External account</div>
        <div> {request.principal}</div>
      </div>
      <div>
        <div className='awsui-util-label'>Catalog ID</div>
        <div> {request.catalogId}</div>
      </div>
      <div>
        <div className='awsui-util-label'>Database name</div>
        <div> {request.databaseName}</div>
      </div>
      <div>
        <div className='awsui-util-label'>Table name</div>
        <div> {request.tableName}</div>
      </div>
      <div>
        <div className='awsui-util-label'>
          Columns ({request.columnWildCard})
        </div>
        <div> {request.columnNames}</div>
      </div>
      {request.columnWildCard !== undefined && (
        <div>
          <div className='awsui-util-label'>Columns wildcard</div>
          <div> {request.columnWildCard}</div>
        </div>
      )}
      <div>
        <div className='awsui-util-label'>Permissions</div>
        <div> {request.permissions}</div>
      </div>
      <div>
        <div className='awsui-util-label'>Grantable permissions</div>
        <div> {request.grantablePermissions}</div>
      </div>
    </ColumnLayout>
    <ColumnLayout columns={2} variant='text-grid'>
      <div data-awsui-column-layout-root='true'>
        <div>
          <div className='awsui-util-label'>Use case</div>
          <div> {request.useCase}</div>
        </div>
        <div>
          <div className='awsui-util-label'>Reason for action</div>
          <div> {request.reasonOfAction}</div>
        </div>
      </div>
    </ColumnLayout>
  </div>
);
