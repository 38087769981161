import {
  Button,
  ColumnLayout,
  Form,
  FormField,
  Container,
  Input,
  Select,
  Flashbar,
  Textarea, Header,
} from '@amzn/awsui-components-react-v3';
import {useEffect, useState} from 'react';
import * as React from 'react';
import { Redirect } from 'react-router';
import { scrollUp } from '../utils/navigation';
import { NotificationDestination } from './notificationSelector';
import {
  convertDataSetsToOptions,
  createDependency,
  listDependencyDataSets,
} from '../../api/dependency';
import { listDataLakeRoleProperty } from '../../api/permissions';
import { Page } from '../../routes/Paths';

export interface CreateDependencyProps {
  setContentType: any;
  activeGroup: string;
}




export const CreateDependency = (props: CreateDependencyProps) => {
  const [redirect, setRedirect] = useState(undefined);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [dependencyNamePlaceHolder, ] = useState('your-dependency-name');
  const [descriptionPlaceHolder, ] = useState('your-description');
  const [iamRoleAssumePlaceHolder, ] = useState('arn:aws:iam::<yourAccount>:role/MyDependencyRole');
  const [dependencyName, setDependencyName] = useState(null);
  const [description, setDescription] = useState(null);
  const [iamRoleAssume, setIamRoleAssume] = useState(null);
  const [selectedDataSet, setSelectedDataSet] = useState(null);
  const [dataSetsOptions, setDataSetsOptions] = useState([]);
  const [dataSetsLoadingStatus, setDataSetsLoadingStatus] = useState(null);
  const [notificationType, setNotificationType] = useState('S3');
  const [s3Path, setS3Path] = useState(null);
  const [dependencyDataSetId, setDependencyDataSetId] = useState(null);
  const [notifications, setNotifications] = useState([]);


  useEffect(  () => {
    props.setContentType('form');
    loadDataSets();
  }, []);

  const loadDataSets = async () => {
    setDataSetsLoadingStatus('loading');

    let nextToken = null;
    let listDataSetsRequest = {
      limit: 100,
      groupId: props.activeGroup,
      nextToken: nextToken,
    };

    let dependencyDataSets = [];

    do {
      const listDependencyDataSetsResponse = await listDependencyDataSets(
        listDataSetsRequest,
      );
      listDataSetsRequest.nextToken = listDependencyDataSetsResponse.nextToken;
      dependencyDataSets.push(...listDependencyDataSetsResponse.dataSets);
    } while (nextToken);

    const rolePropertyResult = await listDataLakeRoleProperty({
      groupId: props.activeGroup,
    });

    const approvedDataSets = dependencyDataSets.filter((dataSet) =>
      rolePropertyResult.approvedPermissions.includes(
        dataSet.hybridCatalogDataSetId,
      ),
    );

    setDataSetsLoadingStatus('finished');
    setDataSetsOptions(convertDataSetsToOptions(approvedDataSets));
  }


  const handleCreate = async () => {
    setButtonLoading(true);

    const createDependencyResponse = await createDependency({
      dependencyName: dependencyName,
      dependencyServiceDataSetId: dependencyDataSetId,
      description: description,
      iamRoleAssume: iamRoleAssume,
      groupId: props.activeGroup,
      notificationTarget: {
        type: notificationType,
        targetLocation: s3Path,
      },
    });

    setButtonLoading(false);
    setNotifications([
      {
        type: 'success',
        content: `Dependency created with ID ${createDependencyResponse.dependencyId}.`,
        dismissible: true,
        action: <Button onClick={() => {setRedirect(`/dependency/${createDependencyResponse.dependencyId}`)}}>View dependency</Button>,
        onDismiss: () => setNotifications([]),
      },
    ]);
    scrollUp();
  };

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <div>
      <Flashbar items={notifications} />
      <Form
        header={
          <Header description='You can create a new dependency for a dataset.' variant="h1">
            Create new dependency
          </Header>
        }
        actions={
          <div>
            <Button
              variant='link'
              onClick={() => setRedirect(Page.DEPENDENCIES)}
            >
              Cancel
            </Button>
            <Button
              variant='primary'
              onClick={handleCreate}
              loading={buttonLoading}
              disabled={false}
            >
              Create
            </Button>
          </div>
        }
      >
        <Container
          className='custom-screenshot-hide'
          header={<h2>Dependency settings</h2>}
        >
          <ColumnLayout>
            <div data-awsui-column-layout-root={true}>
              <FormField
                label='Dependency name'
                description='Please set the name of your dependency.'
              >
                <Input
                  name='dependency-name'
                  placeholder={dependencyNamePlaceHolder}
                  value={dependencyName}
                  ariaRequired={true}
                  onChange={(e) => setDependencyName(e.detail.value.trim())}
                />
              </FormField>
              <FormField
                label='Description'
                description='Please set the description of your dependency.'
              >
                <Textarea
                  name='dependency-description'
                  placeholder={descriptionPlaceHolder}
                  value={description}
                  ariaRequired={true}
                  onChange={(e) => setDescription(e.detail.value)}
                />
              </FormField>

              <FormField
                label={<div>Dependency role</div>}
                description='Please set your dependency role. We will assume this role to access your S3 location.'
              >
                <Input
                  name='dependency-role'
                  placeholder={iamRoleAssumePlaceHolder}
                  value={iamRoleAssume}
                  ariaRequired={true}
                  onChange={(e) => setIamRoleAssume(e.detail.value.trim())}
                />
              </FormField>

              <FormField
                label={<div>Data Lake table</div>}
                description='Please select the Data Lake table to monitor.'
              >
                <Select
                  selectedOption={selectedDataSet}
                  onChange={({ detail }) => {
                    setSelectedDataSet(detail.selectedOption);
                    setDependencyDataSetId(detail.selectedOption.value);
                  }}
                  options={dataSetsOptions}
                  loadingText='Loading tables...'
                  placeholder='Select the table that you want to monitor'
                  errorText='Error fetching type.'
                  recoveryText='Retry'
                  empty={'No types available'}
                  filteringType='auto'
                  filteringAriaLabel='Filter origins'
                  ariaRequired={true}
                  statusType={dataSetsLoadingStatus}
                />
              </FormField>

              <NotificationDestination
                setNotificationType={(type: string) => setNotificationType(type)}
                setS3Path={(path: string) => setS3Path(path)}
              />
            </div>
          </ColumnLayout>
        </Container>
      </Form>
    </div>
  );


}

