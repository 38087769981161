import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import OnboardingComponent from './components/common/allowlist';
import { getActiveHref, isHomePage } from './components/utils/page';
import {
  activeHref,
  appLayoutLabels,
  navItemsForProvider,
  navItemsForConsumer,
  navItemsForAdmins,
  navItemsForTethys,
} from './commons/navigationData';
import { SideNavigation, Spinner } from '@amzn/awsui-components-react-v3';
import { getUser } from './api/auth';
import { getGroupInfo, getUserInfo } from './api/permissions';
import { PageHeader } from './components/common/header';
import { ErrorBoundary } from './components/error/errorBoundary';
import { updateNotificationIcon } from './components/notifications/common';
import { Breadcrumbs, Help, Routes } from './routes';
import {
  DATACONSUMER,
  DATAPROVIDER,
  DATAPROVIDER_AND_CONSUMER,
  DGS_ONCALL_URL,
  NOT_FOUND_EXCEPTION,
} from 'src/commons/constants';
import { Alert, Flashbar, FlashbarProps } from '@amzn/awsui-components-react-v3';
import { Link } from '@amzn/awsui-components-react-v3/polaris';
import { AppLayout } from '@amzn/awsui-components-react';


class App extends Component {
  state = {
    toolsOpen: false,
    toolsHide: false,
    active: activeHref,
    redirect: undefined,
    username: undefined, // string
    userInfo: undefined,
    groups: [],
    activeGroup: undefined,
    activeGroupInfo: undefined,
    activeDatasetName: undefined,
    activeDatabaseName: undefined,
    activeCatalogName: undefined,
    activeTable: undefined,
    activeAccount: undefined,
    contentType: 'form' as AppLayout.ContentType,
    notifications:[] as FlashbarProps.MessageDefinition[],
    navOpen: true,
    allowlisted: true,
    internalError: false,
    loadingGroup: false,
    customerType: DATAPROVIDER_AND_CONSUMER,
    navigationItemsForCustomerType: undefined,
    cartItems: [],
    cartItemIds: [],
    unreadNotifications:undefined,
    unreadNotificationBatches: undefined,
    removeUnreadNotificationId: undefined,
    removeUnreadNotificationBatchId: undefined,
  };

  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    window.location.replace("https://beta.omni.datalake.aws.a2z.com/publisher/tables");
    this.setActiveGroup = this.setActiveGroup.bind(this);

    let user = '';
    let userInfo;
    try {
      user = await getUser();
      userInfo = await getUserInfo({ userId: user });
      console.log('Logged in as: ' + user);
      this.setState({
        active: getActiveHref(),
        username: user,
        groups: userInfo.groupIds,
        userInfo: userInfo,
      });
    } catch (err) {
      if (err?.name.includes(NOT_FOUND_EXCEPTION)) {
        // provided user is not safelisted in Gladstone
        this.setState({
          allowlisted: false,
          username: user,
        });
        console.error('error ..', err);
      } else {
        // Server error (when dynamodb issue)
        this.setState({
          allowlisted: false,
          internalError: true,
          username: user,
        });
        console.log('Something went wrong..please come back later', err);
      }
    }
    const savedGroup = localStorage.getItem('activeGroup');
    if (userInfo == undefined || userInfo.groupIds == undefined || userInfo.groupIds.length == 0) {
      this.setState({allowlisted: false});
      this.setActiveGroup('');
    } else if (savedGroup && userInfo.groupIds.includes(savedGroup)) {
      this.setActiveGroup(savedGroup);
    }  else {
      this.setActiveGroup(userInfo.groupIds[0]);
    }

    //update notifications when the page is open
    this.updateNotifications(this.state.activeGroup)
    //recursively update notifications every 10 minutes
    setInterval(() => this.updateNotifications(this.state.activeGroup), 10 * 60 * 1000)
  }



  async updateNotifications(group) {
    const notfis = await updateNotificationIcon(
      group, this.state.username
    )
    let unreadNotifications = notfis.unreadNotifications

    let unreadNotificationBatches = notfis.unreadNotificationBatches
    this.setState({
      unreadNotifications: unreadNotifications,
      unreadNotificationBatches: unreadNotificationBatches
    })
  }

  async componentDidUpdate() {
    const href = getActiveHref();
    if (this.state.active != href) {
      this.setState({ active: href });
    }
  }

  setNavItems(customerType) {
    this.setState({ navigationItemsForCustomerType: navItemsForTethys })
    if(this.state.activeGroup) return 

    if (
      [
        'AWSDL_Services',
        'AWSDataWarehouse',
        'AWS-DW-PRIMARY',
        'AWS-DW-PRIMARY-GLOBAL',
        'AWS-DW-PRIMARY-IAD',
      ].includes(this.state.activeGroup)
    ) {
      this.setState({ navigationItemsForCustomerType: navItemsForAdmins });
      return;
    }
    switch (customerType) {
      case DATACONSUMER:
        this.setState({ navigationItemsForCustomerType: navItemsForConsumer });
        return;
      case DATAPROVIDER:
        this.setState({ navigationItemsForCustomerType: navItemsForProvider });
        return;
      case DATAPROVIDER_AND_CONSUMER:
        this.setState({ navigationItemsForCustomerType: navItemsForProvider });
        return;
      default:
        this.setState({ navigationItemsForCustomerType: navItemsForConsumer });
    }
  }

  async setActiveGroup(groupId) {
    if (groupId === this.state.activeGroup) return;

    this.setState({
      activeGroup: groupId,
      loadingGroup: true,
      unreadNotifications:[],
      unreadNotificationBatches: [],
    });

    localStorage.setItem('activeGroup', groupId);

    try {
      const groupInfo = await getGroupInfo({ groupId: groupId });
      const teamInfo = groupInfo.teamInfo;
      const customerType = teamInfo['CustomerType'];
      console.log('App page:: CustomerType: ', customerType);
      localStorage.setItem('customerType', customerType);
      const nItems =
        customerType == DATACONSUMER
          ? navItemsForConsumer
          : navItemsForProvider;
      console.log('Logged in with group ' + groupId);
      this.setState({
        activeGroupInfo: groupInfo,
        navigationItemsForCustomerType: nItems,
        customerType: customerType,
      });
      this.updateNotifications(this.state.activeGroup)
    } catch (err) {
      console.error('Error fetching group ' + groupId);
    }

    this.setState({ loadingGroup: false });
    this.setNavItems(this.state.customerType);
  }

  handleFollow = (e) => {
    e.preventDefault();
    if (e.detail.external) {
      window.open(e.detail.href);
    } else {
      this.setState(
        {
          active: e.detail.href,
          redirect: e.detail.href,
        },
        () => this.setState({ redirect: undefined }),
      );
    }
  };

  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }
    if (
      this.state.username === undefined ||
      this.state.activeGroup === undefined
    ) {
      return (
        <div style={{ textAlign: 'center', paddingLeft: '30px' }}>
          <Spinner size='large' />
        </div>
      );
    }

    if (!this.state.allowlisted) {
      if (this.state.internalError) {
        return (
          <Alert header='Something went wrong' type={'error'}>
            Please check back later or reach out to our{' '}
            <Link href={DGS_ONCALL_URL} external={true}>
              oncall
            </Link>{' '}
            for more information.
          </Alert>
        );
      } else {
        // Only display the onboarding page if we can get a valid user from Midway
        return this.state.username ? (
          <OnboardingComponent />
        ) : (
          <h1>Access Denied</h1>
        );
      }
    }

    return (

      <div>
        <PageHeader
          user={this.state.username}
          groups={this.state.groups}
          activeGroup={this.state.activeGroup}
          setGroup={this.setActiveGroup}
          loadingGroup={this.state.loadingGroup}
          groupInfo={this.state.activeGroupInfo}
          cartItems={this.state.cartItems}
          unreadNotifications = {this.state.unreadNotifications}
          removeUnreadNotificationId = {this.state.removeUnreadNotificationId}
          unreadNotificationBatches={this.state.unreadNotificationBatches}
          removeUnreadNotificationBatchId={this.state.removeUnreadNotificationBatchId}
        />
        <AppLayout
          contentType={this.state.contentType}
          className={isHomePage() ? 'awsui-util-no-gutters' : ''}
          content={
            this.state.loadingGroup ? (
              <div>
                <Spinner size='big' />
              </div>
            ) : (
              <div>
                <ErrorBoundary>
                  <Routes
                    {...this.state}
                    toggleHelp={() => this.setState({ toolsOpen: !this.state.toolsOpen })}
                    setContentType={(type) =>
                      this.setState({ contentType: type })
                    }
                    setActiveDatasetName={(name) =>
                      this.setState({ activeDatasetName: name })
                    }
                    setActiveCatalogName={(name) =>
                      this.setState({ activeCatalogName: name })
                    }
                    setActiveDatabaseName={(name) =>
                      this.setState({ activeDatabaseName: name })
                    }
                    setNavigationOpen={(open) =>
                      this.setState({ navOpen: open })
                    }
                    setItemsInCart={(cartItems: object[]) =>
                      this.setCartItemsAndIds(cartItems)
                    }
                    refreshUnreadNotifications={(
                      removeUnreadNotificationId,
                      removeUnreadNotificationBatchId
                    ) => {
                      this.setState({
                        removeUnreadNotificationId: removeUnreadNotificationId,
                        removeUnreadNotificationBatchId: removeUnreadNotificationBatchId
                      });
                    }}
                    addToCart={(items: object[]) => {
                      const newCartItems = [];
                      newCartItems.push(...this.state.cartItems);
                      newCartItems.push(...items);
                      this.setCartItemsAndIds(newCartItems);
                    }}
                    removeFromCart={(items: object[]) => {
                      const itemIDsToRemove = items.map((item) => item['id']);
                      const newCartItems = [];
                      newCartItems.push(
                        ...this.state.cartItems.filter(
                          (item) => !itemIDsToRemove.includes(item['id']),
                        ),
                      );
                      this.setCartItemsAndIds(newCartItems);
                    }}
                    allowListed={this.state.allowlisted}
                    getStartedTarget='/datasets'
                    setNotifications={(notifications)=> this.setState({notifications})}
                  />
                </ErrorBoundary>
                <main className='container' />
              </div>
            )
          }
          breadcrumbs={
            <Breadcrumbs
              activeCatalogName={this.state.activeCatalogName}
              activeDatabaseName={this.state.activeDatabaseName}
              activeDatasetName={this.state.activeDatasetName}
              onFollow={this.handleFollow}
            />
          }
          navigation={
            this.state.loadingGroup ? (
              <div>
                <Spinner size='big' />
              </div>
            ) : (
              <SideNavigation
                items={this.state.navigationItemsForCustomerType}
                activeHref={this.state.active}
                onFollow={this.handleFollow}
              />
            )
          }
          onToolsChange={({ detail }) => this.setState({toolsOpen:detail.open})}
          toolsOpen={this.state.toolsOpen}
          tools={
            <Help
              setToolsHide={(hide) => {
                this.setState({ toolsHide: hide });
              }}
            />
          }
          navigationOpen={this.state.navOpen}
          onNavigationChange={(e) => {
            this.setState({ navOpen: e.detail.open });
          }}
          notifications={<Flashbar items={this.state.notifications}/>}
          toolsHide={this.state.toolsHide}
          labels={appLayoutLabels}
          headerSelector='#top-navbar'
        />
      </div>
    );
  }

  private setCartItemsAndIds(newCartItems: object[]) {
    this.setState({
      cartItems: newCartItems,
      cartItemIds: newCartItems.map((item) => item['id']),
    });
  }
}

export default App;
