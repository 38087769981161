export const values_default = {
  type: 'EMR_GROUP',
  name: '',
  description: 'Emr resource group',
  emrGroupConfig: {
    accountId: '',
    region: 'us-east-1',
    emrManagementRoleArn: '',
    configurations: {
      'capacity-scheduler': {
        'yarn.scheduler.capacity.resource-calculator':
          'org.apache.hadoop.yarn.util.resource.DominantResourceCalculator',
      },
      'emrfs-site': {
        'fs.s3.canned.acl': 'BucketOwnerFullControl',
      },
      'hive-site': {
        'hive.metastore.client.factory.class':
          'com.amazonaws.glue.catalog.metastore.AWSGlueDataCatalogHiveClientFactory',
      },
      'spark-defaults': {
        'spark.pyspark.python': 'python3.6',
        'spark.yarn.appMasterEnv.PYTHONHASHSEED': '0',
      },
      'spark-env': {
        PYSPARK_PYTHON: 'python3.6',
        PYTHONHASHSEED: '0',
        PYTHONPATH:
          "'/home/hadoop:/home/hadoop/emr_sniper:/home/hadoop/bin:/home/hadoop/bin/lib:'$PYTHONPATH",
        'SPARK_YARN_USER_ENV=PYSPARK_PYTHON': 'python3.6',
      },
      'spark-hive-site': {
        'hive.metastore.client.factory.class':
          'com.amazonaws.glue.catalog.metastore.AWSGlueDataCatalogHiveClientFactory',
      },
    },
    clusterRefreshDays: '30',
    clusterRefreshDayHoursInUTC: [9, 10, 11, 12, 13, 14, 15, 16],
    clusterRefreshWeekDaysInUTC: [1, 2, 3, 4, 5],
    applications: ['Hive', 'Spark'],
    bootstrapActions: [],
    ec2SubnetIds: [],
    jobFlowRole: 'EMR_EC2_DefaultRole',
    serviceRole: 'EMR_DefaultRole',
    minClusters: '',
    maxClusters: '',
    isAutoScalingEnabled: false,
    coreInstanceCount: '',
    coreInstanceType: '',
    coreMarket: 'ON_DEMAND',
    masterInstanceCount: '',
    masterInstanceType: '',
    masterMarket: 'ON_DEMAND',
    releaseLabel: '',
    s3LogUri: '',
    securityConfiguration: '',
    customAmiId: '',
    installChronicle: true,
    installSSMPatch: true,
  },
  notification: { enabled: false },
};

export const CREATE_BOOTSTRAP_ACTION = 'CreateBootstrapAction';
export const CREATE_RESOURCE_GROUP_ACTION = 'CreateResourceGroup';
export const CREATE_CONFIG_TEMPLATE = 'CreateTemplate';
export const LIST_RESOURCE_GROUP = 'ListResourceGroups';
export const LIST_BOOTSTRAP_ACTION = 'listBootstrapActions';
export const LIST_TEMPLATE = 'listTemplates';
export const DENY_ACTION = 'Deny';
export const ALLOW_ACTION = 'Allow';

//From https://docs.aws.amazon.com/emr/latest/ManagementGuide/emr-supported-instance-types.html on 06/18/2021
const hardcodedEmrInstances = {
  'General purpose': [
    'm1.small',
    'm1.medium',
    'm1.large',
    'm1.xlarge',
    'm2.xlarge',
    'm2.2xlarge',
    'm2.4xlarge',
    'm3.xlarge',
    'm3.2xlarge',
    'm4.large',
    'm4.xlarge',
    'm4.2xlarge',
    'm4.4xlarge',
    'm4.10xlarge',
    'm4.16xlarge',
    'm5.xlarge',
    'm5.2xlarge',
    'm5.4xlarge',
    'm5.8xlarge',
    'm5.12xlarge',
    'm5.16xlarge',
    'm5.24xlarge',
    'm5a.xlarge',
    'm5a.2xlarge',
    'm5a.4xlarge',
    'm5a.8xlarge',
    'm5a.12xlarge',
    'm5a.16xlarge',
    'm5a.24xlarge',
    'm5d.xlarge',
    'm5d.2xlarge',
    'm5d.4xlarge',
    'm5d.8xlarge',
    'm5d.12xlarge',
    'm5d.16xlarge',
    'm5d.24xlarge',
    'm5zn.xlarge',
    'm5zn.2xlarge',
    'm5zn.3xlarge',
    'm5zn.6xlarge',
    'm5zn.12xlarge',
    'm6g.xlarge',
    'm6g.2xlarge',
    'm6g.4xlarge',
    'm6g.8xlarge',
    'm6g.12xlarge',
    'm6g.16xlarge',
    'm6gd.xlarge',
    'm6gd.2xlarge',
    'm6gd.4xlarge',
    'm6gd.8xlarge',
    'm6gd.12xlarge',
    'm6gd.16xlarge',
  ],
  'Compute-optimized': [
    'c1.medium',
    'c1.xlarge',
    'c3.xlarge',
    'c3.2xlarge',
    'c3.4xlarge',
    'c3.8xlarge',
    'c4.large',
    'c4.xlarge',
    'c4.2xlarge',
    'c4.4xlarge',
    'c4.8xlarge',
    'c5.xlarge',
    'c5.2xlarge',
    'c5.4xlarge',
    'c5.9xlarge',
    'c5.12xlarge',
    'c5.18xlarge',
    'c5.24xlarge',
    'c5a.xlarge',
    'c5a.2xlarge',
    'c5a.4xlarge',
    'c5a.8xlarge',
    'c5d.12xlarge',
    'c5a.16xlarge',
    'c5ad.xlarge',
    'c5ad.2xlarge',
    'c5ad.4xlarge',
    'c5ad.8xlarge',
    'c5ad.12xlarge',
    'c5ad.16xlarge',
    'c5ad.24xlarge',
    'c5d.xlarge',
    'c5d.2xlarge',
    'c5d.4xlarge',
    'c5d.9xlarge',
    'c5d.12xlarge',
    'c5d.18xlarge',
    'c5d.24xlarge',
    'c5n.xlarge',
    'c5n.2xlarge',
    'c5n.4xlarge',
    'c5n.9xlarge',
    'c5n.18xlarge',
    'c6g.xlarge',
    'c6g.2xlarge',
    'c6g.4xlarge',
    'c6g.8xlarge',
    'c6g.12xlarge',
    'c6g.16xlarge',
    'c6gd.xlarge',
    'c6gd.2xlarge',
    'c6gd.4xlarge',
    'c6gd.8xlarge',
    'c6gd.12xlarge',
    'c6gd.16xlarge',
    'c6gn.xlarge',
    'c6gn.2xlarge',
    'c6gn.4xlarge',
    'c6gn.8xlarge',
    'c6gn.12xlarge',
    'c6gn.16xlarge',
    'cc2.8xlarge',
    'z1d.xlarge',
    'z1d.2xlarge',
    'z1d.3xlarge',
    'z1d.6xlarge',
    'z1d.12xlarge',
  ],
  'Memory-optimized': [
    'r3.xlarge',
    'r3.2xlarge',
    'r3.4xlarge',
    'r3.8xlarge',
    'r4.xlarge',
    'r4.2xlarge',
    'r4.4xlarge',
    'r4.8xlarge',
    'r4.16xlarge',
    'r5.xlarge',
    'r5.2xlarge',
    'r5.4xlarge',
    'r5.8xlarge',
    'r5.12xlarge',
    'r5.16xlarge',
    'r5.24xlarge',
    'r5a.xlarge',
    'r5a.2xlarge',
    'r5a.4xlarge',
    'r5a.8xlarge',
    'r5a.12xlarge',
    'r5a.16xlarge',
    'r5a.24xlarge',
    'r5b.xlarge',
    'r5b.2xlarge',
    'r5b.4xlarge',
    'r5b.8xlarge',
    'r5b.12xlarge',
    'r5b.16xlarge',
    'r5b.24xlarge',
    'r5d.xlarge',
    'r5d.2xlarge',
    'r5d.4xlarge',
    'r5d.8xlarge',
    'r5d.12xlarge',
    'r5d.16xlarge',
    'r5d.24xlarge',
    'r5dn.xlarge',
    'r5dn.2xlarge',
    'r5dn.4xlarge',
    'r5dn.8xlarge',
    'r5dn.12xlarge',
    'r5dn.16xlarge',
    'r5dn.24xlarge',
    'r6g.xlarge',
    'r6g.2xlarge',
    'r6g.4xlarge',
    'r6g.8xlarge',
    'r6g.12xlarge',
    'r6g.16xlarge',
    'r6gd.xlarge',
    'r6gd.2xlarge',
    'r6gd.4xlarge',
    'r6gd.8xlarge',
    'r6gd.12xlarge',
    'r6gd.16xlarge',
    'cr1.8xlarge',
  ],
  'Storage-optimized': [
    'h1.2xlarge',
    'h1.4xlarge',
    'h1.8xlarge',
    'h1.16xlarge',
    'hs1.8xlarge',
    'i2.xlarge',
    'i2.2xlarge',
    'i2.4xlarge',
    'i2.8xlarge',
    'i3.xlarge',
    'i3.2xlarge',
    'i3.4xlarge',
    'i3.8xlarge',
    'i3.16xlarge',
    'i3en.xlarge',
    'i3en.2xlarge',
    'i3en.3xlarge',
    'i3en.6xlarge',
    'i3en.12xlarge',
    'i3en.24xlarge',
    'd2.xlarge',
    'd2.2xlarge',
    'd2.4xlarge',
    'd2.8xlarge',
    'd3.xlarge',
    'd3.2xlarge',
    'd3.4xlarge',
    'd3.8xlarge',
    'd3en.xlarge',
    'd3en.2xlarge',
    'd3en.4xlarge',
    'd3en.6xlarge',
    'd3en.8xlarge',
    'd3en.12xlarge',
  ],
  'Accelerated computing (GPU)': [
    'g2.2xlarge',
    'g3.4xlarge',
    'g3.8xlarge',
    'g3.16xlarge',
    'g3s.xlarge',
    'g4dn.xlarge',
    'g4dn.2xlarge',
    'g4dn.4xlarge',
    'g4dn.8xlarge',
    'g4dn.12xlarge',
    'g4dn.16xlarge',
    'p2.xlarge',
    'p2.8xlarge',
    'p2.16xlarge',
    'p3.2xlarge',
    'p3.8xlarge',
    'p3.16xlarge',
  ],
};

const instance_options = Object.keys(hardcodedEmrInstances).map(
  (group, group_ix) =>
    new Object({
      label: group,
      value: 'group_' + group_ix,
      options: hardcodedEmrInstances[group].map(
        (inst) =>
          new Object({
            label: inst,
            value: inst,
            id: inst,
          }),
      ),
    }),
);

export const select_options = {
  clusterRefreshDayHoursInUTC: [...Array(24).keys()].map(
    (i) =>
      new Object({
        id: i,
        label: 1 + ((11 + i) % 12) + (i < 12 ? 'AM' : 'PM'),
        value: i,
      }),
  ),
  clusterRefreshWeekDaysInUTC: [...Array(7).keys()].map(
    (i) =>
      new Object({
        id: i,
        label: ['M', 'T', 'W', 'Th', 'F', 'Sa', 'Su'][i],
        value: i + 1,
      }),
  ),
  bootstrapActions: [], // Updated Asynchronously
  region: [
    {
      id: 0,
      label: 'US East (N. Virginia)',
      description: 'us-east-1',
      value: 'us-east-1',
    },
  ],
  type: [
    {
      id: 0,
      label: 'EMR Cluster',
      value: 'EMR_GROUP',
    },
  ],
  masterInstanceType: [...instance_options],
  coreInstanceType: [...instance_options],
};
