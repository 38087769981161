import { Widget } from '@amzn/cloudwatchdashboards-inside';

export const ingestionKpis = (namespace: string, dataset: string): Widget[] => [
  acquisitionKpis(namespace, dataset),
  ingestion(namespace, dataset),
  recordCounters(namespace, dataset),
];

const timeDelta = 3600*24
const acquisitionKpis = (namespace: string, dataset: string): Widget => ({
  type: 'metric',
  x: 0,
  y: 0,
  width: 8,
  height: 4,
  properties: {
    stat: 'Sum',
    period: timeDelta,
    region: 'us-east-1',
    view: 'singleValue',
    title: 'Acquisition (single events)',
    metrics: [
      [
        namespace,
        'PublishEventsCount',
        'Datasets',
        dataset,
        { label: 'Publish events counter' },
      ],
      [
        '.',
        'AcquisitionFailures',
        '.',
        '.',
        { color: '#ff7f0e', label: 'Acquisition failures' },
      ],
    ],
  },
});

const ingestion = (namespace: string, dataset: string): Widget => ({
  type: 'metric',
  x: 8,
  y: 0,
  width: 8,
  height: 4,
  properties: {
    stat: 'Sum',
    period: timeDelta,
    region: 'us-east-1',
    view: 'singleValue',
    title: 'Ingestion (micro-batches)',
    metrics: [
      [
        namespace,
        'IngestionExecutionCount',
        'Datasets',
        dataset,
        'Views',
        'default_view',
        { color: '#2ca02c', label: 'Ingestion events' },
      ],
      [
        '.',
        'IngestionFailures',
        '.',
        '.',
        '.',
        '.',
        { color: '#d62728', label: 'Ingestion failures' },
      ],
    ],
  },
});

const recordCounters = (namespace: string, dataset: string): Widget => ({
  type: 'metric',
  x: 16,
  y: 0,
  width: 8,
  height: 4,
  properties: {
    stat: 'Sum',
    period: timeDelta,
    region: 'us-east-1',
    view: 'singleValue',
    title: 'Ingested records',
    metrics: [
      [
        namespace,
        'IngestionRecordsCount',
        'Datasets',
        dataset,
        'Views',
        'default_view',
        { label: 'Ingested records', color:'#9467bd' },
      ],
      [
        '.',
        'AcquiredBytes',
        '.',
        '.',
        { color: '#aec7e8', label: 'Acquired bytes' },
      ],
    ],
  },
});
