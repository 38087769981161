import * as React from 'react';
import { useState, useEffect } from 'react';

import { useCollection } from '@amzn/awsui-collection-hooks';

import {
  CollectionPreferencesProps,
  CollectionPreferences,
  Pagination,
  TextFilter,
  TableProps,
  Table,
  Button,
  Modal,
  Flashbar,
  Textarea,
  FlashbarProps,
  Box,
  SpaceBetween,
} from '@amzn/awsui-components-react-v3';

import {
  approveRequest,
  denyRequest,
  cancelRequest,
  getRequestedByRequests,
  getRequestedToRequests,
  getRequestedByRequestsHistory,
  getRequestedToRequestsHistory,
} from '../../../api/permissions';
import { PageHeader } from '../../subscriptions/common';
import { Redirect } from 'react-router-dom';
import { Page } from '../../../routes/Paths';
import {
  defaultWrapLinesPreference,
  paginationLabels,
} from 'src/commons/tables';

export interface LakeFormationTableProps {
  setContentType: any;
  activeGroup: string;
  requestType: string;
  requestBoundOutgoing: boolean;
}

export const LakeFormationTable = (props: LakeFormationTableProps) => {
  const [allItems, setItems] = useState([]);
  const [notifications, setNotifications] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);
  const [isInvalid, setInvalid] = useState(false);
  const [textAreaPlaceholder] = useState('Enter text here');
  const [showHistory, setShowHistory] = useState(false);
  const [redirect, setRedirect] = useState(undefined);
  const [modalVisible, setModalVisible] = useState(false);
  const [action, setAction] = useState(undefined);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loadingLakeFormationPermissions, setLoadingLakeFormationPermissions] =
    useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [tableMessage, setTableMessage] = useState(
    'No Lake Formation permissions',
  );

  const [nextToken] = useState(undefined);
  const [requestType] = useState('CreateLakeFormationGrantPermission');
  const [tableHeading] = useState(`Lake Formation access requests`);
  const [reasonOfAction, setReasonOfAction] = useState(undefined);

  const [preferences, setPreferences] =
    useState<CollectionPreferencesProps.Preferences>({
      wrapLines: false,
      pageSize: 10,
    });

  const columnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'status',
      header: 'Status',
      cell: (item) => item.status,
      minWidth: 100,
    },
    {
      id: 'principal',
      header: 'Principal',
      cell: (item) => item.principal,
      minWidth: 200,
      sortingField: 'principal',
    },
    {
      id: 'catalogId',
      header: 'Catalog ID',
      cell: (item) => item.catalogId,
      minWidth: 200,
      sortingField: 'catalogId',
    },
    {
      id: 'databaseName',
      header: 'Database name',
      cell: (item) => item.databaseName,
      minWidth: 250,
      sortingField: 'databaseName',
    },
    {
      id: 'tableName',
      header: 'Table name',
      cell: (item) => item.tableName,
      minWidth: 250,
      sortingField: 'tableName',
    },
    {
      id: 'requestedBy',
      header: 'Requested by',
      cell: (item) => item.groupRequestedBy,
      minWidth: 100,
    },
  ];

  useEffect(() => {
    props.setContentType('table');
    handleRefresh();
  }, []);

  useEffect(() => {
    handleRefresh();
  }, [props.activeGroup]);

  const transformData = (items) => {
    return items.map((item) => {
      const transform = {};
      transform['requestId'] = item.requestId;
      transform['principal'] = item.dataLakePrincipal;
      transform['status'] = item.status;
      transform['owner'] = item.groupRequestedTo;
      transform['groupRequestedBy'] = item.groupRequestedBy;
      transform['permissions'] = JSON.parse(
        item.lakeFormationRequest.PermissionsString,
      );
      transform['grantablePermissions'] = JSON.parse(
        item.lakeFormationRequest.PermissionsWithGrantOptionString,
      );
      transform['resource'] = JSON.parse(
        item.lakeFormationRequest.ResourceString,
      );
      if (transform['resource'].table) {
        transform['catalogId'] = transform['resource'].table.catalogId;
        transform['databaseName'] = transform['resource'].table.databaseName;
        transform['resource'].table.tableWildcard
          ? (transform['tableName'] = 'All Tables')
          : (transform['tableName'] = transform['resource'].table.name);
      } else {
        transform['catalogId'] =
          transform['resource'].tableWithColumns.catalogId;
        transform['databaseName'] =
          transform['resource'].tableWithColumns.databaseName;
        transform['tableName'] = transform['resource'].tableWithColumns.name;
        if (transform['resource'].tableWithColumns.columnNames) {
          transform['columnNames'] =
            transform['resource'].tableWithColumns.columnNames;
          transform['columnWildCard'] = 'Include';
        } else {
          transform['columnNames'] =
            transform[
              'resource'
            ].tableWithColumns.columnWildcard.excludedColumnNames;
          transform['columnWildCard'] = 'Exclude';
        }
      }
      return transform;
    });
  };

  const handleRefresh = async () => {
    closeModal();
    if (!props.activeGroup) return;
    setLoadingLakeFormationPermissions(true);

    try {
      const request = {
        groupId: props.activeGroup,
        requestType: requestType,
        nextToken: nextToken,
      };

      //TODO: Use token to grab more results
      //Get history of requests (includes pending)
      let targets;
      if (showHistory) {
        !props.requestBoundOutgoing
          ? (targets = await getRequestedToRequestsHistory(request))
          : (targets = await getRequestedByRequestsHistory(request));
      } else {
        //Normal requests for only pending
        !props.requestBoundOutgoing
          ? (targets = await getRequestedToRequests(request))
          : (targets = await getRequestedByRequests(request));
      }

      setItems(transformData(targets.requestList));
      setLoadingLakeFormationPermissions(false);
    } catch (err) {
      setTableMessage(
        `Unable to load Lake Formation permissions: ${err.message}`,
      );
      setLoadingLakeFormationPermissions(false);
    }
  };

  const toggleShowHistory = async () => {
    const history = showHistory;
    setShowHistory(history);
    await handleRefresh();
  };

  const openModal = (clickedAction) => {
    setModalVisible(true);
    setAction(clickedAction);
  };

  const closeModal = () => {
    setModalVisible(false);
    setAction(undefined);
    setButtonLoading(false);
  };

  const successMessage = (message, action) => {
    if (action === 'approved') {
      setNotifications([
        {
          type: 'success',
          content: `Lake Formation '${message}' was '${action}'`,
          dismissible: true,
          action: (
            <Button onClick={() => setRedirect(Page.MY_DATASETS)}>
              View datasets
            </Button>
          ),
          onDismiss: () => setNotifications([]),
        },
      ]);
    } else {
      setNotifications([
        {
          type: 'success',
          content: `Lake Formation '${message}' was '${action}'`,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }
  };

  const errorMessage = (message, action) => {
    setNotifications([
      {
        type: 'error',
        content: `There was an error '${action}' your Lake Formation request: '${message}'`,
        onDismiss: () => setNotifications([]),
      },
    ]);
  };

  const cancelAction = async () => {
    if (!reasonOfAction) {
      setInvalid(true);
      return;
    }
    setButtonLoading(true);
    try {
      await cancelRequest({
        requestId: collectionProps.selectedItems[0].requestId,
        reason: reasonOfAction,
      });
      successMessage(collectionProps.selectedItems[0].requestId, 'cancelled');
      await handleRefresh();
    } catch (err) {
      console.log(err);
      errorMessage(err.message, 'cancelling');
    }
  };

  const approveAction = async () => {
    setActionLoading(true);
    try {
      await approveRequest({
        requestId: collectionProps.selectedItems[0].requestId,
      });
      successMessage(collectionProps.selectedItems[0].requestId, 'approved');
      await handleRefresh();
    } catch (err) {
      console.log(err);
      errorMessage(err.message, 'approving');
    }
    setActionLoading(false);
  };

  const denyAction = async () => {
    if (!reasonOfAction) {
      setInvalid(true);
      return;
    }
    setButtonLoading(true);
    try {
      await denyRequest({
        requestId: collectionProps.selectedItems[0].requestId,
        reason: reasonOfAction,
      });
      successMessage(collectionProps.selectedItems[0].requestId, 'denied');
      await handleRefresh();
    } catch (err) {
      console.log(err);
      errorMessage(err.message, 'denying');
    }
  };

  const viewAction = () => {
    console.log('RequestId:', collectionProps.selectedItems[0].requestId);
    setRedirect(
      Page.REQUEST_DETAILS.replace(
        ':id',
        collectionProps.selectedItems[0].requestId,
      ),
    );
  };

  const submitAction = (action) => {
    if (action === 'cancel') cancelAction();
    else if (action === 'approve') approveAction();
    else if (action === 'deny') denyAction();
    else if (action === 'view') viewAction();
  };

  const handleAction = async (e) => {
    if (e.detail.id === 'cancel') {
      openModal('cancel');
    } else if (e.detail.id === 'approve') {
      await submitAction(e.detail.id);
    } else if (e.detail.id === 'deny') {
      openModal('deny');
    } else if (e.detail.id === 'view') {
      await submitAction(e.detail.id);
    }
  };

  const options = () => {
    const requestButton = {
      text: 'Request access',
      variant: 'primary',
      onItemClick: async () =>
        setRedirect(Page.CREATE_LAKE_FORMATION_PERMISSIONS),
    };
    const options = [
      {
        text: '',
        icon: 'refresh',
        onItemClick: handleRefresh,
      },
      {
        text: !showHistory ? 'Show history' : 'Hide history',
        variant: 'normal',
        onItemClick: toggleShowHistory,
      },
      {
        text: 'Actions',
        onItemClick: handleAction,
        loading: actionLoading,
        items: !props.requestBoundOutgoing
          ? [
              {
                text: 'Approve',
                id: 'approve',
                disabled:
                  !collectionProps.selectedItems.length ||
                  collectionProps.selectedItems[0].status !== 'PENDING',
              },
              {
                text: 'Deny',
                id: 'deny',
                disabled:
                  !collectionProps.selectedItems.length ||
                  collectionProps.selectedItems[0].status !== 'PENDING',
              },
              {
                text: 'View',
                id: 'view',
                disabled: !collectionProps.selectedItems.length,
              },
            ]
          : [
              {
                text: 'Cancel',
                id: 'cancel',
                disabled:
                  !collectionProps.selectedItems.length ||
                  collectionProps.selectedItems[0].status !== 'PENDING',
              },
              {
                text: 'View',
                id: 'view',
                disabled: !collectionProps.selectedItems.length,
              },
            ],
      },
    ];
    if (props.requestBoundOutgoing) options.push(requestButton);
    return options;
  };

  const {
    items,
    collectionProps,
    paginationProps,
    filterProps,
    filteredItemsCount,
  } = useCollection(allItems, {
    filtering: {
      noMatch: (
        <div className='awsui-util-t-c'>
          <div className='awsui-util-pt-s awsui-util-mb-xs'>
            <b>No matches</b>
          </div>
          <p className='awsui-util-mb-s'>We can’t find a match.</p>
        </div>
      ),
      empty: (
        <div className='awsui-util-t-c'>
          <div className='awsui-util-pt-s awsui-util-mb-xs'>
            <b>{tableMessage}</b>
          </div>
          <p className='awsui-util-mb-s'>No Lake Formation permissions</p>
        </div>
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties: [],
    },
  });

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <div>
      <Flashbar items={notifications} />
      <br />
      <Modal
        visible={modalVisible}
        header={
          action === 'cancel'
            ? 'Enter reason for canceling'
            : 'Enter reason for denying'
        }
        onDismiss={() => closeModal()}
        footer={
          <Box float='right'>
            <SpaceBetween direction='horizontal' size='xs'>
              <Button variant='link' onClick={() => closeModal()}>
                No
              </Button>
              <Button
                variant='primary'
                loading={buttonLoading}
                onClick={() => submitAction(action)}
              >
                Yes
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        <Textarea
          value={reasonOfAction}
          onChange={({ detail }) => {
            setInvalid(false);
            setReasonOfAction(detail.value);
          }}
          placeholder={textAreaPlaceholder}
          invalid={isInvalid}
        />
        Are you sure you want to {action} this request?
      </Modal>
      <Table
        {...collectionProps}
        loadingText='Loading Lake Formation permissions...'
        loading={loadingLakeFormationPermissions}
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        header={
          <>
            <PageHeader
              buttons={options()}
              header={
                <>
                  {tableHeading}
                  <span className='awsui-util-header-counter'>
                    {` (${items.length})`}
                  </span>
                </>
              }
            />
          </>
        }
        pagination={
          <Pagination {...paginationProps} ariaLabels={paginationLabels} />
        }
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel='Filter resources'
            filteringPlaceholder='Find resources'
            countText={`${filteredItemsCount} ${
              filteredItemsCount === 1 ? 'match' : 'matches'
            }`}
          />
        }
        selectionType={'single'}
        trackBy={'requestId'}
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={{
              title: 'Page size',
              options: [
                { value: 10, label: '10 items' },
                { value: 25, label: '25 items' },
                { value: 50, label: '50 items' },
              ],
            }}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
      />
    </div>
  );
};
