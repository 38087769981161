import { TextContent } from '@amzn/awsui-components-react-v3';
import * as React from 'react';

export const SchemaText = ({ text }: { text: string }) => (
  <TextContent>
    <p style={{ whiteSpace: 'pre-wrap' }}>
      <code>{text}</code>
    </p>
  </TextContent>
);
