import { useEffect, useState } from 'react';
import { DropdownStatusProps } from '@amzn/awsui-components-react-v3/polaris/internal/components/dropdown-status';
import { getLatestDataSetDetail } from '../../../../api/catalog'
import {HybridCatalogDataSet} from "aws-sdk/clients/awsdlhybridcatalogservicelambda";

export type Status = DropdownStatusProps.StatusType;

export const useColumns = (table: HybridCatalogDataSet | undefined) => {
  const [status, setStatus] = useState<Status>('loading');
  const [columns, setColumns] = useState<any[]>([]);

  const getColumns = async () => {
    let request = {Filter: { IdList: [table['Id']]}};
    const response = await getLatestDataSetDetail(request);
    setColumns(response.DataSetDetailList[0].Columns);
  }

  useEffect(() => {
    if (!table) {
      setColumns([]);
      setStatus('finished');
      return;
    }
    setStatus('loading');
    getColumns()
      .then(() => setStatus('finished'))
      .catch(() => setStatus('error'));
  }, [table]);

  return { status, columns };
};