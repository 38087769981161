import * as React from 'react';
import { Component } from 'react';
import { getResource } from '../../../api/resourcesmanager';
import {
  RMPageHeader,
  ErrorAlert,
  coloredStatus,
  LabeledTextColumns,
  AuditLogsCard,
} from '../components';
import { Redirect } from 'react-router-dom';
import { dateString } from '../helpers';
import { Page } from '../../../routes/Paths';

export interface resourceDetailProps {
  setContentType: any;
  match: any;
  activeGroup: string;
}

export interface resourceDetailState {
  loading: boolean;
  resource: object;
  error: object;
  redirect: string;
  contentType: string;
  redirectParams: object;
}

export class ResourceDetails extends Component<
  resourceDetailProps,
  resourceDetailState
> {
  state = {
    loading: true,
    error: undefined,
    resource: null,
    redirect: undefined,
    contentType: undefined,
    redirectParams: {},
  };

  componentDidMount = async () => {
    this.props.setContentType('cards');
    await this.handleRefresh();
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.activeGroup !== this.props.activeGroup) {
      await this.handleRefresh();
    }
  };

  /**
   * Asynchronously fetches the resources from the RM API and updates the state.
   */
  handleRefresh = async () => {
    this.setState({ loading: true });

    try {
      const resource = await getResource({
        id: this.props.match.params.id,
      });
      this.setState({
        resource,
        loading: false,
      });
    } catch (err) {
      this.setState({
        error: { ...err, while: 'LOADING RESOURCE' },
        resource: null,
        loading: false,
      });
    }
  };

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.redirect,
            state: this.state.redirectParams,
          }}
        />
      );
    }

    const copiable = true;

    return (
      <>
        {ErrorAlert(this.state.error)}
        <RMPageHeader subheader={this.state.resource?.name} />
        <div className='awsui-util-container'>
          <div className='awsui-util-container-header'>
            <h2>Resource details</h2>
          </div>
          <LabeledTextColumns
            labeledTextList={[
              {
                label: 'Resource ID',
                value: this.state.resource?.id,
                copiable,
              },
              { label: 'Name', value: this.state.resource?.name },
              {
                label: 'Description',
                value: this.state.resource?.description,
              },
            ]}
            loading={this.state.loading}
          />
          <LabeledTextColumns
            labeledTextList={[
              { label: 'Type', value: this.state.resource?.type },
              {
                label: 'Status',
                value: coloredStatus(this.state.resource?.status),
              },
              {
                label: 'Status reason',
                value: this.state.resource?.statusReason,
              },
              {
                label: 'EC2 subnet ID',
                value: this.state.resource?.ec2SubnetId,
                copiable,
              },
              {
                label: 'Owner (Group ID)',
                value: this.state.resource?.groupId,
                copiable,
              },
              {
                label: 'Resource group ID',
                value: this.state.resource?.resourceGroupId,
                url: Page.RESOURCEGROUP_DETAILS.replace(
                  ':id',
                  this.state.resource?.resourceGroupId,
                ),
                copiable,
              },
              {
                label: 'Is backup?',
                value: this.state.resource?.isBackup ? 'YES' : 'NO',
              },
              {
                label: 'Create date',
                value: dateString(this.state.resource?.createDate),
              },
              {
                label: 'Update date',
                value: dateString(this.state.resource?.updateDate),
              },
            ]}
            columns={3}
            loading={this.state.loading}
          />
        </div>
        <AuditLogsCard
          auditLogs={this.state.resource?.auditLogs}
          loading={this.state.loading}
        />
      </>
    );
  }
}
