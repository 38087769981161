import React, { useEffect, useCallback, useState } from 'react';
import {
  RadioGroup,
  Checkbox,
  FormField,
  Multiselect,
  CheckboxProps,
  MultiselectProps,
  RadioGroupProps,
  SelectProps,
} from '@amzn/awsui-components-react-v3';
import { TablePermissions } from './tablePermissionsFormFields';
import { useColumns } from './use-columns';
import { HybridCatalogDataSet } from 'aws-sdk/clients/awsdlhybridcatalogservicelambda';

interface ColumnPermissionsFormFieldsProps {
  table: HybridCatalogDataSet | undefined;
  permissions: TablePermissions;
  onChange?: (change: ColumnPermissionsChange) => void;
  onGrantablePermissionsChange?: (permissions: TablePermissions) => void;
}

export interface ColumnPermissionsChange {
  columnPermissionType: string;
  columns: SelectProps.Option[];
}

const ColumnPermissionsFormFields = (
  props: ColumnPermissionsFormFieldsProps,
) => {
  const { table, onChange, onGrantablePermissionsChange } = props;
  const { status, columns } = useColumns(table);
  const [columnPermissionType, setColumnPermissionType] = useState('include');
  const [selectedColumns, setSelectedColumns] = useState<any[]>([]);

  //Update table to "undefined" when the table changes and doesn't exist
  useEffect(() => {
    if (!table) {
      props.onChange(undefined);
      return;
    }
  }, [table]);

  //Set initial tables incase they don't select a column
  useEffect(() => {
    const columnsFromShare = selectedColumnsFromShare(optionsFrom(columns));
    onChange({ columns: columnsFromShare, columnPermissionType });
  }, [columns, columnPermissionType]);

  const handleOptionChange = useCallback(
    (e: CustomEvent<RadioGroupProps.ChangeDetail>) => {
      setColumnPermissionType(e.detail.value);
      setSelectedColumns([]);
    },
    [],
  );

  const columnPermissionOptions = [
    {
      value: 'include',
      label: 'Include columns',
      description: 'Grant permissions to access specific columns.',
      disabled: table['ColumnWildCard'] === 'Exclude',
    },
    {
      value: 'exclude',
      label: 'Exclude columns',
      description: 'Grant permissions to access all but specific columns.',
      disabled: table['ColumnWildCard'] === 'Include',
    },
  ];

  const optionsFrom = (columns: any): SelectProps.Option[] => {
    if (
      table['ColumnWildCard'] === null ||
      table['ColumnWildCard'] === undefined
    )
      return columns.map((column) => ({
        value: column.Name,
        label: column.Name,
        description: column.Type || 'No description available',
      }));
    if (table['ColumnWildCard'] === 'Include') {
      return columns.map((column) => ({
        value: column.Name,
        label: column.Name,
        description: column.Type || 'No description available',
        disabled: !table['Columns'].includes(column.Name),
      }));
    }
    if (table['ColumnWildCard'] === 'Exclude') {
      return columns.map((column) => ({
        value: column.Name,
        label: column.Name,
        description: column.Type || 'No description available',
        disabled: table['Columns'].includes(column.Name),
      }));
    }
  };

  const selectedColumnsFromShare = (columns: SelectProps.Option[]): SelectProps.Option[] => {
    if (
      table['ColumnWildCard'] === null ||
      table['ColumnWildCard'] === undefined
    )
      return [];
    if (table['ColumnWildCard'] === 'Include') {
      if (columnPermissionType === 'exclude')
        return columns.filter(
          (column) => !table['Columns'].includes(column.value),
        );
      if (columnPermissionType === 'include')
        return columns.filter((column) =>
          table['Columns'].includes(column.value),
        );
    }
    if (table['ColumnWildCard'] === 'Exclude') {
      if (columnPermissionType === 'exclude')
        return columns.filter((column) =>
          table['Columns'].includes(column.value),
        );
      if (columnPermissionType === 'include')
        return columns.filter(
          (column) => !table['Columns'].includes(column.value),
        );
    }
    return [];
  };

  const handleColumnSelectionChange = useCallback(
    (e: CustomEvent<MultiselectProps.MultiselectChangeDetail>) => {
      const eventSelectedColumns = [...e.detail.selectedOptions];
      setSelectedColumns(eventSelectedColumns);

      if (onChange) {
        const columnsFromShare = selectedColumnsFromShare(optionsFrom(columns));

        //Combine selected with excluded if excluding
        const combinedColumns =
          columnPermissionType === 'exclude'
            ? eventSelectedColumns.concat(columnsFromShare)
            : eventSelectedColumns.length == 0
            ? columnsFromShare
            : eventSelectedColumns;
        onChange({ columns: combinedColumns, columnPermissionType });
      }
    },
    [columns, columnPermissionType],
  );

  const handleGrantablePermissionsChange = useCallback(
    (e: CustomEvent<CheckboxProps.ChangeDetail>) => {
      const perms: TablePermissions = {
        tablePermissions: ['SELECT'],
        grantablePermissions: e.detail.checked ? ['SELECT'] : [],
      };

      if (onGrantablePermissionsChange) {
        onGrantablePermissionsChange(perms);
      }
    },
    [onGrantablePermissionsChange],
  );

  return (
    <>
      <FormField
        label='Choose permission filter'
        description='Choose whether to include or exclude columns'
      >
        <RadioGroup
          value={columnPermissionType}
          items={columnPermissionOptions}
          onChange={handleOptionChange}
        />
      </FormField>
      <FormField label='Select columns'>
        <Multiselect
          options={optionsFrom(columns)}
          placeholder='Choose one or more columns | Choosing none will choose all available'
          filteringType='auto'
          statusType={status}
          empty={table ? 'No columns available.' : 'Select a table.'}
          loadingText='Loading Columns.'
          errorText='Unable to load columns.'
          keepOpen={true}
          onChange={handleColumnSelectionChange}
          selectedOptions={selectedColumns}
        />
      </FormField>
      <FormField
        label='Grantable permissions'
        description='Choose the permission that may be granted to others.'
      >
        <Checkbox
          id='SELECT'
          onChange={handleGrantablePermissionsChange}
          checked={
            !!props?.permissions?.grantablePermissions?.find(
              (p) => p === 'SELECT',
            )
          }
        >
          Select
        </Checkbox>
      </FormField>
    </>
  );
};

export default ColumnPermissionsFormFields;
