import {
  Box,
  Pagination,
  Table,
  TextFilter,
} from '@amzn/awsui-components-react-v3';
import * as React from 'react';
import { PageHeader } from 'src/components/permissions/myDatasets/common';
import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  getFieldOnChange,
  getFilterCounterText,
} from 'src/components/permissions/myBaselining/baseliningUtils';

export function IAMPrincipal({ info, props, onChange }) {
  const columnDefinitions = [
    {
      id: 'trustedIdentities',
      header: 'Trusted Identity',
      cell: (item) => item,
    },
  ];
  const { selectedIAMPrinciples } = info.BaselineIAMPrincipal;
  const selectedIAMPrinciplesChange = getFieldOnChange(
    'selectedIAMPrinciples',
    onChange,
  );

  const {
    items,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
  } = useCollection(props.trustedEntities, {
    filtering: {
      empty: (
        <Box textAlign='center' variant='p'>
          <div className='awsui-util-pt-s awsui-util-mb-xs'>
            <b>No dataset shares</b>
          </div>
          <p className='awsui-util-mb-s'>
            No dataset shares were found in the catalog.
          </p>
        </Box>
      ),
      noMatch: (
        <Box textAlign='center' variant='p'>
          <div className='awsui-util-pt-s awsui-util-mb-xs'>
            <b>No matches</b>
          </div>
          <p className='awsui-util-mb-s'>We can’t find a match.</p>
        </Box>
      ),
    },
    pagination: { pageSize: 15 },
    sorting: {},
    selection: {},
  });

  return (
    <>
      <Table
        {...collectionProps}
        loadingText='Loading IAM principals...'
        columnDefinitions={columnDefinitions}
        items={items}
        resizableColumns
        header={
          <>
            <PageHeader
              buttons={[]}
              header={
                <>
                  Trusted IAM principals
                  <span className='awsui-util-header-counter'>
                    {` (${items.length})`}
                  </span>
                </>
              }
            />
          </>
        }
        empty={
          <Box textAlign='center' variant='p'>
            <p className='awsui-util-mb-s'>No trusted IAM principals.</p>
          </Box>
        }
        filter={
          <TextFilter
            {...filterProps}
            countText={getFilterCounterText(filteredItemsCount)}
          />
        }
        pagination={<Pagination {...paginationProps} />}
        selectionType='multi'
        selectedItems={selectedIAMPrinciples}
        onSelectionChange={selectedIAMPrinciplesChange}
      />
    </>
  );
}
