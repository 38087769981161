import {
  Button,
  ColumnLayout,
  Container,
  ExpandableSection,
  Flashbar,
  Header,
  Spinner,
} from '@amzn/awsui-components-react-v3';
import StatusIndicator from '@amzn/awsui-components-react-v3/polaris/status-indicator';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { getGroupBaselineInfo } from 'src/api/permissions';

export function StartPage({ props: { activeGroup } }) {
  const [baselineInfo, setBaselineInfo] = useState(undefined);
  const [loadingBaselineInfo, setLoadingBaselineInfo] = useState(true);
  const [notifications, setNotifications] = useState([]);

  const loadBaselineInfo = async () => {
    setLoadingBaselineInfo(true);
    let baselineInfoResult;
    try {
      baselineInfoResult = await getGroupBaselineInfo({
        groupId: activeGroup,
      });
      console.log('info' + JSON.stringify(baselineInfoResult));
    } catch (err) {
      setNotifications([
        {
          type: 'error',
          content:
            'Failed to get baseline information for group: ' + activeGroup,
          dismissible: true,
          dismiss: () => this.setState({ notifications: [] }),
        },
      ]);
      return;
    }
    setBaselineInfo(baselineInfoResult.baselineInfo);
    setLoadingBaselineInfo(false);
  };

  useEffect(() => {
    (async () => {
      await loadBaselineInfo();
    })();
  }, []);

  const formatStatusAndReason = (statusAndReason) => {
    if (statusAndReason.status == 'COMPLETE') {
      return (
        <div>
          <StatusIndicator type='success'>Success</StatusIndicator>
        </div>
      );
    } else if (statusAndReason.status == 'FAILED') {
      return (
        <StatusIndicator type='error'>{statusAndReason.reason}</StatusIndicator>
      );
    } else {
      return <StatusIndicator type='loading'>In progress</StatusIndicator>;
    }
  };

  return (
    <>
      <Flashbar items={notifications} />
      <Container
        header={
          <Header variant='h2'>Welcome to the Omni baselining tool</Header>
        }
      >
        <ColumnLayout columns={1}>
          For security and compliance reasons there is a requirement for data
          providers to periodically double-check the access they have granted to
          consumers, and revoke outdated permissions if necessary. Please update
          the permissions if necessary, and verify the correctness of each step.
        </ColumnLayout>
        &nbsp;
        {loadingBaselineInfo && <Spinner />}
        {loadingBaselineInfo == false && baselineInfo != null && (
          <ExpandableSection header='Last baselining information'>
            <div>
              <strong>
                {' '}
                Last baselining time: {baselineInfo.lastBaselineTimestamp}
              </strong>
              <Button
                iconName='refresh'
                variant='inline-icon'
                onClick={loadBaselineInfo}
              />
              {((baselineInfo.baselineResult.iamDatasetsToDelete != null &&
                baselineInfo.baselineResult.iamDatasetsToDelete.length != 0) ||
                (baselineInfo.baselineResult.iamPrincipalToDelete != null &&
                  baselineInfo.baselineResult.iamPrincipalToDelete.length !=
                    0) ||
                (baselineInfo.baselineResult
                  .dataSetShareToDeactivateAsConsumer != null &&
                  baselineInfo.baselineResult.dataSetShareToDeactivateAsConsumer
                    .length != 0) ||
                (baselineInfo.baselineResult.dataSetShareToDeactivateAsOwner !=
                  null &&
                  baselineInfo.baselineResult.dataSetShareToDeactivateAsOwner
                    .length != 0)) && (
                <div>
                  <p>&nbsp;</p>
                  <ColumnLayout borders='all' columns={3}>
                    <div>
                      <strong>Baseline type</strong>
                    </div>
                    <div>
                      <strong>Baseline content</strong>
                    </div>
                    <div>
                      <strong>Baseline status</strong>
                    </div>

                    {baselineInfo.baselineResult.iamDatasetsToDelete != null &&
                      baselineInfo.baselineResult.iamDatasetsToDelete != '' && (
                        <div>IAM Access</div>
                      )}
                    {baselineInfo.baselineResult.iamDatasetsToDelete != null &&
                      baselineInfo.baselineResult.iamDatasetsToDelete != '' && (
                        <div>
                          {baselineInfo.baselineResult.iamDatasetsToDelete}
                        </div>
                      )}
                    {baselineInfo.baselineResult.iamDatasetsToDelete != null &&
                      baselineInfo.baselineResult.iamDatasetsToDelete != '' && (
                        <div>
                          {formatStatusAndReason(
                            baselineInfo.baselineResult
                              .iamDatasetsToDeleteStatusAndReason,
                          )}
                        </div>
                      )}

                    {baselineInfo.baselineResult.iamPrincipalToDelete != null &&
                      baselineInfo.baselineResult.iamPrincipalToDelete !=
                        '' && <div>IAM Principals</div>}
                    {baselineInfo.baselineResult.iamPrincipalToDelete != null &&
                      baselineInfo.baselineResult.iamPrincipalToDelete !=
                        '' && (
                        <div>
                          {baselineInfo.baselineResult.iamPrincipalToDelete}
                        </div>
                      )}
                    {baselineInfo.baselineResult.iamPrincipalToDelete != null &&
                      baselineInfo.baselineResult.iamPrincipalToDelete !=
                        '' && (
                        <div>
                          {formatStatusAndReason(
                            baselineInfo.baselineResult
                              .iamPrincipalToDeleteStatusAndReason,
                          )}
                        </div>
                      )}

                    {baselineInfo.baselineResult
                      .dataSetShareToDeactivateAsConsumer != null &&
                      baselineInfo.baselineResult
                        .dataSetShareToDeactivateAsConsumer != '' && (
                        <div> Lake Formation access as consumer </div>
                      )}
                    {baselineInfo.baselineResult
                      .dataSetShareToDeactivateAsConsumer != null &&
                      baselineInfo.baselineResult
                        .dataSetShareToDeactivateAsConsumer != '' && (
                        <div>
                          {
                            baselineInfo.baselineResult
                              .dataSetShareToDeactivateAsConsumer
                          }
                        </div>
                      )}
                    {baselineInfo.baselineResult
                      .dataSetShareToDeactivateAsConsumer != null &&
                      baselineInfo.baselineResult
                        .dataSetShareToDeactivateAsConsumer != '' && (
                        <div>
                          {formatStatusAndReason(
                            baselineInfo.baselineResult
                              .dataSetShareToDeactivateAsConsumerStatusAndReason,
                          )}
                        </div>
                      )}

                    {baselineInfo.baselineResult
                      .dataSetShareToDeactivateAsOwner != null &&
                      baselineInfo.baselineResult
                        .dataSetShareToDeactivateAsOwner != '' && (
                        <div> Lake Formation access as owner </div>
                      )}
                    {baselineInfo.baselineResult
                      .dataSetShareToDeactivateAsOwner != null &&
                      baselineInfo.baselineResult
                        .dataSetShareToDeactivateAsOwner != '' && (
                        <div>
                          {
                            baselineInfo.baselineResult
                              .dataSetShareToDeactivateAsOwner
                          }
                        </div>
                      )}
                    {baselineInfo.baselineResult
                      .dataSetShareToDeactivateAsOwner != null &&
                      baselineInfo.baselineResult
                        .dataSetShareToDeactivateAsOwner != '' && (
                        <div>
                          {formatStatusAndReason(
                            baselineInfo.baselineResult
                              .dataSetShareToDeactivateAsOwnerStatusAndReason,
                          )}
                        </div>
                      )}
                  </ColumnLayout>
                </div>
              )}
              {(baselineInfo.baselineResult.iamDatasetsToDelete == null ||
                baselineInfo.baselineResult.iamDatasetsToDelete.length == 0) &&
                (baselineInfo.baselineResult.iamPrincipalToDelete == null ||
                  baselineInfo.baselineResult.iamPrincipalToDelete.length ==
                    0) &&
                (baselineInfo.baselineResult
                  .dataSetShareToDeactivateAsConsumer == null ||
                  baselineInfo.baselineResult.dataSetShareToDeactivateAsConsumer
                    .length == 0) &&
                (baselineInfo.baselineResult.dataSetShareToDeactivateAsOwner ==
                  null ||
                  baselineInfo.baselineResult.dataSetShareToDeactivateAsOwner
                    .length == 0) && (
                  <div>
                    No permission has been revoked or relinquished during last
                    baselining.
                  </div>
                )}
            </div>
          </ExpandableSection>
        )}
        {loadingBaselineInfo == false && baselineInfo == null && (
          <ExpandableSection header='Last baselining information'>
            No baselining record.
          </ExpandableSection>
        )}
      </Container>
    </>
  );
}
