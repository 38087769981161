import { Header, Button, Container } from '@amzn/awsui-components-react-v3';
import { GetDataSetResponse } from 'aws-sdk/clients/tethyscontractservicelambda';
import { UpdateRedirect } from '../../common/navigation';
import { SchemaText } from '../../common/schemaText';
import React from 'react';

export const SchemaTab = ({ dataset }: { dataset: GetDataSetResponse }) => {
  const [redirect, setRedirect] = React.useState<boolean>();

  if (redirect) return <UpdateRedirect id={dataset.DataSetId}/>;

  return (
    <Container
      header={
        <Header
          variant='h2'
          actions={
            <Button onClick={() => setRedirect(true)}>
              Update
            </Button>
          }
        >
          Data Contract
        </Header>
      }
    >
      <SchemaText text={dataset.DataContract.DataProperties.SchemaDefinition} />
    </Container>
  );
};
