import React from 'react';
import {
  Box,
  Header,
  Button,
  Container,
  SpaceBetween,
  ColumnLayout,
} from '@amzn/awsui-components-react-v3';
import { PrimaryKeys } from '../../common/primaryKeys';
import { GetDataSetResponse } from 'aws-sdk/clients/tethyscontractservicelambda';
import { UpdateRedirect } from '../../common/navigation';

export const ContractTab = ({ dataset }: { dataset: GetDataSetResponse }) => {
  const [redirect, setRedirect] = React.useState<boolean>()

  if (redirect) return <UpdateRedirect id={dataset.DataSetId}/>;
  
  return (
    <Container
      header={
        <Header 
          variant='h2' 
          actions={
            <Button onClick={() => setRedirect(true)}> Update </Button>
          }
        >
          Data Contract
        </Header>
      }
    >
      <ColumnLayout columns={3} variant='text-grid'>
        <SpaceBetween size='l'>
          <div>
            <Box variant='awsui-key-label'>Dataset Name</Box>
            <div> {dataset.TableName} </div>
          </div>

          <div>
            <Box variant='awsui-key-label'>Database name</Box>
            <div> {dataset.DatabaseName} </div>
          </div>

          <div>
            <Box variant='awsui-key-label'> Catalog Id </Box>
            <div> {dataset.CatalogId} </div>
          </div>

          <div>
            <Box variant='awsui-key-label'>IAM role</Box>
            <div> {dataset.DataContract.ServiceLevelAgreement.IAMRole} </div>
          </div>

          <div>
            <Box variant='awsui-key-label'>Publish Type</Box>
            <div>
              {dataset.DataContract.ServiceLevelAgreement.PublishType}{' '}
            </div>
          </div>
        </SpaceBetween>

        <SpaceBetween size='l'>
          <div>
            <Box variant='awsui-key-label'>File Type</Box>
            <div> {dataset.DataContract.FileProperties.FileType} </div>
          </div>

          <div>
            <Box variant='awsui-key-label'>Header Lines</Box>
            <div> {dataset.DataContract.FileProperties.HeaderLines} </div>
          </div>

          <div>
            <Box variant='awsui-key-label'>Field Delimeter</Box>
            <div> {dataset.DataContract.FileProperties.FieldDelimiter} </div>
          </div>
        </SpaceBetween>

        <SpaceBetween size='l'>
          <div>
            <Box variant='awsui-key-label'>Compression</Box>
            <div>
              {dataset.DataContract.DataProperties.CompressionFormat || 'None'}
            </div>
          </div>

          <div>
            <Box variant='awsui-key-label'>Primary Keys (dedup)</Box>
            <PrimaryKeys
              keys={dataset.DataContract.DataProperties.PrimaryKeyColumns}
            />
          </div>

          <div>
            <Box variant='awsui-key-label'>Ordering Key (dedup)</Box>
            <div> {dataset.DataContract.DataProperties.DeDupKeyColumn} </div>
          </div>
        </SpaceBetween>
      </ColumnLayout>
    </Container>
  );
};
