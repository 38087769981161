// returns a comparator that compares two objects by multiple keys
export function compareBy(...keys) {
  function compare(item1: object, item2: object) {
    for (const key of keys) {
      if (item1[key] != undefined && item1[key].localeCompare(item2[key]))
        return item1[key].localeCompare(item2[key]);
    }
    return -1;
  }
  return compare;
}
