import * as React from 'react';
import { Page } from './Paths';
import { Redirect, Route, Switch } from 'react-router-dom';
import { CreateSubscriptionComponent } from '../components/subscriptions/create';
import { CreateConnectionComponent } from '../components/connections/create';
import PublishDataSetComponent from '../components/catalog/publish';
import { PublisherAccounts } from '../components/publishTables/listAccounts';
import { RegisterPublisherAccount } from '../components/publishTables/registerAccount';
import { RegisterPublisherTable } from '../components/publishTables/registerTable';
import TableDetail from '../components/publishTables/tableDetail';
import BrowseTable from '../components/catalog/browsedatasets';
import DataSetDetail from '../components/catalog/datasetdetail';
import PublishTablesComponent from '../components/publishTables/publishTables';
import { SubscriptionsTable } from '../components/subscriptions/subscriptions';
import { ConnectionsTable } from '../components/connections/connections';
import { ConnectionDetails } from '../components/connections/connection';
import { ExecutionsTable } from '../components/subscriptions/executions';
import CreateBackfillComponent from '../components/publishTables/createBackfill';
import PostDedupeComponent from '../components/publishTables/dedupe';
import MyRequests from 'src/components/requests/requests';
import { CreateNotificationForm } from 'src/components/notifications/create';
import { MyNotifications } from 'src/components/notifications/notificationsPage';
import { NotificationTemplateTable } from 'src/components/notifications/notificationTemplate/notificationTemplateList';
import { NotificationTemplateDetail } from 'src/components/notifications/notificationTemplate/notificationTemplateDetail';
import { NotificationTemplateCreateForm } from 'src/components/notifications/notificationTemplate/notificationTemplateCreateForm';
import { NotificationDetailPage } from 'src/components/notifications/detailPage/notificationDetailPage';
import { NotificationTemplateUpdateForm } from 'src/components/notifications/notificationTemplate/notificationTemplateUpdateForm';
import { NotificationBatchDetailPage } from 'src/components/notifications/detailPage/notificationBatchDetailPage';
import BrowseCatalogsTable from '../components/catalog/browsecatalogs';
import BrowseDatabasesTable from '../components/catalog/browsedatabases';
import CatalogDetail from '../components/catalog/catalogdetail';
import DatabaseDetail from '../components/catalog/databasedetail';
import CreateLakeFormationPermissionComponent from '../components/permissions/myPermissions/createLakeFormationPermissionsPage';
import { PermissionRequestDetails } from '../components/permissions/myPermissions/permissionRequestDetails';
import { DatasetsTable } from '../components/permissions/myDatasets/datasets';
import { DatasetsDetailsTable } from '../components/permissions/myDatasets/datasetsDetails';
import { MyDependencies } from '../components/dependencies/dependencies';
import { CreateDependency } from '../components/dependencies/create';
import { DependencyDetail } from '../components/dependencies/dependencyDetail';
import { MyResourceGroups } from '../components/resourcesmanager/resourcegroups/myResourceGroups';
import { ResourceGroupDetails } from '../components/resourcesmanager/resourcegroups/resourcegroupDetails';
import { ResourceDetails } from '../components/resourcesmanager/resourcegroups/resourceDetails';
import { ResourceGroupForm } from '../components/resourcesmanager/resourcegroups/resourcegroupForm';
import { BootstrapActionDetails } from '../components/resourcesmanager/bootstrapactions/bootstrapactionDetails';
import { BootstrapActionForm } from '../components/resourcesmanager/bootstrapactions/bootstrapactionForm';
import { TemplateForm } from '../components/resourcesmanager/templates/templateForm';
import { TemplateDetails } from '../components/resourcesmanager/templates/templateDetails';

// Ingestion
import { UpdateContract } from '../components/ingestion/registerContract/update';
import { RegisterDataset } from '../components/ingestion/registerContract';
import { DatasetDetail } from '../components/ingestion/datasetDetail';
import { Monitoring } from 'src/components/ingestion/monitoring';

import { Home } from '../components/home/home';
import { Checkout } from '../components/common/checkout';
import MyGroups from 'src/components/groups/groups';
import { MyBootstrapActions } from 'src/components/resourcesmanager/bootstrapactions/myBootstrapActions';
import { Baselining } from 'src/components/permissions/myBaselining/baselining';
import { NotificationConfiguration } from 'src/components/notifications/notificationConfiguration';

const IngestionRoutes = ({
  username,
  toggleHelp,
  activeGroup,
  setContentType,
  setNotifications,
}) => (
  <>
    <Route
      exact
      path={[Page.INGESTION.REGISTER_CONTRACT]}
      render={() => (
        <RegisterDataset
          username={username}
          activeGroup={activeGroup}
          toggleHelp={toggleHelp}
          setContentType={setContentType}
          setNotifications={setNotifications}
        />
      )}
    />
    <Route
      exact
      path={[Page.INGESTION.DETAIL]}
      render={({ match }) => (
        <DatasetDetail
          params={match.params}
          toggleHelp={toggleHelp}
          activeGroup={activeGroup}
          setContentType={setContentType}
        />
      )}
    />
    <Route
      exact
      path={[Page.INGESTION.MONITORING]}
      render={({ match }) => (
        <Monitoring
          toggleHelp={toggleHelp}
          params={match.params}
          setContentType={setContentType}
        />
      )}
    />
    <Route
      exact
      path={[Page.INGESTION.UPDATE_CONTRACT]}
      render={({ match }) => (
        <UpdateContract
          username={username}
          params={match.params}
          toggleHelp={toggleHelp}
          activeGroup={activeGroup}
          setContentType={setContentType}
          setNotifications={setNotifications}
        />
      )}
    />
  </>
);

export const Routes = (props) => (
  <Switch>
    <Route
      path={[Page.HOME]}
      exact
      render={(route_props) => <Home {...route_props} {...props} />}
    />

    <Route
      path={Page.BROWSE_CATALOGS}
      exact
      render={(route_props) => (
        <BrowseCatalogsTable {...route_props} {...props} title='Catalogs' />
      )}
    />
    <Route
      path={Page.CATALOG_DETAILS}
      exact
      render={(route_props) => <CatalogDetail {...route_props} {...props} />}
    />

    <Route
      path={Page.BROWSE_DATABASES}
      exact
      render={(route_props) => (
        <BrowseDatabasesTable {...route_props} {...props} title='Databases' />
      )}
    />
    <Route
      path={Page.DATABASE_DETAILS}
      exact
      render={(route_props) => <DatabaseDetail {...route_props} {...props} />}
    />

    <Route
      path={Page.BROWSE_DATASETS}
      exact
      render={(route_props) => (
        <BrowseTable
          {...route_props}
          {...props}
          title='Datasets'
          extraFeatures={true}
        />
      )}
    />
    <Route
      path={Page.DATASET_DETAILS}
      exact
      render={(route_props) => <DataSetDetail {...route_props} {...props} />}
    />

    <Route
      path={Page.SUBSCRIPTIONS}
      exact
      render={(route_props) => (
        <SubscriptionsTable {...route_props} {...props} />
      )}
    />
    <Route
      path={[Page.CREATE_SUBSCRIPTION]}
      exact
      render={(route_props) => (
        <CreateSubscriptionComponent {...route_props} {...props} />
      )}
    />
    <Route
      path={Page.SUBSCRIPTION_DETAILS}
      exact
      render={(route_props) => <ExecutionsTable {...route_props} {...props} />}
    />

    <Route
      path={Page.CONNECTIONS}
      exact
      render={(route_props) => <ConnectionsTable {...route_props} {...props} />}
    />
    <Route
      path={Page.CREATE_CONNECTION}
      exact
      render={(route_props) => (
        <CreateConnectionComponent {...route_props} {...props} />
      )}
    />
    <Route
      path={Page.CONNECTION_DETAILS}
      exact
      render={(route_props) => (
        <ConnectionDetails {...route_props} {...props} />
      )}
    />

    <Route
      path={Page.PUBLISH}
      exact
      render={(route_props) => (
        <PublishDataSetComponent {...route_props} {...props} />
      )}
    />

    <Route
      path={Page.PUBLISHER_TABLES}
      exact
      render={(route_props) => (
        <PublishTablesComponent {...route_props} {...props} />
      )}
    />
    <Route
      path={Page.CREATE_PUBLISHER_TABLE}
      exact
      render={(route_props) => (
        <RegisterPublisherTable {...route_props} {...props} />
      )}
    />
    <Route
      path={Page.PUBLISHER_CREATE_BACKFILL}
      exact
      render={(route_props) => (
        <CreateBackfillComponent {...route_props} {...props} />
      )}
    />
    <Route
      path={Page.PUBLISHER_POST_DEDUPE}
      exact
      render={(route_props) => (
        <PostDedupeComponent {...route_props} {...props} />
      )}
    />
    <Route
      path={Page.PUBLISHER_TABLE_DETAILS}
      exact
      render={(route_props) => <TableDetail {...route_props} {...props} />}
    />

    <Route
      path={Page.PUBLISHER_ACCOUNTS}
      exact
      render={(route_props) => (
        <PublisherAccounts {...route_props} {...props} />
      )}
    />
    <Route
      path={Page.PUBLISHER_REGISTER_ACCOUNT}
      exact
      render={(route_props) => (
        <RegisterPublisherAccount {...route_props} {...props} />
      )}
    />

    <Route
      path={Page.MY_DATASETS}
      exact
      render={(route_props) => <DatasetsTable {...route_props} {...props} />}
    />
    <Route
      path={Page.CREATE_LAKE_FORMATION_PERMISSIONS}
      exact
      render={(route_props) => (
        <CreateLakeFormationPermissionComponent
          {...route_props}
          {...props}
          permissionType='request'
        />
      )}
    />
    <Route
      path={Page.DATASETSHARE_DETAILS}
      exact
      render={(route_props) => (
        <DatasetsDetailsTable {...route_props} {...props} />
      )}
    />

    <Route
      path={Page.REQUESTS}
      exact
      render={(route_props) => <MyRequests {...route_props} {...props} />}
    />
    <Route
      path={Page.REQUEST_DETAILS}
      exact
      render={(route_props) => (
        <PermissionRequestDetails {...route_props} {...props} />
      )}
    />

    <Route
      path={Page.DEPENDENCIES}
      exact
      render={(route_props) => <MyDependencies {...route_props} {...props} />}
    />
    <Route
      path={Page.CREATE_DEPENDENCY}
      exact
      render={(route_props) => <CreateDependency {...route_props} {...props} />}
    />
    <Route
      path={Page.DEPENDENCY_DETAILS}
      exact
      render={(route_props) => <DependencyDetail {...route_props} {...props} />}
    />

    <Route
      path={Page.GROUP}
      exact
      render={(route_props) => <MyGroups {...route_props} {...props} />}
    />
    <Route
      path={Page.GROUPS}
      exact
      render={(route_props) => <MyGroups {...route_props} {...props} />}
    />

    {/* START OF ADMIN ONLY ROUTES:  */}

    <Route
      path={Page.RESOURCEGROUPS}
      exact
      render={(route_props) => <MyResourceGroups {...route_props} {...props} />}
    />
    <Route
      path={Page.CREATE_RESOURCEGROUP}
      exact
      render={(route_props) => (
        <ResourceGroupForm {...route_props} {...props} />
      )}
    />
    <Route
      path={Page.UPDATE_RESOURCEGROUP}
      exact
      render={(route_props) => (
        <ResourceGroupForm {...route_props} {...props} isUpdate />
      )}
    />
    <Route
      path={Page.RESOURCEGROUP_DETAILS}
      exact
      render={(route_props) => (
        <ResourceGroupDetails {...route_props} {...props} />
      )}
    />
    <Route
      path={Page.RESOURCE_DETAILS}
      exact
      render={(route_props) => <ResourceDetails {...route_props} {...props} />}
    />

    <Route
      path={Page.BOOTSTRAPACTIONS}
      exact
      render={(route_props) => (
        <MyBootstrapActions {...route_props} {...props} />
      )}
    />
    <Route
      path={Page.CREATE_BOOTSTRAPACTION}
      exact
      render={(route_props) => (
        <BootstrapActionForm {...route_props} {...props} />
      )}
    />
    <Route
      path={Page.UPDATE_BOOTSTRAPACTION}
      exact
      render={(route_props) => (
        <BootstrapActionForm {...route_props} {...props} isUpdate />
      )}
    />
    <Route
      path={Page.BOOTSTRAPACTION_DETAILS}
      exact
      render={(route_props) => (
        <BootstrapActionDetails {...route_props} {...props} />
      )}
    />

    <Route
      path={Page.CREATE_TEMPLATE}
      exact
      render={(route_props) => <TemplateForm {...route_props} {...props} />}
    />
    <Route
      path={Page.UPDATE_TEMPLATE}
      exact
      render={(route_props) => (
        <TemplateForm {...route_props} {...props} isUpdate />
      )}
    />
    <Route
      path={Page.TEMPLATE_DETAILS}
      exact
      render={(route_props) => <TemplateDetails {...route_props} {...props} />}
    />

    {/* END OF ADMIN ONLY ROUTES */}

    <Route
      path={Page.CHECKOUT}
      exact
      render={(route_props) => <Checkout {...route_props} {...props} />}
    />

    <Route
      path={Page.NOTIFICATIONS}
      exact
      render={(route_props) => <MyNotifications {...route_props} {...props} />}
    />
    <Route
      path={[Page.CREATE_NOTIFICATION]}
      exact
      render={(route_props) => (
        <CreateNotificationForm {...route_props} {...props} />
      )}
    />
    <Route
      path={[Page.NOTIFICATION_DETAILS]}
      exact
      render={(route_props) => (
        <NotificationDetailPage {...route_props} {...props} />
      )}
    />
    <Route
      path={[Page.NOTIFICATION_BATCH_DETAILS]}
      exact
      render={(route_props) => (
        <NotificationBatchDetailPage {...route_props} {...props} />
      )}
    />

    <Route
      path={[Page.NOTIFICATION_TEMPLATES]}
      exact
      render={(route_props) => (
        <NotificationTemplateTable {...route_props} {...props} />
      )}
    />
    <Route
      path={[Page.CREATE_NOTIFICATION_TEMPLATE]}
      exact
      render={(route_props) => (
        <NotificationTemplateCreateForm {...route_props} {...props} />
      )}
    />
    <Route
      path={[Page.UPDATE_NOTIFICATION_TEMPLATE]}
      exact
      render={(route_props) => (
        <NotificationTemplateUpdateForm {...route_props} {...props} />
      )}
    />
    <Route
      path={[Page.NOTIFICATION_TEMPLATE_DETAILS]}
      exact
      render={(route_props) => (
        <NotificationTemplateDetail {...route_props} {...props} />
      )}
    />
    <Route
      path={[Page.NOTIFICATION_CONFIGURATION]}
      exact
      render={(route_props) => (
        <NotificationConfiguration {...route_props} {...props} />
      )}
    />
    <Route
      path={Page.BASELINING}
      exact
      render={(route_props) => <Baselining {...route_props} {...props} />}
    />

    <IngestionRoutes {...props} />

    <Route
      path={Page.DEPRECATED_PERMISSIONS}
      exact
      render={(_) => <Redirect to={Page.MY_DATASETS} />}
    />
    <Route
      path={Page.DEPRECATED_PERMISSIONS_DETAILS}
      exact
      render={(route_props) => {
        return (
          <Redirect
            to={Page.REQUEST_DETAILS.replace(
              ':id',
              route_props.match.params.id,
            )}
          />
        );
      }}
    />
    <Route
      path={Page.DEPRECATED_CATALOG}
      exact
      render={(_) => <Redirect to={Page.BROWSE_DATASETS} />}
    />
    <Route
      path={Page.DEPRECATED_CATALOG_DETAILS}
      exact
      render={(route_props) => {
        return (
          <Redirect
            to={Page.DATASET_DETAILS.replace(
              ':id',
              route_props.match.params.id,
            )}
          />
        );
      }}
    />

    <Route path='*' render={(_) => <Redirect to='/' />} />
  </Switch>
);

export default Routes;
