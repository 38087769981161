import * as React from 'react';
import {
  Button,
  Form,
  Container,
  Header,
  SpaceBetween,
  Flashbar,
  FlashbarProps,
} from '@amzn/awsui-components-react-v3';

import * as validate from '../../../commons/validationUtils';
import { Redirect } from 'react-router-dom';
import {
  createBootstrapAction,
  updateBootstrapAction,
  getBootstrapAction,
  listBootstrapActions,
} from '../../../api/resourcesmanager';
import { createBootstrapError, renderInputsFromList, someInputInvalid } from '../components';
import { Page } from '../../../routes/Paths';

export interface BootstrapActionFormProps {
  setContentType: any;
  location: any;
  activeGroup: string;
  isUpdate: boolean;
  match: any;
}

export interface BootstrapActionFormState {
  submitting: boolean;
  notifications: FlashbarProps.MessageDefinition[];
  attempted: boolean;
  redirect: string;
  values: any;
  bsaS3PathArgs: object;
  bsaNames: object;
}

const optional = true;

export class BootstrapActionForm extends React.Component<
  BootstrapActionFormProps,
  BootstrapActionFormState
> {
  state = {
    notifications: [],
    submitting: false,
    attempted: false,
    redirect: undefined,
    values: {
      name: undefined,
      description: undefined,
      s3FileLocation: undefined,
      isDefaultBootstrap: false,
      args: undefined,
    },
    bsaS3PathArgs: new Set([]),
    bsaNames: new Set([]),
  };

  renderInputs = renderInputsFromList.bind(this);
  someInputInvalid = someInputInvalid.bind(this);
  inputs = [
    {
      fieldKey: 'name',
      fieldLabel: 'Name',
      placeholder: 'Name of bootstrap action',
      validation: (val) => !this.state.bsaNames.has(val.trim()),
      errorText: 'Name must be unique.',
    },
    {
      fieldKey: 'description',
      fieldLabel: 'Description',
      placeholder: 'A brief description of the bootstrap action',
      fieldType: 'textarea',
      optional,
    },
    {
      fieldKey: 's3FileLocation',
      fieldLabel: 'S3 location',
      placeholder: 's3://directory/example_file.ext',
      validation: (val) => validate.isValidS3Path(val),
      errorText:
        'S3 File Location must be correctly formatted and unique (in combination with args).',
    },
    {
      fieldKey: 'args',
      fieldLabel: 'Args',
      description: 'Comma-separated list of arguments',
      placeholder: 'arg1, arg2, arg3, ...',
      optional,
    },
    {
      fieldKey: 'isDefaultBootstrap',
      fieldLabel: 'Is default?',
      fieldType: 'toggle',
      optional,
    },
  ];

  componentDidMount = async () => {
    this.props.setContentType('form');

    var values = { ...this.state.values };

    Object.keys(this.state.values).forEach(
      (key) =>
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.values &&
        key in this.props.location.state.values &&
        (values[key] = this.props.location.state.values[key]),
    );

    if (this.props.isUpdate) {
      try {
        const bsa = await getBootstrapAction({
          id: this.props.match.params.id,
        });
        Object.keys(this.state.values).forEach(
          (key) => key in bsa && (values[key] = bsa[key]),
        );
      } catch (err) {
        this.setState({
          notifications: createBootstrapError({
            ...err,
            errorWhile: 'LOADING BOOTSTRAP ACTION',
          }),
        });
        return;
      }
    }

    values.args = values.args ? this.joinArgs(values.args) : '';
    this.setState({ values });

    const bsas = (
      await listBootstrapActions({ groupId: this.props.activeGroup })
    ).bootstrapActions;
    var bsaNames = new Set(bsas.map((bsa) => bsa.name.trim()));
    var bsaS3PathArgs = new Set(
      bsas.map((bsa) => this.s3ArgsJoin(bsa.s3FileLocation, bsa.args)),
    );
    if (this.props.isUpdate) {
      bsaNames.delete(values.name.trim());
      bsaS3PathArgs.delete(this.s3ArgsJoin(values.s3FileLocation, values.args));
    }
    this.setState({ bsaNames, bsaS3PathArgs });
  };

  /**
   * [UNGUARDED]: Asynchronous call to update the existing Bootstrap Action with the user-defined configuration.
   * If succesful, redirects to BSA. If failure, raises Error Flashbar.
   */
  updateBSA = async (values) => {
    values.id = this.props.match.params.id;
    try {
      const output = await updateBootstrapAction(values);
      this.setState({
        submitting: false,
        redirect: Page.BOOTSTRAPACTION_DETAILS.replace(':id', output.id),
      });
    } catch (err) {
      this.setState({
        notifications: createBootstrapError({
          ...err,
          errorWhile: 'UPDATING BOOTSTRAP ACTION',
          submitting: false,
        }),
      });
    }
  };

  /**
   * [UNGUARDED]: Asynchronous call to create a new Bootstrap Action with the user-defined configuration.
   * If succesful, redirects to BSA. If failure, raises Error Flashbar.
   */
  createBSA = async (values) => {
    values.groupId = this.props.activeGroup;
    try {
      const output = await createBootstrapAction(values);
      this.setState({
        submitting: false,
        redirect: Page.BOOTSTRAPACTION_DETAILS.replace(':id', output.id),
      });
    } catch (err) {
      this.setState({
        submitting: false,
        notifications: createBootstrapError({
          ...err,
          errorWhile: 'CREATING BOOTSTRAP ACTION',
        }),
      });
    }
  };

  /**
   * A class that handles either creating a new, or updating the current Bootstrap Action.
   */
  handleSubmit = async () => {
    this.setState({ submitting: true, attempted: true });
    var values = new Object({ ...this.state.values });
    values['args'] = this.splitArgs(values['args']);

    if (this.someInputInvalid(this.inputs)) {
      this.setState({
        submitting: false,
        notifications: createBootstrapError({
          message:
            'Please verify that all required fields are completed and correct. ',
          code: 'Invalid Input',
          errorWhile: 'SUBMITTING FORM',
        }),
      });
      return;
    }

    const s3PathArgs = this.s3ArgsJoin(
      values['s3FileLocation'],
      values['args'],
    );

    if (this.state.bsaS3PathArgs.has(s3PathArgs)) {
      this.setState({
        submitting: false,
        notifications: createBootstrapError({
          message:
            'You must input a unique pair of S3 File and args. The current pair already exists.',
          code: 'Invalid Input',
          errorWhile: 'SUBMITTING FORM',
        }),
      });
      return;
    }

    if (this.props.isUpdate) {
      this.updateBSA(values);
    } else {
      this.createBSA(values);
    }
  };

  /**
   * Returns a cleaned string that joins together the S3 File location and args, in order to form a unique key pair.
   *
   * @param {string} s3FileLocation -  The location of a bootstrap action
   * @param {string or array} args - Comma-separated string or array of strings of arguments for bootstrap action
   * @returns
   */
  s3ArgsJoin = (s3FileLocation, args) => {
    var norm_args = args ? args : [];
    norm_args = this.joinArgs(this.splitArgs(args));
    // Joins s3File and normalized argument string with a separator token in order to form unique element.
    const res = s3FileLocation.trim() + '<_SEP_>' + norm_args;
    return res;
  };

  joinArgs = (args) => {
    if (typeof args === 'undefined') {
      return '';
    } else if (typeof args === 'string') {
      return args;
    } else if (!Array.isArray(args)) {
      throw Error('joinArgs received non-string, non-Array args: ' + args);
    } else {
      return args
        .map((a) => a.trim())
        .filter((a) => a != '')
        .join(', ')
        .trim();
    }
  };

  splitArgs = (args) => {
    if (typeof args === 'undefined') {
      return [];
    } else if (Array.isArray(args)) {
      return args;
    } else if (typeof args !== 'string') {
      throw Error('splitArgs received non-string, non-Array args: ' + args);
    } else {
      return args
        .split(',')
        .map((a) => a.trim())
        .filter((a) => a != '');
    }
  };

  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }

    return (
      <div>
        <Flashbar items={this.state.notifications} />

        <Form
          header={
            <Header variant='h1'>
              {this.props.isUpdate ? 'Edit ' : 'Create new '} bootstrap action
            </Header>
          }
          actions={
            <SpaceBetween size='s' direction='horizontal'>
              <Button
                variant='link'
                onClick={() =>
                  this.setState({ redirect: Page.BOOTSTRAPACTIONS })
                }
              >
                Cancel
              </Button>
              <Button
                variant='primary'
                onClick={this.handleSubmit}
                loading={this.state.submitting}
              >
                {this.props.isUpdate ? 'Update' : 'Create'}
              </Button>
            </SpaceBetween>
          }
        >
          <Container
            className='custom-screenshot-hide'
            header={
              <h2>
                {this.props.isUpdate
                  ? this.props.match.params.id
                  : 'Bootstrap action details'}
              </h2>
            }
          >
            {this.renderInputs(this.inputs)}
          </Container>
        </Form>
      </div>
    );
  }
}
