import { useEffect, useState } from 'react';
import { getDataSetsFromHybridCatalogDatabase } from '../../../../api/catalog';
import { getDataSetSharesFiltered } from '../../../../api/permissions';
import { Database } from 'aws-sdk/clients/glue';
import { HybridCatalogDataSet } from 'aws-sdk/clients/awsdlhybridcatalogservicelambda';
import { Status } from '../permissionSelector/use-columns';


const ALL_TABLES_OPTION = (dataSetShare): any => ({
  Id: 'All|Tables',
  Owners: dataSetShare.groupId,
  IdInfo: {
    DatabaseName: dataSetShare.databaseName,
    CatalogId: dataSetShare.catalogId,
    TableName: 'All Tables',
    Region: dataSetShare.region,
  },
  LakeFormationRoleARN: dataSetShare.audit['DataLocationArn'],
  LakeFormationAuditARN: dataSetShare.audit['DataAuditArn'],
  DataSetName: 'Click here to select all entries below.',
});

const DATA_ID = (database: Database): string =>
  'DS-' +
  (database['DataSourceType'] === undefined ||
  database['DataSourceType'] === null
    ? 'glue'
    : database['DataSourceType']) +
  '|A-' +
  database['CatalogId'] +
  '|DN-' +
  database['DatabaseName'] +
  '|R-' +
  database['Region'];

export const useTables = (
  database: Database | undefined,
  groupId: string | undefined,
) => {
  const [status, setStatus] = useState<Status>('loading');
  const [tables, setTables] = useState<HybridCatalogDataSet[]>([]);

  const getTables = async () => {
    //Get Data Set Shares for Tables / DB
    let request = {
      groupId: groupId,
      dataId: DATA_ID(database),
      status: 'Active',
      option: 'Publisher',
      type: 'LF',
      nextToken: null,
    };
    let response = await getDataSetSharesFiltered(request);
    //Check if there is an all tables
    let isAllTables = response.dataSetShareList.filter((val) => {
      return val.tableName.includes('ALL_TABLES');
    });
    if (isAllTables.length > 0) {
      await getAllTables(isAllTables[0]);
    } else {
      transformSharesToTables(response.dataSetShareList);
    }
  };

  const transformSharesToTables = (dataSetShareList) => {
    const convertedTables = dataSetShareList.map((item) => {
      const transform = {};
      const dataParts = item.dataId.split('|');
      dataParts.splice(3, 0, 'TN-' + item.tableName);
      transform['Id'] = dataParts.join('|');
      transform['DataId'] = item.dataId;
      transform['Owners'] = item.groupId;
      //Id Info
      const idInfo = {};
      idInfo['DatabaseName'] = item.databaseName;
      idInfo['CatalogId'] = item.catalogId;
      idInfo['TableName'] = item.tableName;
      idInfo['Region'] = item.region;
      transform['IdInfo'] = idInfo;
      transform['Columns'] = item.columns;
      transform['ColumnWildCard'] = item.columnWildCard;
      transform['LakeFormationRoleARN'] = item.audit['DataLocationArn'];
      transform['LakeFormationAuditARN'] = item.audit['DataAuditArn'];
      transform['DataSetName'] = ''; //This doesn't exist from the share.. if this is needed its in hybrid catalog, another query though
      return transform;
    });
    setTables(convertedTables ? convertedTables : []);
  };

  //If Share is all tables (also all columns)
  const getAllTables = async (dataSetShare) => {
    //Set catalogID and Database to get first batch of tables
    let request = {
      DatabaseName: database['DatabaseName'],
      CatalogId: database['CatalogId'],
      NextToken: undefined,
    };
    let response = await getDataSetsFromHybridCatalogDatabase(request);
    let dataSetList = [...response.DataSetList];
    //Loop and get remaining tables
    while (response.NextToken != null) {
      request.NextToken = response.NextToken;
      response = await getDataSetsFromHybridCatalogDatabase(request);
      dataSetList.push(...response.DataSetList);
    }
    //Add Arn from Share to Tables
    dataSetList.forEach((dataSet) => {
      dataSet.LakeFormationRoleARN = dataSetShare.audit['DataLocationArn'];
      dataSet.Owners = dataSetShare.groupId;
    });
    //All Tables option.
    const selectAllOption = ALL_TABLES_OPTION(dataSetShare);
    //Set all Tables for selection
    setTables(dataSetList ? [selectAllOption, ...dataSetList] : []);
  };

  useEffect(() => {
    if (!database) {
      setTables([]);
      setStatus('finished');
      return;
    }
    setStatus('loading');
    getTables()
      .then(() => setStatus('finished'))
      .catch(() => setStatus('error'));
  }, [database]);

  return { status, tables };
};
