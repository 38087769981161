export const Page = {
  /*
    NOTE: the order of the paths should be noted here, as they are checked
    in this order. For example, connections/new comes before connections/:id
    so that 'new' is not taken as the ID of a connection and the wrong page
    rendered. Breadcrumbs.tsx, breadcrumbx.tsx, and Routes.tsx should follow
    this order too.
  */

  HOME: '/',

  BROWSE_CATALOGS: '/catalogs',
  CATALOG_DETAILS: '/catalogs/:catalogid/:region',

  BROWSE_DATABASES: '/databases',
  DATABASE_DETAILS: '/databases/:catalogid/:databasename',

  BROWSE_DATASETS: '/datasets',
  DATASET_DETAILS: '/datasets/:id',

  CONNECTIONS: '/connections',
  CREATE_CONNECTION: '/connections/new',
  CONNECTION_DETAILS: '/connections/:id',

  SUBSCRIPTIONS: '/subscriptions',
  CREATE_SUBSCRIPTION: '/subscriptions/new',
  SUBSCRIPTION_DETAILS: '/subscriptions/:id',

  PUBLISH: '/publish',

  PUBLISHER_TABLES: '/publisher',
  CREATE_PUBLISHER_TABLE: '/publisher/tables/new',
  PUBLISHER_CREATE_BACKFILL:
    '/publisher/tables/:TableId/:TableName/createBackfill',
  PUBLISHER_POST_DEDUPE: '/publisher/tables/:TableId/:TableName/dedupe',
  PUBLISHER_TABLE_DETAILS: '/publisher/tables/:TableId/:TableName',

  PUBLISHER_ACCOUNTS: '/accounts',
  PUBLISHER_REGISTER_ACCOUNT: '/accounts/new',

  MY_DATASETS: '/mydatasets',
  CREATE_LAKE_FORMATION_PERMISSIONS: '/mydatasets/lakeformation/new',
  DATASETSHARE_DETAILS: '/mydatasets/:id',

  REQUESTS: '/requests',
  REQUEST_DETAILS: '/requests/:id',

  DEPENDENCIES: '/dependencies',
  CREATE_DEPENDENCY: '/dependencies/new',
  DEPENDENCY_DETAILS: '/dependency/:id',

  RESOURCEGROUPS: '/resourcegroups',
  CREATE_RESOURCEGROUP: '/resourcegroups/new',
  UPDATE_RESOURCEGROUP: '/resourcegroups/edit/:id',
  RESOURCEGROUP_DETAILS: '/resourcegroups/:id',
  RESOURCE_DETAILS: '/resourcegroups/:rgid/:id',

  BOOTSTRAPACTIONS: '/bootstrapactions',
  CREATE_BOOTSTRAPACTION: '/bootstrapactions/new',
  UPDATE_BOOTSTRAPACTION: '/bootstrapactions/edit/:id',
  BOOTSTRAPACTION_DETAILS: '/bootstrapactions/:id',

  CREATE_TEMPLATE: '/template/new',
  UPDATE_TEMPLATE: '/template/edit/:id',
  TEMPLATE_DETAILS: '/template/:id',
  CHECKOUT: '/checkout',

  NOTIFICATIONS: '/notifications',
  GROUPS: '/groups/:id',
  GROUP: '/groups',
  CREATE_NOTIFICATION: '/notifications/new',
  NOTIFICATION_DETAILS: '/notifications/:NotificationId',
  NOTIFICATION_BATCH_DETAILS: '/notification-batch/:NotificationBatchId',

  NOTIFICATION_TEMPLATES: '/notificationtemplates',
  CREATE_NOTIFICATION_TEMPLATE: '/notificationtemplates/create',
  UPDATE_NOTIFICATION_TEMPLATE:
    '/notificationtemplates/:NotificationTemplateId/update',
  NOTIFICATION_TEMPLATE_DETAILS:
    '/notificationtemplates/:NotificationTemplateId',
  NOTIFICATION_CONFIGURATION:
    '/notification-configuration',

  BASELINING: '/baselining',

  // INGESTION
  INGESTION:{
    REGISTER_CONTRACT: '/publisher/register',
    MANAGE_DATASETS: '/publisher',
    DETAIL: '/publisher/dataset/:arn/:dataset',
    UPDATE_CONTRACT: '/publisher/dataset/:arn/:dataset/update',
    MONITORING: '/publisher/monitoring/:team/:database/:arn/:dataset',
  },

  // Deprecated paths should still exist for URL backwards compatibility
  // if you change a route you need to keep the old one and redirect to the new route from Routes.tsx
  DEPRECATED_PERMISSIONS: '/permissions',
  DEPRECATED_PERMISSIONS_DETAILS: '/permissions/:id',
  DEPRECATED_CATALOG: '/catalog',
  DEPRECATED_CATALOG_DETAILS: '/catalog/:id',
};

export function createPublisherTableLink(tableId, tableName) {
  return Page.PUBLISHER_TABLES + '/tables/' + tableId + '/' + tableName;
}

export function createResourceGroupLink(id) {
  return Page.RESOURCEGROUPS + '/' + id;
}

export function createBootstrapActionLink(id) {
  return Page.BOOTSTRAPACTIONS + '/' + id;
}

export function createTemplateLink(id) {
  return Page.TEMPLATE_DETAILS.replace(':id', id);
}
