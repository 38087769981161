import * as React from 'react';
import {useEffect, useState} from 'react';
import { Redirect } from 'react-router';

import {
  Button,
  ColumnLayout,
  Modal,
  Textarea,
  FormField,
  Input,
  Flashbar,
  Icon,
} from '@amzn/awsui-components-react-v3';

import {
  deleteDependency,
  getDataSet,
  getDependency,
  updateDependency,
} from '../../api/dependency';
import { CopiableText } from '../catalog/common';
import { NotificationDestination } from './notificationSelector';
import { DependencyModal } from './dependencyModal';
import { PageHeader } from '../subscriptions/common';
import { Page } from '../../routes/Paths';

export interface DependencyDetailProps {
  setContentType: any;
  activeGroup: string;
  match: any;
  userName: string;
}


export const DependencyDetail = (props: DependencyDetailProps) => {
  const [dependency, setDependency] = useState(undefined);
  const [hybridCatalogDataSetId, setHybridCatalogDataSetId] = useState(null);
  const [tableName, setTableName] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [updateModalVisible, setUpdateModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [enableModalVisible, setEnableModalVisible] = useState(false);
  const [disableModalVisible, setDisableModalVisible] = useState(false);
  const [updatedDependencyName, setUpdatedDependencyName] = useState(undefined);
  const [updatedDescription, setUpdatedDescription] = useState(undefined);
  const [updatedIAMRoleAssume, setUpdatedIAMRoleAssume] = useState(undefined);
  const [updatedNotificationType, setUpdatedNotificationType] = useState(undefined);
  const [updatedS3Path, setUpdatedS3Path] = useState(undefined);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [enableLoading, setEnableLoading] = useState(false);
  const [disableLoading, setDisableLoading] = useState(false);
  const [redirect, setRedirect] = useState(undefined);
  const [notifications, setNotifications] = useState([]);
  const [dependencyStatus, setDependencyStatus] = useState(undefined);

  useEffect(  () => {
    props.setContentType('table');
    loadData();
  }, []);

  const loadData = async () => {
    await loadDependency();

    let hcDataSetId = null;
    let tableName = null;

    if (dependency) {
      const getDataSetResponse = await getDataSet({
        dependencyServiceDataSetId: dependency
          .dependencyServiceDataSetId,
      });
      hcDataSetId = getDataSetResponse.hybridCatalogDataSetId;
      tableName = getDataSetResponse.tableName;
    }

    setHybridCatalogDataSetId(hcDataSetId);
    setTableName(tableName);
    setLoading(false);
  }

  const loadDependency = async () => {
    const getDependencyResponse = await getDependency({
      dependencyId: props.match.params.id,
    });

    const curDependency = getDependencyResponse.dependency;

    console.assert(
      curDependency != null,
      'Entry not found with ID: ' + props.match.params.id,
    );

    setDependency(curDependency);
    setDependencyStatus(curDependency.status == 'active' || curDependency.status == 'enabled'
      ? true
      : false);
  };

  const getDependencyStatus = (dependency) => {
    if (dependency.status == 'enabled' || dependency.status == 'active') {
      return (
        <div className='awsui-util-status-positive'>
          <Icon variant='success' name='status-positive'></Icon> Enabled
        </div>
      );
    } else {
      return (
        <div className='awsui-util-status-negative'>
          <Icon variant='disabled' name='status-negative'></Icon> Disabled
        </div>
      );
    }
  }

  const displayDependency = (dependency) => {
    const metadata = [];

    metadata.push(
      <CopiableText
        name='Description'
        value={dependency.description}
        key='Description'
      />,
    );

    metadata.push(
      <CopiableText
        name='IAM Role to Assume'
        value={dependency.iamRoleAssume}
        key='IAM Role to Assume'
      />,
    );

    metadata.push(
      <div key='status'>
        <div className='awsui-util-label'>Status</div>
        {getDependencyStatus(dependency)}
      </div>,
    );

    metadata.push(
      <CopiableText
        name='Confidence File Location'
        value={dependency.notificationTarget.targetLocation}
        key='Confidence File Location'
      />,
    );

    const dataSetUrl = '/catalog/' + hybridCatalogDataSetId;

    metadata.push(
      <CopiableText
        name='Table Name'
        value={tableName}
        url={dataSetUrl}
        key='Table Name'
      />,
    );

    metadata.push(
      <CopiableText
        name='Latest Confidence File Generation Time'
        value={
          dependency.lastDependencyCheckTimestamp
            ? dependency.lastDependencyCheckTimestamp
            : 'N/A'
        }
        key='Latest Confidence File Generation Time'
      />,
    );

    return metadata;
  }

  const handleDelete = async () => {
    setDeleteLoading(true);

    try {
      deleteDependency({
        dependencyId: dependency.dependencyId,
      });

      setDeleteLoading(false);
      setDeleteModalVisible(false);
      setRedirect(Page.DEPENDENCIES);

    } catch (err) {
      setDeleteLoading(false);
      setDeleteModalVisible(false);
      handleException(err, 'delete');
    }
  };

  const handleException = (err, opeartion) => {
    if (err.name == 'PermissionDeniedException') {
      setNotifications([
        {
          type: 'error',
          content: `User ${props.userName} does not have the permission to ${opeartion} dependencies`,
        },
      ])

    } else {
      setNotifications([
        {
          type: 'error',
          content: `An error occured when ${opeartion} the dependency, please try again.`,
        },
      ])

    }
  };

  const handleUpdate = async () => {
    setUpdateLoading(true);


    let updateRequest = {
      dependencyId: dependency.dependencyId,
      updatedDependencyName: updatedDependencyName,
      updatedDescription: updatedDescription,
      updatedIamRoleAssume: updatedIAMRoleAssume,
      updatedNotificationTarget: null,
    };

    if (updatedNotificationType && updatedS3Path) {
      updateRequest.updatedNotificationTarget = {
        type: updatedNotificationType,
        targetLocation: updatedS3Path,
      };
    }

    try {
      await updateDependency(updateRequest);
      setNotifications([
        {
          type: 'success',
          content: `Dependency ${dependency.dependencyName} is updated`,
          dismissible: true,
          dismiss: () => setNotifications([]),
        },
      ])

    } catch (err) {
      setDeleteLoading(false);
      setDeleteModalVisible(false);
      handleException(err, 'update');
    }

    loadDependency();
    setUpdateLoading(false);
    setUpdateModalVisible(false);

  };

  const handleEnable = async () => {
    setEnableLoading(true);

    try {
      await updateDependency({
        dependencyId: dependency.dependencyId,
        updatedStatus: 'enabled',
      });

      setNotifications([
        {
          type: 'success',
          content: `Dependency ${dependency.dependencyName} is enabled`,
          dismissible: true,
          dismiss: () => setNotifications([]),
        },
      ])
    } catch (err) {
      handleException(err, 'enable');
    }

    loadDependency();
    setEnableLoading(false);
    setEnableModalVisible(false);

  };

  const handleDisable = async () => {
    setDisableLoading(true);

    try {
      await updateDependency({
        dependencyId: dependency.dependencyId,
        updatedStatus: 'disabled',
      });

      setNotifications([
        {
          type: 'success',
          content: `Dependency ${dependency.dependencyName} is disabled`,
          dismissible: true,
          dismiss: () => setNotifications([]),
        },
      ])

    } catch (err) {
      handleException(err, 'disable');
    }

    loadDependency();

    setDisableLoading(false);
    setDisableModalVisible(false);

  };

  const closeModal = () => {
    setDisableModalVisible(false);
    setDeleteModalVisible(false);
    setEnableModalVisible(false);
    setUpdateModalVisible(false);
  };

  if (redirect) return <Redirect push to={redirect} />;

  if (!loading && dependency == null) {
    return (
      <div>
        <h1>No no dependency found for id "{props.match.params.id}"</h1>
      </div>
    );
  }

  if (loading) {
    return <div></div>;
  }

  return (
    <div>
      <Flashbar items={notifications}></Flashbar>
      <PageHeader
        header={dependency.dependencyName}
        buttons={[
          {
            text: 'Actions',
            loading: false,
            onItemClick: (props) => {
              if (props.detail.id == 'update') {
                setUpdateModalVisible(true);
              } else if (props.detail.id == 'delete') {
                setDeleteModalVisible(true);
              } else if (props.detail.id == 'enable') {
                setEnableModalVisible(true);
              } else if (props.detail.id == 'disable') {
                setDisableModalVisible(true);
              }
            },
            items: [
              {
                text: 'Edit',
                id: 'update',
              },
              {
                text: 'Delete',
                id: 'delete',
              },
              {
                text: 'Enable',
                id: 'enable',
                disabled: dependencyStatus,
              },
              {
                text: 'Disable',
                id: 'disable',
                disabled: !dependencyStatus,
              },
            ],
          },
        ]}
      />
      <Modal
        onDismiss={() => setUpdateModalVisible(false)}
        visible={updateModalVisible}
        closeAriaLabel='Close modal'
        size='medium'
        footer={
          <span className='awsui-util-f-r'>
              <Button
                variant='link'
                onClick={() => { setUpdateModalVisible(false)}}
              >
                Cancel
              </Button>
              <Button
                variant='primary'
                onClick={handleUpdate}
                loading={updateLoading}
              >
                Update
              </Button>
            </span>
        }
        header={'Update ' + dependency.dependencyName}
      >
        <FormField
          label='Updated Dependency Name'
          description='Please enter your updated dependency name'
        ></FormField>
        <Input
          name='updated-dependency-name'
          placeholder='your-updated-dependency-name'
          value={updatedDependencyName}
          onChange={(e) => setUpdatedDependencyName(e.detail.value.trim())}
        />
        <FormField
          label='Updated Description'
          description='Please enter your updated description'
        ></FormField>
        <Textarea
          name='updated-dependeny-description'
          placeholder='your-updated-dependency-description'
          value={updatedDescription}
          onChange={(e) => setUpdatedDescription(e.detail.value.trim())}
        />
        <FormField
          label='Updated Dependency Role'
          description='Please enter your updated dependency role ARN'
        ></FormField>
        <Input
          name='updated-dependeny-role'
          placeholder='your-updated-dependency-role-arn'
          value={updatedIAMRoleAssume}
          onChange={(e) => setUpdatedIAMRoleAssume(e.detail.value.trim())}
        />
        <NotificationDestination
          setNotificationType={(type: string) => setUpdatedNotificationType(type)}
          setS3Path={(s3Path: string) => setUpdatedS3Path(s3Path)}
        />
      </Modal>

      <DependencyModal
        setOndismiss={() => setDeleteModalVisible(false)}
        handleOnClick={handleDelete}
        visible={deleteModalVisible}
        loading={deleteLoading}
        operation='Delete'
        dependencyName={dependency.dependencyName}
        handleCancel={closeModal}
      />

      <DependencyModal
        setOndismiss={() => setEnableModalVisible(false)}
        handleOnClick={handleEnable}
        visible={enableModalVisible}
        loading={enableLoading}
        operation='Enable'
        dependencyName={dependency.dependencyName}
        handleCancel={closeModal}
      />

      <DependencyModal
        setOndismiss={() => setDisableModalVisible(false)}
        handleOnClick={handleDisable}
        visible={disableModalVisible}
        loading={disableLoading}
        operation='Disable'
        dependencyName={dependency.dependencyName}
        handleCancel={closeModal}
      />
      <div className='awsui-util-container'>
        <ColumnLayout columns={3} borders='horizontal'>
          <div data-awsui-column-layout-root='true'>
            {' '}
            {displayDependency(dependency)}
          </div>
        </ColumnLayout>
      </div>
    </div>
  );


}
