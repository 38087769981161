import React from 'react';
import { WEBSITE_NAME } from '../commons/constants';
import { HelpPanel, Icon, Link } from '@amzn/awsui-components-react-v3';

export const subscriptionHelp = (
  <HelpPanel
    header={<h2>Subscriptions</h2>}
    footer={
      <div>
        <h3>Learn more</h3>
        <ul>
          <li>
            <a href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Omni#HConnectionsandSubscriptions'>
              Getting started
            </a>
          </li>
          <li>
            <a href='https://quip-amazon.com/rfUxA2ebnU99/AWS-DataLake-Subscription-Service'>
              How subscriptions work
            </a>
          </li>
        </ul>
      </div>
    }
  >
    <p>
      Subscriptions are a simple way to load various available files in S3 into
      a Redshift cluster without having to create a Load activity. Here you can
      manage current subscriptions or create new ones.
    </p>
    <p>
      To subscribe to a dataset, simply search for the source file name in the
      search bar and select the right one from the list of results. Then fill
      out the target Redshift cluster, database, schema, table and an IAM role
      and choose Confirm. The page will then display your subscription and begin
      executing the load activity.
    </p>
  </HelpPanel>
);

export const connectionsHelp = (
  <HelpPanel
    header={<h2>Connections</h2>}
    footer={
      <div>
        <h3>Learn more</h3>
        <ul>
          <li>
            <a href='https://w.amazon.com/bin/view/Hammerstone/Subscriptions#HUserGuide'>
              Getting started
            </a>
          </li>
          <li>
            <a href='https://quip-amazon.com/rfUxA2ebnU99/AWS-DataLake-Subscription-Service'>
              How subscriptions work
            </a>
          </li>
        </ul>
      </div>
    }
  >
    <p>
      A connection is a target where you want to load the data you have access
      to. We support connections of type <b>Redshift Local</b>; this type of
      connection uses a role in your account and a secret stored in{' '}
      <b>Secrets Manager</b> to connect to your cluster.
    </p>
    <p>
      Once we connect to your cluster we will execute a <b>COPY</b> command
      using the Redshift role that you provide chained with your Data Lake role.
    </p>
  </HelpPanel>
);

export const catalogHelp = (
  <HelpPanel
    header={<h2>Catalog</h2>}
    footer={
      <div>
        <h3>Learn more</h3>
        <ul>
          <li>
            <a href='https://quip-amazon.com/qMthADdQhY3n/AWS-Data-Lake-Catalog-Service-Design-Doc'>
              How the catalog works
            </a>
          </li>
        </ul>
      </div>
    }
  >
    <p>
      Data Lake Catalog is a data catalog service which retrieves, stores, and
      syncs the metadata of datasets in the Data Lake or other Glue catalogs.
      All changes are tracked cross-account between the service account and the
      data source account and processed by the Catalog Service. Information
      about a given dataset (table) is kept such as ownership, input and output
      formats, S3 location, as well as a history of schema and partition keys.
    </p>
    <p>
      You can find the available datasets by using the search bar. Once you find
      a dataset that you are interested in, you can click on it to see all the
      details.
    </p>
  </HelpPanel>
);

export const catalogDetailsHelp = (
  <HelpPanel
    header={<h2>Dataset details</h2>}
    footer={
      <div>
        <h3>Learn more</h3>
        <ul>
          <li>
            <a href='https://quip-amazon.com/qMthADdQhY3n/AWS-Data-Lake-Catalog-Service-Design-Doc'>
              How the catalog works
            </a>
          </li>
        </ul>
      </div>
    }
  >
    <p>
      The Data Lake Catalog stores a complete history of all the changes to any
      of the fields you see here. In the metadata history dropdown, you can view
      older versions of the metadata, for example, if there was a schema change
      and you'd like to see the old columns.
    </p>
    <p>
      The request access button sends a request to the dataset owners for your
      group's Data Lake Role to be safelisted for the dataset. Once the request
      is sent, the owners can approve or deny the request. If it is approved,
      you can access and subscribe to the data.
    </p>
  </HelpPanel>
);

export const permissionsHelp = (
  <HelpPanel
    header={<h2>Permissions</h2>}
    footer={
      <div>
        <h3>Learn more</h3>
        <ul>
          <li>
            <a href='https://w.amazon.com/bin/view/AWSDataLake/Gladstone/DataLakeRoleAccess'>
              How can I use my Data Lake Role?
            </a>
          </li>
          <li>
            <a href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Omni/AccessDataLakeFormationRequests/'>
              How do I create and manage my Lake Formation requests?
            </a>
          </li>
        </ul>
      </div>
    }
  >
    <p>
      When you onboard to <b>{WEBSITE_NAME}</b>, a role is created for your
      group. You can request access to the datasets in the Catalog section and
      see those datasets here. You can also add and remove principals that can
      assume the Data Lake role for your group.
    </p>
    <p>
      Once your access is approved you can use your Data Lake role to read the
      data using AWS Services such as Redshift, Spectrum or EMR.
    </p>
  </HelpPanel>
);

export const myDatasetsHelp = (
  <HelpPanel
    header={<h2>My datasets</h2>}
    footer={
      <div>
        <h3>Learn more</h3>
        <ul>
          <li>
            <a href='https://aws.amazon.com/lake-formation/'>
              What is Lake Formation?
            </a>
          </li>
          <li>
            <a href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Omni/AccessDataLakeFormationRequests/'>
              How do I create and manage my Lake Formation requests?
            </a>
          </li>
        </ul>
      </div>
    }
  >
    <p>
      In this page you can see all the datasets you have been granted access to,
      either through IAM or Lake Formation permissions. Datasets shared using
      Lake Formation are called dataset shares.
    </p>
    <p>
      A dataset share is a mechanism of sharing datasets. It contains all the
      metadata of the share itself. You have the abilities to <b>audit</b>,
      which validates the permissions, and <b>deactivate</b>, which revokes the
      permissions of the share. At the moment an audit failure doesn't mean
      permissions don't exist, it just doesn't match what's in the source
      account.
    </p>
    <p>
      Looking at an individual dataset share will contain more information. A
      <b>consumer</b> share will show whats being consumed. A <b>publisher</b>{' '}
      share will show what's being shared and who is consuming that dataset.
    </p>
  </HelpPanel>
);

export const publishDataSetShareHelp = (
  <HelpPanel
    header={<h2>Lake Formation sharing</h2>}
    footer={
      <div>
        <h3>Learn more</h3>
        <ul>
          <li>
            <a href='https://aws.amazon.com/lake-formation/'>
              What is Lake Formation?
            </a>
          </li>
          <li>
            <a href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Omni/LakeFormationRoles/#HPublishingRoles'>
              How do I create the audit and sharing roles?
            </a>
          </li>
          <li>
            <a href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Omni/AccessDataLakeFormationRequests/'>
              How do I create and manage my Lake Formation requests?
            </a>
          </li>
        </ul>
      </div>
    }
  >
    <p>
      Fill out this form for the datasets you would like shared via Lake
      Formation shares. After you enter both roles and a region, Omni will let
      you select the table(s) you want to share.
    </p>
    <p>
      Once your publish your data, you will need to monitor the request page to
      approve requests for accessing your data.
    </p>
  </HelpPanel>
);

export const createLakeFormationRequestHelp = (
  <HelpPanel
    header={<h2>Lake Formation Permission Request</h2>}
    footer={
      <div>
        <h3>Learn more</h3>
        <ul>
          <li>
            <a href='https://aws.amazon.com/lake-formation/'>
              What is Lake Formation?
            </a>
          </li>
          <li>
            <a href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Omni/LakeFormationRoles/#HConsumingRole'>
              How do I create a Resource Access Manager role?
            </a>
          </li>
          <li>
            <a href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Omni/AccessDataLakeFormationRequests/'>
              How do I submit a Lake Formation Request?
            </a>
          </li>
          <li>
            <a href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Omni/AccessDataLakeFormation/'>
              How do I use my Lake Formation shares?
            </a>
          </li>
        </ul>
      </div>
    }
  >
    <p>
      Fill out this form for the datasets you would like shared via Lake
      Formation shares. Instead of using an external AWS Account ID,{' '}
      <b>{WEBSITE_NAME}</b> requires a role that has Resource Access Manager
      (RAM) access. This allows us to auto accept all RAM shares created from
      Lake Formation.
    </p>
    <p>
      Once your access is approved you can set up your permissions on your
      compute roles in the Lake Formation console.
    </p>
  </HelpPanel>
);

export const helpContentNewCustomers = (
  <HelpPanel
    header={<h2>Onboarding</h2>}
    footer={
      <div>
        <h3>Learn more</h3>
        <ul>
          <li>
            <a href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Documentation'>
              Data Lake services
            </a>
          </li>
          <li>
            <a href='https://docs.aws.amazon.com/IAM/latest/UserGuide/reference_policies_elements_principal.html'>
              IAM principals
            </a>
          </li>
        </ul>
      </div>
    }
  >
    <p>
      To onboard, you will need to submit an onboarding request with information
      about your team, data access and processing, use case, and valid IAM
      principal(s) to assume your Data Lake role.
    </p>
    <p>
      Once the request is submitted, Data Lake oncall will review and process
      it. A group then will be created for your team with you and your teammates
      as group members.
    </p>
    <p>
      Once onboarded, you can use Data Lake services with ease to support your
      operational or reporting and analytical data use.
    </p>
  </HelpPanel>
);

export const publisherHelp = (
  <HelpPanel
    header={<h2>Published table actions</h2>}
    footer={
      <div>
        <h3>Learn more</h3>
        <ul>
          <li>
            <a href='https://quip-amazon.com/fNs9Ap6IJGY5/Publisher-as-Managed-Service'>
              Learn more about Publisher
            </a>
          </li>
        </ul>
      </div>
    }
  >
    <p>
      Backfill: When a pipeline which has already been set up and has been
      streaming in past has an interruption such as service issues, AWS outage,
      etc., then source team can re-run the recent batch of files to be
      processed against the last verfied snapshot.
    </p>
    <p>
      Dedupe: Whenever there are lot of updates that happen on a table, it can
      happen that the updates in the stream can lose the order of changes
      received. Please select Dedupe column to avoid overlap of updates.
    </p>
    <p>
      Initial Dump: In order to get a full dump of your table, please select
      <b>Initial Dump</b> from the Actions menu. Once the Initial Dump button is
      clicked, a new execution will be created that will show up on "Full dump
      executions" with the status.
    </p>
  </HelpPanel>
);

export const notificationHelp = (
  <HelpPanel header={<h2>Notifications</h2>}>
    <p>
      You can see all the notifications for your group in the Inbox tab, all
      your archived notifications in the Archive tab, and all the notifications
      your group has sent from the Sent tab. Also, you can add comments to
      notifications and the notification sender would get notified. Both
      notification sender and other groups who received the same notification
      can share the comments.
    </p>
  </HelpPanel>
);

export const createNotificationHelp = (
  <HelpPanel header={<h2>Create new notifications</h2>}>
    <p>
      When creating a new notification, you can choose to send notifications to
      any groups who have access to your datasets. You can create templates for
      common notifications you would like to send.
    </p>
  </HelpPanel>
);

const monoSpan = (text) => (
  <span style={{ fontFamily: 'monospace' }}>{text}</span>
);

export const resourcegroupsHelp = (
  <HelpPanel
    header={<h2>Resource groups</h2>}
    footer={
      <div>
        <h3>Learn more</h3>
        <ul>
          <li>
            <a href='https://quip-amazon.com/Y7sbAhfIKL7x/ResourcesManager-Design'>
              Resource Manager
            </a>
          </li>
        </ul>
      </div>
    }
  >
    <p>
      <b>Resource groups: </b>
      Resource Manager is responsible for adaptively scaling your active
      resources, keeping resources up-to-date, and managing their security. From
      your resource groups dashboard, you can complete the following actions:
    </p>
    <p>
      <ul>
        <li>
          Create/Clone: Create a new resource group from scratch, or clone the
          configuration of an existing group to a new one.
        </li>
        <li>
          Search: Filter the groups displayed on the table based on the
          displayed properties.
        </li>
        <li>Inspect: See the details of the resource group's configuration.</li>
        <li>Edit: Update the resource group's configuration.</li>
        <li>
          Toggle status: Quickly {monoSpan('ENABLE')}, {monoSpan('DISABLE')}, or{' '}
          {monoSpan('DELETE')} a group.
        </li>
      </ul>
    </p>
    <p>
      <i>Note: </i>
      EMR clusters are currently the only supported resource type.
    </p>
    <hr />
    <p>
      <b>Templates: </b> You may save a particular resource group's
      configuration as a template, and then {monoSpan('Quick Create')} (under{' '}
      {monoSpan('Actions')}) new groups from a selected Template.
    </p>
  </HelpPanel>
);

export const bootstrapactionsHelp = (
  <HelpPanel
    header={<h2>Bootstrap actions</h2>}
    footer={
      <div>
        <h3>Learn more</h3>
        <ul>
          <li>
            <a href='https://quip-amazon.com/Y7sbAhfIKL7x/ResourcesManager-Design'>
              Resource Manager
            </a>
          </li>
        </ul>
      </div>
    }
  >
    <p>
      <b>Bootstrap actions: </b>
      Bootstrap actions are executable S3-hosted files which are run (with
      user-defined arguments) on newly created resources under the supervision
      of Resources Manager. You may create, inspect, search, and edit bootstrap
      actions from this table. You may delete or clone a bootstrap action from
      its details page.
    </p>
  </HelpPanel>
);

export const groupsHelp = (
  <HelpPanel
    header={<h2>Groups</h2>}
    footer={
      <div>
        <h3>Need help</h3>
        <ul>
          <li>
            <a href='https://quip-amazon.com/zaNcA7uR5KyP/Data-Lake-Gladstone-Design#RXF9CAUHRyX'>
              How Omni groups work
            </a>
          </li>
        </ul>
      </div>
    }
  >
    <p>
      When you onboard to <b>{WEBSITE_NAME}</b>, a group is created for your
      team. You and your teammates that were specified in the onboarding request
      are group members. In this page, you can request to edit your group
      details or add new members to your group.
    </p>
  </HelpPanel>
);

export const tethysHelp = (
  <HelpPanel
    header={<h2> Welcome to Tethys! </h2>}
    footer={
      <div>
        <h3>
          Learn more <Icon name='external' />
        </h3>
        <ul>
          <li>
            <a
              href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataIngestion/Internal/TethysOnboarding/#HAPI'
              target='_blank'
              rel='noopener noreferrer'
            >
              API documentation
            </a>
          </li>
          <li>
            <a
              href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataIngestion/Internal/TethysOnboarding/CustomerOnboardingDocument/'
              target='_blank'
              rel='noopener noreferrer'
            >
              Onboarding guide
            </a>
          </li>
          <li>
            <a
              href='https://amzn-aws.slack.com/archives/C03KS0T6V0B'
              target='_blank'
              rel='noopener noreferrer'
            >
              #tethys-interest
            </a>
          </li>
        </ul>
      </div>
    }
  >
    <div>
      <p>
        We are in beta and working hard to deliver you the best possible
        product.
      </p>

      <p>
        Please take a few moments to share with us your experience using
        <Link
          external
          externalIconAriaLabel='Opens in a new tab'
          href='https://quip-amazon.com/qLvYAOLOKCHP/Tethys-UI-Feedback-Beta'
        >
          {' '}
          Tethys
        </Link>
      </p>
    </div>
  </HelpPanel>
);
