import tethyscontractservicelambda, {
	GetDataSetRequest,
	GetDataSetResponse,
	ListDataSetsRequest,
	ListDataSetsResponse,
	RegisterDataSetRequest,
	RegisterDataSetResponse,
	RegisterContractVersionRequest,
	RegisterContractVersionResponse
} from 'aws-sdk/clients/tethyscontractservicelambda';

import basicCallWrapper from './client_call';
import { getDatasetServiceConfig } from './config';
import { getCredentialProvider } from './auth';

const config = getDatasetServiceConfig();
const credentials = getCredentialProvider();

const datasetServiceClientConfig: tethyscontractservicelambda.ClientConfiguration = {
  region: config.region,
  endpoint: config.endpoint,
  credentials: credentials,
};

const datasetServiceClient = new tethyscontractservicelambda(
  datasetServiceClientConfig,
);

export const registerDataset = (input:RegisterDataSetRequest) => {
  return basicCallWrapper<RegisterDataSetRequest, RegisterDataSetResponse>(
    datasetServiceClient,
    datasetServiceClient.registerDataSet,
  )(input);
};

export const getDataset = (input:GetDataSetRequest) => {
  return basicCallWrapper<GetDataSetRequest, GetDataSetResponse>(
    datasetServiceClient,
    datasetServiceClient.getDataSet,
  )(input);
};

export const listDatasets = (input:ListDataSetsRequest) => {
	return basicCallWrapper<ListDataSetsRequest, ListDataSetsResponse>(
	  datasetServiceClient,
	  datasetServiceClient.listDataSets,
	)(input);
};

export const updateDataset = (input:RegisterContractVersionRequest) => {
	return basicCallWrapper<RegisterContractVersionRequest, RegisterContractVersionResponse>(
	  datasetServiceClient,
	  datasetServiceClient.registerContractVersion,
	)(input);
};
