import * as React from 'react';
import { useEffect, useState } from 'react';
import {Textarea, ColumnLayout, Container, Header} from '@amzn/awsui-components-react-v3';
import {
  getNotification,
  updateNotifications,
} from 'src/api/notifications';
import { NotificationComments } from 'src/components/notifications/comment';

export interface NotificationDetailPageProps {
  setContentType: any;
  match: any;
  activeGroup: string;
  username: string;
}


export const NotificationDetailPage = (props: NotificationDetailPageProps,) => {
  const [notification, setNotification] = useState({});

  useEffect(() => {
    props.setContentType('table');
    handleRefresh();
  }, []);

  useEffect(() => {
    handleRefresh();
    props.setContentType('table');
  }, [props.match.params.NotificationId]);

  const handleRefresh = async () => {
    try {
      const notification = await getNotification({
        NotificationId: props.match.params.NotificationId,
        GroupId: props.activeGroup,
      });

      setNotification(notification.Notification);
      await updateNotifications({
        GroupId: props.activeGroup,
        NotificationIdList: [notification.Notification.NotificationId],
        MarkRead: true,
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Container
        header={
          <Header
            variant="h2"
          >
            {notification['Subject']}
          </Header>
        }
      >
        <Textarea
          value={notification['Content']}
          readOnly
          rows={10}
        />
      </Container>
      <br/>

      <div className='awsui-util-container'>
        <div className='awsui-util-container-header'>
          <h2>Details</h2>
        </div>

        <ColumnLayout columns={4} variant='text-grid'>

          <div>
            <div className='awsui-util-label'>Sender</div>
            <div> <a href={`https://phonetool.amazon.com/users/${notification['SenderUserId']}`}>{notification['SenderUserId']} </a></div>
          </div>
          <div>
            <div className='awsui-util-label'>Sender group</div>
            <div> {notification['SenderGroupId']}</div>
          </div>
          <div>
            <div className='awsui-util-label'>Priority</div>
            <div> {notification['PriorityLevel']}</div>
          </div>
          <div>
            <div className='awsui-util-label'>Email sent</div>
            <div>
              {' '}
              {notification['EmailSent'] ? 'Sent' : 'Not Sent'}
            </div>
          </div>
          <div>
            <div className='awsui-util-label'>Create time</div>
            <div> {new Date(notification['CreateTime']).toLocaleString()}</div>
          </div>

        </ColumnLayout>
      </div>
      {notification['NotificationBatchId'] &&
        notification['NotificationId'] && (
          <NotificationComments
            {...props}
            notificationBatchId={
              notification['NotificationBatchId']
            }
            notificationId={notification['NotificationId']}
          />
        )}
    </>
  );
}





