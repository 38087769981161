import * as React from 'react';
import {useEffect, useState} from 'react';
import {
  Button,
  ColumnLayout,
  Flashbar,
  Form,
  FormField,
  Container,
  Input,
  Header,
  Select,
} from '@amzn/awsui-components-react-v3';
import { createTarget } from '../../api/subscriptions';
import { Redirect } from 'react-router-dom';
import { scrollUp } from '../utils/navigation';
import * as validate from '../../commons/validationUtils';
import { Page } from '../../routes/Paths';

export interface CreateConnectionComponentProps {
  setContentType: any;
  activeGroup: string;
}


export const CreateConnectionComponent = (props: CreateConnectionComponentProps) => {
  // const [options, setOptions] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [useSecretManger, setUseSecretManger] = useState('');
  const [secret, setSecret] = useState(null);
  const [iamRole, setIamRole] = useState(null);
  const [tableInfo, setTableInfo] = useState(null);
  const [targetType, setTargetType] = useState(null);
  const [clusterName, setClusterName] = useState(null);
  const [defaultSchema, setDefaultSchema] = useState('public');
  const [defaultDatabase, setDefaultDatabase] = useState('dev');
  const [defaultAttachedRole, setDefaultAttachedRole] = useState(null);
  const [redirect, setRedirect] = useState(undefined);

  useEffect(() => {
    props.setContentType('form');
  }, []);

  const handleConfirm = async () => {
    setLoading(true);
    const response = await createTarget({
      defaultDatabase: defaultDatabase,
      iamRole: iamRole,
      name: clusterName,
      ownerId: props.activeGroup,
      region: 'us-east-1', // TODO: add it to the component
      secret: secret,
      type: targetType.label,
      defaultSchema: defaultSchema,
      defaultAttachedRole: defaultAttachedRole,
    });
    console.log(response);
    setNotifications([
      {
        type: 'success',
        content: `Connection '${response.targetId}' created.`,
        dismissible: true,
        action: <Button onClick={() => {setRedirect(`/connections/${response.targetId}`)}}>View connection</Button>,
        onDismiss: () => setNotifications([]),
      },
    ])
    setLoading(false);
    scrollUp();
  };

  const allFieldsSet = () => {
    return (
      validate.isNotEmpty(clusterName) &&
      validate.isNotEmpty(defaultDatabase) &&
      validate.isNotEmpty(defaultSchema) &&
      validate.isValidRole(defaultAttachedRole) &&
      validate.isNotEmpty(secret) &&
      validate.isValidRole(iamRole)
    );
  }

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <div>
      <Flashbar items={notifications}></Flashbar>
      <Form
        header={
          <Header description='You can subscribe to a Data Lake table by specifying a target Redshift cluster.' variant="h1">
            Create new connection
          </Header>
        }
        actions={
          <div>
            <Button
              variant='link'
              onClick={() => setRedirect(Page.CONNECTIONS)}
            >
              Cancel
            </Button>
            <Button
              variant='primary'
              loading={loading}
              onClick={handleConfirm}
              disabled={!allFieldsSet()}
            >
              Create Connection
            </Button>
          </div>
        }
      >
        <Container
          className='custom-screenshot-hide'
          header={<h2>Connection settings</h2>}
        >
          <ColumnLayout>
            <div data-awsui-column-layout-root={true}>
              <FormField
                label={<div>Connection type</div>}
                description='The connection type defines where you want to get the data.'
              >
                <Select
                  selectedAriaLabel='Selected'
                  options={[
                    {
                      value: 'redshift',
                      label: 'Redshift Local',
                    },
                    {
                      value: 'spectrum',
                      label: 'Redshift Spectrum',
                      disabled: true,
                    },
                    {
                      value: 'glue',
                      label: 'Glue Catalog',
                      disabled: true,
                    },
                  ]}
                  onChange={(event) =>
                    setTargetType(event.detail.selectedOption)
                  }
                  placeholder='Please select the connection type'
                  errorText='Error fetching type.'
                  recoveryText='Retry'
                  empty={'No types available'}
                  filteringType='auto'
                  filteringAriaLabel='Filter origins'
                  ariaRequired={true}
                  selectedOption={targetType}
                />
              </FormField>

              {targetType != null && targetType.label == 'Redshift Local' && (
                <>
                  <FormField
                    label={<div>Cluster name</div>}
                    description='Input the name that you want to use for your cluster.'
                  >
                    <Input
                      name='redshift-cluster-name'
                      placeholder='My Redshift Cluster'
                      ariaRequired={true}
                      onChange={(event) =>
                        setClusterName(event.detail.value.trim())
                      }
                      invalid={
                        clusterName !== null &&
                        validate.isEmpty(clusterName)
                      }
                      value={clusterName}/>
                  </FormField>

                  <FormField
                    label={<div>Default database</div>}
                    description='Input the name of the default database to use.'
                  >
                    <Input
                      name='redshift-default-database'
                      value={defaultDatabase}
                      ariaRequired={true}
                      onChange={(event) =>
                        setDefaultDatabase(event.detail.value.trim())
                      }
                      invalid={
                        defaultDatabase !== null &&
                        validate.isEmpty(defaultDatabase)
                      }
                    />
                  </FormField>

                  <FormField
                    label={<div>Default schema</div>}
                    description='Input the name of the default schema to use.'
                  >
                    <Input
                      name='redshift-default-schema'
                      value={defaultSchema}
                      ariaRequired={true}
                      onChange={(event) =>
                        setDefaultSchema(event.detail.value.trim())
                      }
                      invalid={
                        defaultSchema !== null &&
                        validate.isEmpty(defaultSchema)
                      }
                    />
                  </FormField>

                  <FormField
                    label={<div>Default attached IAM role</div>}
                    description='Input the ARN of the default role to use that is attached to the cluster.'
                  >
                    <Input
                      name='redshift-default-attached-role'
                      placeholder='arn:aws:iam::<yourAccount>:role/RedshiftRole'
                      value={defaultAttachedRole}
                      ariaRequired={true}
                      onChange={(event) =>
                        setDefaultAttachedRole(event.detail.value.trim())
                      }
                      invalid={
                        defaultAttachedRole !== null &&
                        !validate.isValidRole(defaultAttachedRole)
                      }
                    />
                  </FormField>

                  <FormField
                    label={<div>Credentials type</div>}
                    description='Select the way that you want to provide credentials.'
                  >
                    <Select
                      selectedAriaLabel='Selected'
                      options={[
                        {
                          value: 'sm',
                          label: 'Secrets Manager',
                          description:
                            'Redshift secret stored in Secrets Manager (recommended)',
                        },
                        {
                          value: 'iam',
                          label: 'IAM authentication',
                          description:
                            'Uses the Redshift API for credentials (limited to 1 hour)',
                          disabled: true,
                        },
                      ]}
                      onChange={(event) =>
                        setTableInfo(event.detail.selectedOption)
                      }
                      placeholder='Please select the connection type'
                      errorText='Error fetching type.'
                      recoveryText='Retry'
                      empty={'No types available'}
                      filteringType='auto'
                      filteringAriaLabel='Filter origins'
                      ariaRequired={true}
                      selectedOption={tableInfo}/>
                  </FormField>

                  {tableInfo!= null && tableInfo.label == 'Secrets Manager' && (
                    <>
                      <FormField
                        label={<div>Secret name</div>}
                        description={
                          <div>
                            Name of the Redshift Secret to get the connection
                            details.'{' '}
                            <a href='https://docs.aws.amazon.com/secretsmanager/latest/userguide/manage_create-basic-secret.html'>
                              Instructions
                            </a>
                          </div>
                        }
                      >
                        <Input
                          name='secretmanager-name'
                          placeholder='mysecret'
                          value={secret}
                          ariaRequired={true}
                          onChange={(e) =>
                            setSecret(e.detail.value.trim())
                          }
                          invalid={
                            secret != null &&
                            validate.isEmpty(secret)
                          }
                        />
                      </FormField>
                    </>
                  )}
                  {tableInfo != null && (tableInfo.label == 'Secrets Manager' ||
                    tableInfo.label == 'IAM authentication') && (
                    <>
                      <FormField
                        label={<div>Connection IAM Role</div>}
                        description={
                          <div>
                            This role is used to get the connection details
                            and to whitelist inbound access to the cluster.{' '}
                            <a href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Omni#HConnectionsandSubscriptions'>
                              Instructions
                            </a>
                          </div>
                        }
                      >
                        <Input
                          name='connection-iam-role'
                          placeholder='arn:aws:iam::<yourAccount>:role/DataLakeSubscriptions'
                          value={iamRole}
                          ariaRequired={true}
                          onChange={(e) =>
                            setIamRole(e.detail.value.trim())
                          }
                          invalid={
                            iamRole != null &&
                            !validate.isValidRole(iamRole)
                          }
                        />
                      </FormField>
                    </>
                  )}
                </>
              )}
            </div>
          </ColumnLayout>
        </Container>
      </Form>
    </div>
  );







}




