import * as React from 'react';
import { useEffect, useState } from 'react';
import { ColumnLayout, Container, Header, Textarea } from '@amzn/awsui-components-react-v3';
import {
  getNotificationBatch,
  updateNotificationBatch,
} from 'src/api/notifications';
import { NotificationComments } from 'src/components/notifications/comment';

export interface NotificationBatchDetailPageProps {
  setContentType: any;
  match: any;
  activeGroup: string;
  username: string;
}

export const NotificationBatchDetailPage = (props: NotificationBatchDetailPageProps) => {
  const [notificationBatch, setNotificationBatch] = useState({});


  useEffect(() => {
    props.setContentType('table');
    handleRefresh();
  }, [props.match.params.NotificationBatchId]);

  const handleRefresh = async () => {
    if (!props.activeGroup) return;
    const notification = await getNotificationBatch({
      NotificationBatchId: props.match.params.NotificationBatchId,
      GroupId: props.activeGroup
    });
    setNotificationBatch(notification.NotificationBatch)
    await updateNotificationBatch({
      NotificationBatchId: notification.NotificationBatch.NotificationBatchId,
      MarkComment: false,
      GroupId: props.activeGroup,
    });
  };

  return (
    <>
      <Container
        header={
          <Header
            variant="h2"
          >
            {notificationBatch['Subject']}
          </Header>
        }
      >
        <Textarea
          value={notificationBatch['Content']}
          readOnly
          rows={10}
        />
      </Container>
      <br/>
      <div className='awsui-util-container'>
        <div className='awsui-util-container-header'>
          <h2>Details</h2>
        </div>

        <ColumnLayout columns={1} variant='text-grid'>
          <div>
            <div className='awsui-util-label'>Receiver Group List</div>
            {notificationBatch['GroupIdList'] && (
            <div>{notificationBatch['GroupIdList'].join('; ')}</div>
            )}
          </div>
        </ColumnLayout>

        <ColumnLayout columns={4} variant='text-grid'>
          <div>
            <div className='awsui-util-label'>Sender</div>
            <div> <a href={`https://phonetool.amazon.com/users/${notificationBatch['UserId']}`}>{notificationBatch['UserId']}</a></div>
          </div>
          <div>
            <div className='awsui-util-label'>Sender group</div>
            <div> {notificationBatch['GroupId']}</div>
          </div>
          <div>
            <div className='awsui-util-label'>Priority</div>
            <div> {notificationBatch['PriorityLevel']}</div>
          </div>
          <div>
            <div className='awsui-util-label'>Email sent</div>
            <div>
              {' '}
              {notificationBatch['EmailSent']
                ? 'Sent'
                : 'Not Sent'}
            </div>
          </div>
          <div>
            <div className='awsui-util-label'>Create time</div>
            <div> {new Date(notificationBatch['CreateTime']).toLocaleString()}</div>
          </div>
          <div>
            <div className='awsui-util-label'>Last update time</div>

            <div> {new Date(notificationBatch['LastCommentTime']).toLocaleString()}</div>
          </div>
        </ColumnLayout>
      </div>
      {notificationBatch['NotificationBatchId'] && (
        <NotificationComments
          {...props}
          notificationBatchId={
            notificationBatch['NotificationBatchId']
          }
          notificationId=''
        />
      )}
    </>
  );
}
