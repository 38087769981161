import * as React from 'react';
import {
  Button,
  ButtonDropdown,
  Icon,
  Popover,
  Spinner,
} from '@amzn/awsui-components-react';
import { Link } from 'react-router-dom';
import {
  ACCOUNT_ID_PREFIX,
  DATABASE_NAME_PREFIX,
  REGION_NAME_PREFIX,
  TABLE_NAME_PREFIX,
} from 'src/commons/constants';
import { isValidDataSetIdFormat } from 'src/commons/validationUtils';

export const getGroupingMechanismType = (groupingId: string) => {
  if (groupingId.includes('.')) {
    return 'Teams';
  } else {
    return 'Posix';
  }
};

export const getPhoneToolLink = (userId) => {
  const url = 'https://phonetool.amazon.com/users/';
  let userUrl = url.concat(userId);
  return (
    <a href={userUrl} target={'_blank'}>
      {userId}
    </a>
  );
};

export const getOmniGroupLink = (groupId) => {
  return <a href={'/groups/' + groupId}>{groupId}</a>;
};

export const getWikiLink = (wiki) => {
  return (
    <a href={wiki} target={'_blank'}>
      {wiki}
    </a>
  );
};

export const getCtiUrl = (c, t, i) => {
  return (
    'https://t.corp.amazon.com/create/options?ticket_type=Regular+Ticket&severity=FIVE&category=' +
    c +
    '&type=' +
    t +
    '&item=' +
    i
  );
};

export const getCtiUrlFromCti = (cti) => {
  if (!cti) return;
  const arr = cti.split('/');
  const c = arr[0];
  const t = arr[1];
  const i = arr[2];
  return getCtiUrl(c, t, i);
};

export const getAccountIdFromDatasetId = (datasetId) => {
  if (isValidDataSetIdFormat(datasetId)) {
    return datasetId.split('|')[1].substring(ACCOUNT_ID_PREFIX.length);
  }
  return '';
};

export const getDatabaseNameFromDatasetId = (datasetId) => {
  if (isValidDataSetIdFormat(datasetId)) {
    return datasetId.split('|')[2].substring(DATABASE_NAME_PREFIX.length);
  }
  return '';
};

export const getTableNameFromDatasetId = (datasetId) => {
  if (isValidDataSetIdFormat(datasetId)) {
    return datasetId.split('|')[3].substring(TABLE_NAME_PREFIX.length);
  }
  return '';
};

export const getRegionFromDatasetId = (datasetId) => {
  if (isValidDataSetIdFormat(datasetId)) {
    return datasetId.split('|')[4].substring(REGION_NAME_PREFIX.length);
  }
  return '';
};

export const CopiableText = ({
  name = '',
  value = '',
  loading = false,
  url = null,
}) => {
  value ? value + ' ' : '';
  return (
    <div>
      <div className='awsui-util-label'>{name}</div>
      {loading ? (
        <Spinner />
      ) : (
        <div className='awsui-util-copy-text'>
          {url == null ? value : <Link to={`${url}`}>{value}</Link>}
          {value && (
            <Popover
              size='small'
              position='top'
              dismissButton={false}
              triggerType='custom'
              content={
                <span className='awsui-util-status-positive'>
                  <Icon variant='success' name='status-positive' /> Copied
                </span>
              }
            >
              <Button
                variant='icon'
                icon='copy'
                iconAlt='Copy'
                label='Copy ARN'
                onClick={() => {
                  navigator.clipboard.writeText(value);
                }}
              />
            </Popover>
          )}
        </div>
      )}
    </div>
  );
};

export const DetailsPageHeader = ({
  header,
  buttons,
  versionHeader,
  leftSubheader,
  rightSubheader,
  versionSelect,
  editButton,
}) => {
  return (
    <div className='awsui-util-mb-m awsui-util-mt-xs'>
      <div className='awsui-util-action-stripe'>
        <div className='awsui-util-action-stripe-title'>
          <h1>{header}</h1>
          <br />
        </div>
      </div>
      <div className='awsui-util-action-stripe'>
        <div className='awsui-util-action-stripe-title'>
          <h2>{versionHeader}</h2>
        </div>
        <div className='awsui-util-action-stripe-group awsui-util-pv-n'>
          <div
            style={{ paddingTop: 10, paddingRight: 12 }}
            hidden={editButton.hidden}
          >
            <Button
              href={editButton.href || ''}
              icon={editButton.icon}
              onClick={editButton.onItemClick}
              key={0}
              loading={editButton.loading}
              variant={editButton.variant || 'normal'}
            >
              {editButton.text}
            </Button>
          </div>
          <div style={{ width: 160, paddingTop: 10 }}>{versionSelect}</div>
          <div style={{ paddingTop: 10, paddingLeft: 12 }}>
            {buttons.map((button, key) =>
              !button.items ? (
                <Button
                  href={button.href || ''}
                  disabled={button.disabled || false}
                  icon={button.icon}
                  onClick={button.onItemClick}
                  key={key}
                  loading={button.loading || false}
                  variant={button.variant || 'normal'}
                >
                  {button.text}
                </Button>
              ) : (
                <ButtonDropdown
                  items={button.items}
                  key={key}
                  onItemClick={button.onItemClick}
                  loading={button.loading}
                >
                  {button.text}
                </ButtonDropdown>
              ),
            )}
          </div>
        </div>
      </div>
      {(rightSubheader || leftSubheader) && (
        <div className='awsui-util-action-stripe'>
          <div className='awsui-util-action-stripe-title'>
            {leftSubheader && <h5>{leftSubheader}</h5>}
          </div>
          {rightSubheader && (
            <div className='awsui-util-action-stripe-title'>
              <h5>{rightSubheader}</h5>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
