import {Button, ButtonDropdown, SpaceBetween} from "@amzn/awsui-components-react-v3";
import * as React from "react";

export const PageHeader = ({ buttons, header }) => {
  return (
    <div className='awsui-util-mb-m awsui-util-mt-xs'>
      <div className='awsui-util-action-stripe'>
        <div className='awsui-util-action-stripe-title'>
          <h2>{header}</h2>
        </div>
        <div className='awsui-util-action-stripe-group awsui-util-pv-n'>
          <SpaceBetween direction="horizontal" size="xs">
            {buttons.map((button, key) =>
              !button.items ? (
                <Button
                  href={button.href || ''}
                  disabled={button.disabled || false}
                  iconName={button.icon}
                  onClick={button.onItemClick}
                  key={key}
                  loading={button.loading || false}
                  variant={button.variant || 'normal'}
                >
                  {button.text}
                </Button>
              ) : (
                <ButtonDropdown
                  variant={button.variant}
                  items={button.items}
                  key={key}
                  onItemClick={button.onItemClick}
                  loading={button.loading}
                >
                  {button.text}
                </ButtonDropdown>
              ),
            )}
          </SpaceBetween>
        </div>
      </div>
    </div>

  );
};

export const COLUMN_DEFINITIONS = [
  {
    id: 'principal',
    header: () => 'DataLake Principal',
    cell: (item) => item.principal,
    minWidth: '180px',
    allowLineWrap: true
  },
  {
    id: 'catalogId',
    header: () => 'Catalog Id',
    cell: (item) => item.catalogId,
    minWidth: '100px',
    allowLineWrap: true
  },
  {
    id: 'databaseName',
    header: () => 'Database',
    cell: (item) => item.databaseName,
    minWidth: '100px',
    allowLineWrap: true
  },
  {
    id: 'tableName',
    header: 'Table',
    cell: (item) => item.tableName,
    minWidth: '100px',
    allowLineWrap: true
  },
  {
    id: 'columns',
    header: 'Columns',
    cell: (item) => item.columnNames !== undefined && item.columnNames !== null ? item.columnNames.join(', ') : '',
    minWidth: '100px',
    allowLineWrap: true
  },
  {
    id: 'permissions',
    header: 'Permissions',
    cell: (item) => item.permissions,
    minWidth: '100px',
    allowLineWrap: true
  },
  {
    id: 'grantPermissions',
    header: 'Grant Permissions',
    cell: (item) => item.grantablePermissions,
    minWidth: '100px',
    allowLineWrap: true
  },
  {
    id: 'logging',
    header: () => 'Logging',
    cell: item => item.logging,
    minWidth: '100px',
    allowLineWrap: true
  }
];

export const SORTABLE_COLUMNS = [
  { id: 'catalogId', field: 'catalogId' },
  { id: 'databaseName', field: 'databaseName' },
  { id: 'tableName', field: 'tableName' },
  { id: 'columns', field: 'columns' },
];

export const CONTENT_SELECTOR_OPTIONS = [
  {
    label: 'Lake Formation Share properties',
    options: [
      { id: 'principal', label: 'DataLake Principal', editable: true, visible: false },
      { id: 'catalogId', label: 'Catalog Id', editable: true, visible: false },
      { id: 'databaseName', label: 'Database', editable: true, visible: true },
      { id: 'tableName', label: 'Table', editable: true, visible: true },
      { id: 'columns', label: 'Columns', editable: true, visible: true },
      { id: 'permissions', label: 'Permissions', editable: true, visible: false },
      { id: 'grantPermissions', label: 'Grant Permissions', editable: true, visible: false },
    ]
  }
];



export const PAGE_SELECTOR_OPTIONS = [
  { value: 10, label: '10 Shares' },
  { value: 30, label: '30 Shares' },
  { value: 50, label: '50 Shares' }
];

export const PROPERTY_FILTERING_OPTIONS = [
  { propertyLabel: 'DataLake Principal', propertyKey: 'principal', groupValuesLabel: 'DataLake Principal values', values: [] },
  { propertyLabel: 'Catalog Id', propertyKey: 'catalogId', groupValuesLabel: 'Catalog Id values', values: [] },
  { propertyLabel: 'Database', propertyKey: 'databaseName', groupValuesLabel: 'Database values', values: [] },
  { propertyLabel: 'Table', propertyKey: 'tableName', groupValuesLabel: 'Table values', values: [] },
  { propertyLabel: 'Columns', propertyKey: 'columns', groupValuesLabel: 'Columns values', values: [] },
  { propertyLabel: 'Permissions', propertyKey: 'permissions', groupValuesLabel: 'Permission values', values: [] },
  { propertyLabel: 'Grant Permissions', propertyKey: 'grantPermissions', groupValuesLabel: 'Grant permission values', values: [] },
];

export const PROPERTY_FILTERING_I18N_CONSTANTS = {
  placeholder: 'Filter Lake Formation Shares by property or value',
  operationAndText: 'and',
  operationNotAndText: 'and not',
  operationOrText: 'or',
  operationNotOrText: 'or not',
  clearFiltersText: 'Clear filters',
  groupPropertiesText: 'Properties',
  groupValuesText: 'Values',
  filteringEmpty: 'No Lake Formation Shares found',
  filteringLabel: 'Filter Shares',
  filteringStatusText: 'Loading Lake Formation Shares'
};