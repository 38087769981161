import * as React from 'react';
import { Component } from 'react';
import {
  AttributeEditor,
  Box,
  Button,
  ColumnLayout,
  Container,
  Flashbar,
  FlashbarProps,
  Header,
  Input,
  Modal,
  NonCancelableCustomEvent,
  SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import {
  editDataLakeRole,
  listDataLakeRoleProperty,
} from '../../../api/permissions';
import * as validate from '../../../commons/validationUtils';
import { BaseChangeDetail } from '@amzn/awsui-components-react-v3/polaris/input/interfaces';

export interface TrustedIAMPrincipalsProps {
  setContentType: any;
  groupInfo: any;
  activeGroup: string;
  username: string;
}

export interface TrustedIAMPrincipalsState {
  approvedPermissions: string[];
  approvedLakeFormationPermissions: string[];
  pendingPermissions: string[];
  trustedEntities: string[];
  trustedEntitiesOld: string[];
  pendingEntities: string[];
  addedEntities: string[];
  removedEntities: string[];
  roleArns: string[];

  editingEntities: boolean;
  modalVisible: boolean;
  noPrincipalsModalVisible: boolean;
  invalidArnsModalVisible: boolean;
  invalidArns: string[];

  redirect: object;
  notifications: FlashbarProps.MessageDefinition[];
  activeGroup: string;
  loadingRoleProperties: boolean;
  loadingEditRoleRequest: boolean;
  failedToGetProperties: boolean;
  failedToUpdateProperties: boolean;
}

export default class TrustedIAMPrincipals extends Component<
  TrustedIAMPrincipalsProps,
  TrustedIAMPrincipalsState
> {
  state = {
    approvedPermissions: [],
    approvedLakeFormationPermissions: [],
    pendingPermissions: [],
    trustedEntities: [],
    trustedEntitiesOld: [],
    pendingEntities: [],
    addedEntities: [],
    removedEntities: [],
    roleArns: [],

    editingEntities: false,
    modalVisible: false,
    noPrincipalsModalVisible: false,
    invalidArnsModalVisible: false,
    invalidArns: [],

    redirect: undefined,
    notifications: [],
    activeGroup: '',
    loadingRoleProperties: true,
    loadingEditRoleRequest: false,
    failedToGetProperties: false,
    failedToUpdateProperties: false,
  };

  onAddButtonClickHandler() {
    this.setState({ trustedEntities: [...this.state.trustedEntities, ''] });
  }

  onRemoveButtonClickHandler({ detail: { itemIndex } }) {
    const trustedEntities = this.state.trustedEntities.slice();
    trustedEntities.splice(itemIndex, 1);
    this.setState({ trustedEntities: trustedEntities });
  }

  getOnInputHandler(
    e: NonCancelableCustomEvent<BaseChangeDetail>,
    index: number,
  ) {
    const {
      detail: { value },
    } = e;
    const trustedEntities = this.state.trustedEntities;
    trustedEntities[index] = value;
    this.setState({ trustedEntities: trustedEntities });
  }

  edit() {
    const trustedEntitiesOld = [...this.state.trustedEntities];
    this.setState({
      editingEntities: true,
      trustedEntitiesOld: trustedEntitiesOld,
    });
  }

  cancel() {
    const trustedEntities = [...this.state.trustedEntitiesOld];
    this.setState({
      editingEntities: false,
      trustedEntities: trustedEntities,
    });
  }

  save() {
    const differences = this.processEdits(
      this.state.trustedEntitiesOld,
      this.state.trustedEntities,
    );

    const addedEntities = differences.adds;
    const removedEntities = differences.removes;
    this.setState({ addedEntities, removedEntities });

    const invalidArns = [];
    addedEntities.forEach((e) => {
      if (!validate.isValidPrincipal(e)) {
        invalidArns.push(e);
      }
    });
    this.setState({ invalidArns: invalidArns });

    // IAM has a rule that a role cannot have 0 principals. Because removes go
    // through immediately and adds require approval, some edits are not possible.
    if (
      this.state.removedEntities.length ===
        this.state.trustedEntitiesOld.length &&
      this.state.removedEntities.length > 0
    ) {
      this.openNoPrincipalsModal();
    } else if (invalidArns.length > 0) {
      this.openInvalidArnsModal();
    } else {
      this.openModal();
    }
  }

  saveButtonDisabled() {
    const differences = this.processEdits(
      this.state.trustedEntitiesOld,
      this.state.trustedEntities,
    );

    if (differences.adds.length === 0 && differences.removes.length === 0) {
      return true;
    }

    let emptyStringExists = false;
    this.state.trustedEntities.forEach((e) => {
      if (e === '') {
        emptyStringExists = true;
        return;
      }
    });

    return emptyStringExists;
  }

  // compares the lists of entities before and after edits
  // and returns list of those added and those removed
  processEdits(trustedEntitiesOld, trustedEntities) {
    const adds = [];
    const removes = [];

    trustedEntities.forEach((e) => {
      if (trustedEntitiesOld.includes(e) == false) {
        adds.push(e);
      }
    });

    trustedEntitiesOld.forEach((e) => {
      if (trustedEntities.includes(e) == false) {
        removes.push(e);
      }
    });

    return {
      adds: adds,
      removes: removes,
    };
  }

  openModal() {
    this.setState({ modalVisible: true });
  }

  closeModal() {
    this.setState({ modalVisible: false, loadingEditRoleRequest: false });
  }

  submitModal = async () => {
    this.setState({ loadingEditRoleRequest: true });
    try {
      await editDataLakeRole({
        groupId: this.props.activeGroup,
        principalsToAdd: this.state.addedEntities,
        principalsToDelete: this.state.removedEntities,
      });
      this.setState({ failedToUpdateProperties: false });
    } catch (err) {
      this.setState({ failedToUpdateProperties: true });
    }

    this.closeModal();
    this.cancel();

    if (this.state.failedToUpdateProperties) {
      this.setState({
        notifications: [
          {
            type: 'error',
            content:
              'Failed to update properties for group: ' +
              this.props.activeGroup,
            dismissible: true,
            onDismiss: () => this.setState({ notifications: [] }),
          },
        ],
      });
    } else {
      this.setState({
        notifications: [
          {
            type: 'success',
            content:
              'Successfully updated properties for group: ' +
              this.props.activeGroup,
            dismissible: true,
            onDismiss: () => this.setState({ notifications: [] }),
          },
        ],
      });
    }
    await this.loadPermissions();
  };

  openNoPrincipalsModal() {
    this.setState({ noPrincipalsModalVisible: true });
  }

  closeNoPrincipalsModal() {
    this.setState({ noPrincipalsModalVisible: false });
  }

  openInvalidArnsModal() {
    this.setState({ invalidArnsModalVisible: true });
  }

  closeInvalidArnsModal() {
    this.setState({ invalidArnsModalVisible: false });
  }

  loadPermissions = async () => {
    this.setState({
      loadingRoleProperties: true,
    });
    let roleProperties;
    try {
      roleProperties = await listDataLakeRoleProperty({
        groupId: this.props.activeGroup,
      });
    } catch (err) {
      this.setState({
        failedToGetProperties: true,
        notifications: [
          {
            type: 'error',
            content:
              'Failed to get properties for group: ' + this.props.activeGroup,
            dismissible: true,
            onDismiss: () => this.setState({ notifications: [] }),
          },
        ],
      });
      return;
    }

    this.setState({
      failedToGetProperties: false,
      approvedPermissions: roleProperties.approvedPermissions,
      pendingPermissions: roleProperties.pendingPermissions,
      trustedEntities: roleProperties.trustedEntities,
      roleArns: roleProperties.roleArns,
      loadingRoleProperties: false,
    });
  };

  componentDidMount = async () => {
    this.onAddButtonClickHandler = this.onAddButtonClickHandler.bind(this);
    this.onRemoveButtonClickHandler =
      this.onRemoveButtonClickHandler.bind(this);
    this.getOnInputHandler = this.getOnInputHandler.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.submitModal = this.submitModal.bind(this);
    this.openNoPrincipalsModal = this.openNoPrincipalsModal.bind(this);
    this.closeNoPrincipalsModal = this.closeNoPrincipalsModal.bind(this);
    this.openInvalidArnsModal = this.openInvalidArnsModal.bind(this);
    this.closeInvalidArnsModal = this.closeInvalidArnsModal.bind(this);
    this.loadPermissions = this.loadPermissions.bind(this);

    this.props.setContentType('table');

    await this.loadPermissions();
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.activeGroup !== this.props.activeGroup) {
      await this.loadPermissions();
    }
  };

  render() {
    return (
      <div>
        <Flashbar items={this.state.notifications} />
        <Modal
          visible={this.state.modalVisible}
          header='Confirm edits'
          onDismiss={() => this.closeModal()}
          footer={
            <Box float='right'>
              <SpaceBetween direction='horizontal' size='xs'>
                <Button
                  variant='link'
                  disabled={this.state.loadingEditRoleRequest}
                  onClick={() => this.closeModal()}
                >
                  No
                </Button>
                <Button
                  variant='primary'
                  loading={this.state.loadingEditRoleRequest}
                  disabled={this.state.loadingEditRoleRequest}
                  onClick={() => this.submitModal()}
                >
                  Yes
                </Button>
              </SpaceBetween>
            </Box>
          }
        >
          {this.state.removedEntities.length > 0 && (
            <>
              The following items will be removed. Changes will take effect
              immediately.
              <ul>
                {this.state.removedEntities.map((e) => (
                  <li>{e}</li>
                ))}
              </ul>
            </>
          )}
          {this.state.addedEntities.length > 0 && (
            <>
              The following items will be added. Changes will not take effect
              until the request has been approved.
              <ul>
                {this.state.addedEntities.map((e) => (
                  <li>{e}</li>
                ))}
              </ul>
            </>
          )}
          Are you sure you want to submit?
        </Modal>
        <Modal
          visible={this.state.noPrincipalsModalVisible}
          header='Error'
          onDismiss={() => this.closeNoPrincipalsModal()}
          footer={
            <Box float='right'>
              <Button
                variant='primary'
                onClick={() => this.closeNoPrincipalsModal()}
              >
                OK
              </Button>
            </Box>
          }
        >
          These edits cannot be submitted because of an IAM policy disallowing
          trust policies with zero principals. First, submit your new principals
          and wait for them to be approved, and then remove the old principals.
        </Modal>
        <Modal
          visible={this.state.invalidArnsModalVisible}
          header='Error'
          onDismiss={() => this.closeInvalidArnsModal()}
          footer={
            <Box float='right'>
              <Button
                variant='primary'
                onClick={() => this.closeInvalidArnsModal()}
              >
                OK
              </Button>
            </Box>
          }
        >
          The following principals do not appear to be valid:
          <ul>
            {this.state.invalidArns.map((e) => (
              <li>{e}</li>
            ))}
          </ul>
        </Modal>
        {this.state.roleArns.length > 0 && (
          <SpaceBetween size='l'>
            <Container
              header={
                <Header
                  variant='h2'
                  actions={
                    <SpaceBetween direction='horizontal' size='xs'>
                      {this.state.editingEntities ? (
                        <>
                          <Button onClick={() => this.cancel()}>Cancel</Button>
                          <Button
                            variant='primary'
                            onClick={() => this.save()}
                            disabled={this.saveButtonDisabled()}
                          >
                            Save
                          </Button>
                        </>
                      ) : (
                        <Button onClick={() => this.edit()}>Edit</Button>
                      )}
                    </SpaceBetween>
                  }
                >
                  Trusted IAM principals
                </Header>
              }
            >
              {this.state.editingEntities ? (
                <AttributeEditor
                  disableAddButton={this.state.trustedEntities.length === 25}
                  empty='There are no trusted entities.'
                  addButtonText='Add'
                  removeButtonText='Remove'
                  items={this.state.trustedEntities}
                  definition={[
                    {
                      label: 'IAM Role',
                      control: (item, index) => (
                        <Input
                          value={item}
                          onChange={(e) => this.getOnInputHandler(e, index)}
                        />
                      ),
                    },
                  ]}
                  onAddButtonClick={this.onAddButtonClickHandler}
                  onRemoveButtonClick={this.onRemoveButtonClickHandler}
                />
              ) : (
                <ColumnLayout columns={1} borders='horizontal'>
                  {this.state.trustedEntities.map((e) => (
                    <div>{e}</div>
                  ))}
                </ColumnLayout>
              )}
            </Container>

            {this.state.pendingEntities.length > 0 && (
              <Container
                header={
                  <Header variant='h2'>
                    Trusted IAM principals pending approval
                  </Header>
                }
              >
                <ColumnLayout columns={1} borders='horizontal'>
                  {this.state.pendingEntities.map((e) => (
                    <div>{e}</div>
                  ))}
                </ColumnLayout>
              </Container>
            )}
          </SpaceBetween>
        )}
      </div>
    );
  }
}
