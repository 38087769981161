import {
  Input,
  Header,
  Container,
  FormField,
  SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import { validateStepOne } from '../utils/validation';
import { iStep } from '../index';
import * as React from 'react';

export const RegisterStep = ({
  request,
  isUpdate,
  validate,
  setRequest,
}: iStep) => {
  const [validated, setValidated] = React.useState(false);
  React.useEffect(() => {
    setValidated(validate);
  }, [validate]);

  return (
    <Container header={<Header variant='h2'>Dataset properties</Header>}>
      <SpaceBetween direction='vertical' size='l'>
        <FormField
          label='Dataset name'
          description='This is the how your customers will refer, discover, and identify your dataset.'
          errorText={
            validated && !validateStepOne(request).TableName
              ? !request.TableName
                ? 'Dataset name is required.'
                : 'Please select a dataset name without spaces.'
              : ''
          }
        >
          <Input
            disabled={isUpdate}
            value={request.TableName}
            onChange={({ detail: { value } }) =>
              setRequest({ ...request, TableName: value, DataSetName: value })
            }
          />
        </FormField>

        <FormField
          label='Database'
          description='The database of origin of your dataset.'
          errorText={
            validated && !validateStepOne(request).DatabaseName
              ? !request.DatabaseName
                ? 'Database name is required.'
                : 'Please select a database name without spaces.'
              : ''
          }
        >
          <Input
            disabled={isUpdate}
            value={request.DatabaseName}
            onChange={({ detail }) =>
              setRequest({ ...request, DatabaseName: detail.value })
            }
          />
        </FormField>

        <FormField
          label={'Catalog ID (AWS Account)'}
          description='The AWS account of origin of your data.'
          errorText={
            validated && !validateStepOne(request).CatalogId
              ? !request.CatalogId
                ? 'Catalog ID is required.'
                : 'Catalog ID should be a 12-digit number.'
              : ''
          }
        >
          <Input
            type='number'
            disabled={isUpdate}
            value={request.CatalogId}
            onChange={({ detail }) =>
              setRequest({ ...request, CatalogId: detail.value })
            }
          />
        </FormField>
      </SpaceBetween>
    </Container>
  );
};
