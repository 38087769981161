import { RegisterDataSetRequest } from 'aws-sdk/clients/tethyscontractservicelambda';
import {
  isValidRole,
  hasNoSpaces,
  isValidAccount,
} from 'src/commons/validationUtils';

const validateSchema = (schema: string): boolean => {
  if (!schema) return false;

  try {
    const { fields } = JSON.parse(schema);
    const columns = fields.map(({ name }) => name);
    if (!columns.length) return false;
  } catch {
    return false;
  }

  return true;
};

export const validateStepOne = (request: RegisterDataSetRequest) => ({
  TableName: request.TableName && hasNoSpaces(request.TableName),
  DatabaseName: request.DatabaseName && hasNoSpaces(request.DatabaseName),
  CatalogId: isValidAccount(request.CatalogId),
});

export const validateStepTwo = (request: RegisterDataSetRequest) => ({
  FieldDelimiter: !!request.DataContract.FileProperties.FieldDelimiter,
});

export const validateStepThree = (request: RegisterDataSetRequest) => ({
  SchemaDefinition: validateSchema(
    request.DataContract.DataProperties.SchemaDefinition,
  ),
});

export const validateStepFour = (request: RegisterDataSetRequest) => ({
  PrimaryKeyColumns:
    !!request.DataContract.DataProperties.PrimaryKeyColumns.length,
  DeDupKeyColumn: request.DataContract.DataProperties.DeDupKeyColumn,
  IAMRole: isValidRole(request.DataContract.ServiceLevelAgreement.IAMRole),
  PublishType: !!request.DataContract.ServiceLevelAgreement.PublishType,
});

export const validatePayload = (request: RegisterDataSetRequest) => ({
  step1: validateStepOne(request),
  step2: validateStepTwo(request),
  step3: validateStepThree(request),
  step4: validateStepFour(request),
});

/*
ARN:
arn:aws:iam::773135466957:role/GlueAndLakeFormationAdmin

Used for testing AWS Schema Registry:
{
  "type": "record",
  "namespace": "ABC_Organization",
  "name": "Employee",
  "fields": [
    {
      "name": "Name",
      "type": "string"
    },
    {
      "name": "Age",
      "type": "int"
    },
    {
      "name": "Address",
      "type": "string"            
    }
  ]
}
*/
