import {useEffect, useState} from "react";
import {
  Button,
  ColumnLayout,
  Container,
  Flashbar,
  Form,
  FormField,
  Header,
  Toggle
} from "@amzn/awsui-components-react-v3";
import * as React from "react";
import {
  getNotificationConfiguration,
  updateNotificationConfiguration
} from "src/api/notifications";
import {scrollUp} from "src/components/utils/navigation";

export interface NotificationConfigurationProps {
  activeGroup: string | undefined;
  username: string | undefined;
  setContentType: any;
}

export const NotificationConfiguration = (props: NotificationConfigurationProps,) => {
  const [blockGroupEmail, setBlockGroupEmail] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    props.setContentType('table');
    initConfiguration();
  }, []);

  const initConfiguration = async () => {
    const notificationConfiguration = await getNotificationConfiguration({
      GroupId: props.activeGroup
    });
    if (notificationConfiguration != null
      && notificationConfiguration.NotificationConfiguration != null
      && notificationConfiguration.NotificationConfiguration.EmailBlocked) {
      setBlockGroupEmail(true);
    }
  }

  const handleNotificationConfigurationUpdate = async () => {
    setButtonLoading(true);
    const response = await updateNotificationConfiguration({
      GroupId: props.activeGroup,
      EmailBlocked: blockGroupEmail
    })
    if (response.Message == "Update Success") {
      setNotifications([
        {
          type: 'success',
          content: `Notification Configuration Update Success`,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }
    setButtonLoading(false);
    scrollUp();
  }

  return (
    <div>
      <Flashbar items={notifications}></Flashbar>
      <Form
        header={
          <Header description='You can update notification configuration here'>
            <h1 className='awsui-util-d-ib'>Notification configuration</h1>
          </Header>
        }
        actions={
          <div>
            <Button
              variant='primary'
              onClick={handleNotificationConfigurationUpdate}
              loading={buttonLoading}
            >
              Update
            </Button>
          </div>
        }
      >
        <Container
          className='custom-screenshot-hide'
          header={<h2>Update Email Configuration</h2>}
        >
          <ColumnLayout>
            <>
              <FormField label='Block receiving email' description='block group email'>
                <Toggle
                  onChange={(e) => setBlockGroupEmail(e.detail.checked)}
                  checked={blockGroupEmail}
                />
              </FormField>
            </>
          </ColumnLayout>
        </Container>
      </Form>
    </div>
  );
}
