import React from 'react';
import { Page } from '../routes';

// Navigation
export const navItemsForProvider = [
  {
    type: 'section',
    text: 'Discover data',
    items: [
      { type: 'link', text: 'Browse datasets', href: Page.BROWSE_DATASETS },
      { type: 'link', text: 'Browse databases', href: Page.BROWSE_DATABASES },
      { type: 'link', text: 'Browse datalogs', href: Page.BROWSE_CATALOGS },
    ],
  },
  {
    type: 'section',
    text: 'Subscribe to data',
    items: [
      { type: 'link', text: 'Connections', href: Page.CONNECTIONS },
      { type: 'link', text: 'Subscriptions', href: Page.SUBSCRIPTIONS },
    ],
  },
  {
    type: 'section',
    text: 'Share data',
    items: [
      { type: 'link', text: 'Import dataset', href: Page.PUBLISH },
      { type: 'link', text: 'Manage datasets', href: Page.PUBLISHER_TABLES },
      {
        type: 'link',
        text: 'Published accounts',
        href: Page.PUBLISHER_ACCOUNTS,
      },
    ],
  },
  {
    type: 'section',
    text: 'Manage permissions',
    items: [
      { type: 'link', text: 'Access requests', href: Page.REQUESTS },
      { type: 'link', text: 'Baselining permissions', href: Page.BASELINING },
      { type: 'link', text: 'Datasets', href: Page.MY_DATASETS },
      { type: 'link', text: 'Groups', href: Page.GROUP },
    ],
  },
  {
    type: 'section',
    text: 'Resources manager',
    items: [
      { type: 'link', text: 'Resource groups', href: Page.RESOURCEGROUPS },
      {
        type: 'link',
        text: 'Bootstrap actions',
        href: Page.BOOTSTRAPACTIONS,
      },
    ],
  },
  //TODO: resolve dependencies issues before allowing access.
  // {
  //   type: 'section',
  //   text: 'Dependencies',
  //   items: [{ type: 'link', text: 'My Dependencies', href: Page.DEPENDENCIES }],
  // },
  { type: 'divider' },
  {
    type: 'link',
    text: 'How-to guide',
    href: 'https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Omni',
    external: true,
  },
  {
    type: 'link',
    text: 'About AWS Data Lake',
    href: 'https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake',
    external: true,
  },
  {
    type: 'link',
    text: 'Feature request',
    href: 'https://sim.amazon.com/issues/create?template=eca3d756-6bd2-43ae-8950-020629c0a94b',
    external: true,
  },
];

export const navItemsForAdmins = [
  {
    type: 'section',
    text: 'Discover data',
    items: [
      { type: 'link', text: 'Browse datasets', href: Page.BROWSE_DATASETS },
      { type: 'link', text: 'Browse databases', href: Page.BROWSE_DATABASES },
      { type: 'link', text: 'Browse catalogs', href: Page.BROWSE_CATALOGS },
    ],
  },
  {
    type: 'section',
    text: 'Subscribe to data',
    items: [
      { type: 'link', text: 'Connections', href: Page.CONNECTIONS },
      { type: 'link', text: 'Subscriptions', href: Page.SUBSCRIPTIONS },
    ],
  },
  {
    type: 'section',
    text: 'Share data',
    items: [
      { type: 'link', text: 'Import dataset', href: Page.PUBLISH },
      {
        type: 'link',
        text: 'Published accounts',
        href: Page.PUBLISHER_ACCOUNTS,
      },
      {
        type: 'link',
        text: 'Manage datasets',
        href: Page.PUBLISHER_TABLES,
      },
    ],
  },
  {
    type: 'section',
    text: 'Manage permissions',
    items: [
      { type: 'link', text: 'Access requests', href: Page.REQUESTS },
      { type: 'link', text: 'Baselining permissions', href: Page.BASELINING },
      { type: 'link', text: 'Datasets', href: Page.MY_DATASETS },
      { type: 'link', text: 'Groups', href: Page.GROUP },
    ],
  },
  //TODO: resolve dependencies issues before allowing access.
  // {
  //   type: 'section',
  //   text: 'Dependencies',
  //   items: [{ type: 'link', text: 'My Dependencies', href: Page.DEPENDENCIES }],
  // },
  {
    type: 'section',
    text: 'Manage resources',
    items: [
      { type: 'link', text: 'Resource groups', href: Page.RESOURCEGROUPS },
      {
        type: 'link',
        text: 'Bootstrap actions',
        href: Page.BOOTSTRAPACTIONS,
      },
    ],
  },
  { type: 'divider' },
  {
    type: 'link',
    text: 'How-to guide',
    href: 'https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Omni',
    external: true,
  },
  {
    type: 'link',
    text: 'About AWS Data Lake',
    href: 'https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake',
    external: true,
  },
  {
    type: 'link',
    text: 'Feature request',
    href: 'https://sim.amazon.com/issues/create?template=eca3d756-6bd2-43ae-8950-020629c0a94b',
    external: true,
  },
];

export const navItemsForTethys = [
  ...navItemsForProvider.filter(({ type }) => type === 'section'),
  ...navItemsForProvider.filter(({ type }) => type !== 'section'),
];

export const navItemsForConsumer = [
  {
    type: 'section',
    text: 'Discover data',

    items: [
      { type: 'link', text: 'Browse datasets', href: Page.BROWSE_DATASETS },
      { type: 'link', text: 'Browse databases', href: Page.BROWSE_DATABASES },
      { type: 'link', text: 'Browse catalogs', href: Page.BROWSE_CATALOGS },
    ],
  },
  {
    type: 'section',
    text: 'Subscribe to Data',
    items: [
      { type: 'link', text: 'Connections', href: Page.CONNECTIONS },
      { type: 'link', text: 'Subscriptions', href: Page.SUBSCRIPTIONS },
    ],
  },
  {
    type: 'section',
    text: 'Manage permissions',
    items: [
      { type: 'link', text: 'Access requests', href: Page.REQUESTS },
      { type: 'link', text: 'Baselining permissions', href: Page.BASELINING },
      { type: 'link', text: 'Datasets', href: Page.MY_DATASETS },
      { type: 'link', text: 'Groups', href: Page.GROUP },
    ],
  },
  {
    type: 'section',
    text: 'Resources manager',
    items: [
      { type: 'link', text: 'Resource groups', href: Page.RESOURCEGROUPS },
      {
        type: 'link',
        text: 'Bootstrap actions',
        href: Page.BOOTSTRAPACTIONS,
      },
    ],
  },
  //TODO: resolve dependencies issues before allowing access.
  // {
  //   type: 'section',
  //   text: 'Dependencies',
  //   items: [{ type: 'link', text: 'My Dependencies', href: Page.DEPENDENCIES }],
  // },
  { type: 'divider' },
  {
    type: 'link',
    text: 'How-to guide',
    href: 'https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Omni',
    external: true,
  },
  {
    type: 'link',
    text: 'About AWS Data Lake',
    href: 'https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake',
    external: true,
  },
  {
    type: 'link',
    text: 'Feature request',
    href: 'https://sim.amazon.com/issues/create?template=eca3d756-6bd2-43ae-8950-020629c0a94b',
    external: true,
  },
];

// Help
export const helpContent = () => (
  <div className='awsui-util-help-panel'>
    <div className='awsui-util-help-panel-header'>
      <h2>Create a new subscription</h2>
    </div>
    <p>
      Subscriptions are a simple way to load various available files in S3 into
      a Redshift cluster without having to create a Load activity. Here you can
      manage current subscriptions or create new ones.
    </p>
    <p>
      To subscribe to a data set, simply search for the source file name in the
      search bar and select the right one from the list of results. Then fill
      out the target Redshift cluster, database, schema, table and an IAM role
      and click "confirm". The page will then display your subscription and
      begin executing the load activity.
    </p>
    <div className='awsui-util-help-panel-footer'>
      <h3>Learn more</h3>
      <ul>
        <li>
          <a href='https://w.amazon.com/bin/view/Hammerstone/Subscriptions#HUserGuide'>
            Getting started
          </a>
        </li>
        <li>
          <a href='https://quip-amazon.com/rfUxA2ebnU99/AWS-DataLake-Subscription-Service'>
            How Subscriptions Work
          </a>
        </li>
      </ul>
    </div>
  </div>
);

// Navigation for new customers
export const navItemsNewCustomers = [
  {
    type: 'section',
    text: 'Onboard',
    items: [
      { type: 'link', text: 'Submit Onboarding Request', href: '/onboarding' },
    ],
  },
  { type: 'divider' },
  {
    type: 'link',
    text: 'How-to guide',
    href: 'https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Website',
    external: true,
  },
  {
    type: 'link',
    text: 'About AWS Data Lake',
    href: 'https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake',
    external: true,
  },
];

export const appLayoutLabels = {
  navigation: 'Side navigation',
  navigationToggle: 'Open side navigation',
  navigationClose: 'Close side navigation',
  tools: 'Help panel',
  toolsToggle: 'Open help panel',
  toolsClose: 'Close help panel',
};

export const activeHref = '/subscription';
