import awsdlhybridcatalogservicelambda, {
  EditDatabaseResponse,
  EditDatabaseRequest,
  EditDataSetResponse,
  EditDataSetRequest,
  GetCatalogRequest,
  GetCatalogResponse,
  GetHybridCatalogDatabasesRequest,
  GetHybridCatalogDatabasesResponse,
  GetDataSetsFromHybridCatalogDatabaseRequest,
  GetDataSetsFromHybridCatalogDatabaseResponse,
  GetDataSetDetailRequest,
  GetDataSetDetailResponse,
  GetDataSetRequest,
  GetDataSetResponse,
  GetDataSourcesResponse,
  GetDataSourceTablesRequest,
  GetDataSourceTablesResponse,
  RegisterCatalogRequest,
  RegisterCatalogResponse,
  RegisterDatabaseRequest,
  RegisterDatabaseResponse,
  RegisterDataSetRequest,
  RegisterDataSetResponse,
  SyncDataSetDetailRequest,
  SyncDataSetDetailResponse,
  GetLatestDataSetDetailRequest,
  GetLatestDataSetDetailResponse,
  GetDataSetLineageRequest,
  GetDataSetLineageResponse,
} from 'aws-sdk/clients/awsdlhybridcatalogservicelambda';
import basicCallWrapper from './client_call';
import { getCatalogConfig } from './config';
import { getCredentialProvider } from './auth';

const config = getCatalogConfig();
const credentials = getCredentialProvider();

const catalogClientConfig: awsdlhybridcatalogservicelambda.ClientConfiguration =
  {
    region: config.region,
    endpoint: config.endpoint,
    credentials: credentials,
  };

const catalog = new awsdlhybridcatalogservicelambda(catalogClientConfig);

export const getDataSetLineage = (input?: GetDataSetLineageRequest) => {
  return basicCallWrapper<GetDataSetLineageRequest, GetDataSetLineageResponse>(
    catalog,
    catalog.getDataSetLineage,
  )(input);
};

export const editDatabase = (input: EditDatabaseRequest) => {
  return basicCallWrapper<EditDatabaseRequest, EditDatabaseResponse>(
    catalog,
    catalog.editDatabase,
  )(input);
};

export const editDataset = (input: EditDataSetRequest) => {
  return basicCallWrapper<EditDataSetRequest, EditDataSetResponse>(
    catalog,
    catalog.editDataSet,
  )(input);
};

export const registerCatalog = (input: RegisterCatalogRequest) => {
  return basicCallWrapper<RegisterCatalogRequest, RegisterCatalogResponse>(
    catalog,
    catalog.registerCatalog,
  )(input);
};

export const registerDatabase = (input: RegisterDatabaseRequest) => {
  return basicCallWrapper<RegisterDatabaseRequest, RegisterDatabaseResponse>(
    catalog,
    catalog.registerDatabase,
  )(input);
};

export const registerDataSets = (input: RegisterDataSetRequest) => {
  return basicCallWrapper<RegisterDataSetRequest, RegisterDataSetResponse>(
    catalog,
    catalog.registerDataSet,
  )(input);
};

export const syncDataSets = (input: SyncDataSetDetailRequest) => {
  return basicCallWrapper<SyncDataSetDetailRequest, SyncDataSetDetailResponse>(
    catalog,
    catalog.syncDataSetDetail,
  )(input);
};

export const listDataSources = () => {
  return basicCallWrapper<object, GetDataSourcesResponse>(
    catalog,
    catalog.getDataSources,
  )({});
};

export const listDataSets = (input?: GetDataSetRequest) => {
  return basicCallWrapper<GetDataSetRequest, GetDataSetResponse>(
    catalog,
    catalog.getDataSet,
  )(input);
};

export const listDatabases = (input?: GetHybridCatalogDatabasesRequest) => {
  return basicCallWrapper<
    GetHybridCatalogDatabasesRequest,
    GetHybridCatalogDatabasesResponse
  >(
    catalog,
    catalog.getHybridCatalogDatabases,
  )(input);
};

export const listCatalogs = (input?: GetCatalogRequest) => {
  return basicCallWrapper<GetCatalogRequest, GetCatalogResponse>(
    catalog,
    catalog.getCatalog,
  )(input);
};

export const listDatasetsForDatabase = (
  input?: GetDataSetsFromHybridCatalogDatabaseRequest,
) => {
  return basicCallWrapper<
    GetDataSetsFromHybridCatalogDatabaseRequest,
    GetDataSetsFromHybridCatalogDatabaseResponse
  >(
    catalog,
    catalog.getDataSetsFromHybridCatalogDatabase,
  )(input);
};

export const dataSetDetail = (input: GetDataSetDetailRequest) => {
  return basicCallWrapper<GetDataSetDetailRequest, GetDataSetDetailResponse>(
    catalog,
    catalog.getDataSetDetail,
  )(input);
};

export const getDataSourceTables = (input: GetDataSourceTablesRequest) => {
  return basicCallWrapper<
    GetDataSourceTablesRequest,
    GetDataSourceTablesResponse
  >(
    catalog,
    catalog.getDataSourceTables,
  )(input);
};

export const getHybridCatalogDatabases = (
  input: GetHybridCatalogDatabasesRequest,
) => {
  return basicCallWrapper<
    GetHybridCatalogDatabasesRequest,
    GetHybridCatalogDatabasesResponse
  >(
    catalog,
    catalog.getHybridCatalogDatabases,
  )(input);
};

export const getDataSetsFromHybridCatalogDatabase = (
  input: GetDataSetsFromHybridCatalogDatabaseRequest,
) => {
  return basicCallWrapper<
    GetDataSetsFromHybridCatalogDatabaseRequest,
    GetDataSetsFromHybridCatalogDatabaseResponse
  >(
    catalog,
    catalog.getDataSetsFromHybridCatalogDatabase,
  )(input);
};

export const getLatestDataSetDetail = (
  input: GetLatestDataSetDetailRequest,
) => {
  return basicCallWrapper<
    GetLatestDataSetDetailRequest,
    GetLatestDataSetDetailResponse
  >(
    catalog,
    catalog.getLatestDataSetDetail,
  )(input);
};
