import * as React from 'react';
import {
  Button,
  ColumnLayout,
  Flashbar,
  Form,
  FormField,
  Container,
  Header,
  Select,
  FlashbarProps,
  SelectProps,
  SpaceBetween,
} from '@amzn/awsui-components-react-v3';

import { Redirect } from 'react-router-dom';
import { dedupe, getDedupeInfo, postTableInfo } from 'src/api/publisher';
import {
  convertDedupeColumnInfoToOptions,
  describeResource,
} from '../../api/publisher';
import { getRegion } from 'src/api/config';
import {
  AttributeDefinition,
  ColumnsFormat,
} from 'aws-sdk/clients/awsdatalakepublisherlambda';
import { createPublisherTableLink } from '../../routes/Paths';

export interface PostDedupeComponentProps {
  setContentType: any;
  activeGroup: string;
  match;
}

export interface PostDedupeComponentState {
  redirect;
  notifications: FlashbarProps.MessageDefinition[];
  buttonLoading: boolean;
  cancelRedirect;
  columns: SelectProps.Option[];
  selectedColumn: SelectProps.Option;
}

export default class PostDedupeComponent extends React.Component<
  PostDedupeComponentProps,
  PostDedupeComponentState
> {
  state = {
    columns: [],
    notifications: [],
    buttonLoading: false,
    redirect: undefined,
    cancelRedirect: undefined,
    selectedColumn: undefined,
  };

  componentDidMount = async () => {
    this.props.setContentType('form');
    this.fetchResourceInfo(this.props.match.params.TableName);
    this.setState({
      cancelRedirect: createPublisherTableLink(
        this.props.match.params.TableId,
        this.props.match.params.TableName,
      ),
    });
  };

  fetchResourceInfo = async (tableName) => {
    console.log('Dedupe page:: Table name', tableName);
    const tableDetails = await postTableInfo({
      GroupId: this.props.activeGroup,
      TableId: this.props.match.params.TableId,
    });
    console.log('Dedupe page:: Table details: ', tableDetails.Tables);
    const resourceInfo = await describeResource({
      GroupId: this.props.activeGroup,
      AwsAccountId: tableDetails.Tables.AwsAccountId,
      PhysicalTableName: tableName,
      Region: getRegion(),
    });
    console.log('Dedupe page:: ResourceInfoResponse: ', resourceInfo);
    const getColumnsInfo = await getDedupeInfo({
      TableId: this.props.match.params.TableId,
      GroupId: this.props.activeGroup,
    });
    console.log('Dedupe page:: GetDedupeInfoResponse: ', getColumnsInfo);
    this.setState({
      columns: convertDedupeColumnInfoToOptions(getColumnsInfo),
    });
  };

  updateSelectedOption = async (event) => {
    this.setState({
      selectedColumn: event.detail.selectedOption,
    });
  };

  handleConfirm = async () => {
    this.setState({ buttonLoading: true });
    console.log('Selected options', this.state.selectedColumn);
    const attributeDefinition: AttributeDefinition = {
      AttributeType: 'String',
      AttributeName: this.state.selectedColumn.label,
    };
    const attributeDefinitionList = [];
    attributeDefinitionList.push(attributeDefinition);
    const columnformat: ColumnsFormat = attributeDefinitionList;
    try {
      const dedupeResponse = await dedupe({
        TableId: this.props.match.params.TableId,
        Columns: columnformat,
        GroupId: this.props.activeGroup,
      });
      this.setState({ buttonLoading: false });
      console.log('Dedupe page:: Dedupe response', dedupeResponse);
      const status = dedupeResponse.Status == 'Success' ? 'success' : 'error';
      const message =
        dedupeResponse.Status == 'Success'
          ? 'Successfully submitted Dedupe column'
          : 'Failed!';
      console.log(message);
      this.setState({
        notifications: [
          {
            type: status,
            content: ` ${message}`,
            dismissible: true,
            onDismiss: () => this.setState({ notifications: [] }),
          },
        ],
      });
    } catch (e) {
      this.setState({
        notifications: [
          {
            type: 'error',
            content: `Failed! ${e.message}`,
            dismissible: true,
            onDismiss: () => this.setState({ notifications: [] }),
          },
        ],
      });
    }
  };

  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }
    return (
      <div>
        <Flashbar items={this.state.notifications} />
        <Form
          header={
            <Header description='Please select dedupe column' variant='h1'>
              Select dedupe column
            </Header>
          }
          actions={
            <SpaceBetween size='s' direction='horizontal'>
              <Button
                variant='link'
                onClick={() =>
                  this.setState({ redirect: this.state.cancelRedirect })
                }
              >
                Cancel
              </Button>
              <Button
                variant='primary'
                disabled={!this.state.selectedColumn}
                loading={this.state.buttonLoading}
                onClick={this.handleConfirm}
              >
                Submit
              </Button>
            </SpaceBetween>
          }
        >
          <Container
            className='custom-screenshot-hide'
            header={<Header variant='h2'>Dedupe Columns</Header>}
          >
            <ColumnLayout>
              <FormField
                label='Columns'
                description='Please select the column that you want to mark as dedupe'
              >
                <Select
                  selectedAriaLabel='Selected'
                  options={this.state.columns}
                  placeholder='Please select the dedupe column'
                  selectedOption={this.state.selectedColumn}
                  onChange={this.updateSelectedOption}
                />
              </FormField>
            </ColumnLayout>
          </Container>
        </Form>
      </div>
    );
  }
}
