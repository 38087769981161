import {
  Box,
	Link,
  Modal,
  Button,
  Header,
  Select,
  Checkbox,
  FormField,
  SpaceBetween,
  ColumnLayout,
} from '@amzn/awsui-components-react-v3';
import { OptionDefinition } from '@amzn/awsui-components-react-v3/polaris/internal/components/option/interfaces';
import React, { useState } from 'react';

interface iTicketModal {
  visible: boolean;
  setVisible(boolean): void;
}

export const TicketModal = ({ visible, setVisible }: iTicketModal) => {
  const [ticketType, setTicketType] = useState<OptionDefinition>();
  const [slackCheckBox, setSlackCheckbox] = useState<boolean>();
  const [simCheckBox, setSimCheckbox] = useState<boolean>();
  const [slaCheckBox, setSlaCheckBox] = useState<boolean>();

  return (
    <Modal
      onDismiss={() => setVisible(false)}
      visible={visible}
      closeAriaLabel='Close modal'
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button variant='link' onClick={() => setVisible(false)}>Cancel</Button>
            <Button variant='primary' disabled>Go to SIM</Button>
          </SpaceBetween>
        </Box>
      }
      header={
        <Header
          variant='h2'
          description='Help us resolve your issue faster by preventing duplicate tickets.'
        >
          Ticket deduplication
        </Header>
      }
    >
      <ColumnLayout>
        <FormField
          label='Select your issue:'
          description='This helps us determine who will resolve the ticket.'
        >
          <Select
            selectedOption={ticketType}
            onChange={({ detail }) => setTicketType(detail.selectedOption)}
            options={[
              { label: 'Missing data', value: '1' },
              { label: 'Data quality error', value: '2' },
              { label: 'Ingestion failure', value: '3' },
            ]}
            selectedAriaLabel='Selected'
          />
        </FormField>

        <FormField
          label='Slack announcement'
          description='Has your issue been communicated via Slack?'
        >
          <Checkbox
            onChange={({ detail }) => setSlackCheckbox(detail.checked)}
            checked={slackCheckBox}
          >
            I confirm there are no recent announcements on <Link href="#">Slack</Link> relevant to my issue.
          </Checkbox>
        </FormField>

        <FormField
          label='Recent tickets'
          description='Is there a ticket already created for your issue?'
        >
          <Checkbox
            onChange={({ detail }) => setSimCheckbox(detail.checked)}
            checked={simCheckBox}
          >
            I confirm there are no recent <Link href="#">tagged tickets</Link> relevant to my issue.
          </Checkbox>
        </FormField>

        <FormField
          label='Service level agreement'
          description='Does the issue represent a breach on the SLA?'
        >
          <Checkbox
            onChange={({ detail }) => setSlaCheckBox(detail.checked)}
            checked={slaCheckBox}
          >
            I confirm the issue is ocurring after the agreed data ingestion SLA: <strong> 8:00am PT </strong>.
          </Checkbox>
        </FormField>
      </ColumnLayout>
    </Modal>
  );
};
