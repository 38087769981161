import React from 'react';
import { Icon } from '@amzn/awsui-components-react-v3';

export const StatusIcon = (status) => {
  switch (status['status']) {
    case 'SUCCEEDED':
      return (
        <div className='awsui-util-status-positive'>
          <Icon name='status-positive' /> Success
        </div>
      );

    case 'ACTIVE':
      return (
        <div className='awsui-util-status-positive'>
          <Icon name='status-positive' /> Active
        </div>
      );

    case 'AVAILABLE':
      return (
        <div className='awsui-util-status-positive'>
          <Icon name='status-positive' /> Associated
        </div>
      );

    case 'Active':
      return (
        <div className='awsui-util-status-positive'>
          <Icon name='status-in-progress' /> Active
        </div>
      );

    case 'FAILED':
      return (
        <div className='awsui-util-status-negative'>
          <Icon name='status-negative' /> Failed
        </div>
      );

    case 'ABORTED':
      return (
        <div className='awsui-util-status-negative'>
          <Icon name='status-negative' /> Aborted
        </div>
      );

    case 'InActive':
      return (
        <div className='awsui-util-status-inactive'>
          <Icon name='status-pending' /> Inactive
        </div>
      );

    case 'TIMEOUT':
      return (
        <div className='awsui-util-status-negative'>
          <Icon name='status-warning' /> Timeout
        </div>
      );

    case 'RUNNING':
      return (
        <div className='awsui-util-status-inactive'>
          <Icon name='status-in-progress' /> Running
        </div>
      );

    default:
      return (
        <div className='awsui-util-status-in-negative'>
          <Icon name='status-warning' /> Unknown
        </div>
      );
  }
};
