import * as React from 'react';
import { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import { DetailsComponent, TableDetailsComponent, Status } from './common';

import {
  Tabs,
  CollectionPreferences,
  CollectionPreferencesProps,
  Pagination,
  PropertyFilter,
  TableProps,
  Table,
  Flashbar,
} from '@amzn/awsui-components-react-v3';
import { useCollection } from '@amzn/awsui-collection-hooks';

import { PageHeader } from './../subscriptions/common';
import {
  getBackFillInfo,
  getPipelineInfo,
  initialDump,
  parseColumns,
  postTableInfo,
} from 'src/api/publisher';
import { CopiableText } from './../catalog/common';
import { Page } from '../../routes/Paths';
import {
  defaultWrapLinesPreference,
  i18nStrings,
  largePageSizePreference,
  paginationLabels,
} from 'src/commons/tables';
import { scrollUp } from '../utils/navigation';

export interface TableDetailProps {
  setContentType: any;
  setActiveTable: any;
  setActiveAccount: any;
  activeGroup: string;
  match: any;
}

const TableDetail = (props: TableDetailProps) => {
  const [tableDetails, setTableDetails] = useState<any>();
  const [tableState, setTableState] = useState('PENDING');
  const [pipelineInfo, setPipelineInfo] = useState<any>();
  const [tableName, setTableName] = useState<any>();
  const [accountID, setAccountID] = useState<any>();
  const [, setExecutionsLoading] = useState(false);
  const [redirect, setRedirect] = useState(undefined);
  const [notifications, setNotifications] = useState([]);
  const [actionLoading] = useState(false);
  const [backfillItems, setBackfillItems] = useState([]);
  const [columnMappingItems, setColumnMappingItems] = useState<any>();

  const [preferences, setPreferences] =
    useState<CollectionPreferencesProps.Preferences>({
      wrapLines: false,
      pageSize: 25,
    });

  const columnMappingDefinition: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'actualColumn',
      header: 'Source column',
      cell: (item) => item.actualColumn,
      minWidth: '200px',
    },
    {
      id: 'mappedColumn',
      header: 'Mapped column',
      cell: (item) => item.mappedColumn,
      minWidth: '200px',
    },
    {
      id: 'type',
      header: 'Column type',
      cell: (item) => item.type,
      minWidth: '200px',
    },
    {
      id: 'keyType',
      header: 'Key type',
      cell: (item) => item.keyType,
      minWidth: '200px',
    },
  ];

  const columnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'ProcessType',
      header: 'Process type',
      cell: (item) => item.ProcessType,
      minWidth: '200px',
    },

    {
      id: 'PreviousPartition',
      header: 'Previous partition',
      cell: (item) => item.PreviousPartition,
      minWidth: '200px',
    },
    {
      id: 'CurrentPartition',
      header: 'Current partition',
      cell: (item) => item.CurrentPartition,
      minWidth: '200px',
    },
    {
      id: 'StartTimestamp',
      header: 'Start timestamp',
      cell: (item) => item.StartTimestamp,
      minWidth: '200px',
      sortingField: 'StartTimestamp',
    },
    {
      id: 'EndTimestamp',
      header: 'End timestamp',
      cell: (item) => item.EndTimestamp,
      minWidth: '200px',
    },
    {
      id: 'Status',
      header: 'Status',
      cell: (item) => item.Status,
      minWidth: '200px',
    },
  ];

  useEffect(() => {
    props.setContentType('table');
    fetchAndRefresh();
  }, []);

  const addNotification = (value) => {
    scrollUp();
    setNotifications([...notifications, value]);
  };

  const fetchAndRefresh = async () => {
    setExecutionsLoading(true);
    const tableInfo = await postTableInfo({
      GroupId: props.activeGroup,
      TableId: props.match.params.TableId,
    });

    const pipelineInfo = await getPipelineInfo({
      GroupId: props.activeGroup,
      TableId: props.match.params.TableId,
    });
    const backfillData = await getBackFillInfo({
      GroupId: props.activeGroup,
      TableId: props.match.params.TableId,
    });
    console.log('Tabledetail page:: Pipeline information: ', pipelineInfo);
    console.log('Tabledetail page:: Table details:  ', tableInfo);
    console.log('Tabledetail page:: Backfill data: ', backfillData);
    const columnMappingObjects = parseColumns(tableInfo.Tables.ColumnMapping);

    setTableDetails(tableInfo.Tables);
    setTableState(tableInfo.Tables.Status);
    setPipelineInfo(pipelineInfo.Pipelines);
    setTableName(tableInfo.Tables.TableName);
    setAccountID(tableInfo.Tables.AwsAccountId);
    setExecutionsLoading(false);
    setBackfillItems(backfillData.Processes);
    setColumnMappingItems(columnMappingObjects);

    console.log(
      'Tabledetail page:: columnMappingObjects: ',
      columnMappingObjects,
    );

    props.setActiveTable(tableName);
    props.setActiveAccount(accountID);
  };

  const mandatoryDataforTableTab = (tableInfo) => {
    const mandatoryData = [];
    mandatoryData.push(
      <CopiableText name='Table name' value={tableInfo?.TableName} />,
    );
    mandatoryData.push(<Status name='Table state' value={tableInfo?.Status} />);
    mandatoryData.push(
      <CopiableText name='Source S3' value={tableInfo?.SourceS3} />,
    );
    mandatoryData.push(
      <CopiableText
        name='Last delta processed'
        value={tableInfo?.LastDeltaProcessed}
      />,
    );
    mandatoryData.push(
      <CopiableText
        name='Last refresh processed'
        value={tableInfo?.LastRefreshProcessed}
      />,
    );
    mandatoryData.push(
      <CopiableText name='Last refresh ID' value={tableInfo?.LastRefreshId} />,
    );
    return mandatoryData;
  };

  const additionalDataforTableTab = (tableInfo) => {
    const additionalData = [];
    additionalData.push(
      <CopiableText
        name='Latest stream ARN'
        value={tableInfo?.LatestStreamArn}
      />,
    );
    additionalData.push(
      <CopiableText
        name='Created timestamp (UTC)'
        value={tableInfo?.CreatedTimeStamp}
      />,
    );
    additionalData.push(
      <CopiableText
        name='Last updated timestamp (UTC)'
        value={tableInfo?.LastUpdatedTimeStamp}
      />,
    );
    additionalData.push(
      <CopiableText name='AWS account ID' value={tableInfo?.AwsAccountId} />,
    );
    additionalData.push(
      <CopiableText name='Region' value={tableInfo?.Region} />,
    );
    additionalData.push(
      <CopiableText
        name='Current refresh ID'
        value={tableInfo?.CurrentRefreshId}
      />,
    );
    additionalData.push(
      <CopiableText
        name='DDB Table Name'
        value={tableInfo?.PhysicalTableName}
      />,
    );

    return additionalData;
  };

  const mandatoryDataforPipelineTab = (pipelineInfo) => {
    const mandatoryData = [];
    mandatoryData.push(
      <CopiableText name='Raw Glue table' value={pipelineInfo?.RawGlueTable} />,
    );
    mandatoryData.push(
      <CopiableText
        name='Processed Glue table'
        value={pipelineInfo?.ProcessedGlueTable}
      />,
    );
    mandatoryData.push(
      <CopiableText
        name='Last process ID'
        value={pipelineInfo?.LastProcessId}
      />,
    );
    mandatoryData.push(
      <CopiableText
        name='Current file list'
        value={pipelineInfo?.CurrentFileList}
      />,
    );
    mandatoryData.push(
      <CopiableText
        name='Previous partition'
        value={pipelineInfo?.PreviousPartition}
      />,
    );
    mandatoryData.push(
      <CopiableText
        name='Current partition'
        value={pipelineInfo?.CurrentPartition}
      />,
    );
    mandatoryData.push(
      <CopiableText name='Processed S3' value={pipelineInfo?.ProcessedS3} />,
    );
    mandatoryData.push(
      <CopiableText
        name='Refresh code file S3'
        value={pipelineInfo?.RefreshCodeFileS3}
      />,
    );
    return mandatoryData;
  };

  const additionalDataforPipelineTab = (pipelineInfo) => {
    const additionalData = [];
    additionalData.push(
      <CopiableText name='Raw S3' value={pipelineInfo?.RawS3} />,
    );
    additionalData.push(
      <CopiableText
        name='Processed partition size'
        value={pipelineInfo?.ProcessedPartitionSize}
      />,
    );
    additionalData.push(
      <CopiableText
        name='Raw partition size'
        value={pipelineInfo?.RawPartitionSize}
      />,
    );
    additionalData.push(
      <CopiableText
        name='Pending file list'
        value={pipelineInfo?.PendingFileList}
      />,
    );
    additionalData.push(
      <CopiableText
        name='Created timestamp'
        value={pipelineInfo?.CreatedTimeStamp}
      />,
    );
    additionalData.push(
      <CopiableText
        name='Last updated timestamp'
        value={pipelineInfo?.LastUpdatedTimeStamp}
      />,
    );
    return additionalData;
  };

  const handleAction = async (e) => {
    console.log(e.detail);
    if (e.detail.id === 'backfill') {
      console.log(e.detail);
      handleBackfill();
    } else if (e.detail.id === 'initialDump') {
      console.log(e.detail);
      await handleInitialDump();
    } else if (e.detail.id === 'dedupe') {
      console.log(e.detail);
      await handleDedupe();
    }
  };

  const handleBackfill = () => {
    let url =
      Page.PUBLISHER_TABLES +
      '/tables/' +
      props.match.params.TableId +
      '/' +
      props.match.params.TableName +
      '/createBackfill';
    console.log('Tabledetail page:: Backfill redirect URL:', url);
    setRedirect(url);
  };

  const handleInitialDump = async () => {
    const initialDumpCall = await initialDump({
      TableId: props.match.params.TableId,
      GroupId: props.activeGroup,
    });

    console.log(initialDumpCall.Message);
    console.log(initialDumpCall.JobRunId);

    addNotification({
      type: 'success',
      content: initialDumpCall.Message,
      dismissible: true,
      onDismiss: () =>
        setNotifications(
          notifications.filter(
            ({ content }) => content !== initialDumpCall.Message,
          ),
        ),
    });
  };

  const handleDedupe = async () => {
    let url =
      Page.PUBLISHER_TABLES +
      '/tables/' +
      props.match.params.TableId +
      '/' +
      props.match.params.TableName +
      '/dedupe';
    console.log('Tabledetail page:: Dedupe redirect URL:', url);

    setRedirect(url);
  };

  const {
    items,
    collectionProps,
    paginationProps,
    propertyFilterProps,
    filteredItemsCount,
  } = useCollection(backfillItems, {
    filtering: {
      empty: (
        <div className='awsui-util-t-c'>
          <div className='awsui-util-pt-s awsui-util-mb-xs'>
            <b>No executions</b>
          </div>
          <p className='awsui-util-mb-s'>No backfill executions found.</p>
        </div>
      ),

      noMatch: (
        <div className='awsui-util-t-c'>
          <div className='awsui-util-pt-s awsui-util-mb-xs'>
            <b>No matches</b>
          </div>
          <p className='awsui-util-mb-s'>We can’t find a match.</p>
        </div>
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties: [
        {
          propertyLabel: 'Process type',
          key: 'ProcessType',
          groupValuesLabel: 'Process type',
        },
        {
          propertyLabel: 'Status',
          key: 'Status',
          groupValuesLabel: 'Status',
        },
      ],
    },
  });

  if (redirect) {
    return <Redirect push to={redirect} />;
  }

  return (
    <>
      <Flashbar items={notifications} />
      {notifications !== [] && <br />}
      <PageHeader
        buttons={[
          {
            text: '',
            icon: 'refresh',
            //              onItemClick: fetchAndRefresh,
          },
          {
            text: 'Actions',
            onItemClick: handleAction,
            items: [
              {
                text: 'Refresh table',
                id: 'initialDump',
                disabled: !(
                  tableState === 'COMPLETED' || tableState === 'FAILED'
                ),
              },
              {
                text: 'Dedupe column',
                id: 'dedupe',
                disabled: !(
                  tableState === 'COMPLETED' || tableState === 'FAILED'
                ),
              },
            ],
            loading: actionLoading,
          },
          {
            text: 'Submit job',
            variant: 'primary',
            onItemClick: handleBackfill,
            disabled: !(tableState === 'COMPLETED' || tableState === 'FAILED'),
          },
        ]}
        header={`${props.match.params.TableName}`}
      />
      <div>
        <Tabs
          tabs={[
            {
              label: 'Table',
              id: 'first',
              content: (
                <TableDetailsComponent
                  mandatoryData={mandatoryDataforTableTab(tableDetails)}
                  additionalData={additionalDataforTableTab(tableDetails)}
                  columnDefinitions={columnMappingDefinition}
                  items={columnMappingItems}
                />
              ),
            },
            {
              label: 'Pipeline',
              id: 'second',
              content: (
                <DetailsComponent
                  mandatoryData={mandatoryDataforPipelineTab(pipelineInfo)}
                  additionalData={additionalDataforPipelineTab(pipelineInfo)}
                />
              ),
            },
            {
              label: 'Executions',
              id: 'third',
              content: (
                <div>
                  <Table
                    {...collectionProps}
                    loadingText='Loading tables...'
                    columnDefinitions={columnDefinitions}
                    items={items}
                    wrapLines={false}
                    resizableColumns={true}
                    header={
                      <h2>
                        Executions
                        <span className='awsui-util-header-counter'>
                          {` (${backfillItems.length})`}
                        </span>
                      </h2>
                    }
                    filter={
                      <PropertyFilter
                        {...propertyFilterProps}
                        i18nStrings={i18nStrings}
                        countText={`${filteredItemsCount} ${
                          filteredItemsCount === 1 ? 'match' : 'matches'
                        }`}
                      />
                    }
                    pagination={
                      <Pagination
                        {...paginationProps}
                        ariaLabels={paginationLabels}
                      />
                    }
                    preferences={
                      <CollectionPreferences
                        title={'Preferences'}
                        confirmLabel={'Confirm'}
                        cancelLabel={'Cancel'}
                        preferences={preferences}
                        onConfirm={({ detail }) => setPreferences(detail)}
                        pageSizePreference={largePageSizePreference}
                        wrapLinesPreference={defaultWrapLinesPreference}
                      />
                    }
                  />
                </div>
              ),
            },
          ]}
        />
      </div>
    </>
  );
};

export default TableDetail;
