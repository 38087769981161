export const WEBSITE_NAME = 'Data Lake Omni';

export const NONE = 'javascript:void(0)';

export const DATA_LAKE_CTI_URL =
  'https://t.corp.amazon.com/create/options?ticket_type=Regular+Ticket&severity=FIVE&category=AWS&type=DataLake&item=Catalog+Issues&group=AWS+Datalake&assignee=aws-datalake-primary';

export const ADMIN_GROUPS = [
  'AWSDL_Services',
  'AWSDataWarehouse',
  'AWS-DW-PRIMARY',
  'AWS-DW-PRIMARY-GLOBAL',
  'AWS-DW-PRIMARY-IAD',
];

// possible values for DataClassification
export const dataClassificationOptions = [
  {
    label: 'Public',
    id: '1',
  },
  {
    label: 'Private',
    id: '2',
  },
  {
    label: 'Restricted',
    id: '3',
  },
];
// possible values for RefreshCadence
export const refreshCadenceOptions = [
  {
    label: 'Hourly',
    id: '1',
  },
  {
    label: 'Daily',
    id: '2',
  },
  {
    label: 'Weekly',
    id: '3',
  },
  {
    label: 'Monthly',
    id: '4',
  },
  {
    label: 'Never',
    id: '5',
  },
];
// possible values for Table Status
export const tableStatusOptions = [
  {
    label: 'Pending deprecation',
    id: '1',
  },
  {
    label: 'Deprecated',
    id: '2',
  },
  {
    label: 'Active',
    id: '3',
  },
];

export const DATACONSUMER = 'DATACONSUMER';

export const DATAPROVIDER = 'DATAPROVIDER';

export const DATAPROVIDER_AND_CONSUMER = 'DATAPROVIDER_AND_DATACONSUMER';

// datasetId split details
export const ACCOUNT_ID_PREFIX = 'A-';
export const DATABASE_NAME_PREFIX = 'DN-';
export const TABLE_NAME_PREFIX = 'TN-';
export const REGION_NAME_PREFIX = 'R-';

// djs job url
export const DJS_JOB_URL_PREFIX = 'https://djs.amazon.com/jobs/';
// Exception Name
export const NOT_FOUND_EXCEPTION = 'NotFoundException';
// oncall url
export const DGS_ONCALL_URL =
  'https://oncall.corp.amazon.com/#/view/aws-datalake-primary/schedule';
