import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { Component } from 'react';
import {ColumnLayout, Container, Header, Spinner} from '@amzn/awsui-components-react-v3';
import { listCatalogs, listDatabases } from '../../api/catalog';
import { getCtiUrlFromCti } from '../../commons/common';
import { PageHeader } from '../subscriptions/common';
import { CopiableText } from './common';
import { LabeledText } from '../resourcesmanager/components';
import BrowseDatabasesTable from './browsedatabases';

export interface CatalogDetailProps {
  setContentType: any;
  setActiveCatalogName: any;
  activeGroup: string;
  match: any;
}

export interface CatalogDetailState {
  loading: boolean;
  catalog: object;
  databases: object[];
  redirect: string;
  contentType: string;
}

export default class CatalogDetail extends Component<
  CatalogDetailProps,
  CatalogDetailState
> {
  state = {
    loading: true,
    catalog: null,
    databases: [],
    redirect: undefined,
    contentType: undefined,
  };

  componentDidMount = async () => {
    this.props.setContentType('table');
    await this.handleRefresh();
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.activeGroup !== this.props.activeGroup) {
      await this.handleRefresh();
    }
  };

  handleRefresh = async () => {
    this.setState({ loading: true });

    try {
      const catalogs = await listCatalogs({
        Filter: {
          CatalogKeyList: [
            {
              CatalogId: this.props.match.params.catalogid,
              Region: this.props.match.params.region,
            },
          ],
        },
      });
      this.setState({
        catalog: catalogs.CatalogInfoList[0],
      });

      // here we pull all databases and filter them by catalog ID and region.
      // this will not scale but should work fine until a GetDatabasesForCatalog API exists.
      const databases = await listDatabases({});
      var databasesInCatalog = [];
      for (const database of databases.DatabaseInfoList) {
        if (
          database.CatalogId == this.state.catalog.CatalogId &&
          database.Region == this.state.catalog.Region
        ) {
          databasesInCatalog.push({
            CatalogId: database.CatalogId,
            DatabaseName: database.DatabaseName,
          });
        }
      }
      this.setState({
        databases: databasesInCatalog,
      });
    } catch (err) {
      console.log(err);
      this.setState({ catalog: null, databases: [] });
    }

    if (this.state.catalog) {
      this.props.setActiveCatalogName(this.state.catalog.Name);
    }

    this.setState({ loading: false });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }
    if (!this.state.catalog && !this.state.loading) {
      return (
        <>
          <h2>Catalog not found</h2>
          The given catalog at {this.props.match.params.catalogid} is not valid,
          or you do not have permission to view it. Please check the URL for
          mistakes and try again.
        </>
      );
    }

    let header;
    if (this.state.catalog?.Name) {
      header = this.state.catalog.Name;
    } else {
      header = <Spinner size='big' />;
    }

    return (
      <>
        <PageHeader buttons={[]} header={header} />
        <Container header={<Header variant='h2'>Catalog details</Header>}>
          <ColumnLayout columns={3} borders='horizontal'>
            <>
              <CopiableText
                name={'Catalog name'}
                value={this.state.catalog?.Name}
              />
              <CopiableText
                name={'Description'}
                value={this.state.catalog?.Description}
              />
              <CopiableText
                name={'Region'}
                value={this.state.catalog?.Region}
              />
              <CopiableText
                name={'Catalog ID'}
                value={this.state.catalog?.CatalogId}
              />
              <CopiableText
                name={'Owner'}
                value={this.state.catalog?.Owner}
              />
              <LabeledText
                label='CTI'
                value={this.state.catalog?.CTI}
                url={getCtiUrlFromCti(this.state.catalog?.CTI)}
                copiable={true}
              />
            </>
          </ColumnLayout>


        </Container>
        <br/>

        <BrowseDatabasesTable
          setContentType={(type) => this.setState({ contentType: type })}
          title='Databases'
          filter={this.state.databases}
          loading={this.state.loading}
          doNotLinkCatalogs={true}
        />
      </>
    );
  }
}
